import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: grid;
  grid-template: 1/1;
`;

const PageBody = styled.div`
  grid-area: 1/1;

  transition: all 0.3s 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  transition-property: opacity, transform;

  /* It looks like when something is hidden then it loses the display:block, 
    so we force it to keep it 
    
    Also, when we import bootstrap/dist/css/bootstrap.min.css, something breaks. 
    fix is to set !important
    */

  display: block !important;

  &[hidden] {
    transition-delay: 0s;
    opacity: 0;
    transform: scale(0.95);
  }
`;

interface PageProps {
  path: string;
  children: () => ReactNode;
  exact?: boolean;
  id?: string;
}

// Inspiration for this technique came from : https://dev.to/joostkiens/til-a-better-way-to-handle-in-out-transitions-2bc1
export function Page(props: PageProps) {
  /**
   * Lifecycle of a page
   * - Born hidden, when first matches
   * - 50ms later, hidden is removed
   *
   * - When it no longer matches, remove the hidden tag
   * - 300ms later, we stop rendering it
   */

  const location = useLocation();

  function isMatch() {
    if (props.exact) {
      return location.pathname == props.path;
    } else {
      return location.pathname.startsWith(props.path);
    }
  }

  const match = isMatch();

  const [render, setRender] = useState(false);
  const [hidden, setHidden] = useState(true);

  const [clearRenderTimeout, setClearRenderTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const [clearHiddenTimeout, setClearHiddenTimeout] = useState<
    NodeJS.Timeout | undefined
  >();

  // console.log(`Rendering page`, {
  //     path: props.path,
  //     matchInfo,
  //     match,
  //     render,
  //     hidden
  // });

  useEffect(
    function () {
      //console.log(`useEffect match=${match}`);
      if (!match) {
        if (clearHiddenTimeout !== undefined) {
          clearTimeout(clearHiddenTimeout);
        }

        setHidden(true);
        //console.log(` - ${props.path} : setting render timeout`);
        setClearRenderTimeout(
          setTimeout(function () {
            // console.log(
            //     ` - ${props.path} : setting render to false`
            // );
            setRender(false);
          }, 300),
        );
      } else {
        if (clearRenderTimeout !== undefined) {
          //console.log(` - ${props.path} : clearing render timeout`);
          clearTimeout(clearRenderTimeout);
        }
        setRender(true);
        setClearHiddenTimeout(
          setTimeout(function () {
            setHidden(false);
          }, 50),
        );
      }

      return function cleanup() {
        if (clearHiddenTimeout !== undefined) {
          // console.log(
          //     ` - ${props.path} : clean up - clearing hidden timeout`
          // );
          clearTimeout(clearHiddenTimeout);
        }

        if (clearRenderTimeout !== undefined) {
          // console.log(
          //     ` - ${props.path} : clean up - clearing render timeout`
          // );
          clearTimeout(clearRenderTimeout);
        }
      };
    },

    [match],
  );

  if (!render) {
    return <></>;
  }
  return (
    <PageBody id={props.id} hidden={hidden}>
      {props.children()}
    </PageBody>
  );
}
