import Input from "../../../Cargo/Controls/Input";
import { ErrorMessageType } from "../../../Cargo/Validation";

interface CardholderNameProps {
  cardholderName: string;
  onCardholderNameChanged: (_: string) => void;
  errorMessage: ErrorMessageType;
  validated: boolean;
}

function CardholderName(props: CardholderNameProps) {
  return (
    <Input
      type="text"
      required
      name="cardholder_name"
      value={props.cardholderName}
      onChange={props.onCardholderNameChanged}
      width={360}
      errorMessage={props.errorMessage}
      forceValidation={props.validated}
    />
  );
}
export default CardholderName;
