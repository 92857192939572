import LinkButton from "../../../../../Cargo/Controls/LinkButton";
import {
  NormalQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../../Cargo/Controls/QuestionBubble";

interface HandlingUnitsQuestionBubbleProps {
  onAddLineItem: (() => void) | undefined;
}

const HandlingUnitsQuestionBubble: React.FC<
  HandlingUnitsQuestionBubbleProps
> = (props: HandlingUnitsQuestionBubbleProps) => {
  return (
    <NormalQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>Handling Units</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            Select how many of the same sized handling units you have.
          </QuestionBubbleLabel>
          {props.onAddLineItem && (
            <QuestionBubbleLabel>
              If you have multiple of the same type of handling unit, but with
              different dimensions, please{" "}
              <LinkButton regularWeight onClick={props.onAddLineItem}>
                add an additional row
              </LinkButton>{" "}
              for each one.
            </QuestionBubbleLabel>
          )}
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/shipment-contents#handling-units"
    />
  );
};
export default HandlingUnitsQuestionBubble;
