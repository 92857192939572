import Stack from "../../../Cargo/Layout/Stack";
import { ErrorMessage } from "../../../Cargo/Text/Text";
import {
  OpenClaimLineItem,
  Currency,
  FreightClaimType,
  Shipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { DamageClaimLineItemRow } from "./DamageClaimLineItemRow";
import { SubmitClaimSection } from "./SubmitClaimSection";

interface DamageClaimLineItemsSectionProps {
  shipment: Shipment;
  currency: Currency;
  claimLineItems: OpenClaimLineItem[];
  setClaimLineItems: (_: OpenClaimLineItem[]) => void;
  forceValidation: boolean;
}

export function DamageClaimLineItemsSection(
  props: DamageClaimLineItemsSectionProps,
) {
  const { currency, claimLineItems } = props;
  const errorMessageForAllRows = props.claimLineItems.every(
    (o) => o.claimType === FreightClaimType.NoDamageOrLoss,
  )
    ? "No damage or loss has been indicated"
    : undefined;
  return (
    <SubmitClaimSection
      index="2"
      title="Lost or Damaged Line Items"
      description="Please tell us which line items of your shipment suffered damage or loss"
      content={
        <Stack align="left">
          {props.shipment.lineItems.map(function (lineItem, index) {
            return (
              <DamageClaimLineItemRow
                key={index}
                lineItem={lineItem}
                index={index}
                currency={currency}
                claimLineItem={claimLineItems[index]}
                setClaimLineItem={function (updatedClaimLineItem) {
                  claimLineItems[index] = updatedClaimLineItem;
                  // do the ... to force a new array and cause react to rerender
                  props.setClaimLineItems([...claimLineItems]);
                }}
                forceValidation={props.forceValidation}
              />
            );
          })}
          {props.forceValidation && errorMessageForAllRows && (
            <ErrorMessage>{errorMessageForAllRows}</ErrorMessage>
          )}
        </Stack>
      }
    />
  );
}
