import {
  QuestionBubbleHeader,
  QuestionBubbleLabel,
  SmallQuestionBubble,
} from "../../../../../Cargo/Controls/QuestionBubble";

interface ShipmentContentsQuestionBubbleProps {
  handlingUnitType: string | undefined;
  usePlural: boolean;
  preposition: string;
}

const ShipmentContentsQuestionBubble: React.FC<
  ShipmentContentsQuestionBubbleProps
> = (props: ShipmentContentsQuestionBubbleProps) => {
  const handlingUnitType = props.handlingUnitType
    ? props.handlingUnitType.toLowerCase()
    : undefined;
  return (
    <SmallQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>
            {`${props.handlingUnitType || "Shipment"} Content`}
          </QuestionBubbleHeader>
          {props.handlingUnitType ? (
            <>
              <QuestionBubbleLabel>
                {`Enter a accurate description of what is ${props.preposition} each ${handlingUnitType}.`}
              </QuestionBubbleLabel>
            </>
          ) : (
            <QuestionBubbleLabel>
              Enter a description of what you are shipping, and the number of
              pieces that make up the shipment.
            </QuestionBubbleLabel>
          )}

          <QuestionBubbleLabel>
            A vague description can cause delays if your shipment is crossing
            the border.
          </QuestionBubbleLabel>

          <QuestionBubbleLabel>
            Each carrier maintains their own list of prohibited items. If you
            have any doubt if something is suitable to ship please contact us,
            and we can advise.
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/shipment-contents#description"
    />
  );
};
export default ShipmentContentsQuestionBubble;
