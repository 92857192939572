import { useSavedBrokersApi } from "../../apis";
import Colors from "../../Cargo/Colors";
import Button from "../../Cargo/Controls/Button";
import { CircleButton } from "../../Cargo/Controls/CircleButton";
import Icon from "../../Cargo/Icons/Icon";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import useConfirmModal from "../../Cargo/Modal/useConfirmModal";
import PageTitle from "../../Cargo/Text/PageTitle";
import { Heading1, Microcopy } from "../../Cargo/Text/Text";
import { UUID } from "../../Cargo/Types/types";
import {
  Broker,
  PreferredBrokerType,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../Hooks/useOnce";
import { useState } from "react";
import BrokerBox from "./Components/BrokerBox";
import useAddBrokerModal from "./Hooks/useAddBrokerModal";
import useModifyBrokerModal from "./Hooks/useModifyBrokerModal";
import { useSavedBrokersService } from "./Services/SavedBrokersService";

interface AddBrokerButtonProps {
  showAddBrokerModal: () => void;
}

function AddBrokerButton(props: AddBrokerButtonProps) {
  return (
    <Button size="large" onClick={props.showAddBrokerModal}>
      <Icon
        name="plus"
        color={Colors.White}
        size={20}
        solid
        style={{
          position: "relative",
          top: "2px",
          marginRight: "16px",
        }}
      />{" "}
      Add New Customs Broker
    </Button>
  );
}

const BrokersScreen: React.FC = () => {
  const savedBrokersService = useSavedBrokersService();

  const [loading, setLoading] = useState(false);

  const [brokers, setBrokers] = useState<undefined | Array<SavedBroker>>();
  const savedBrokersApi = useSavedBrokersApi();
  const [preferredNorthboundId, setPreferredNorthboundId] = useState<
    UUID | undefined
  >(undefined);
  const [preferredSouthboundId, setPreferredSouthboundId] = useState<
    UUID | undefined
  >(undefined);

  const confirmDelete = useConfirmModal(
    "Confirm delete",
    "Are you sure you want to delete this customs broker?",
  );

  async function loadSavedBrokers() {
    setLoading(true);
    const savedBrokersResponse = await savedBrokersApi.getAllSavedBrokers();
    setBrokers(
      savedBrokersResponse.items.sort((a, b) => {
        return a.broker.businessName.localeCompare(b.broker.businessName);
      }),
    );
    setPreferredNorthboundId(savedBrokersResponse.preferredNorthboundBrokerId);
    setPreferredSouthboundId(savedBrokersResponse.preferredSouthboundBrokerId);

    setLoading(false);
  }

  useOnce(loadSavedBrokers);

  async function onNewBroker(broker: SavedBroker) {
    await savedBrokersService.addBroker(broker);
    await loadSavedBrokers();
  }

  async function onModifyBroker(brokerId: UUID, updatedBroker: Broker) {
    // Need to update address book on the server
    await savedBrokersService.modifyBroker(brokerId, updatedBroker);
    await loadSavedBrokers();
  }

  const showAddBrokerModal = useAddBrokerModal();

  async function onAddBroker() {
    const data = await showAddBrokerModal();
    onNewBroker(data.broker);
    loadSavedBrokers();
  }

  const showModifyBrokerModal = useModifyBrokerModal();

  async function onDelete(savedBrokerId: UUID) {
    const confirmed = await confirmDelete();

    if (confirmed) {
      await savedBrokersService.deleteSavedBroker(savedBrokerId);
      await loadSavedBrokers();
    }
  }

  async function onSelectPreferred(
    savedBrokerId: UUID,
    preferredBrokerType: PreferredBrokerType,
  ) {
    await savedBrokersService.setPreferred(savedBrokerId, preferredBrokerType);
    await loadSavedBrokers();
  }

  return (
    <>
      <PageTitle>Brokers</PageTitle>
      <FullWidthLayout
        header={
          <>
            Customs Brokers
            <span
              style={{
                position: "relative",
                top: "-3px",
                marginLeft: "6px",
              }}
            >
              <CircleButton id="add-new-customs-broker" onClick={onAddBroker} />
            </span>
          </>
        }
        microcopy="Save brokerage info here to speed up booking future shipments."
        loading={loading}
        showEmptyState={brokers?.length === 0}
        emptyState={
          <Stack>
            <Heading1>You have no saved customs brokers</Heading1>
            <Microcopy>
              Save customs brokerage info here to speed up booking future
              shipments.
            </Microcopy>
            <Spacer height={32} />
            <AddBrokerButton showAddBrokerModal={onAddBroker} />
          </Stack>
        }
        content={
          <>
            <>
              {brokers &&
                brokers.map((item) => {
                  const broker = item.broker;

                  return (
                    <>
                      <BrokerBox
                        key={item.savedBrokerId}
                        broker={broker}
                        onModify={async function () {
                          const data = await showModifyBrokerModal(item);
                          if (data !== undefined) {
                            onModifyBroker(data.savedBrokerId, data.broker);
                          }
                        }}
                        showMenu={true}
                        onDelete={function () {
                          onDelete(item.savedBrokerId);
                        }}
                        preferredNorthbound={
                          preferredNorthboundId === item.savedBrokerId
                        }
                        preferredSouthbound={
                          preferredSouthboundId === item.savedBrokerId
                        }
                        onSelectPreferred={function (
                          preferredBrokerType: PreferredBrokerType,
                        ) {
                          onSelectPreferred(
                            item.savedBrokerId,
                            preferredBrokerType,
                          );
                        }}
                      />
                      <Spacer height={32} />
                    </>
                  );
                })}
            </>
          </>
        }
      />
    </>
  );
};

export default BrokersScreen;
