import TrackingTimeline from "../../Tracking/Components/TrackingTimeline";
import { TrackingLine } from "../../Tracking/Helpers/groupTrackingLinesIntoDates";
import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";

import ViewShipmentSection from "./ViewShipmentSection";

interface TrackingNoteProps {
  trackingNote: string;
}

function TrackingNote(props: TrackingNoteProps) {
  if (props.trackingNote === "") {
    return <></>;
  } else {
    return <div>{props.trackingNote}</div>;
  }
}

interface TrackingSectionProps {
  lines: Array<TrackingLine>;
  shipmentState: ShipmentState;
  trackingNote: string;
}
function TrackingSection(props: TrackingSectionProps) {
  return (
    <ViewShipmentSection
      title="Detailed Tracking"
      subtitle="Tracking will automatically be updated as information is provided by the carrier"
      id="tracking-section"
    >
      <TrackingNote trackingNote={props.trackingNote} />
      <TrackingTimeline
        lines={props.lines}
        shipmentState={props.shipmentState}
      />
    </ViewShipmentSection>
  );
}
export default TrackingSection;
