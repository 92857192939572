import { UUID } from "../../Cargo/Types/types";
import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";
import { delay } from "../../Helpers/delay";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useShipmentService } from "../../Services/ShipmentService";
import BookingInProgress from "./Components/BookingInProgress";

interface BookShipmentProcessingScreenProps {
  shipmentId: UUID;
}

export function BookShipmentProcessingScreen(
  props: BookShipmentProcessingScreenProps,
) {
  const shipmentsService = useShipmentService();
  const { shipmentId } = props;
  const navigate = useNavigate();

  // TODO: Handle navigating away
  async function pollForBooking(
    timeAllotmentSecondsRemaining: number,
  ): Promise<boolean> {
    const shipment = await shipmentsService.getShipment(shipmentId);

    if (shipment.shipmentState === ShipmentState.BookingConfirmed) {
      return true;
    } else if (shipment.shipmentState === ShipmentState.BookingFailed) {
      return false;
    } else {
      // Booking hasn't been confirmed, so loop around again

      if (timeAllotmentSecondsRemaining > 0) {
        await delay(1000);
        return await pollForBooking(timeAllotmentSecondsRemaining - 1);
      } else {
        // Ran out of time allotment, so we should show an error message
        return false;
      }
    }
  }

  function onBookingError() {
    navigate(`/book/nearlydone?shipmentId=${shipmentId}`);
  }

  useEffect(function () {
    (async function () {
      try {
        // TODO : Change url to a waiting screen here
        const bookingCompleted = await pollForBooking(90);

        if (bookingCompleted) {
          const wasThisTheFirstShipmentBooked =
            await shipmentsService.hasBookedOnlyOneShipment();

          if (wasThisTheFirstShipmentBooked) {
            navigate(`/booking-success?shipmentId=${shipmentId}`);
          } else {
            navigate(`/view-shipment?shipmentId=${shipmentId}`);
          }
        } else {
          onBookingError();
        }
      } catch (e) {
        console.error(e);
        onBookingError();
      }
    })();
  });

  return <BookingInProgress />;
}
