import NewLocationDetails from "../../BookShipment/Components/Location/NewLocationDetails";
import {
  errorMessagesForPickupLocation,
  warningMessagesForPickupLocation,
} from "../../Locations/Validators/errorMessagesForPickupLocation";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface PickupDetailsPageProp {
  shipment: Shipment;
}

export function PickupDetailsPage(props: PickupDetailsPageProp) {
  const errorMessages = errorMessagesForPickupLocation(
    props.shipment.pickupDate,
    props.shipment.pickupLocation,
  );
  const warningMessages = warningMessagesForPickupLocation(
    props.shipment.pickupLocation,
  );
  return (
    <NewLocationDetails
      locationErrorMessages={errorMessages}
      locationWarningMessages={warningMessages}
      context={LocationContext.Pickup}
      setLocation={() => {}}
      location={props.shipment.pickupLocation}
      forceValidation={false}
      disablePostalCodeEntry={false}
      pickupDate={props.shipment.pickupDate}
    />
  );
}
