import YellowExclamationAnimatedIcon from "../../../Cargo/Icons/YellowExclamationAnimatedIcon";
import { ReactNode } from "react";

interface BookShipmentWarningMessagesProps {
  warnings: ReactNode[];
}

export function BookShipmentWarningMessages({
  warnings,
}: BookShipmentWarningMessagesProps) {
  return (
    <div
      style={{
        columnGap: "10px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <YellowExclamationAnimatedIcon />
      {warnings.map((w, i) => (
        <div key={i} style={{ marginBlock: "2px" }}>
          {w}
        </div>
      ))}
    </div>
  );
}
