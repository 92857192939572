import { AddContactContactType } from "../../Contacts/Types/contactTypes";
import { AddLocationAddressType } from "../../Locations/Types/locationTypes";
import {
  Address,
  Contact,
} from "@freightsimple/generated-dashboard-openapi-client";

export type AddBrokerAddressType = Partial<Address> | Address;

export type AddBrokerBrokerType = {
  businessName?: string;
  address: AddLocationAddressType | Address;
  contact: AddContactContactType | Contact;
};

export function emptyBrokerInfo(): AddBrokerBrokerType {
  return {
    businessName: undefined,
    address: {},
    contact: {},
  };
}
