import Colors from "../Colors";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

const TableWrapper = styled.div`
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid #d4d8f3; // inbetween VeryLightBlue and VeryVeryLightBlue
  overflow: hidden;
`;

const TableBody = styled.div`
  display: table;
  padding-bottom: 16px;
  width: 100%;
`;

interface TableProps {
  children: ReactNode;
  style?: CSSProperties;
}

export function Table(props: TableProps) {
  return (
    <TableWrapper style={props.style}>
      <TableBody>{props.children}</TableBody>
    </TableWrapper>
  );
}

export const TableHeaderRow = styled.div`
  display: table-row;
  background-color: ${Colors.VeryVeryLightBlue};
  background-clip: border-box;
  color: ${Colors.NormalText};
  margin-bottom: 16px;
  font-weight: var(--nhu-font-weight-bold);
`;
export const TableRow = styled.div`
  display: table-row;
`;
export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 8px 0px 8px 0px;

  &:first-of-type {
    padding-left: 40px;
  }
`;
export const TableCell = styled.div`
  display: table-cell;
  padding: 10px 0px 10px 0px;

  &:first-of-type {
    padding-left: 40px;
  }

  vertical-align: middle;
`;
