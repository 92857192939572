interface BadgeProps {
  value: string;
}
function Badge(props: BadgeProps) {
  function fontSize() {
    if (props.value.length > 1) {
      return "18";
    } else {
      return "24";
    }
  }
  return (
    <div style={{ width: "48px", height: "48px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <circle
            cx="24"
            cy="24"
            r="23.5"
            fill="#4C62EA"
            stroke="#4C62EA"
          ></circle>
          <text
            fill="#FFF"
            fontFamily="NeueHaasUnicaW1G-Bold, Neue Haas Unica W1G"
            fontSize={fontSize()}
            fontWeight="bold"
          >
            <tspan x="16.5" y="32">
              {props.value}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}
export default Badge;
