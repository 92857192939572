import Button from "../../Cargo/Controls/Button";
import Link from "../../Cargo/Controls/Link";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { Heading1, Microcopy } from "../../Cargo/Text/Text";
import { UUID } from "../../Cargo/Types/types";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShipmentService } from "../../Services/ShipmentService";
import styled from "styled-components";
import DashboardRow from "./Components/DashboardRow";
import { BranchFilterOption } from "./Components/BranchFilterOption";
import { UserDropdownType, useUserDropdown } from "./Hooks/useUserDropdown";

const SectionHeading = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 24px;
  color: var(--freightsimple-color-normal-text);
  margin-top: 16px;
`;

const SectionSubtitle = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 14px;
  color: var(--freightsimple-color-light-text);
`;

export const Subtitle = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  margin: 0 67px 9px 67px;
  color: var(--freightsimple-color-normal-text);
`;

function dashboardRowForShipment(shipment: Shipment, onClick: () => void) {
  const quote = shipment.selectedQuote;

  return (
    <DashboardRow
      key={shipment.shipmentId}
      lineItems={shipment.lineItems}
      shipmentState={shipment.shipmentState}
      pickupLocation={shipment.pickupLocation}
      deliveryLocation={shipment.deliveryLocation}
      pickupDate={shipment.pickupDate}
      pickupHours={shipment.pickupLocation.hours}
      appointmentDate={shipment.appointmentDate}
      expectedDeliveryDate={shipment.expectedDeliveryDate}
      expectedDeliveryHours={shipment.expectedDeliveryHours}
      actualDeliveryDate={shipment.actualDeliveryDate}
      actualDeliveryTime={shipment.actualDeliveryTime}
      carrierIdentifier={quote?.carrierIdentifier}
      onClick={onClick}
      pickupReference={shipment.pickupReferenceNumber}
      deliveryReference={shipment.deliveryReferenceNumber}
      needsCustomsDocs={shipment.needsCustomsDocs}
      manualQuotingOpen={false}
      freightClaims={shipment.hasFreightClaims}
    />
  );
}

interface SectionForShipmentStateProps {
  sectionTitle: string;
  subtitle: ReactNode | undefined;
  shipments: Array<Shipment>;
}

function SectionForShipmentState(props: SectionForShipmentStateProps) {
  const { sectionTitle, subtitle, shipments } = props;
  const navigate = useNavigate();

  function onClick(shipmentId: UUID) {
    const url = `/view-shipment?shipmentId=${shipmentId}`;
    navigate(url);
  }

  if (shipments.length === 0) {
    return <></>;
  }

  return (
    <>
      <SectionHeading>{sectionTitle}</SectionHeading>
      {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
      <Spacer height={8} />
      {shipments.map((s) => {
        return dashboardRowForShipment(s, function () {
          onClick(s.shipmentId);
        });
      })}
    </>
  );
}

function AddShipmentButton() {
  const navigate = useNavigate();

  function onClick() {
    navigate("/book/details");
  }

  return (
    <Button size="large" onClick={onClick}>
      Get Instant Quotes
    </Button>
  );
}

const DashboardScreen: React.FC = () => {
  const shipmentsService = useShipmentService();
  const [bookedShipments, setBookedShipments] = useState<Array<Shipment>>([]);
  const [loading, setLoading] = useState(false);

  const {
    usersLoading,
    element: userDropdownElement,
    filterByUserId,
    filterByBranchId,
  } = useUserDropdown(UserDropdownType.Booking);

  async function loadShipments() {
    setLoading(true);
    const response = await shipmentsService.getShipments();
    setBookedShipments(response.booked);
    setLoading(false);
  }

  const filteredShipments = bookedShipments
    .filter(function (shipment) {
      if (shipment.bookedBy === undefined) {
        return true;
      }

      if (filterByUserId === undefined || filterByUserId === "") {
        return true;
      }

      return shipment.bookedBy == filterByUserId;
    })
    .filter(function (shipment) {
      if (filterByBranchId === undefined || filterByBranchId === "") {
        return true;
      }

      if (filterByBranchId === BranchFilterOption.Uncoded) {
        return shipment.branchId === undefined;
      }

      return shipment.branchId == filterByBranchId;
    });

  useEffect(
    function () {
      loadShipments();
    },

    [filterByUserId],
  );

  if (loading) {
    return <LoadingShipment />;
  }

  function isDeliveringoTodayOrTomorrow(shipment: Shipment) {
    if (shipment.expectedDeliveryDate === undefined) {
      return false;
    }

    const today = moment().startOf("day");
    const tomorrow = moment().startOf("day").add(1, "day");

    const m = moment(shipment.expectedDeliveryDate);

    return m.isSame(today) || m.isSame(tomorrow);
  }

  function wasShipmentDeliveredWithinLast3Days(shipment: Shipment) {
    const actualDeliveryDate = shipment.actualDeliveryDate;
    if (actualDeliveryDate === undefined) {
      return false;
    }

    const threeDaysAgo = moment().startOf("day").subtract(3, "days");
    if (moment(actualDeliveryDate).isSameOrAfter(threeDaysAgo)) {
      return true;
    } else {
      return false;
    }
  }

  const shipmentsFinishingUp = filteredShipments.filter(
    (s) => s.shipmentState === ShipmentState.BookingFailed,
  );

  const shipmentsOnHold = filteredShipments.filter(
    (s) => s.shipmentState === ShipmentState.OnHold,
  );

  const shipmentsPickingUpSoon = filteredShipments.filter(
    (s) => s.shipmentState === ShipmentState.BookingConfirmed,
  );

  const shipmentsInTransit = filteredShipments.filter(
    (s) =>
      s.shipmentState === ShipmentState.InTransit &&
      !isDeliveringoTodayOrTomorrow(s),
  );

  const shipmentsDeliveringTodayOrTomorrow = filteredShipments.filter(
    (s) =>
      s.shipmentState === ShipmentState.InTransit &&
      isDeliveringoTodayOrTomorrow(s),
  );

  const shipmentsRecentlyDelivered = filteredShipments.filter(
    (s) =>
      s.shipmentState === ShipmentState.Delivered &&
      wasShipmentDeliveredWithinLast3Days(s),
  );

  const noShipments = bookedShipments.length === 0;

  const showDeliveredShipmentsLink = filteredShipments.length > 0;

  const showUncodedOption = bookedShipments.some(
    (s) => s.branchId === undefined,
  );

  return (
    <>
      <FullWidthLayout
        header={<>Active Shipments</>}
        microcopy={
          <>
            Here are the shipments that are most relevant for today. You can get
            quotes for your next shipment <Link to="/book/details">here</Link>.
          </>
        }
        loading={loading || usersLoading}
        showEmptyState={noShipments}
        emptyState={
          <Stack>
            <Heading1>You don&apos;t have any recent shipments</Heading1>
            <Microcopy>
              Shipments that are relevant to you today will show here. Get
              quotes to start booking your next shipment.{" "}
              {showDeliveredShipmentsLink && (
                <>
                  You can view all your delivered shipments{" "}
                  <Link to="/view-all-shipments?shipmentState=delivered&sort=DeliveryDate">
                    here
                  </Link>
                </>
              )}
            </Microcopy>
            <Spacer height={32} />
            <AddShipmentButton />
          </Stack>
        }
        rightContent={userDropdownElement(showUncodedOption)}
        content={
          <>
            <SectionForShipmentState
              sectionTitle={"Finishing up"}
              subtitle={`Preparation of documents is being finalized`}
              shipments={shipmentsFinishingUp}
            />
            <SectionForShipmentState
              sectionTitle={"On Hold"}
              subtitle={undefined}
              shipments={shipmentsOnHold}
            />
            <SectionForShipmentState
              sectionTitle={"Picking up soon"}
              subtitle={undefined}
              shipments={shipmentsPickingUpSoon}
            />
            <SectionForShipmentState
              sectionTitle={"In Transit"}
              subtitle={undefined}
              shipments={shipmentsInTransit}
            />
            <SectionForShipmentState
              sectionTitle={"Delivering Today or Tomorrow"}
              subtitle={undefined}
              shipments={shipmentsDeliveringTodayOrTomorrow}
            />
            <SectionForShipmentState
              sectionTitle={"Recently Delivered"}
              subtitle={
                <>
                  These shipments were delivered in the last 3 days. You can
                  view all your delivered shipments{" "}
                  <Link to="/view-all-shipments?shipmentState=delivered&sort=DeliveryDate">
                    here
                  </Link>
                </>
              }
              shipments={shipmentsRecentlyDelivered}
            />
          </>
        }
      />
    </>
  );
};

export default DashboardScreen;
