import { MomentHolidays } from "@freightsimple/shared";
import { getCountriesOfHoliday } from "@freightsimple/shared";
import { CountryCode } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";

interface HolidayMessageAlertProps {
  date: moment.Moment;
  country?: CountryCode;
}

export function HolidayMessageAlert({
  date,
  country,
}: HolidayMessageAlertProps) {
  let isHolidayDate = MomentHolidays.isHoliday(date);

  if (country) {
    isHolidayDate = MomentHolidays.isHolidayForCountry(date, country);
  }

  if (!isHolidayDate) {
    return <></>;
  }

  const holidays = MomentHolidays.getHolidaysByDate(date);

  return (
    <div style={{ display: "flex", columnGap: "10px" }}>
      <span>{"⚠️"}</span>
      <div>
        {holidays.map((h, i) => (
          <p style={{ margin: 0, padding: 0 }} key={i}>
            {h.description + " in "}{" "}
            {getCountriesOfHoliday(h).join(" and ") + "."}
          </p>
        ))}
      </div>
    </div>
  );
}
