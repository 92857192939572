import { useUsersApi } from "../apis";
import {
  Invitation,
  SendInvitationResponse,
  User,
} from "@freightsimple/generated-dashboard-openapi-client";

interface UserService {
  deleteAccount: () => Promise<void>;
  listUsersForCompany: () => Promise<User[]>;
  listInvitationsForCompany: () => Promise<Invitation[]>;
  revokeInvitation: (_: string) => Promise<void>;
  resendInvitation: (_: string) => Promise<SendInvitationResponse>;
}

export function useUserService(): UserService {
  const api = useUsersApi();
  async function deleteAccount() {
    await api.postUsersDeleteAccount();
    alert("Account deleted");
  }

  async function listUsersForCompany() {
    return await api.postUsersListForCompany();
  }

  async function listInvitationsForCompany() {
    return await api.getUsersListInvitationsForCompany();
  }

  async function revokeInvitation(inviteeEmailAddress: string) {
    return await api.postUsersRevokeInvitation({
      revokeInvitationRequest: {
        inviteeEmailAddress,
      },
    });
  }

  async function resendInvitation(inviteeEmailAddress: string) {
    return await api.postUsersResendInvitation({
      resendInvitationRequest: {
        inviteeEmailAddress,
      },
    });
  }

  return {
    deleteAccount,
    listUsersForCompany,
    listInvitationsForCompany,
    revokeInvitation,
    resendInvitation,
  };
}
