import { useAuthentication } from "../../Authentication/Slices/authenticationSlice";
import { SavedCommodity } from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../../Hooks/useOnce";
import { useState } from "react";
import { useSavedCommoditiesService } from "../Services/SavedCommoditiesService";

export function useSavedCommodities() {
  const { isAuthenticated } = useAuthentication();
  const savedCommoditiesService = useSavedCommoditiesService();
  const [loadingSavedCommodities, setLoadingSavedCommodities] = useState(true);
  const [savedCommodities, setSavedCommodities] = useState<
    Array<SavedCommodity>
  >([]);

  async function loadSavedCommodities() {
    if (!isAuthenticated) {
      setLoadingSavedCommodities(false);
      return;
    }
    setLoadingSavedCommodities(true);
    const response = await savedCommoditiesService.getAllSavedCommodities();

    setSavedCommodities(response);

    setLoadingSavedCommodities(false);
  }

  useOnce(async () => {
    await loadSavedCommodities();
  });

  return {
    savedCommodities,
    loadingSavedCommodities,
  };
}
