import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import { ErrorMessageType } from "../Validation";
import { useState } from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";

interface HoursDropdownProps {
  errorMessage: ErrorMessageType;
  warningMessage?: ErrorMessageType;
  selectedHours: string;
  onHoursChanged: (_: string) => void;
  placeholder: string;
}

function HoursDropdown(props: HoursDropdownProps) {
  const options = [
    { item: "07:00", description: "7:00 AM" },
    { item: "07:30", description: "7:30 AM" },
    { item: "08:00", description: "8:00 AM" },
    { item: "08:30", description: "8:30 AM" },
    { item: "09:00", description: "9:00 AM" },
    { item: "09:30", description: "9:30 AM" },
    { item: "10:00", description: "10:00 AM" },
    { item: "10:30", description: "10:30 AM" },
    { item: "11:00", description: "11:00 AM" },
    { item: "11:30", description: "11:30 AM" },
    { item: "12:00", description: "12:00 PM" },
    { item: "12:30", description: "12:30 PM" },
    { item: "13:00", description: "1:00 PM" },
    { item: "13:30", description: "1:30 PM" },
    { item: "14:00", description: "2:00 PM" },
    { item: "14:30", description: "2:30 PM" },
    { item: "15:00", description: "3:00 PM" },
    { item: "15:30", description: "3:30 PM" },
    { item: "16:00", description: "4:00 PM" },
    { item: "16:30", description: "4:30 PM" },
    { item: "17:00", description: "5:00 PM" },
    { item: "17:30", description: "5:30 PM" },
    { item: "18:00", description: "6:00 PM" },
    { item: "18:30", description: "6:30 PM" },
  ];

  return (
    <Dropdown
      unselectedDescription="Select an option"
      selectedItem={props.selectedHours}
      onOptionSelected={props.onHoursChanged}
      options={options}
      errorMessage={props.errorMessage}
      placeholder={props.placeholder}
      width={110}
    />
  );
}

const InvalidLabel = styled.div`
  color: #ea554c;
  font-size: 12px;
  margin-top: 4px;
`;

const WarningLabel = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

interface DockHoursInputProps {
  openFrom: string;
  openUntil: string;
  setOpenFrom: (_: string) => void;
  setOpenUntil: (_: string) => void;
  errorMessage: ErrorMessageType;
  warningMessage?: ErrorMessageType;
  forceValidation: boolean;
}

function HoursInput(props: DockHoursInputProps) {
  const [validated, setValidated] = useState(false);

  function showErrorMessage() {
    return validated || props.forceValidation;
  }

  function setOpenFrom(hours: string) {
    setValidated(true);
    props.setOpenFrom(hours);
  }

  function setOpenUntil(hours: string) {
    setValidated(true);
    props.setOpenUntil(hours);
  }

  return (
    <div>
      <HorizontalStack>
        <HoursDropdown
          selectedHours={props.openFrom}
          onHoursChanged={setOpenFrom}
          errorMessage={undefined}
          placeholder="Open From"
        />
        <Spacer width={32} />
        <HoursDropdown
          selectedHours={props.openUntil}
          onHoursChanged={setOpenUntil}
          errorMessage={undefined}
          placeholder="Open Until"
        />
      </HorizontalStack>

      <InvalidLabel>{showErrorMessage() && props.errorMessage}</InvalidLabel>
      {props.warningMessage && (
        <>
          <Spacer height={4} />
          <WarningLabel>
            <HorizontalStack verticalAlign="top" style={{ columnGap: "8px" }}>
              <span>⚠️ </span>
              {props.warningMessage}
            </HorizontalStack>
          </WarningLabel>
        </>
      )}
    </div>
  );
}
export default HoursInput;
