import Colors from "../Colors";
import Form from "../Layout/Form";
import { ErrorMessageType } from "../Validation";
import { ReactNode, useEffect, useRef, useState } from "react";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import DropdownButton, {
  DropdownButtonProps,
} from "react-bootstrap/DropdownButton";
import scrollIntoView from "scroll-into-view-if-needed";
import styled from "styled-components";

interface StyledDropdownButtonProps {
  $isInvalid: boolean;
  $menuWidth: string | undefined;
  width: string | undefined;
  $small: boolean;
  $somethingSelected: boolean;
  $maxHeight: string;
}

function fontWeight(small: boolean, somethingSelected: boolean) {
  if (!small) {
    if (somethingSelected) {
      return "var(--nhu-font-weight-bold)";
    } else {
      return "var(--nhu-font-weight-regular)";
    }
  } else {
    if (somethingSelected) {
      return "var(--nhu-font-weight-bold)";
    } else {
      return "var(--nhu-font-weight-regular)";
    }
  }
}

function color(somethingSelected: boolean) {
  return somethingSelected ? "#495057" : "#666";
}

function borderColor(prominent: boolean, isInvalid: boolean) {
  if (isInvalid) {
    return Colors.Red;
  }

  if (prominent === true) {
    return Colors.Blue;
  } else {
    return Colors.LightText;
  }
}

function margin(isInvalid: boolean) {
  if (isInvalid) {
    return "1px";
  } else {
    return "2px";
  }
}

function borderThickness(isInvalid: boolean) {
  if (isInvalid) {
    return "2px";
  } else {
    return "1px";
  }
}

// This is to workaround the runtime error "React does not recognize the 'somethingSelected' prop on a DOM element"
const StyledDropdownButton = styled(
  ({ ...rest }: StyledDropdownButtonProps & DropdownButtonProps) => (
    <DropdownButton {...rest} />
  ),
)<StyledDropdownButtonProps>`
  & > button {
    ${(props) => (props.width ? `width: ${props.width} !important;` : "")}
    font-weight: ${(props) =>
      fontWeight(props.$small, props.$somethingSelected)} !important;
    font-size: ${(props) => (props.$small ? "16px" : "20px")} !important;
    color: ${(props) => color(props.$somethingSelected)} !important;
    background: none !important;
    border-radius: 0 !important;
    border: none !important;

    border-bottom: ${(props) => borderThickness(props.$isInvalid)} solid
      ${(props) => borderColor(false, props.$isInvalid)} !important;
    margin-bottom: ${(props) => margin(props.$isInvalid)} !important;

    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 3px !important;
    padding-top: 9px !important;
    box-shadow: none !important;
    text-align: left !important;
    margin-top: 0 !important;

    position: relative !important;
  }

  &.show > button {
    color: #858585 !important;
    background: none !important;
    border: none !important;
    border-bottom: 2px solid ${(props) => borderColor(false, props.$isInvalid)} !important;
    margin-bottom: 1px !important;
  }

  & > button:after {
    text-align: right !important;
    float: right !important;
    position: relative !important;
    top: 13px !important;

    /* If we are not specifying the width of the dropdown, then position the dropdown arrow with a bit more padding*/
    ${(props) => (props.width ? `` : "margin-left: .6em;")}
  }

  & > button:hover {
    color: #495057 !important;
    background: none !important;
    border: none !important;
    border-bottom: 2px solid ${(props) => borderColor(true, props.$isInvalid)} !important;
    margin-bottom: 2px !important;
    padding-bottom: 2px !important;
  }

  & > button.dropdown-toggle:focus {
    color: #495057 !important;
    background: none !important;
    border: none !important;
    border-bottom: 2px solid ${(props) => borderColor(true, props.$isInvalid)} !important;
    margin-bottom: 2px !important;
    padding-bottom: 2px !important;
  }

  & > .dropdown-menu {
    ${(props) =>
      props.$menuWidth ? `min-width: ${props.$menuWidth}px !important;` : ""}
    max-height: ${(props) => props.$maxHeight};
    overflow: scroll;
  }
`;

const PlaceholderLabel = styled(Form.Label)`
  height: 12px;
  padding: 0;
  position: absolute;
  top: -9px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default <label> margin */
  line-height: 1.5;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  font-size: 12px;
  color: #858585;
`;

interface DropdownContainerProps {
  $showPlaceholder: boolean;
}

const DropdownContainer = styled.div<DropdownContainerProps>`
  position: relative;
  /* 12px difference matches the height of the placeholder element. 2px matches the bottom margin  */
  margin-top: ${(props) => (props.$showPlaceholder ? "14px" : "2px")};
  margin-bottom: 2px;
`;

export interface DropdownItem<T> {
  id?: string;
  item: T | undefined;
  description: ReactNode;
  isHeader?: boolean;
  isDivider?: boolean;
}

interface DropdownProps<T> {
  id?: string;
  name?: string;
  unselectedDescription?: ReactNode;
  selectedItem: T | undefined;
  onOptionSelected: (item: T, index: number) => void;
  options: Array<DropdownItem<T>> | undefined;
  width?: number;
  menuWidth?: number;
  small?: boolean;
  placeholder?: ReactNode;
  errorMessage?: ErrorMessageType;
  warningMessage?: ErrorMessageType;
  infoMessage?: ErrorMessageType;
  forceValidation?: boolean;
  maxDropdownHeight?: string;
  disableValidateOnBlur?: boolean;
  disabled?: boolean;
}

const InvalidLabel = styled.div`
  color: #ea554c;
  font-size: 12px;
  margin-top: 4px;
`;

const WarningLabel = styled.div`
  color: ${Colors.NormalText};
  font-size: 12px;
  margin-top: 4px;
`;

interface DropdownRow<T> {
  index: number;
  o: DropdownItem<T>;
  selectedItem: T;
}

function DropdownRow<T>(props: DropdownRow<T>) {
  const { index, o, selectedItem } = props;

  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (props.selectedItem === o.item && ref.current) {
        scrollIntoView(ref.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
      }
    });
  }, [props.selectedItem]);

  const selectedStyle = {
    backgroundColor: Colors.Blue,
    color: "white",
  };

  if (o.isDivider) {
    return <BootstrapDropdown.Divider />;
  }

  if (o.isHeader) {
    return <BootstrapDropdown.Header>{o.description}</BootstrapDropdown.Header>;
  }

  return (
    <BootstrapDropdown.Item
      // Typing on eventKey seems to be wrongly string|undefined
      // eslint-disable-next-line
      // @ts-ignore
      eventKey={index}
      style={o.item === selectedItem ? selectedStyle : {}}
      ref={ref}
      className={o.item === selectedItem ? "selected-dropdown-row" : undefined}
    >
      {o.description}
    </BootstrapDropdown.Item>
  );
}

function Dropdown<T>(props: DropdownProps<T>) {
  const [validate, setValidate] = useState(false);

  function showError(): boolean {
    return (
      (validate || Boolean(props.forceValidation)) &&
      props.errorMessage !== undefined
    );
  }

  function showWarning() {
    return props.warningMessage && !showError();
  }

  function showInfo() {
    return props.infoMessage && !showWarning() && !showError();
  }

  let title = props.unselectedDescription;

  if (props.selectedItem !== undefined && props.options) {
    const index = props.options.findIndex((o) => o.item === props.selectedItem);

    if (index >= 0) {
      const s = props.options[index];
      title = s.description;
    }
  }

  function onSelect(eventKey: string | null) {
    if (!eventKey) {
      console.error("No event key. Should not be here");
      return;
    }

    const index = parseInt(eventKey);

    if (props.options !== undefined) {
      const item = props.options[index].item;
      if (item !== undefined) {
        props.onOptionSelected(item, index);
      }
    }
  }

  function toPx(value: number | undefined, defaultValue: string) {
    return value ? value.toString() + "px" : defaultValue;
  }

  return (
    <DropdownContainer $showPlaceholder={props.placeholder !== undefined}>
      {props.placeholder && (
        <PlaceholderLabel>{props.placeholder}</PlaceholderLabel>
      )}

      <StyledDropdownButton
        id={props.id ?? "dropdown"}
        title={title || ""}
        disabled={props.disabled}
        onSelect={onSelect}
        $isInvalid={showError()}
        $menuWidth={toPx(props.menuWidth, "349px")}
        width={toPx(props.width, "349px")}
        $small={props.small || false}
        $somethingSelected={props.selectedItem !== undefined}
        onBlur={() => {
          if (props.disableValidateOnBlur) {
            return;
          }

          setValidate(true);
        }}
        $maxHeight={props.maxDropdownHeight || "340px"}
      >
        {props.options &&
          props.options.map((o, index) => {
            return (
              <DropdownRow
                key={index}
                o={o}
                index={index}
                selectedItem={props.selectedItem}
              />
            );
          })}
      </StyledDropdownButton>
      {showError() && <InvalidLabel>{props.errorMessage}</InvalidLabel>}
      {showWarning() && <WarningLabel>⚠️ {props.warningMessage}</WarningLabel>}
      {showInfo() && <WarningLabel>{props.infoMessage}</WarningLabel>}
    </DropdownContainer>
  );
}
export default Dropdown;
