import { useState } from "react";
import { CreateAccountPane } from "./CreateAccountPane";
import { SignInPane } from "./SignInPane";
import { AuthenticateForQuotesModalProps } from "./useAuthenticateForQuotesModal";

export function AuthenticateForQuotesModal(
  props: AuthenticateForQuotesModalProps,
) {
  const [showSignIn, setShowSignIn] = useState(false);
  async function onSignInInstead() {
    setShowSignIn(true);
  }

  async function onCreateAccountInstead() {
    setShowSignIn(false);
  }

  if (showSignIn) {
    return (
      <SignInPane {...props} onCreateAccountInstead={onCreateAccountInstead} />
    );
  } else {
    return <CreateAccountPane {...props} onSignInInstead={onSignInInstead} />;
  }
}
