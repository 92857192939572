import { Hours } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";

export function describeHours(hours: Hours) {
  // eg. convert 08:00 to 8am or 15:30 to 3:30pm
  function describeHour(hour: string) {
    if (hour.endsWith(":00")) {
      return moment(hour, "HH:mm").format("ha");
    } else {
      return moment(hour, "HH:mm").format("h:mma");
    }
  }

  return `${describeHour(hours.openFrom)}-${describeHour(hours.openUntil)}`;
}
