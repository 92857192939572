import { IconName } from "@fortawesome/fontawesome-svg-core";
import Colors from "../Colors";
import Icon from "../Icons/Icon";
import styled from "styled-components";

interface LargeIconButtonProps {
  size?: number;
  isSelected: boolean;
  icon: IconName;
  text: string;
  onClick: () => void;
}

const HoverContainer = styled.div`
  cursor: pointer;
  &:hover {
    filter: brightness(0.97);
  }
`;

export function LargeIconButton(props: LargeIconButtonProps) {
  function colorForFill() {
    if (props.isSelected) {
      return "#F6F7FE";
    } else {
      return "#F6F6F6";
    }
  }

  function colorForStroke() {
    if (props.isSelected) {
      return Colors.Blue;
    } else {
      return "#C5C5C5";
    }
  }

  function colorForIcon() {
    if (props.isSelected) {
      return Colors.Blue;
    } else {
      return "#B4B4B4";
    }
  }

  return (
    <HoverContainer onClick={props.onClick}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size ?? 176}
          height={props.size ?? 176}
          viewBox="0 0 176 176"
        >
          <rect
            x="1"
            y="1"
            rx="20"
            ry="20"
            width="174"
            height="174"
            fill={colorForFill()}
            stroke={colorForStroke()}
            strokeWidth={1}
          />
          <g transform="translate(54, 54) scale(0.4)">
            <Icon
              size={45}
              solid={true}
              color={colorForIcon()}
              name={props.icon}
            />
          </g>
        </svg>
        <div
          style={{
            fontSize: "16px",
            color: Colors.NormalText,
            textAlign: "left",
          }}
        >
          {props.text}
        </div>
      </div>
    </HoverContainer>
  );
}
