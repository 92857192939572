import Input from "../../../../Cargo/Controls/Input";
import PasswordInput, {
  PasswordQuestionBubble,
} from "../../../../Cargo/Controls/PasswordInput";
import DynamicHeightSpacer from "../../../../Cargo/Layout/DynamicSpacer";
import Stack from "../../../../Cargo/Layout/Stack";
import { Label } from "../../../../Cargo/Text/Label";
import {
  AccountType,
  CreateAccountErrorMessages,
} from "../../Hooks/useCreateAccount";

interface CreateAccountPageEmailAndPasswordProps {
  accountType: AccountType | undefined;
  email: string;
  setEmail: (_: string) => void;
  password: string;
  setPassword: (_: string) => void;
  forceValidation: boolean;
  errorMessages: CreateAccountErrorMessages;
}

export function CreateAccountPageEmailAndPassword(
  props: CreateAccountPageEmailAndPasswordProps,
) {
  const {
    accountType,
    email,
    setEmail,
    password,
    setPassword,
    forceValidation,
    errorMessages,
  } = props;

  if (accountType === undefined) {
    return <></>;
  }

  function emailLabel() {
    if (accountType === AccountType.Personal) {
      return "Tell us your email address";
    } else {
      return "Tell us your work email address";
    }
  }

  return (
    <Stack align="left" style={{ width: "100%" }}>
      <Label>{emailLabel()}</Label>
      <Input
        type="text"
        value={email}
        onChange={(value) => setEmail(value)}
        errorMessage={errorMessages.email}
        forceValidation={forceValidation}
        autoComplete="email"
        name="signup_email"
      />
      <DynamicHeightSpacer maxHeight={18} minHeight={4} />
      <Label>
        Choose your password <PasswordQuestionBubble />
      </Label>
      <PasswordInput
        placeholder="Enter your new password"
        value={password}
        onChange={(value) => setPassword(value)}
        width={429}
        errorMessage={errorMessages.password}
        forceValidation={forceValidation}
        autoComplete="new-password"
        name="signup_password"
      />
    </Stack>
  );
}
