// TODO we should generate this file
import locationTypes from "../GeneratedFixtures/LocationTypes.json";
import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";

export interface LocationTypeDescription {
  name: string;
  type: LocationType;
  description: string;
}

export function describeLocationType(locationType: LocationType): string {
  return (
    locationTypes.find((lt) => lt.type == locationType)?.name ?? locationType
  );
}
