import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ReactionType } from "./ReactionType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactionsSliceType } from "./ReactionsSliceType";

function emptyReactionsSlice(): ReactionsSliceType {
  return {
    currentReaction: undefined,
  };
}

export const reactionsSlice = createSlice({
  name: "reactions",
  initialState: emptyReactionsSlice(),
  reducers: {
    showReactionAction(state, action: PayloadAction<ReactionType>) {
      state.currentReaction = action.payload;
    },

    clearReactionAction(state) {
      state.currentReaction = undefined;
    },
  },
});

const { showReactionAction, clearReactionAction } = reactionsSlice.actions;

export function useReaction() {
  const { currentReaction } = useSelector(
    (state: RootState) => state.reactions,
  );
  const dispatch = useDispatch();
  function showReaction(reaction: ReactionType) {
    dispatch(showReactionAction(reaction));
    setTimeout(function () {
      dispatch(clearReactionAction());
    }, 2500);
  }

  return { showReaction, currentReaction };
}
