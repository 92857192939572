import { useDynamicHeight } from "../Layout/useDynamicHeight";
import { InnerLabel } from "./Text";
import { LabelProps } from "./LabelProps";

export function Label(props: LabelProps) {
  const marginBottomHeight = useDynamicHeight(0, 5);
  const marginTopHeight = useDynamicHeight(4, 15);
  return (
    <InnerLabel
      $marginTopHeight={marginTopHeight}
      $marginBottomHeight={marginBottomHeight}
    >
      {props.children}
    </InnerLabel>
  );
}
