import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";

function imagePathForPickup(state: ShipmentState) {
  switch (state) {
    case ShipmentState.Quoted:
      return "/map-pin.png";
    case ShipmentState.QuoteRequested:
      return "/map-pin.png";
    case ShipmentState.BookingConfirmed:
      return "/map-pin-gold.png";
    case ShipmentState.BookingRequested:
      return "/map-pin-gold.png";
    case ShipmentState.BookingFailed:
      return "/map-pin-gray.png";
    case ShipmentState.InTransit:
      return "/map-pin.png";
    case ShipmentState.Delivered:
      return "/map-pin-green.png";
    case ShipmentState.OnHold:
      return "/map-pin-gray.png";
    case ShipmentState.Cancelled:
      return "/map-pin-black.png";
    case ShipmentState.Lost:
      return "/map-pin-black.png";
  }
}

function imagePathForDelivery(state: ShipmentState) {
  switch (state) {
    case ShipmentState.Quoted:
      return "/map-pin.png";
    case ShipmentState.QuoteRequested:
      return "/map-pin.png";
    case ShipmentState.BookingConfirmed:
      return "/map-pin-gray.png";
    case ShipmentState.BookingRequested:
      return "/map-pin-gray.png";
    case ShipmentState.BookingFailed:
      return "/map-pin-gray.png";
    case ShipmentState.OnHold:
      return "/map-pin-gray.png";
    case ShipmentState.InTransit:
      return "/map-pin.png";
    case ShipmentState.Delivered:
      return "/map-pin-green.png";
    case ShipmentState.Cancelled:
      return "/map-pin-black.png";
    case ShipmentState.Lost:
      return "/map-pin-black.png";
  }
}

function markerSuffixForState(state: ShipmentState) {
  switch (state) {
    case ShipmentState.Quoted:
      return "quoted";
    case ShipmentState.QuoteRequested:
      return "quote-requested";
    case ShipmentState.BookingConfirmed:
      return "booking-confirmed";
    case ShipmentState.BookingRequested:
      return "booking-requested";
    case ShipmentState.BookingFailed:
      return "booking-failed";
    case ShipmentState.InTransit:
      return "in-transit";
    case ShipmentState.Delivered:
      return "delivered";
    case ShipmentState.Cancelled:
      return "cancelled";
    case ShipmentState.Lost:
      return "lost";
    default:
      // should not be here
      throw new Error("Invalid state");
  }
}

export function getMarkerName(prefix: string, state: ShipmentState) {
  return `${prefix}-marker-${markerSuffixForState(state)}`;
}

async function loadImage(
  map: mapboxgl.Map,
  markerName: string,
  imagePath: string,
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    map.loadImage(
      imagePath,
      (
        error?: Error | null,
        result?: ImageBitmap | HTMLImageElement | ImageData | null,
      ) => {
        if (error) {
          return reject(error);
        }

        if (!result) {
          return reject(new Error("Missing image"));
        }

        map.addImage(markerName, result);
        resolve();
      },
    );
  });
}

async function loadImageForPickup(map: mapboxgl.Map, state: ShipmentState) {
  await loadImage(
    map,
    getMarkerName("pickup", state),
    imagePathForPickup(state),
  );
}

async function loadImageForDelivery(map: mapboxgl.Map, state: ShipmentState) {
  await loadImage(
    map,
    getMarkerName("delivery", state),
    imagePathForDelivery(state),
  );
}

export async function loadImages(map: mapboxgl.Map) {
  await loadImageForPickup(map, ShipmentState.QuoteRequested);
  await loadImageForPickup(map, ShipmentState.Quoted);
  await loadImageForPickup(map, ShipmentState.BookingRequested);
  await loadImageForPickup(map, ShipmentState.BookingConfirmed);
  await loadImageForPickup(map, ShipmentState.BookingFailed);
  await loadImageForPickup(map, ShipmentState.InTransit);
  await loadImageForPickup(map, ShipmentState.Delivered);

  await loadImageForDelivery(map, ShipmentState.QuoteRequested);
  await loadImageForDelivery(map, ShipmentState.Quoted);
  await loadImageForDelivery(map, ShipmentState.BookingRequested);
  await loadImageForDelivery(map, ShipmentState.BookingConfirmed);
  await loadImageForDelivery(map, ShipmentState.BookingFailed);
  await loadImageForDelivery(map, ShipmentState.InTransit);
  await loadImageForDelivery(map, ShipmentState.Delivered);

  await loadImage(map, "animated-marker-pin", "/map-pin.png");
  await loadImage(map, "animated-marker-arrow", "/map-arrow.png");
  await loadImage(map, "animated-marker-arrow-green", "/map-arrow-green.png");
}
