import Input from "../../../Cargo/Controls/Input";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { isEmptyOrUndefined } from "../../../Helpers/isNotEmptyOrUndefined";

interface CurrencyInputProps {
  id?: string;
  label?: string;
  autoComplete?: string;
  value?: number;
  onChange: (e: number | undefined) => void;
  name?: string;
  enabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  pattern?: string;
  readOnly?: boolean;
  hidden?: boolean;
  width?: number;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
  hideErrorIcon?: boolean;
  labelPadding?: number;
  validateOnTimeoutMs?: number;
  placeholder?: string;
}

function formatCurrency(value: string) {
  return parseFloat(parseFloat(value).toFixed(2));
}

export function ClaimCurrencyInput(props: CurrencyInputProps) {
  const value = props.value?.toString() ?? "";

  function handleChange(input: string) {
    props.onChange(
      isEmptyOrUndefined(input) ? undefined : formatCurrency(input),
    );
  }

  return (
    <Input
      id={props.id}
      label={"Amount"}
      autoComplete={props.autoComplete}
      value={value}
      onChange={handleChange}
      name={props.name}
      enabled={props.enabled}
      autoFocus={props.autoFocus}
      required={props.required}
      readOnly={props.readOnly}
      hidden={props.hidden}
      width={props.width}
      type="number"
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      hideErrorIcon={props.hideErrorIcon}
      labelPadding={props.labelPadding}
      validateOnTimeoutMs={props.validateOnTimeoutMs}
    />
  );
}
