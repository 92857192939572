import { useModal } from "../../../Cargo/Modal/useModal";
import { CreditCard } from "@freightsimple/generated-dashboard-openapi-client";
import { ChangeCreditCardModal } from "./ChangeCreditCardModal";

function useChangeCreditCardModal() {
  const showChangeCreditCardModal = useModal<string>();

  async function show(
    selectedStripePaymentMethodId: string | undefined,
    cards: Array<CreditCard>,
  ) {
    const p = new Promise<string | undefined>((resolve) => {
      showChangeCreditCardModal(
        (done) => {
          return (
            <ChangeCreditCardModal
              selectedStripePaymentMethodId={selectedStripePaymentMethodId}
              cards={cards}
              onDone={done}
            ></ChangeCreditCardModal>
          );
        },
        (data) => {
          resolve(data);
        },
      );
    });

    return p;
  }

  return show;
}

export default useChangeCreditCardModal;
