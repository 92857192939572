import { Slider2 } from "../../../Cargo/Animations/Slider";
import { SliderDirection } from "../../../Cargo/Animations/SliderDirection";
import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import Link from "../../../Cargo/Controls/Link";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import { Stages } from "../../../Cargo/Controls/Stages";
import Logo from "../../../Cargo/Icons/Logo";
import DynamicHeightSpacer from "../../../Cargo/Layout/DynamicSpacer";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ErrorMessage, Legalese } from "../../../Cargo/Text/Text";
import { useState } from "react";
import styled from "styled-components";
import useCreateAccount, { AccountType } from "../Hooks/useCreateAccount";
import { CreateAccountPageCompanyDetails } from "./CreateAccountPages/CreateAccountPageCompanyDetails";
import { CreateAccountPageEmailAndPassword } from "./CreateAccountPages/CreateAccountPageEmailAndPassword";
import { CreateAccountPageNameAndPhoneNumber } from "./CreateAccountPages/CreateAccountPageNameAndPhoneNumber";
import { CreateAccountPageAccountType } from "./CreateAccountPages/CreateAccountPageAccountType";
import { useIsAuthViewHeightToSmall } from "../Hooks/useIsAuthViewHeightTooSmall";

const LoginTitle = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: var(--freightsimple-color-normal-text);
`;
const LoginSubtitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  color: var(--freightsimple-color-light-text);
`;

interface CreateAccountForm2Props {
  onSignInInstead: () => void;
  onSuccess: () => void;
  prefilledEmail?: string | undefined;
  prefilledName?: string | undefined;
  prefilledCode?: string | undefined;
  showCancel?: boolean;
  onCancel?: () => void;
}

enum Pages {
  AccountType = 0,
  EmailAndPassword = 1,
  NameAndPhone = 2,
  CompanyDetails = 3,
}

export function CreateAccountForm2(props: CreateAccountForm2Props) {
  const [page, setPage] = useState<number>(Pages.AccountType);
  const [direction, setDirection] = useState(SliderDirection.Right);
  const [forceValidation, setForceValidation] = useState(false);

  const { prefilledEmail, prefilledName, prefilledCode } = props;

  const {
    accountType,
    setAccountType,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    phoneNumber,
    setPhoneNumber,
    phoneNumberExtension,
    setPhoneNumberExtension,
    companyName,
    setCompanyName,
    companyCurrency,
    setCompanyCurrency,
    shipmentFrequency,
    setShipmentFrequency,
    generalErrorMessage,
    createAccount,
    createAccountInProgress,
    isValid,
    errorMessages,
    isEmailUsed,
    checkEmailInProgress,
  } = useCreateAccount(prefilledEmail, prefilledName, prefilledCode);

  async function onCreateAccount() {
    if (!isValid) {
      setForceValidation(true);
      return;
    }

    const success = await createAccount();

    if (success) {
      props.onSuccess();
    }
  }

  function isCurrentPageValid() {
    if (page == Pages.AccountType) {
      return errorMessages.accountType === undefined;
    }
    if (page == Pages.EmailAndPassword) {
      return (
        errorMessages.email === undefined &&
        errorMessages.password === undefined
      );
    }
    if (page == Pages.NameAndPhone) {
      return (
        errorMessages.name === undefined &&
        errorMessages.phoneNumber === undefined
      );
    }
    if (page == Pages.CompanyDetails) {
      return (
        errorMessages.companyCurrency === undefined &&
        errorMessages.companyName === undefined &&
        errorMessages.shipmentFrequency === undefined
      );
    }

    // should not be here
    throw new Error("Invalid page");
  }

  function nextPage() {
    setDirection(() => SliderDirection.Right);
    setPage((prev) => prev + 1);
  }

  function previousPage() {
    setDirection(() => SliderDirection.Left);
    setPage((prev) => prev - 1);
  }

  async function checkEmailAndNextPage() {
    const isUsed = await isEmailUsed();
    if (!isUsed) {
      nextPage();
    }
  }

  async function handleNextClick() {
    switch (page) {
      case Pages.AccountType:
        nextPage();
        break;
      case Pages.EmailAndPassword:
        await checkEmailAndNextPage();
        break;
      case Pages.NameAndPhone:
        nextPage();
        break;
      case Pages.CompanyDetails:
        onCreateAccount();
        break;
    }
  }

  const isViewHeightTooSmall = useIsAuthViewHeightToSmall();

  return (
    <Stack
      align="left"
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <HorizontalStack align="spread" style={{ width: "100%" }}>
        <Logo />
        <Stack align="right">
          <span
            style={{
              fontSize: "11px",
              color: Colors.LightText,
            }}
          >
            Already have a profile?
          </span>
          <Link
            onClick={props.onSignInInstead}
            style={{
              fontSize: "14px",
              fontWeight: 500,
              position: "relative",
              top: "-2px",
            }}
          >
            Sign in instead
          </Link>
        </Stack>
      </HorizontalStack>
      <DynamicHeightSpacer minHeight={4} maxHeight={64} />
      <LoginTitle
        style={{
          fontSize: isViewHeightTooSmall ? "24px" : "40px",
          lineHeight: isViewHeightTooSmall ? "24px" : "54px",
        }}
      >
        Create Profile
      </LoginTitle>
      <DynamicHeightSpacer minHeight={0} maxHeight={8} />
      <LoginSubtitle>
        It takes under a minute to make your profile and gives you access to the
        best discounts, easy shipment management and billing
      </LoginSubtitle>
      <DynamicHeightSpacer minHeight={4} maxHeight={24} />
      <div style={{ width: "400px" }}>
        <Stages currentStage={page} totalStages={4} />
      </div>
      <DynamicHeightSpacer minHeight={4} maxHeight={40} />
      <Slider2
        direction={direction}
        currentIndex={page}
        style={{ minHeight: isViewHeightTooSmall ? "210px" : "250px" }}
      >
        <CreateAccountPageAccountType
          accountType={accountType}
          setAccountType={setAccountType}
        />
        <CreateAccountPageEmailAndPassword
          accountType={accountType}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          forceValidation={forceValidation}
          errorMessages={errorMessages}
        />
        <CreateAccountPageNameAndPhoneNumber
          name={name}
          setName={setName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberExtension={phoneNumberExtension}
          setPhoneNumberExtension={setPhoneNumberExtension}
          forceValidation={forceValidation}
          errorMessages={errorMessages}
        />
        <CreateAccountPageCompanyDetails
          accountType={accountType}
          isPersonalSelected={accountType === AccountType.Personal}
          companyName={companyName}
          setCompanyName={setCompanyName}
          companyCurrency={companyCurrency}
          setCompanyCurrency={setCompanyCurrency}
          shipmentFrequency={shipmentFrequency}
          setShipmentFrequency={setShipmentFrequency}
          forceValidation={forceValidation}
          errorMessages={errorMessages}
        />
      </Slider2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "120px",
        }}
      >
        {generalErrorMessage && (
          <>
            <ErrorMessage>{generalErrorMessage}</ErrorMessage>
            {!isViewHeightTooSmall && <Spacer height={16} />}
          </>
        )}
        {page === Pages.CompanyDetails && (
          <>
            <Stack align="left">
              <Legalese>
                By creating an account, you agree to our{" "}
                <a href="https://www.freightsimple.com/terms-of-service">
                  terms of service
                </a>{" "}
                and{" "}
                <a href="https://www.freightsimple.com/privacy-policy">
                  privacy policy
                </a>
                .
              </Legalese>
              <Legalese>
                We’ll occasionally send account-related emails.
              </Legalese>
            </Stack>
          </>
        )}
        <Spacer height={16} />
        <HorizontalStack>
          {page !== Pages.AccountType && (
            <>
              <LinkButton onClick={previousPage}>Previous</LinkButton>
              <Spacer width={16} />
            </>
          )}

          {page === Pages.AccountType && props.showCancel && (
            <>
              <LinkButton onClick={props.onCancel}>Cancel</LinkButton>
              <Spacer width={16} />
            </>
          )}

          <Button
            disabled={!isCurrentPageValid()}
            onClick={handleNextClick}
            id="sign_in_button"
            label={
              page === Pages.CompanyDetails
                ? "Create Profile and Get Your Quotes"
                : "Next"
            }
            stackStyle={{ width: "100%" }}
            width="200"
            size="xl"
            textStyle={{
              fontSize: "16px",
              fontWeight: 400,
              marginTop: "6px",
            }}
            loading={createAccountInProgress || checkEmailInProgress}
          />
        </HorizontalStack>
      </div>
    </Stack>
  );
}
