interface TrackPieceProps {
  color: string;
  showCircle: boolean;
  showArrow: boolean;
  connectTop: boolean;
  connectBottom: boolean;
}
function TrackPiece(props: TrackPieceProps) {
  return (
    <svg height="100%" width="32" style={{ marginRight: "24px" }}>
      {props.connectTop && (
        <line
          x1="16"
          y1="0"
          x2="16"
          y2="14"
          stroke={props.color}
          strokeLinecap="square"
          strokeWidth={2}
        ></line>
      )}

      {props.connectBottom && (
        <line
          x1="16"
          y1="14"
          x2="16"
          y2="100%"
          stroke={props.color}
          strokeLinecap="square"
          strokeWidth={2}
        ></line>
      )}

      {props.showCircle && (
        <>
          <circle
            cx="16"
            cy="14"
            r="12"
            stroke={props.color}
            fill={"#ffffff"}
            strokeWidth={2}
          />

          {props.showArrow && (
            <g
              transform="translate(10,7)"
              stroke={props.color}
              strokeLinecap="round"
              strokeWidth="3"
            >
              <line x1="6" y1="1" x2="6" y2="14"></line>
              <line x1="6" y1="0" x2="12" y2="6"></line>
              <line
                x1="0"
                y1="0"
                x2="6"
                y2="6"
                transform="translate(3.000000, 3.000000) rotate(-90.000000) translate(-3.000000, -3.000000) "
              ></line>
            </g>
          )}
        </>
      )}
    </svg>
  );
}
export default TrackPiece;
