import { useRandomSigninBackgroundImage } from "../../../Helpers/randomBackgroundImage";
import { SignInForm } from "../../Authentication/Components/SignInForm";
import { AuthenticationPaneContainer } from "./AuthenticationPaneContainer";
import { SignInPaneProps } from "./useAuthenticateForQuotesModal";

export function SignInPane(props: SignInPaneProps) {
  const { onCreateAccountInstead } = props;
  const backgroundImage = useRandomSigninBackgroundImage();

  function onForgotPassword() {
    window.open("/request-reset-password", "_new");
  }

  return (
    <AuthenticationPaneContainer image={backgroundImage}>
      <SignInForm
        onCreateAccountInstead={onCreateAccountInstead}
        onForgotPassword={onForgotPassword}
        onSuccess={props.onDone}
        redirectAfterSignin={false}
      />
    </AuthenticationPaneContainer>
  );
}
