import { IconName } from "@fortawesome/fontawesome-svg-core";
import Colors from "../../../Cargo/Colors";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ReactNode } from "react";
import styled from "styled-components";
import ViewShipmentSection from "./ViewShipmentSection";

interface NextStepsForDeliverySectionProps {
  deliveringByAppointment: boolean;
  appointmentSet: boolean;
}

interface SimpleStep {
  icon: IconName;
  stepName: ReactNode;
  stepDescription: ReactNode;
}

interface SimpleStepsProps {
  steps: SimpleStep[];
}

const Header = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 20px;
  color: var(--freightsimple-color-normal-text);
`;

const Description = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 14px;
  color: var(--freightsimple-color-normal-text);
  max-width: 300px;
`;

function SimpleSteps(props: SimpleStepsProps) {
  return (
    <HorizontalStack
      verticalAlign="top"
      spacing={16}
      align="spread"
      style={{ marginLeft: "-16px" }}
    >
      {props.steps.map(function (step, index) {
        return (
          <Stack align="left" key={index}>
            <Icon name={step.icon} color={Colors.LightGray} size={32} solid />
            <Spacer height={8} />
            <Header>{step.stepName}</Header>
            <Description>{step.stepDescription}</Description>
          </Stack>
        );
      })}
    </HorizontalStack>
  );
}

function NextStepsForDeliverySection(props: NextStepsForDeliverySectionProps) {
  const steps: SimpleStep[] = [];

  steps.push({
    stepName: "Track your shipment here",
    icon: "truck",
    stepDescription: (
      <>
        No need to track on the carrier's site. FreightSimple always has the
        best information about a shipment
      </>
    ),
  });

  if (props.deliveringByAppointment) {
    steps.push({
      stepName: "Delivery Appointment",
      icon: "clock",
      stepDescription: props.appointmentSet ? (
        <>
          The carrier has informed us that an appointment has been set with the
          delivery contact for this
        </>
      ) : (
        <>
          When the freight is close to delivery, the carrier will call to make
          an appointment for delivery at a convenient time
        </>
      ),
    });
  } else {
    steps.push({
      stepName: "No Delivery Appointment",
      icon: "clock",
      stepDescription: (
        <>This will deliver as soon as possible within the provided hours</>
      ),
    });
  }

  steps.push({
    stepName: "At Delivery",
    icon: "clipboard",
    stepDescription: (
      <>
        When receiving freight, make sure any damage is noted when you sign the
        proof-of-delivery
      </>
    ),
  });

  return (
    <ViewShipmentSection title={undefined} id="next-steps-section">
      <SimpleSteps steps={steps} />
    </ViewShipmentSection>
  );
}
export default NextStepsForDeliverySection;
