import Colors from "../../../Cargo/Colors";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import {
  ErrorMessage,
  Heading3,
  Legalese,
  Microcopy,
} from "../../../Cargo/Text/Text";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ClaimDocuments } from "./ClaimDocuments";

interface DropContainerProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
}

function getColor(props: DropContainerProps) {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#ccc";
}

const DropContainer = styled.div<DropContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 5px;
  border-radius: 20px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 480px;
  margin-bottom: 32px;
`;

interface UploadClaimDocumentsSectionProps {
  id: string;
  type: ClaimDocuments;
  files: Array<File>;
  setFiles: (_: Array<File>) => void;
  forceValidation: boolean;
  errorMessage: ErrorMessageType;
}

export function UploadClaimDocumentsSection(
  props: UploadClaimDocumentsSectionProps,
) {
  const { files, setFiles } = props;

  const onDrop = function (acceptedFiles: Array<File>) {
    setFiles([...files, ...acceptedFiles]);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: {
      "application/pdf": [".pdf"],
      "image/tiff": [".tiff", ".tif"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  function title() {
    switch (props.type) {
      case ClaimDocuments.Documents:
        return "Documents";
      case ClaimDocuments.OriginalInvoice:
        return "Original Invoice";
      case ClaimDocuments.Photos:
        return "Photos";
    }
  }

  function iconName() {
    switch (props.type) {
      case ClaimDocuments.Documents:
        return "file-pdf";
      case ClaimDocuments.OriginalInvoice:
        return "file-pdf";
      case ClaimDocuments.Photos:
        return "file-image";
    }
  }

  return (
    <Stack align="left">
      <Stack align="center">
        <DropContainer
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
          })}
        >
          <input {...getInputProps()} id={props.id} />

          <Stack>
            <Spacer height={16} />
            <Heading3>{title()}</Heading3>
            <Microcopy>
              Drag and drop or click to choose a file to upload
            </Microcopy>
            <Spacer height={16} />
            {files.length === 0 && (
              <>
                <Icon name={iconName()} color="#ccc" size={64} solid />
              </>
            )}

            <Stack align="left">
              {files.map(function (af) {
                return (
                  <HorizontalStack key={af.name}>
                    <Icon
                      name="file-pdf"
                      color={Colors.Blue}
                      size={16}
                      solid
                      style={{ marginRight: "4px" }}
                    />

                    <Legalese>{af.name}</Legalese>
                  </HorizontalStack>
                );
              })}
            </Stack>
            <Spacer height={16} />
            <Legalese>Multiple files allowed</Legalese>
            <Spacer height={16} />
          </Stack>
        </DropContainer>
      </Stack>
      {props.errorMessage && props.forceValidation && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Stack>
  );
}
