import {
  LocationContext,
  Contact,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import { ManageContactsModal } from "./ManageContactsModal";
import { ManageContactsModalFlavour } from "./ManageContactsModalFlavour";

export function useManageContactsModal(
  flavour: ManageContactsModalFlavour,
  locationContext?: LocationContext,
) {
  const showChangeContactModal = useModal<Contact>();

  async function show(savedLocationId: UUID) {
    const p = new Promise<Contact | undefined>((resolve) => {
      showChangeContactModal(
        (done) => {
          return (
            <ManageContactsModal
              savedLocationId={savedLocationId}
              onDone={done}
              onCancel={done}
              flavour={flavour}
              locationContext={locationContext}
            ></ManageContactsModal>
          );
        },
        (data) => {
          resolve(data);
        },
      );
    });

    return p;
  }

  return show;
}
