import { ErrorMessageType } from "../../../Cargo/Validation";

export function errorMessageForRequiredField(
  field: string | undefined,
): ErrorMessageType {
  if (field === undefined || field === "") {
    return "Required";
  }

  return undefined;
}
