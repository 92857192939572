import {
  NormalQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../Cargo/Controls/QuestionBubble";

function DeliveryDeadlineQuestionBubble() {
  return (
    <NormalQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>
            {`Does this freight have a delivery deadline?`}
          </QuestionBubbleHeader>
          <QuestionBubbleLabel>
            Some distribution warehouses impose fines if freight is beyond the
            deadline
          </QuestionBubbleLabel>
          <QuestionBubbleLabel>
            Neither FreightSimple not our carrier partners can guarantee freight
            will arrive in time, but knowing the deadline can help our team plan
            better
          </QuestionBubbleLabel>
          <QuestionBubbleLabel>
            Neither FreightSimple not our carrier partners will be responsible
            for any fines issued by warehouses
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/freight-101/location-types"
    />
  );
}
export default DeliveryDeadlineQuestionBubble;
