import { ErrorMessageType } from "../../../Cargo/Validation";
import { AddLocationLocationType } from "../Types/locationTypes";

export function errorMessageForInsurance(
  addInsuranceToShipment: boolean,
  insuranceAmount: number | undefined,
  pickupLocation: AddLocationLocationType,
  deliveryLocation: AddLocationLocationType,
): ErrorMessageType {
  if (addInsuranceToShipment) {
    if (insuranceAmount === undefined) {
      return `Please enter a value`;
    }

    if (insuranceAmount < 250) {
      return `Too low`;
    }

    // Our account has a $400,000 CAD max, but put it at something lower for now
    if (insuranceAmount > 100000) {
      return `Too high`;
    }

    if (
      pickupLocation.address.stateOrProvinceCode === "PR" ||
      deliveryLocation.address.stateOrProvinceCode === "PR"
    ) {
      return `Sorry, Insurance for Puerto Rico is not supported`;
    }
  }

  return undefined;
}
