export const StripeElementOptions = {
  style: {
    base: {
      fontSize: "20px",
      fontWeight: "600", // bold
      color: "#212529",
      height: "38px",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#bbb",
        fontWeight: "400", // regular
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
