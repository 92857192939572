import { loadStripe } from "@stripe/stripe-js";
import AppRouter from "./AppRouter";
import { getStripeKey } from "./environment";
import { Elements } from "@stripe/react-stripe-js";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "./store";
import { ErrorBoundaryView } from "./Features/Authentication/ErrorHandling/ErrorBoundaryView";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(getStripeKey());

// expose store when run in Cypress
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.store = store;
}

export function App() {
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <CookiesProvider>
          <BrowserRouter>
            <ErrorBoundaryView>
              <AppRouter />
            </ErrorBoundaryView>
          </BrowserRouter>
        </CookiesProvider>
      </Elements>
    </Provider>
  );
}
