import Dropdown from "../../../Cargo/Controls/Dropdown";
import { dropdownDivider } from "../../../Cargo/Controls/dropdownDivider";
import {
  canadianProvinceCodeMap,
  usStateCodeMap,
} from "../../../Helpers/fullStateOrProvinceName";

interface ShipmentStatesDropdownProps {
  stateOrProvinceCode: string | undefined;
  onStateOrProvinceCodeChange: (newValue: string | undefined) => void;
  label: string;
}

function StateProvinceDropdown(props: ShipmentStatesDropdownProps) {
  const options = [
    {
      item: "any-state",
      description: "Any state / province",
    },

    dropdownDivider(),

    {
      item: undefined,
      isHeader: true,
      description: "Canadian Provinces",
    },

    ...Object.entries(canadianProvinceCodeMap).map(function ([key, value]) {
      return {
        item: key,
        description: value,
      };
    }),

    dropdownDivider(),

    {
      item: undefined,
      isHeader: true,
      description: "US States",
    },

    ...Object.entries(usStateCodeMap).map(function ([key, value]) {
      return {
        item: key,
        description: value,
      };
    }),
  ];

  return (
    <Dropdown
      unselectedDescription="Any state / province"
      selectedItem={props.stateOrProvinceCode}
      onOptionSelected={function (newState: string) {
        if (newState === "any-state") {
          props.onStateOrProvinceCodeChange(undefined);
        } else {
          props.onStateOrProvinceCodeChange(newState);
        }
      }}
      options={options}
      width={200}
      errorMessage={undefined}
      forceValidation={false}
      placeholder={props.label}
    />
  );
}
export default StateProvinceDropdown;
