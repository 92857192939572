import Link from "../../../Cargo/Controls/Link";
import { FreightClaim } from "@freightsimple/generated-dashboard-openapi-client";
import { DocumentsSectionItem } from "./DocumentsSection";
import { FreightClaimSectionRow } from "./FreightClaimSectionRow";
import ViewShipmentSection from "./ViewShipmentSection";

interface FreightClaimsSectionProps {
  documents: DocumentsSectionItem[];
  freightClaims: FreightClaim[];
}

export function FreightClaimsSection({
  documents,
  freightClaims,
}: FreightClaimsSectionProps) {
  const singleClaim = freightClaims.length === 1;

  if (freightClaims.length === 0) {
    return null;
  }

  return (
    <ViewShipmentSection
      title="Claims"
      subtitle={
        <>
          {singleClaim ? (
            <>Here is the claim attached to this shipment.</>
          ) : (
            <>Here are all the claims attached to this shipment.</>
          )}{" "}
          To learn more about the claim process, please visit our{" "}
          <Link to="https://help.freightsimple.com/">Help Center</Link>
        </>
      }
      id="claims-section"
    >
      <div style={{ marginTop: "-12px" }}>
        {freightClaims.map((claim, index) => (
          <FreightClaimSectionRow
            key={claim.freightClaimId}
            freightClaim={claim}
            documents={documents}
            last={index === freightClaims.length - 1}
            disableReveal={singleClaim}
          />
        ))}
      </div>
    </ViewShipmentSection>
  );
}
