import { CustomsDocsApi } from "./Apis/CustomsDocsApi";
import { FreightClaimsApi } from "./Apis/FreightClaimsApi";
import { PhotosApi } from "./Apis/PhotosApi";
import { UsersApi2 } from "./Apis/UsersApi2";
import { useAuthentication } from "./Features/Authentication/Slices/authenticationSlice";
import { apiServerUrl } from "./environment";
import {
  Configuration,
  ConfigurationParameters,
  GetStartedApi,
  InvitationsApi,
  InvoicesApi,
  Middleware,
  PaymentMethodsApi,
  PostalCodesApi,
  RequestContext,
  SavedBrokersApi,
  SavedCommoditiesApi,
  SavedLocationsApi,
  ShipmentsApi,
  TrackingApi,
  UsersApi,
} from "@freightsimple/generated-dashboard-openapi-client";
import { v4 as uuidv4 } from "uuid";

function generateW3CTraceParent(): string {
  const version = "00";
  const traceId = uuidv4().replace(/-/g, "");
  const spanId = uuidv4().replace(/-/g, "").slice(0, 16);
  const flags = "01"; // Sampled

  return `${version}-${traceId}-${spanId}-${flags}`;
}

// Create a new type with required basePath
type RequiredBasePathConfig = ConfigurationParameters & {
  basePath: string; // This overrides the optional basePath?: string from ConfigurationParameters
};

export function getConfig(): RequiredBasePathConfig {
  return {
    basePath: apiServerUrl(),
    middleware: [
      {
        pre: async (context: RequestContext): Promise<RequestContext> => {
          const traceParent = generateW3CTraceParent();
          // Initialize headers if they don't exist
          if (!context.init.headers) {
            context.init.headers = {};
          }
          // Use type assertion to handle custom headers
          (context.init.headers as Record<string, string>)["traceparent"] =
            traceParent;
          return context;
        },
      } as Middleware,
    ],
  };
}

export function useShipmentsApi(): ShipmentsApi {
  const { accessToken } = useAuthentication();

  return new ShipmentsApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useTrackingApi(): TrackingApi {
  return new TrackingApi(new Configuration({ ...getConfig() }));
}

export function usePostalCodesApi(): PostalCodesApi {
  return new PostalCodesApi(new Configuration({ ...getConfig() }));
}

export function useUsersApi(): UsersApi {
  const { accessToken } = useAuthentication();

  return new UsersApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useUsersApi2(): UsersApi2 {
  const { accessToken } = useAuthentication();

  return new UsersApi2(getConfig().basePath, accessToken);
}

export function useCustomsDocsApi(): CustomsDocsApi {
  const { accessToken } = useAuthentication();

  return new CustomsDocsApi(getConfig().basePath, accessToken);
}

export function usePhotosApi(): PhotosApi {
  const { accessToken } = useAuthentication();

  return new PhotosApi(getConfig().basePath, accessToken);
}

export function useFreightClaimsApi(): FreightClaimsApi {
  const { accessToken } = useAuthentication();

  return new FreightClaimsApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useInvoicesApi(): InvoicesApi {
  const { accessToken } = useAuthentication();

  return new InvoicesApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useSavedLocationsApi(): SavedLocationsApi {
  const { accessToken } = useAuthentication();

  return new SavedLocationsApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useSavedBrokersApi(): SavedBrokersApi {
  const { accessToken } = useAuthentication();

  return new SavedBrokersApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useSavedCommoditiesApi(): SavedCommoditiesApi {
  const { accessToken } = useAuthentication();

  return new SavedCommoditiesApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function usePaymentMethodsApi(): PaymentMethodsApi {
  const { accessToken } = useAuthentication();

  return new PaymentMethodsApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function createUsersApi(accessToken: string): UsersApi {
  return new UsersApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useGetStartedApi(): GetStartedApi {
  const { accessToken } = useAuthentication();

  return new GetStartedApi(
    new Configuration({ ...getConfig(), accessToken: accessToken }),
  );
}

export function useInvitationsApi(): InvitationsApi {
  return new InvitationsApi(new Configuration({ ...getConfig() }));
}

export type Accessorials = string[];
