import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Microcopy } from "../../../Cargo/Text/Text";
import { Label } from "../../../Cargo/Text/Label";
import AddressInput from "../../Locations/Components/AddressInput";
import { errorMessageForBusinessName } from "../../Locations/Validators/errorMessageForBusinessName";
import { errorMessagesForAddress } from "../../Locations/Validators/errorMessagesForAddress";
import {
  Address,
  LocationType,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface ModifyLocationModalAddressPage {
  address: Address;
  setAddress: (_: Address) => void;
  businessName: string;
  setBusinessName: (_: string) => void;
  onDone: () => void;
  onPrevious: () => void;
}

function ModifyLocationModalAddressPage(props: ModifyLocationModalAddressPage) {
  const {
    address,
    setAddress,
    businessName,
    setBusinessName,
    onDone,
    onPrevious,
  } = props;
  const [forceValidation, setForceValidation] = useState(false);

  function updateAddress(addressUpdates: Partial<Address>) {
    const updatedAddress = {
      ...address,
      ...addressUpdates,
    };

    setAddress(updatedAddress);
  }

  const addressErrorMessages = errorMessagesForAddress(address);
  const businessNameErrorMessages = errorMessageForBusinessName(businessName);

  function isValid() {
    if (addressErrorMessages.addressLine !== undefined) {
      return false;
    }

    if (businessNameErrorMessages !== undefined) {
      return false;
    }

    return true;
  }

  async function onNext() {
    if (isValid()) {
      onDone();
    } else {
      setForceValidation(true);
    }
  }

  return (
    <>
      <Microcopy>Please enter the broker&apos;s details</Microcopy>
      <Stack align="left">
        <>
          <Spacer height={16} />

          <Label>Address Details</Label>
          <AddressInput
            enabled={true}
            businessName={businessName || ""}
            businessNameChanged={setBusinessName}
            locationType={LocationType.Warehouse}
            address={address}
            addressChanged={updateAddress}
            forceValidation={forceValidation}
            businessNameErrorMessages={businessNameErrorMessages}
            addressErrorMessages={addressErrorMessages}
            addressOptions={[]}
            allowCityAndPostalCodeEditing={true}
            warnAboutPotentialBusinessNameErrors={false}
          />
          <HorizontalStack width="100%" align="spread">
            <Button onClick={onPrevious} secondary>
              Cancel
            </Button>
            <Button onClick={onNext}>Next</Button>
          </HorizontalStack>
        </>
      </Stack>
    </>
  );
}

export default ModifyLocationModalAddressPage;
