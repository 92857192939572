import { useShipmentsApi } from "../../../apis";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { isEmptyOrUndefined } from "../../../Helpers/isNotEmptyOrUndefined";
import { ReactNode, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface Result {
  isValid: boolean;
  message?: string | ReactNode;
  count?: number;
}

const invalidResult: Result = {
  isValid: false,
};

export interface UseValidateReferenceNumberReturn {
  isLoading: boolean;
  result: Result;
  validate: (_: string | undefined) => void;
}

export function useValidateReferenceNumber({
  locationContext,
  originalReferenceNumber,
  shipmentId,
}: {
  locationContext: LocationContext | undefined;
  originalReferenceNumber: string | undefined;
  shipmentId?: string;
}): UseValidateReferenceNumberReturn {
  const [result, setResult] = useState({ ...invalidResult });
  const [isLoading, setIsLoading] = useState(false);
  const shipmentApi = useShipmentsApi();

  const timeoutId = useRef<NodeJS.Timeout | undefined>();

  const REFERENCE_NUMBER_MIN_CHARS = 5;

  async function serverValidation(referenceNumber: string) {
    if (locationContext === undefined) {
      console.error("Unexpected location context");
      return;
    }

    setIsLoading(true);
    try {
      const response = await shipmentApi.lookUpReferenceNumber({
        locationContext,
        referenceNumber,
      });

      const isNotReused =
        response.shipmentIds.filter((id) => id !== shipmentId).length === 0;

      if (response.isValid || isNotReused) {
        setValid();
      } else {
        setInvalid(
          referenceNumber,
          response.message,
          response.shipmentIds.length,
        );
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  function clearQueuedValidation() {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
  }

  function enqueueServerValidation(referenceNumber: string) {
    timeoutId.current = setTimeout(function () {
      serverValidation(referenceNumber);
    }, 1000);
  }

  function setValid() {
    setResult({ isValid: true });
  }

  function setInvalid(
    referenceNumber: string,
    message: string,
    count?: number,
  ) {
    const result: Result = {
      isValid: false,
      message,
      count,
    };

    if (count && count > 0) {
      const referenceNumberType =
        locationContext === LocationContext.Pickup
          ? "pickupReferenceNumber"
          : "deliveryReferenceNumber";

      const url = `/view-all-shipments?${referenceNumberType}=${referenceNumber}&sort=PickupDate`;

      result.message = (
        <span>
          Reference number already used in {count > 1 ? "other" : "another"}{" "}
          <Link
            to={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {count > 1 ? "shipments" : "shipment"}
          </Link>
        </span>
      );
    }

    setResult(result);
  }

  function noChanges(original: string | undefined, actual: string | undefined) {
    return isEmptyOrUndefined(original) && isEmptyOrUndefined(actual);
  }

  function validate(referenceNumber: string | undefined) {
    /*
     * Clear state
     */
    clearQueuedValidation();
    setResult({ ...invalidResult });

    if (referenceNumber === undefined || referenceNumber.trim() === "") {
      setValid();
      return;
    }

    /*
     * Local validation
     */
    const cleanReferenceNumber = referenceNumber.trim();
    const cleanOriginalReferenceNumber = originalReferenceNumber?.trim();
    if (noChanges(cleanOriginalReferenceNumber, cleanReferenceNumber)) {
      setValid();
      return;
    }

    if (cleanReferenceNumber.length < REFERENCE_NUMBER_MIN_CHARS) {
      setInvalid(
        referenceNumber,
        `Reference number should be at least ${REFERENCE_NUMBER_MIN_CHARS} characters`,
      );
      return;
    }

    /*
     * Server validation
     */
    enqueueServerValidation(referenceNumber);
  }

  return { isLoading, result: result, validate };
}
