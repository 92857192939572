import { CountryCode } from "@freightsimple/generated-dashboard-openapi-client";

export function isCrossBorder(
  pickupCountryCode: CountryCode | undefined,
  deliveryCountryCode: CountryCode | undefined,
) {
  // If we don't know which country it is, assume domestic
  if (pickupCountryCode === undefined) {
    return false;
  }
  if (deliveryCountryCode === undefined) {
    return false;
  }

  if (pickupCountryCode !== deliveryCountryCode) {
    // Cross Border
    return true;
  } else {
    return false;
  }
}
