import { useModal } from "../../../Cargo/Modal/useModal";
import { UpdatedBOLModal } from "./UpdatedBOLModal";

export function useUpdatedBOLModal() {
  const showModal = useModal<undefined>();

  async function show(): Promise<undefined> {
    return new Promise<undefined>((resolve) => {
      showModal(
        (done) => {
          return <UpdatedBOLModal onDone={done}></UpdatedBOLModal>;
        },
        (data) => {
          resolve(data);
        },
      );
    });
  }

  return show;
}
