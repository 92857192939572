import Animation from "../../Cargo/Animations/Animation";
import Button from "../../Cargo/Controls/Button";
import Input from "../../Cargo/Controls/Input";
import PasswordInput from "../../Cargo/Controls/PasswordInput";
import PhoneInput from "../../Cargo/Controls/PhoneInput";
import Box from "../../Cargo/Layout/Box";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { ErrorMessage, Heading2, Microcopy } from "../../Cargo/Text/Text";
import { Label } from "../../Cargo/Text/Label";
import { ResponseError } from "@freightsimple/generated-dashboard-openapi-client";
import useQuery from "../../Hooks/useQuery";
import { useEffect, useState } from "react";
import { useAcceptInvitation } from "./Hooks/useAcceptInvitation";
import { useSignInDirectly } from "./Hooks/useSignIn";
import { useInvitationsApi } from "../../apis";

function AcceptInvitationScreen() {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [wasSuccess, setWasSuccess] = useState(false);
  const [forceValidation, setForceValidation] = useState(false);
  const { signIn, continueAfterReset } = useSignInDirectly();

  const invitationsApi = useInvitationsApi();

  const companyName = query.companyName as string;
  const inviteeEmail = query.inviteeEmail as string;
  const inviteeName = query.inviteeName as string;
  const inviterName = query.inviterName as string;
  const inviteToken = query.inviteToken as string;

  const [accessToken, setAccessToken] = useState("");
  const [idToken, setIdToken] = useState("");

  const [isInvitationInvalid, setIsInvitationInvalid] = useState(false);
  const [invitationInvalidReason, setInvitationInvalidReason] = useState("");

  const {
    acceptInvitation,
    errorMessages,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    phoneNumber,
    setPhoneNumber,
    phoneNumberExtension,
    setPhoneNumberExtension,
    generalErrorMessage,
    isValid,
  } = useAcceptInvitation(inviteeEmail, inviteeName);

  async function validateInvitation() {
    if (!inviteToken) {
      return;
    }
    try {
      await invitationsApi.getInvitationByToken(
        { tokenId: inviteToken },
        { cache: "no-cache" },
      );
    } catch (e) {
      if (e instanceof ResponseError) {
        const error = e as ResponseError;
        if (error.response.status === 410) {
          setIsInvitationInvalid(true);
          setInvitationInvalidReason("Expired");
        }

        if (error.response.status === 404) {
          setIsInvitationInvalid(true);
          setInvitationInvalidReason("Revoked");
        }
      }
    }
  }

  useEffect(() => {
    validateInvitation();
  }, [inviteToken]);

  async function onAcceptInvitation() {
    setForceValidation(true);
    if (!isValid) {
      return;
    }

    setLoading(true);
    try {
      const success = await acceptInvitation(inviteToken);

      if (success) {
        const { accessToken: _accessToken, idToken: _idToken } = await signIn(
          email,
          password,
        );
        setAccessToken(_accessToken);
        setIdToken(_idToken);

        setWasSuccess(true);
      }
    } catch (e) {
      console.error("Something went wrong accepting the invitation", {
        e,
      });
    }

    setLoading(false);
  }

  function onContinueAfterAccountCreation() {
    continueAfterReset(accessToken, idToken);
  }

  if (isInvitationInvalid) {
    return (
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 140px)",
          alignItems: "center",
        }}
      >
        <Stack align="center" width="100%">
          <Heading2>Invitation {invitationInvalidReason}</Heading2>
          <Microcopy>Please ask for another invitation link</Microcopy>
          <Animation
            lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets4.lottiefiles.com%2Fpackages%2Flf20_HYlQBb.json"
            pathToAnimation="/animations/yellow-exclamation.json"
            speed={0.5}
          />
        </Stack>
      </div>
    );
  }

  if (wasSuccess) {
    return (
      <Stack align="center" width="100%">
        <Heading2>Account successfully created!</Heading2>
        <Microcopy>You can now continue to use FreightSimple</Microcopy>
        <div style={{ height: "450px" }}>
          <Animation
            pathToAnimation="/animations/success-blue.json"
            lottieFilesUrl="https://lottiefiles.com/4431-success"
            loop={true}
            speed={0.5}
          />
        </div>
        <Button
          onClick={onContinueAfterAccountCreation}
          label="Continue"
          width="300px"
          size="large"
        />
      </Stack>
    );
  }

  return (
    <Stack align="center" width="100%">
      <Box>
        <div style={{ padding: "48px" }}>
          <Stack align="left" width="500px">
            <Heading2>Accept Invitation</Heading2>
            <Microcopy>
              <strong>{inviterName}</strong> has invited you to join the company{" "}
              <strong>{companyName}</strong> using FreightSimple.
            </Microcopy>
            <Spacer height={8} />
            <Microcopy>
              Please complete your details below to join this organization.
            </Microcopy>
            <Spacer height={56} />
            <Label>Email Address</Label>
            <Input
              type="text"
              value={email}
              onChange={(value) => setEmail(value)}
              width={429}
              errorMessage={errorMessages.email}
              forceValidation={forceValidation}
              autoComplete="email"
              name="accept_invitation_email"
            />
            <Spacer height={16} />
            <Label>Full Name</Label>
            <Input
              type="text"
              value={name}
              onChange={(value) => setName(value)}
              width={429}
              errorMessage={errorMessages.name}
              forceValidation={forceValidation}
              autoComplete="name"
              name="accept_invitation_name"
            />
            <Spacer height={16} />

            <Label>Phone Number</Label>
            <PhoneInput
              phoneNumber={phoneNumber}
              extension={phoneNumberExtension}
              onPhoneNumberChange={(value: string) => {
                setPhoneNumber(value);
              }}
              onExtensionChange={(value: string) => {
                setPhoneNumberExtension(value);
              }}
              phoneNumberErrorMessage={errorMessages.phoneNumber}
              extensionErrorMessage={undefined}
              forceValidation={forceValidation}
            />
            <Spacer height={16} />

            <Label>Password</Label>
            <PasswordInput
              placeholder="Enter your new password"
              value={password}
              onChange={(value) => setPassword(value)}
              width={429}
              errorMessage={errorMessages.password}
              forceValidation={true}
              autoComplete="new-password"
              name="accept_invitation_password"
            />
          </Stack>
          <Spacer height={32} />
          <Button
            onClick={onAcceptInvitation}
            label="Accept Invitation"
            width="300px"
            size="large"
            loading={loading}
            id="acceptInvitation"
          />
          <Spacer height={16} />
          {generalErrorMessage && (
            <ErrorMessage>{generalErrorMessage}</ErrorMessage>
          )}
        </div>
      </Box>
    </Stack>
  );
}
export default AcceptInvitationScreen;
