import { reactionContent } from "./reactionContent";
import { useReaction } from "./useReaction";
import { ReactionOverlay } from "./ReactionOverlay";
export function useReactionOverlay() {
  const { currentReaction } = useReaction();
  function renderReactions() {
    if (currentReaction === undefined) {
      return <></>;
    }

    return (
      <ReactionOverlay>{reactionContent(currentReaction)}</ReactionOverlay>
    );
  }

  return { renderReactions };
}
