import Colors from "../Colors";
import Icon from "../Icons/Icon";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";

import styled from "styled-components";

interface FilterPillProps {
  title: string;
  description: string;
  onDelete: () => void;
}

const FilterPillBody = styled.div`
  background-color: ${Colors.VeryLightBlue};
  position: relative;
  left: -8px;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 8px;
  display: inline-block;
  margin-bottom: 4px;
`;

const FilterPillTitle = styled.div`
  font-weight: var(--nhu-font-weight-bold);
`;

const HoverWrapper = styled.div`
  cursor: pointer;
  margin-left: 4px;
  top: 2px;
  position: relative;
  &:hover svg {
    filter: brightness(1.2);
  }
`;

function FilterPill(props: FilterPillProps) {
  return (
    <FilterPillBody>
      <HorizontalStack verticalAlign="middle">
        <FilterPillTitle>{props.title}</FilterPillTitle>
        <Spacer width={4} />
        <div>{props.description}</div>
        <HoverWrapper onClick={props.onDelete}>
          <Icon name="times" color={Colors.Red} size={16} solid />
        </HoverWrapper>
      </HorizontalStack>
    </FilterPillBody>
  );
}
export default FilterPill;
