import Button from "../Controls/Button";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import Stack from "../Layout/Stack";
import { Heading2, Microcopy } from "../Text/Text";
import { ReactElement, ReactNode } from "react";
import { ConfirmModalOptions } from "./ConfirmModalOptions";

interface ConfirmModalProps {
  onDone: (confirmed: boolean) => void;
  message: ReactNode;
  title: ReactNode;
  options?: ConfirmModalOptions;
}

export function ConfirmModal(props: ConfirmModalProps): ReactElement {
  return (
    <Stack align="center" id="ConfirmModal">
      <Heading2>{props.title}</Heading2>
      <Microcopy>{props.message}</Microcopy>
      <Spacer height={24} />
      <HorizontalStack>
        <Button
          id="cancelButton"
          size="large"
          secondary
          onClick={() => {
            props.onDone(false);
          }}
        >
          {props.options?.buttons?.secondary || `Cancel`}
        </Button>
        <Spacer width={16} />
        <Button
          id="doneButton"
          size="large"
          onClick={() => {
            props.onDone(true);
          }}
        >
          {props.options?.buttons?.primary || `Ok!`}
        </Button>
      </HorizontalStack>
    </Stack>
  );
}
