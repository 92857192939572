import Colors from "../../../Cargo/Colors";
import { ToolTipWrapper } from "../../../Cargo/Controls/QuestionBubble";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { AddLocationLocationType } from "../../Locations/Types/locationTypes";
import {
  Hours,
  Location,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { colorForShipmentState } from "../../../Helpers/colorForShipmentState";
import { describeDateRelative } from "../../../Helpers/describeDateRelative";
import {
  describeDateRelativeWithTime,
  describeDateRelativeWithYearWithTime,
} from "../../../Helpers/describeDateRelativeWithTime";
import { describeHours } from "../../../Helpers/describeHours";
import { nameForShipmentState } from "../../../Helpers/nameForShipmentState";
import {
  timeLabel2ForShipmentState,
  timeLabelForShipmentState,
  timeLabelTooltipForShipmentState,
} from "../../../Helpers/timeLabelForShipmentState";
import moment from "moment";
import { ReactNode } from "react";
import styled from "styled-components";
import { formatExpectedDeliveryHours } from "./formatExpectedDeliveryHours";

const Company = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 18px;
  color: #545454;
`;
const City = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 14px;
  color: #858585;
`;

const TopRightLabel = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 16px;
  color: #858585;
`;

const TopRightValue = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 16px;
  color: #545454;
`;

function makeTimeDescription(
  shipmentState: ShipmentState,
  pickupDateDescription: string | undefined,
  expectedDeliveryDateDescription: string | undefined,
  appointmentDateDescription: string | undefined,
  actualDeliveryDateDescription: string | undefined,
): string {
  switch (shipmentState) {
    case ShipmentState.Quoted:
    case ShipmentState.QuoteRequested:
    case ShipmentState.BookingConfirmed:
    case ShipmentState.BookingRequested:
    case ShipmentState.BookingFailed:
      if (pickupDateDescription === undefined) {
        throw new Error("pickupDateDescription missing");
      }
      return pickupDateDescription;
    case ShipmentState.InTransit:
      if (appointmentDateDescription !== undefined) {
        return appointmentDateDescription;
      }

      if (expectedDeliveryDateDescription === undefined) {
        return "Waiting for update";
      } else {
        return expectedDeliveryDateDescription;
      }
    case ShipmentState.OnHold:
      if (pickupDateDescription === undefined) {
        throw new Error("pickupDateDescription missing");
      }
      return `${pickupDateDescription} (On Hold)`;
    case ShipmentState.Delivered:
      if (actualDeliveryDateDescription === undefined) {
        // This happens in the pickup information screen when it's delivered for example
        return "";
      }
      return actualDeliveryDateDescription;
    case ShipmentState.Cancelled:
      return "";
    case ShipmentState.Lost:
      return "";
  }
}

interface TopRightProps {
  shipmentState: ShipmentState;
  referenceNumberLabel: string | undefined;
  referenceNumber: string | undefined;
  timeLabel: string | undefined;
  timeDescription: string;
  timeLabel2: string | undefined;
  timeDescription2: string | undefined;
  timeLabelTooltip: ReactNode | undefined;
}

interface LabelTooltipProps {
  description: ReactNode | undefined;
  children: ReactNode;
}

function LabelTooltip(props: LabelTooltipProps) {
  if (props.description === undefined) {
    return <>{props.children}</>;
  } else {
    return (
      <ToolTipWrapper description={props.description}>
        {props.children}
      </ToolTipWrapper>
    );
  }
}

function TopRight(props: TopRightProps) {
  return (
    <HorizontalStack>
      <Stack align="right" width="100px">
        <TopRightLabel>Status</TopRightLabel>
        <TopRightValue>
          {nameForShipmentState(props.shipmentState)}
        </TopRightValue>
      </Stack>
      <Spacer width={12} />

      {props.timeLabel && (
        <>
          <LabelTooltip description={props.timeLabelTooltip}>
            <Stack align="right" width="210px">
              <TopRightLabel>{props.timeLabel}</TopRightLabel>
              <TopRightValue>{props.timeDescription}</TopRightValue>
            </Stack>
          </LabelTooltip>
          <Spacer width={12} />
        </>
      )}

      {props.timeLabel2 && props.timeDescription2 && (
        <>
          <LabelTooltip description={props.timeLabelTooltip}>
            <Stack align="right" width="180px">
              <TopRightLabel>{props.timeLabel2}</TopRightLabel>
              <TopRightValue>{props.timeDescription2}</TopRightValue>
            </Stack>
          </LabelTooltip>
          <Spacer width={12} />
        </>
      )}

      {props.referenceNumberLabel && (
        <Stack align="right" width="120px">
          <TopRightLabel>{props.referenceNumberLabel}</TopRightLabel>
          <TopRightValue>{props.referenceNumber}</TopRightValue>
        </Stack>
      )}
    </HorizontalStack>
  );
}

interface TopBarProps {
  shipmentState: ShipmentState;
  pickupLocation: AddLocationLocationType | Location;
  deliveryLocation: AddLocationLocationType | Location;
  pickupDate: string;
  pickupHours: Hours | undefined;
  appointmentDate: string | undefined;
  expectedDeliveryDate: string | undefined;
  expectedDeliveryHours: string | undefined;
  actualDeliveryDate: string | undefined;
  actualDeliveryTime: string | undefined;
  predictedLatestDeliveryDate: string | undefined;
  proNumber: string;
  referenceNumber?: string;
  preferRenderReferenceNumber?: boolean;
  deliveryAppointment: boolean;
}

function TopBar(props: TopBarProps) {
  const {
    shipmentState,
    pickupLocation,
    deliveryLocation,
    pickupDate,
    pickupHours,
    appointmentDate,
    expectedDeliveryDate,
    expectedDeliveryHours,
    predictedLatestDeliveryDate,

    actualDeliveryDate,
    actualDeliveryTime,
    deliveryAppointment,
  } = props;

  const pickupCity = `${pickupLocation.address.city}, ${pickupLocation.address.stateOrProvinceCode}`;
  const deliveryCity = `${deliveryLocation.address.city}, ${deliveryLocation.address.stateOrProvinceCode}`;

  const pickupBusinessName = pickupLocation.businessName;
  const deliveryBusinessName = deliveryLocation.businessName;

  const pickupDateDescription = describeDateRelativeWithTime(
    pickupDate,
    pickupHours ? describeHours(pickupHours) : undefined,
    // If the pickup was missed, we still want to show eg 'Yesterday'
    // as it will make it clearer to the user what happened
    false,
  );

  const formattedExpectedDeliveryHours = formatExpectedDeliveryHours(
    expectedDeliveryHours,
  );

  const expectedDeliveryDateDescription = describeDateRelativeWithTime(
    expectedDeliveryDate,
    deliveryAppointment ? "by appointment" : formattedExpectedDeliveryHours,
    true,
  );

  const appointmentDateDescription = appointmentDate
    ? describeDateRelative(appointmentDate)
    : undefined;

  const actualDeliveryTimeDescription = actualDeliveryTime
    ? moment(actualDeliveryTime, "HH:mm").format("h:mma")
    : undefined;

  const actualDeliveryDateDescription = describeDateRelativeWithYearWithTime(
    actualDeliveryDate,
    actualDeliveryTimeDescription,
    false,
  );

  const timeLabel = timeLabelForShipmentState(shipmentState, appointmentDate);
  const timeLabel2 = timeLabel2ForShipmentState(shipmentState, appointmentDate);

  const timeLabelTooltip = timeLabelTooltipForShipmentState(shipmentState);

  const timeDescription = makeTimeDescription(
    shipmentState,
    pickupDateDescription,
    expectedDeliveryDateDescription,
    appointmentDateDescription,
    actualDeliveryDateDescription,
  );

  // Show the latest delivery only if the current expected delivery is before the latest (otherwise it looks bad
  const timeDescription2 =
    predictedLatestDeliveryDate !== undefined &&
    expectedDeliveryDate !== undefined &&
    appointmentDate === undefined &&
    moment(predictedLatestDeliveryDate).isAfter(moment(expectedDeliveryDate))
      ? describeDateRelative(predictedLatestDeliveryDate)
      : undefined;

  function iconForShipmentState(shipmentState: ShipmentState) {
    switch (shipmentState) {
      case ShipmentState.Quoted:
      case ShipmentState.QuoteRequested:
        // Don't believe we use this
        return "clock";
      case ShipmentState.BookingConfirmed:
      case ShipmentState.BookingRequested:
      case ShipmentState.BookingFailed:
      case ShipmentState.OnHold:
        return "clock";
      case ShipmentState.InTransit:
        return "truck";
      case ShipmentState.Delivered:
        return "check-circle";
      case ShipmentState.Cancelled:
        return "window-close";
      case ShipmentState.Lost:
        return "window-close";
    }
  }

  function decideReferenceNumber() {
    if (props.preferRenderReferenceNumber === true) {
      if (props.referenceNumber) {
        return {
          referenceNumber: props.referenceNumber,
          referenceNumberLabel: "Reference",
        };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  const referenceNumberInfo = decideReferenceNumber();

  return (
    <>
      <HorizontalStack
        align="spread"
        verticalAlign="middle"
        style={{ height: "100%", width: "100%", marginTop: "16px" }}
      >
        <HorizontalStack style={{ width: "75%" }}>
          <Icon
            name={iconForShipmentState(shipmentState)}
            color={colorForShipmentState(shipmentState)}
            size={36}
            solid
            style={{ position: "relative", top: "4px" }}
          />
          <Spacer width={24} />
          <Stack align="left">
            <Company>{pickupBusinessName}</Company>
            <City>{pickupCity}</City>
          </Stack>
          <Icon
            name="arrow-right"
            color={Colors.NormalText}
            size={14}
            solid
            style={{
              position: "relative",
              top: "-10px",
              marginRight: "16px",
              marginLeft: "16px",
            }}
          />
          <Stack align="left">
            <Company>{deliveryBusinessName}</Company>
            <City>{deliveryCity}</City>
          </Stack>
        </HorizontalStack>

        <TopRight
          timeLabel={timeLabel}
          timeDescription={timeDescription}
          timeLabelTooltip={timeLabelTooltip}
          timeLabel2={timeLabel2}
          timeDescription2={timeDescription2}
          shipmentState={shipmentState}
          referenceNumber={referenceNumberInfo?.referenceNumber}
          referenceNumberLabel={referenceNumberInfo?.referenceNumberLabel}
        />
      </HorizontalStack>
    </>
  );
}
export default TopBar;
