import { useSavedCommoditiesApi } from "../../../apis";
import { UUID } from "../../../Cargo/Types/types";
import {
  PostSavedCommoditiesAddRequest,
  PostSavedCommoditiesDeleteRequest,
  PostSavedCommoditiesModifyRequest,
  SavedCommodity,
} from "@freightsimple/generated-dashboard-openapi-client";

export function useSavedCommoditiesService() {
  const savedCommoditiesApi = useSavedCommoditiesApi();

  return {
    addSavedCommodity: async function (savedCommodity: SavedCommodity) {
      const request: PostSavedCommoditiesAddRequest = {
        savedCommodity,
      };

      await savedCommoditiesApi.postSavedCommoditiesAdd(request);
    },

    modifySavedCommodity: async function (savedCommodity: SavedCommodity) {
      const request: PostSavedCommoditiesModifyRequest = {
        savedCommodity,
      };

      await savedCommoditiesApi.postSavedCommoditiesModify(request);
    },

    deleteSavedCommodity: async function (savedCommodityId: UUID) {
      const request: PostSavedCommoditiesDeleteRequest = {
        savedCommodityId,
      };
      await savedCommoditiesApi.postSavedCommoditiesDelete(request);
    },

    getAllSavedCommodities: async function () {
      return savedCommoditiesApi.getSavedCommoditiesAll();
    },
  };
}
