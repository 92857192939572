import { useModalsSlice } from "../../../Cargo/Modal/modalsSlice";

import { useSavedLocations } from "../../Locations/Hooks/useSavedLocations";
import { useSavedLocationsService } from "../../Locations/Services/SavedLocationsService";
import { useShipmentService } from "../../../Services/ShipmentService";
import {
  SavedLocation,
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { useUpdatedBOLModal } from "./useUpdatedBOLModal";
import { useModifyReferenceAndNotesAfterBookingModalImplementation } from "./useModifyReferenceAndNotesAfterBookingModalImplementation";
import { findSavedLocationForLocation } from "../../Locations/Helpers/findSavedLocationForLocation";

function findSavedLocationId(
  locationContext: LocationContext,
  shipment: Shipment,
  savedLocations: Array<SavedLocation>,
) {
  const location =
    locationContext === LocationContext.Pickup
      ? shipment.pickupLocation
      : shipment.deliveryLocation;

  return findSavedLocationForLocation(savedLocations, location)
    ?.savedLocationId;
}

export function useModifyReferenceAndNotesAfterBookingModal(
  shipment: Shipment,
  locationContext: LocationContext,
) {
  const locationType =
    locationContext === LocationContext.Pickup
      ? shipment.pickupLocation.locationType
      : shipment.deliveryLocation.locationType;

  const showModal = useModifyReferenceAndNotesAfterBookingModalImplementation(
    locationContext,
    locationType,
  );
  const showUpdatedBOLModal = useUpdatedBOLModal();
  const shipmentService = useShipmentService();
  const savedLocationsService = useSavedLocationsService();
  const { setLoading } = useModalsSlice();
  const { savedLocations } = useSavedLocations();

  // Returns if the broker has changed, hence we should reload
  async function show(): Promise<boolean> {
    const savedLocationId = findSavedLocationId(
      locationContext,
      shipment,
      savedLocations,
    );

    const originalNotes =
      locationContext === LocationContext.Pickup
        ? shipment.pickupLocation.notes
        : shipment.deliveryLocation.notes;

    const originalReference =
      locationContext === LocationContext.Pickup
        ? shipment.pickupReferenceNumber
        : shipment.deliveryReferenceNumber;

    const originalBoothNumber =
      locationContext === LocationContext.Pickup
        ? shipment.pickupBoothNumber
        : shipment.deliveryBoothNumber;

    if (savedLocationId === undefined) {
      // We should handle this - seems like a real edge case
      // where they have modified the location after booking
      throw new Error("No saved location found");
    }

    const updatedInfo = await showModal(
      shipment.shipmentId,
      originalReference,
      originalBoothNumber,
      originalNotes,
    );

    if (updatedInfo === undefined) {
      // Cancel pressed
      return false;
    }

    if (
      originalNotes === updatedInfo.notes &&
      originalReference === updatedInfo.referenceNumber
    ) {
      // Nothing changed
      return false;
    }

    setLoading(true);

    const savedLocation = savedLocations.find(
      (sl) => sl.savedLocationId === savedLocationId,
    );
    if (savedLocation === undefined) {
      throw new Error("Should not happen");
    }

    const locationToUpdate = savedLocation.location;
    locationToUpdate.notes = updatedInfo.notes;

    // Make an API call to add the notes changed into the saved location
    await savedLocationsService.modifyLocation(
      savedLocationId,
      locationToUpdate,
    );

    // Make an API call to change the reference/notes on the shipment
    await shipmentService.updateReferenceAndNotes(
      shipment.shipmentId,
      locationContext,
      updatedInfo.referenceNumber,
      updatedInfo.boothNumber,
      updatedInfo.notes,
    );
    setLoading(false);

    // If the shipment is in transit, then it's too late to give the driver
    // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
    if (shipment.shipmentState !== ShipmentState.InTransit) {
      await showUpdatedBOLModal();
    }

    // Do we need to do something with the custom documents?
    // CS: Jan 2022: I'm currently taking the stance that we do nothing
    // - If they're not yet uploaded then nothing to do anyway
    // - If they have been uploaded, then they're still probably mostly valid, and hopefully
    //   the carrier can work with them
    // We should at some point give the customer the ability to upload replacement customs docs

    return true;
  }

  return show;
}
