import moment from "moment";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { DatePickerDropdown } from "../../../Cargo/Dates/DatePickerDropdown";
import { DatePickerTooltipType } from "../../../Cargo/Dates/DatePickerTooltipType";

interface DeadlineDateControlProps {
  date: moment.Moment | undefined;
  pickupDate: string;
  onDateChange: (date: moment.Moment | undefined) => void;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
  disabled: boolean;
}

export function DeadlineDateControl(props: DeadlineDateControlProps) {
  return (
    <DatePickerDropdown
      selectedDate={props.date?.format("YYYY-MM-DD")}
      setSelectedDate={function (selectedDate, _tooltip) {
        props.onDateChange(selectedDate ? moment(selectedDate) : undefined);
      }}
      onInitialize={() => {}}
      firstAllowedDate={props.pickupDate}
      lastAllowedDate={undefined}
      showHolidayTooltipsForCountry={undefined}
      dateTooltips={{
        [DatePickerTooltipType.BeforeFirstAllowedDate]:
          "⚠️ Before the pickup date",
      }}
    />
  );
}
