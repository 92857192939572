import Button from "../../../../Cargo/Controls/Button";
import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import { Microcopy, ModalTitle } from "../../../../Cargo/Text/Text";
import { LineItem } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { DangerousGoodsTabs } from "./Tabs/DangerousGoodsTabs";
import { EnterDetailsTab } from "./Tabs/EnterDetailsTab";
import { LookupTab } from "./Tabs/LookupTab";

interface SelectUNNumberModalProps {
  lineItem: Partial<LineItem>;

  onDone: (_: Partial<LineItem>) => void;
  onCancel: () => void;
}

export function SelectUNNumberModal(props: SelectUNNumberModalProps) {
  const [initial, setInitial] = useState("");
  const [lineItem, setLineItem] = useState(props.lineItem);

  const { onCancel } = props;

  function onChange(updates: Partial<LineItem>) {
    setLineItem(function (originalLineItem) {
      return {
        ...originalLineItem,
        ...updates,
      };
    });
  }

  function onLookup(
    _dangerousUnNumber: string,
    _dangerousClassification: string,
    _dangerousProperShippingName: string,
    _dangerousPackingGroup: string | undefined,
  ) {
    onChange({
      isDangerous: true,
      dangerousUnNumber: _dangerousUnNumber,
      dangerousClassification: _dangerousClassification,
      dangerousProperShippingName: _dangerousProperShippingName,
      dangerousPackingGroup: _dangerousPackingGroup,
    });
  }

  const [selectedTab, setSelectedTab] = useState(
    props.lineItem.isDangerous
      ? DangerousGoodsTabs.EnterDetails
      : DangerousGoodsTabs.Lookup,
  );
  const [forceValidation, setForceValidation] = useState(false);

  function onNext() {
    if (lineItem.dangerousUnNumber === undefined) {
      setForceValidation(true);
      return;
    }
    setForceValidation(false);
    setSelectedTab(DangerousGoodsTabs.EnterDetails);
  }

  function onChangeUnNumber() {
    setInitial(lineItem.dangerousUnNumber || "");
    setForceValidation(false);
    setSelectedTab(DangerousGoodsTabs.Lookup);
    setLineItem(props.lineItem);
  }

  async function onDone() {
    setForceValidation(true);

    if (
      lineItem.dangerousUnNumber !== undefined &&
      lineItem.dangerousClassification !== undefined &&
      lineItem.dangerousPackingGroup !== undefined &&
      lineItem.dangerousProperShippingName !== undefined &&
      lineItem.dangerousNumberPackages !== undefined &&
      lineItem.dangerousPackagingType !== undefined
    ) {
      props.onDone(lineItem);
    }
  }

  return (
    <div style={{ height: "678px" }}>
      <ModalTitle>Dangerous Goods Details</ModalTitle>
      <Microcopy>
        To ship dangerous goods, we need to know some more details. Start by
        either entering the UN number for this commodity, or search by
        description
      </Microcopy>
      <Spacer height={32} />
      <div style={{ height: "502px" }}>
        {selectedTab === DangerousGoodsTabs.Lookup && (
          <LookupTab
            initial={initial}
            forceValidation={forceValidation}
            onLookup={onLookup}
            onResetForceValidation={function () {
              setForceValidation(false);
            }}
          />
        )}
        {selectedTab === DangerousGoodsTabs.EnterDetails && (
          <EnterDetailsTab
            forceValidation={forceValidation}
            lineItem={lineItem}
            onChange={onChange}
            onChangeUnNumber={onChangeUnNumber}
          />
        )}
      </div>
      <Spacer height={32} />
      {selectedTab === DangerousGoodsTabs.Lookup && (
        <HorizontalStack width="100%" align="spread">
          <Button onClick={onCancel} secondary>
            Cancel
          </Button>
          <Button onClick={onNext}>Next</Button>
        </HorizontalStack>
      )}
      {selectedTab === DangerousGoodsTabs.EnterDetails && (
        <HorizontalStack width="100%" align="spread">
          <Button onClick={onCancel} secondary>
            Cancel
          </Button>
          <Button onClick={onDone}>Done</Button>
        </HorizontalStack>
      )}
    </div>
  );
}
