import {
  QuestionBubbleHeader,
  QuestionBubbleLabel,
  SmallQuestionBubble,
} from "../../../../Cargo/Controls/QuestionBubble";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";

import {
  AccessorialQuestionBubble,
  AccessorialQuestionBubbleProps,
} from "./AccessorialQuestionBubbleProps";
import { describeLocationContext } from "../../../../Helpers/describeLocationContext";

const InsideQuestionBubble: AccessorialQuestionBubble = (
  props: AccessorialQuestionBubbleProps,
) => {
  const verb =
    props.locationContext == LocationContext.Pickup ? "pickup" : "deliver";

  return (
    <SmallQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>{`Inside ${describeLocationContext(props.locationContext)}`}</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            {`The driver is required to go beyond the immediate area of the receiving door to ${verb} the freight.`}
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/freight-101/special-services/inside-pickup-delivery"
    />
  );
};
export default InsideQuestionBubble;
