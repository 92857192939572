import { datadogRum } from "@datadog/browser-rum";
import { assertNever } from "./Helpers/assertNever";

export enum Environment {
  Local = "local",
  Preprod = "preprod",
  Demo = "demo",
  Production = "production",
}

export function isVitest(): boolean {
  return typeof process !== 'undefined' && process.env.VITEST === 'true';
}

export function getEnvironment(): Environment {
  // Check if running in Vitest first
  if (isVitest()) {
    return Environment.Local;
  }
  
  // Try to get environment from meta tag first
  const envMeta = document.querySelector('meta[name="environment"]');
  const metaEnvironment = envMeta?.getAttribute("content");

  if (metaEnvironment === undefined) {
    throw new Error("Meta environment tag not found");
  }

  if (metaEnvironment === "%ENVIRONMENT%") {
    return Environment.Local;
  }

  // Check if the value exists in Environment enum
  if (Object.values(Environment).includes(metaEnvironment as Environment)) {
    return metaEnvironment as Environment;
  }

  throw new Error(
    `Should not be here - problem with meta environment tag: ${metaEnvironment}`,
  );
}

export function isProduction(): boolean {
  return getEnvironment() === Environment.Production;
}

export function isDemo(): boolean {
  return getEnvironment() === Environment.Demo;
}

export function isPreprod(): boolean {
  return getEnvironment() === Environment.Preprod;
}

export function isLocal(): boolean {
  return getEnvironment() === Environment.Local;
}

export function apiServerUrl(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "http://localhost:8080";
    case Environment.Preprod:
      return "https://api.fspreprod.com";
    case Environment.Demo:
      return "https://api.freightsimpledemo.com";
    case Environment.Production:
      return "https://api.freightsimple.com";
    default:
      assertNever(environment);
  }
}

export function cookieDomain(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Production:
      return "freightsimple.com";
    case Environment.Demo:
      return "freightsimpledemo.com";
    case Environment.Preprod:
      return "fspreprod.com";
    case Environment.Local:
      return "localhost";
    default:
      assertNever(environment);
  }
}

interface Auth0Creds {
  domain: string;
  clientId: string;
  audience: string;
}

function getAuth0Domain(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "freightsimple-local.auth0.com";
    case Environment.Preprod:
      return "freightsimple-preprod.auth0.com";
    case Environment.Demo:
      return "freightsimple-demo.us.auth0.com";
    case Environment.Production:
      return "freightsimple-production.auth0.com";
    default:
      assertNever(environment);
  }
}

function getAuth0ClientId(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "CbTvxGPy0INxRqvGoTZNMhfmH7BD0yDX";
    case Environment.Preprod:
      return "7Wpmu72Ku0sampTsq66WT2gdiAAcrqwK";
    case Environment.Demo:
      return "1u4UXU3MqHs0Jxk4iGlvUlpcL650irsL";
    case Environment.Production:
      return "wtanv5Dz8sZU340M55sEsWkc4cIBVQgJ";
    default:
      assertNever(environment);
  }
}

function getAuth0Audience(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "http://localhost:8080";
    case Environment.Preprod:
      return "https://api.fspreprod.com";
    case Environment.Demo:
      return "https://api.freightsimpledemo.com";
    case Environment.Production:
      return "https://api.freightsimple.com";
    default:
      assertNever(environment);
  }
}

export function auth0Creds(): Auth0Creds {
  return {
    domain: getAuth0Domain(),
    clientId: getAuth0ClientId(),
    audience: getAuth0Audience(),
  };
}

export function rootUrl(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "http://localhost:3000/";
    case Environment.Preprod:
      return "https://dashboard.fspreprod.com/";
    case Environment.Demo:
      return "https://dashboard.freightsimpledemo.com/";
    case Environment.Production:
      return "https://dashboard.freightsimple.com/";
    default:
      assertNever(environment);
  }
}

export function getStripeKey(): string {
  if (isProduction()) {
    return "pk_live_23rWgGvkbZhsZ9dWnezporWD00KZhNv5FG";
  } else {
    return "pk_test_6GHYhSyObuzDjZHy9zKOactB00hc9juaCE";
  }
}

function initializeDatadog() {
  if (isProduction()) {
    datadogRum.init({
      applicationId: "d21ee269-2a42-44b5-b8e9-6f8a59d445eb",
      clientToken: "pub60b84113f8fb4df5d2cbe9112ac05192",
      site: "datadoghq.com",
      service: "freightsimple-production",
      env: "production",
      sessionSampleRate: 100,

      // Record all sessions
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
    });

    datadogRum.startSessionReplayRecording();
  }
}

initializeDatadog();
