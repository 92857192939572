import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface LineItemsPageProp {
  shipment: Shipment;
}

export function LineItemsPage(_props: LineItemsPageProp) {
  return <Heading1>Line Items</Heading1>;
}
