import { useSavedBrokersApi } from "../../../apis";
import Button from "../../../Cargo/Controls/Button";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { useModalsSlice } from "../../../Cargo/Modal/modalsSlice";
import { Microcopy, ModalTitle } from "../../../Cargo/Text/Text";
import { UUID } from "../../../Cargo/Types/types";
import BrokerBox from "../../Brokers/Components/BrokerBox";
import SavedBrokersDropdown from "../../Brokers/Components/SavedBrokersDropdown";
import useAddBrokerModal from "../../Brokers/Hooks/useAddBrokerModal";
import useModifyBrokerModal from "../../Brokers/Hooks/useModifyBrokerModal";
import {
  Broker,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../../Hooks/useOnce";
import { useState } from "react";

interface SwitchBrokerModalProps {
  broker: Broker;
  onDone: (selectedSavedBrokerId: UUID) => void;
  onCancel: () => void;
}

export function SwitchBrokerModal(props: SwitchBrokerModalProps) {
  const { setLoading } = useModalsSlice();
  const [savedBrokers, setSavedBrokers] = useState<Array<SavedBroker>>([]);
  const showModifyBrokerModal = useModifyBrokerModal();
  const savedBrokersApi = useSavedBrokersApi();
  // const [loadingSavedBrokers, setLoadingSavedBrokers] = useState(false);
  const [selectedSavedBrokerId, setSelectedSavedBrokerId] = useState<
    UUID | undefined
  >();

  async function loadSavedBrokers() {
    const savedBrokersResponse = await savedBrokersApi.getAllSavedBrokers();
    setSavedBrokers(savedBrokersResponse.items);

    // First time loading
    if (selectedSavedBrokerId === undefined) {
      setSelectedSavedBrokerId(
        findSavedBrokerId(props.broker, savedBrokersResponse.items),
      );
    }
  }

  useOnce(async function () {
    setLoading(true);
    await loadSavedBrokers();
    setLoading(false);
  });

  const showAddBrokerModal = useAddBrokerModal();

  async function onAddBroker() {
    const data = await showAddBrokerModal();
    setLoading(true);
    const newBroker = data.broker;
    await savedBrokersApi.postSavedBrokersAdd({
      addSavedBrokerRequest: { broker: newBroker },
    });
    await loadSavedBrokers();
    setSelectedSavedBrokerId(newBroker.savedBrokerId);
    setLoading(false);
  }

  const selectedSavedBroker = savedBrokers.find(function (sb) {
    return sb.savedBrokerId === selectedSavedBrokerId;
  });

  function onSwitch(newSavedBrokerId: UUID) {
    setSelectedSavedBrokerId(newSavedBrokerId);
    const newSelectedBroker = savedBrokers.find(function (sb) {
      return sb.savedBrokerId === newSavedBrokerId;
    })?.broker;

    if (newSelectedBroker === undefined) {
      throw new Error("Missing saved broker");
    }
  }

  return (
    <div id="change-customs-broker" style={{ width: "420px" }}>
      <ModalTitle>Change Customs Broker</ModalTitle>
      <Microcopy>Select the customs broker for this shipment</Microcopy>
      <Spacer height={8} />
      <SavedBrokersDropdown
        savedBrokerId={selectedSavedBrokerId}
        savedBrokers={savedBrokers}
        onSetSavedBrokerId={onSwitch}
      />
      {selectedSavedBroker !== undefined && (
        <>
          <Spacer height={24} />
          <BrokerBox
            key={selectedSavedBrokerId}
            broker={selectedSavedBroker.broker}
            onModify={async function () {
              const result = await showModifyBrokerModal(selectedSavedBroker);
              await savedBrokersApi.postSavedBrokersModify({
                modifySavedBrokerRequest: result,
              });
              await loadSavedBrokers();
            }}
            showMenu={false}
            onDelete={undefined}
            preferredNorthbound={false}
            preferredSouthbound={false}
            onSelectPreferred={undefined}
          />
        </>
      )}
      <Spacer height={24} />
      <LinkButton onClick={onAddBroker}>Add new customs broker</LinkButton>
      <Spacer height={24} />
      <HorizontalStack width="100%" align="spread">
        <Button secondary onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          onClick={function () {
            if (selectedSavedBrokerId !== undefined) {
              props.onDone(selectedSavedBrokerId);
            }
          }}
          disabled={selectedSavedBrokerId === undefined}
        >
          Done
        </Button>
      </HorizontalStack>
    </div>
  );
}

function findSavedBrokerId(currentBroker: Broker, savedBrokers: SavedBroker[]) {
  const matchingSavedBroker = savedBrokers.find(function (b) {
    return (
      b.broker.businessName === currentBroker?.businessName &&
      b.broker.address.postalCode === currentBroker.address.postalCode
    );
  });

  return matchingSavedBroker?.savedBrokerId;
}
