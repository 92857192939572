import { useCustomsDocsApi } from "../../../apis";
import { CustomsDocsUploadMode } from "../../../Apis/CustomsDocsApi";
import Button from "../../../Cargo/Controls/Button";
import Link from "../../../Cargo/Controls/Link";
import Box from "../../../Cargo/Layout/Box";
import Stack from "../../../Cargo/Layout/Stack";
import { UUID } from "../../../Cargo/Types/types";
import { CustomsDocsSubmitSection } from "../../CustomDocs/Components/CustomsDocsSubmitSection";
import { useCustomsDocsDropzone } from "../../CustomDocs/Hooks/useCustomsDocsDropzone";
import { useState } from "react";

interface CustomsDocsSectionProps {
  shipmentId: UUID;
  onUploaded: () => void;
}

export function CustomsDocsSection(props: CustomsDocsSectionProps) {
  const customsDocsApi = useCustomsDocsApi();

  const [loading, setLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [loadingFailure, setLoadingFailure] = useState(false);

  const { files, setFiles, dropzoneState } = useCustomsDocsDropzone();

  async function onUpload() {
    setLoadingSuccess(false);
    setLoadingFailure(false);
    setLoading(true);
    try {
      await customsDocsApi.upload(
        props.shipmentId,
        CustomsDocsUploadMode.Initial,
        files,
      );
      setLoadingSuccess(true);
      setTimeout(function () {
        props.onUploaded();
      }, 1000);
    } catch (e) {
      console.error(`Failure uploading customs docs`, { e });
      setLoadingFailure(true);
    } finally {
      setLoading(false);
    }
  }

  function acceptedFilesCount() {
    if (files.length === 0) {
      return "";
    }

    if (files.length === 1) {
      return " (1 file)";
    }

    if (files.length > 1) {
      return ` (${files.length} files)`;
    }

    // should not be here
    throw new Error("Invalid files count");
  }

  return (
    <div id="next-steps-section" style={{ width: "100%" }}>
      <Box
        style={{
          padding: "24px 40px 40px 40px",
          marginBottom: "48px",
        }}
      >
        <Stack>
          <CustomsDocsSubmitSection
            setFiles={setFiles}
            dropzoneState={dropzoneState}
            files={files}
            title={"Next: Upload Customs Documents"}
            subtitle={
              <>
                <p>
                  FreightSimple will send your customs documents to the carrier
                  to ensure there are no delays. These <strong>MUST</strong> be
                  submitted before pickup as some carriers will not pick up the
                  freight until they receive these documents.
                </p>
                <p>
                  If you're not sure what documents you need, or to learn more
                  please see our{" "}
                  <Link
                    to="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/uploading-your-customs-documents"
                    newTab
                  >
                    Help Center
                  </Link>
                </p>
              </>
            }
          />
          {files.length !== 0 && (
            <Button
              size="large"
              onClick={onUpload}
              loadingSuccess={loadingSuccess}
              loading={loading}
              loadingFailure={loadingFailure}
              disabled={loading || loadingSuccess}
            >
              Upload Customs Docs {acceptedFilesCount()}
            </Button>
          )}
        </Stack>
      </Box>
    </div>
  );
}
