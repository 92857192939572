import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import { SavedContact } from "@freightsimple/generated-dashboard-openapi-client";

import { AddContactModal } from "./AddContactModal";

export interface AddContactModalProps {
  savedLocationId: UUID;
  onAddNewContact: (_: SavedContact) => void;
  onCancel: () => void;
}

export function useAddContactModal() {
  const showAddContactModal = useModal<SavedContact>();

  function show(savedLocationId: UUID) {
    const p = new Promise<SavedContact | undefined>((resolve) => {
      showAddContactModal(
        (done) => {
          return (
            <AddContactModal
              savedLocationId={savedLocationId}
              onAddNewContact={done}
              onCancel={() => done()}
            ></AddContactModal>
          );
        },
        (data) => {
          resolve(data);
        },
      );
    });

    return p;
  }

  return show;
}
