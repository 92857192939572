import Button from "../../../Cargo/Controls/Button";
import Input from "../../../Cargo/Controls/Input";
import Link from "../../../Cargo/Controls/Link";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import Logo from "../../../Cargo/Icons/Logo";
import DynamicHeightSpacer from "../../../Cargo/Layout/DynamicSpacer";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ErrorMessage } from "../../../Cargo/Text/Text";
import { Label } from "../../../Cargo/Text/Label";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import useSignIn from "../Hooks/useSignIn";
import { setEmailAddressForReset } from "../Slices/authenticationSlice";

const LoginTitle = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: var(--freightsimple-color-normal-text);
`;
const LoginSubtitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  color: var(--freightsimple-color-light-text);
`;

interface SignInFormProps {
  onCreateAccountInstead: () => void;
  onForgotPassword: () => void;
  redirectAfterSignin: boolean;
  onSuccess: () => void;
}

export function SignInForm(props: SignInFormProps) {
  const { onCreateAccountInstead, onForgotPassword, redirectAfterSignin } =
    props;
  const dispatch = useDispatch();
  const { signIn, email, setEmail, password, setPassword, errorMessage } =
    useSignIn();

  function updateEmail(email: string) {
    setEmail(email);
    dispatch(setEmailAddressForReset(email));
  }

  async function onSignIn() {
    const success = await signIn(redirectAfterSignin); // Do not redirect

    if (success) {
      props.onSuccess();
    }
  }
  return (
    <Stack align="left">
      <Logo></Logo>
      <DynamicHeightSpacer minHeight={20} maxHeight={120} />
      <LoginTitle>Log into your profile</LoginTitle>
      <DynamicHeightSpacer minHeight={8} maxHeight={32} />
      <LoginSubtitle>
        Sign into your FreightSimple profile and simplify your shipping.
      </LoginSubtitle>
      <DynamicHeightSpacer minHeight={8} maxHeight={48} />
      <Stack align="left" style={{ width: "100%" }}>
        <Label>Your Email</Label>
        <Input
          type="text"
          value={email}
          onChange={(value) => updateEmail(value)}
          errorMessage={undefined}
          forceValidation={true}
          autoComplete="email"
          name="sign_in_email"
        />
        <Spacer height={18} />

        <HorizontalStack align="spread" width="100%">
          <Label>Password</Label>
          <LinkButton
            onClick={onForgotPassword}
            regularWeight
            style={{
              position: "relative",
              top: "3px",
            }}
          >
            Forgot your password?
          </LinkButton>
        </HorizontalStack>
        <Input
          type="password"
          value={password}
          onChange={(value) => setPassword(value)}
          errorMessage={undefined}
          forceValidation={true}
          autoComplete="current-password"
          name="sign_in_password"
        />
      </Stack>
      <Spacer height={32} />
      <Button
        onClick={onSignIn}
        id="sign_in_button"
        label="Sign In"
        stackStyle={{ width: "100%" }}
        width="100%"
        size="xl"
        textStyle={{
          fontSize: "16px",
          fontWeight: 400,
          marginTop: "6px",
        }}
      />
      <Spacer height={16} />
      {errorMessage && (
        <>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <Spacer height={16} />
        </>
      )}
      <Stack align="center" style={{ width: "100%" }}>
        <HorizontalStack>
          <span>New to FreightSimple?</span>
          <Spacer width={4} />
          <Link onClick={onCreateAccountInstead}>Create a profile</Link>
        </HorizontalStack>
      </Stack>
    </Stack>
  );
}
