import moment from "moment";

export function formatExpectedDeliveryHours(
  unformatted: string | undefined,
): string | undefined {
  if (unformatted === undefined) {
    return undefined;
  }

  function timeFormat(t: string) {
    if (t.includes(":00")) {
      // On the hour, use a simpler format
      return moment(t, "hh:mmA").format("ha");
    } else {
      return moment(t, "hh:mmA").format("h:mma");
    }
  }

  const parts = unformatted.split(" - ");

  if (parts.length === 1) {
    return unformatted;
  }

  const from = timeFormat(parts[0]);
  const until = timeFormat(parts[1]);

  return `${from}-${until}`;
}
