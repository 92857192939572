export function ensureAccessorialsContain(
  currentAccessorials: string[],
  expectedAccessorials: string[],
): string[] {
  // Create a Set from the current accessorials to ensure uniqueness
  const uniqueAccessorials = new Set(currentAccessorials);

  // Iterate through expected accessorials and add them if they're not already present
  for (const accessorial of expectedAccessorials) {
    uniqueAccessorials.add(accessorial);
  }

  // Convert the Set back to an array and return
  return Array.from(uniqueAccessorials);
}
