import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import { ErrorMessageType } from "../Validation";
import { formatPhoneNumber } from "./formatPhoneNumber";

import Input from "./Input";

interface PhoneInputProps {
  id?: string;
  phoneNumber?: string;
  extension?: string;
  onPhoneNumberChange: (e: string) => void;
  onExtensionChange: (e: string) => void;
  phoneNumberErrorMessage: ErrorMessageType;
  extensionErrorMessage: ErrorMessageType;
  forceValidation: boolean;
  preferPlaceholder?: boolean;
  width?: number;
}

function unformatPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, "");
}

function sanitizePhoneExtension(phoneNumberExtension: string) {
  return phoneNumberExtension.replace(/\D/g, "").substr(0, 6);
}

const PhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
  return (
    <HorizontalStack align="left" width="100%">
      <Input
        type="tel"
        name="contact_phone_number_phone"
        label={props.preferPlaceholder ? undefined : `Phone Number`}
        placeholder={props.preferPlaceholder ? `Phone Number` : undefined}
        pattern="^\+?1?\d{10}$"
        value={formatPhoneNumber(props.phoneNumber)}
        // Enough for +1 (778) 668 5574
        maxLength={17}
        onChange={(
          newValue: string,
          e: React.FormEvent<HTMLInputElement> | undefined,
        ) => {
          let newRawDigits = unformatPhoneNumber(newValue);

          // We have a problem when hitting backspace when we get to +1 (777),
          // Because when we strip the non-numbers, we end up with the same result
          // and get stuck in a cycle
          //
          // So if we're in a cycle where the new raw digits equals the old raw digits
          // then remove the last of the new raw digits to perform the delete we
          // wanted to do
          if (newRawDigits === props.phoneNumber && newRawDigits.length < 10) {
            newRawDigits = newRawDigits.slice(0, -1);
          }

          if (newRawDigits[0] === "1") {
            newRawDigits = newRawDigits.substr(0, 11);
          } else {
            newRawDigits = newRawDigits.substr(0, 10);
          }

          const formatted = formatPhoneNumber(newRawDigits);

          // This is special code to deal with putting the cursor behind the ) while typing
          // if the formatting inserted a )  eg +1 (77<cursor>), not +1 (77)<cursor>

          // Basically, if it ends with ) and the cursor is at the end
          if (
            formatted.endsWith(")") &&
            e?.currentTarget.selectionStart === newValue.length &&
            e?.currentTarget.selectionEnd === newValue.length
          ) {
            const currentTarget = e.currentTarget;

            // We need a timeout to break out of the render cycle
            // There is probably a more elegant way,
            // but this seems to work
            setTimeout(() => {
              currentTarget.setSelectionRange(
                formatted.length - 1,
                formatted.length - 1,
              );
            }, 10);
          }

          props.onPhoneNumberChange(newRawDigits);
        }}
        required
        errorMessage={props.phoneNumberErrorMessage}
        forceValidation={props.forceValidation}
        width={props.width}
      ></Input>
      <Spacer width={10} />
      <Input
        type="tel"
        name="contact_phone_number_extension"
        label={props.preferPlaceholder ? undefined : `Ext.`}
        placeholder={props.preferPlaceholder ? `Ext.` : undefined}
        value={props.extension || ""}
        onChange={(value: string) => {
          const sanitized = sanitizePhoneExtension(value);

          props.onExtensionChange(sanitized);
        }}
        errorMessage={props.extensionErrorMessage}
        forceValidation={props.forceValidation}
        width={80}
      ></Input>
    </HorizontalStack>
  );
};
export default PhoneInput;
