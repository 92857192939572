import { CenteredContainer } from "../../Cargo/Layout/Container";
import useQuery from "../../Hooks/useQuery";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function ReferralScreen() {
  const query = useQuery();
  const navigate = useNavigate();

  const referralCode = query.code as string | undefined;

  useEffect(
    function () {
      if (referralCode !== undefined && referralCode !== "") {
        localStorage.setItem("referralCode", referralCode);

        navigate("/create-account");
      }
    },

    [referralCode],
  );

  if (referralCode === undefined || referralCode === "") {
    return <CenteredContainer>⚠️ Unknown Referral Code</CenteredContainer>;
  }

  // We should have redirected
  return <></>;
}
