import { ErrorMessageType } from "../../../Cargo/Validation";
import {
  errorMessageForDescription,
  errorMessageForTemperatureHandling,
} from "../../LineItems/Validators/errorMessagesForLineItem";
import { SavedCommodity } from "@freightsimple/generated-dashboard-openapi-client";

export interface SavedCommodityErrors {
  handlingUnitType: ErrorMessageType;
  description: ErrorMessageType;
  isStackable: ErrorMessageType;
  temperatureHandling: ErrorMessageType;
}

export function errorMessagesForSavedCommodity(
  savedCommodity: Partial<SavedCommodity>,
): SavedCommodityErrors {
  return {
    handlingUnitType:
      savedCommodity.handlingUnitType === undefined ? "Required" : undefined,
    description: errorMessageForDescription(savedCommodity.description),
    isStackable:
      savedCommodity.isStackable === undefined ? "Required" : undefined,
    temperatureHandling:
      savedCommodity.temperatureHandling === undefined
        ? "Required"
        : errorMessageForTemperatureHandling(
            undefined,
            savedCommodity.temperatureHandling,
          ),
  };
}
