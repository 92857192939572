import auth0 from "auth0-js";
import { auth0Creds } from "../../../environment";
import { useAuthentication } from "../Slices/authenticationSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Made this for the reset password flow. Should probably be combined with the other hook at some point

interface AccessAndIdTokens {
  accessToken: string;
  idToken: string;
}

export function useSignInDirectly() {
  const navigate = useNavigate();
  const { authenticate, redirectUrl } = useAuthentication();

  async function signIn(
    email: string,
    password: string,
  ): Promise<AccessAndIdTokens> {
    return new Promise(function (resolve, reject) {
      const auth = new auth0.WebAuth({
        domain: auth0Creds().domain,
        clientID: auth0Creds().clientId,
        scope: "openid profile email",
      });

      auth.client.login(
        {
          username: email,
          password: password,
          realm: "Username-Password-Authentication",
          audience: auth0Creds().audience,
        },
        async function (error, result) {
          if (error) {
            return reject(error.description);
          } else {
            const accessToken = result.accessToken;
            const idToken = result.idToken;

            return resolve({ accessToken, idToken });
          }
        },
      );
    });
  }

  async function continueAfterReset(accessToken: string, idToken: string) {
    await authenticate(accessToken, idToken);

    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate("/");
    }
  }

  return { signIn, continueAfterReset };
}

function useSignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { authenticate, redirectUrl } = useAuthentication();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  async function signIn(redirectAfterSuccess = true) {
    const auth = new auth0.WebAuth({
      domain: auth0Creds().domain,
      clientID: auth0Creds().clientId,
      scope: "openid profile email",
    });

    return new Promise<boolean>(function (resolve) {
      auth.client.login(
        {
          username: email,
          password: password,
          realm: "Username-Password-Authentication",
          audience: auth0Creds().audience,
        },
        async function (error, result) {
          if (error) {
            setErrorMessage(error.description);
            return resolve(false);
          } else {
            setErrorMessage(undefined);
            const accessToken = result.accessToken;
            const idToken = result.idToken;

            await authenticate(accessToken, idToken);

            if (redirectAfterSuccess) {
              if (redirectUrl) {
                navigate(redirectUrl);
              } else {
                navigate("/");
              }
            }

            return resolve(true);
          }
        },
      );
    }).catch(function (e) {
      console.error("Error with signin", { e });
    });
  }

  return { signIn, email, setEmail, password, setPassword, errorMessage };
}

export default useSignIn;
