import { useModal } from "../Modal/useModal";
import { ReactNode } from "react";
import { MultiOptionConfirmModalOptions } from "./MultiOptionConfirmModalOptions";
import { MultiOptionConfirmModal } from "./MultiOptionConfirmModal";

export function useMultiOptionConfirmModal(
  title: ReactNode,
  message: ReactNode,
  options: MultiOptionConfirmModalOptions,
): () => Promise<number | undefined> {
  const showModal = useModal<number>();

  function confirm(): Promise<number | undefined> {
    const p = new Promise<number | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <MultiOptionConfirmModal
              onDone={(index: number | undefined) => {
                done(index);
              }}
              message={message}
              title={title}
              options={options}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }
  return confirm;
}
