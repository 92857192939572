import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import PageTitle from "../../../Cargo/Text/PageTitle";

import styled from "styled-components";

const Text = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 16px;
  color: var(--freightsimple-color-normal-text);
  text-align: center;
`;

const Text2 = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 16px;
  color: var(--freightsimple-color-light-text);
  text-align: center;
`;

function ShipmentError() {
  return (
    <>
      <PageTitle>Nearly there</PageTitle>
      <Stack align="center">
        <Spacer height={51} />
        <Text>
          Nearly there! Looks like this shipment needs a little help to finish
          booking.
        </Text>
        <Spacer height={60} />
        <img width="180" src="/waiting-truck.png" />
        <Spacer height={60} />
        <Text2>
          Our team will finish the job and contact you with your documents
          within the next 60 minutes.
        </Text2>
        <Spacer height={15} />
      </Stack>
    </>
  );
}
export default ShipmentError;
