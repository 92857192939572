import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ModalTitle } from "../../../Cargo/Text/Text";
import { ModalBody } from "../../ViewShipments/Modals/UpdatedBOLModal";

interface StripeBankAdviceModalProps {
  onDone: () => void;
}

export function StripeBankAdviceModal(props: StripeBankAdviceModalProps) {
  return (
    <Stack align="center" style={{ padding: "16px", width: "800px" }}>
      <ModalTitle>Connect Bank Account with Void Cheque Information</ModalTitle>
      <Spacer height={32} />
      <ModalBody>
        Connecting your bank account enables easy convenient payments for your
        freight. You have the option to connect with your online bank's username
        and password, if you have it.
      </ModalBody>
      <Spacer height={16} />
      <ModalBody>
        More likely, you want to enter your account's void cheque information.
        This is called 'Manually Verify'.
      </ModalBody>
      <Spacer height={16} />
      <img
        src="/manualBankVerify.png"
        style={{ margin: "32px", width: "500px" }}
      />
      <ModalBody>
        Make sure you click on 'Manually Verify' at the bottom of the next
        screen to enter void cheque info.
      </ModalBody>
      <Spacer height={32} />
      <HorizontalStack align="center">
        <Button onClick={props.onDone}>Got it!</Button>
      </HorizontalStack>
    </Stack>
  );
}
