import { Broker } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import { SwitchBrokerModal } from "./SwitchBrokerModal";

export function useSwitchBrokerModal() {
  const showModal = useModal<UUID>();

  async function show(broker: Broker): Promise<UUID | undefined> {
    return new Promise<UUID | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <SwitchBrokerModal
              broker={broker}
              onDone={done}
              onCancel={function () {
                done(undefined);
              }}
            ></SwitchBrokerModal>
          );
        },
        (data) => {
          resolve(data);
        },
      );
    });
  }

  return show;
}
