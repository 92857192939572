import { animated, useSpring, useTransition } from "@react-spring/web";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { SliderDirection } from "./SliderDirection";

interface SliderProps {
  currentIndex: number;
  children: ReactElement[];
  style?: React.CSSProperties;
}

function Slider(props: SliderProps) {
  const { currentIndex, children } = props;
  // This animation will slide the components horizontally
  const sliding = useSpring({
    to: { transform: `translate3d(-${currentIndex * 100}%, 0, 0)` },
    from: { transform: "translate3d(0%, 0, 0)" },
  });

  return (
    <div style={{ width: "100%", height: "100%", ...props.style }}>
      <animated.div
        style={{
          ...sliding,
          display: "flex",
          willChange: "transform",
        }}
      >
        {React.Children.map(children, (child, index) => (
          <div style={{ flex: "none", width: "100%" }} key={index}>
            {child}
          </div>
        ))}
      </animated.div>
    </div>
  );
}

interface Slider2Props {
  children: ReactNode[];
  currentIndex: number;
  direction?: SliderDirection;
  style?: React.CSSProperties;
}
export function Slider2({
  children,
  currentIndex,
  direction = SliderDirection.Right,
  style,
}: Slider2Props) {
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  const transitions = useTransition(currentIndex, {
    initial: { opacity: 1, transform: "translate3d(0%,0,0)" },
    from: { opacity: 0, transform: `translate3d(${100 * direction}%,0,0)` },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: {
      opacity: 0,
      transform: `translate3d(${-100 * direction}%,0,0)`,
    },
    config: { tension: 350, friction: 60 },
  });

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref.current?.clientHeight, style?.minHeight]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        minHeight: height,
        ...style,
      }}
    >
      {transitions((animationStyle, i) => (
        <animated.div
          style={{
            ...animationStyle,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <div ref={ref}>{children[i]}</div>
        </animated.div>
      ))}
    </div>
  );
}

export default Slider;
