import { ReactNode } from "react";
import { useModal } from "../../../../Cargo/Modal/useModal";
import { ConfirmResidentialModal } from "./ConfirmResidentialModal";
import { ConfirmResidentialResult } from "./ConfirmResidentialResult";

export function useResidentialConfirmModal(): (
  _?: ReactNode,
) => Promise<ConfirmResidentialResult> {
  const showModal = useModal<ConfirmResidentialResult>();

  function confirm(): Promise<ConfirmResidentialResult> {
    const p = new Promise<ConfirmResidentialResult>((resolve) => {
      showModal(
        (done) => {
          return (
            <ConfirmResidentialModal
              onDone={(confirmed: ConfirmResidentialResult) => {
                done(confirmed);
              }}
            />
          );
        },
        (confirmed) => {
          if (confirmed) {
            resolve(confirmed);
          } else {
            // Should not be here
            console.error("Should not here here");
          }
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }
  return confirm;
}
