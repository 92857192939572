import { SavedLocation } from "@freightsimple/generated-dashboard-openapi-client";
import { fullStateOrProvinceName } from "../../../Helpers/fullStateOrProvinceName";

export function useFilterSavedLocations(filter: string) {
  return function doFilter(location: SavedLocation) {
    if (filter === "") {
      return true;
    }

    if (
      location.location.businessName
        .toLowerCase()
        .includes(filter.toLowerCase())
    ) {
      return true;
    }

    if (
      location.location.address.addressLine
        .toLowerCase()
        .includes(filter.toLowerCase())
    ) {
      return true;
    }

    if (
      location.location.address.addressLine2
        ?.toLowerCase()
        ?.includes(filter.toLowerCase())
    ) {
      return true;
    }

    if (
      location.location.address.postalCode
        ?.toLowerCase()
        ?.includes(filter.toLowerCase())
    ) {
      return true;
    }

    if (
      location.location.address.stateOrProvinceCode
        ?.toLowerCase()
        ?.includes(filter.toLowerCase())
    ) {
      return true;
    }

    if (
      fullStateOrProvinceName(location.location.address.stateOrProvinceCode)
        ?.toLowerCase()
        ?.includes(filter.toLowerCase())
    ) {
      return true;
    }

    if (
      location.location.address.city
        ?.toLowerCase()
        ?.includes(filter.toLowerCase())
    ) {
      return true;
    }

    return false;
  };
}
