import Button from "../../../Cargo/Controls/Button";
import Input from "../../../Cargo/Controls/Input";
import { QuestionBubbleContext } from "../../../Cargo/Controls/QuestionBubbleContext";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Legalese, ModalTitle } from "../../../Cargo/Text/Text";
import NotesQuestionBubble from "../../BookShipment/Components/Addresses/QuestionBubbles/NotesQuestionBubble";
import {
  BoothNumberQuestionBubble,
  ReferenceNumberQuestionBubble,
} from "../../BookShipment/Components/Addresses/QuestionBubbles/ReferenceNumberQuestionBubble";
import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { ReferenceNumberInput } from "../../ReferenceNumbers/Components/ReferenceNumberInput";
import { useValidateReferenceNumber } from "../../ReferenceNumbers/Hooks/useValidateReferenceNumber";

interface ModifyReferenceAndNotesModalProps {
  shipmentId: string;
  referenceNumber: string;
  boothNumber: string;
  notes: string;
  onDone: (result: ReferenceNumberAndNotes) => void;
  onCancel: () => void;
  locationContext: LocationContext | undefined;
  locationType: LocationType;
}

export function ModifyReferenceAndNotesModal(
  props: ModifyReferenceAndNotesModalProps,
) {
  const { onCancel, locationContext } = props;
  const [referenceNumber, setReferenceNumber] = useState(props.referenceNumber);
  const [notes, setNotes] = useState(props.notes);
  const [boothNumber, setBoothNumber] = useState(props.boothNumber);

  const validation = useValidateReferenceNumber({
    locationContext: props.locationContext,
    originalReferenceNumber: props.referenceNumber,
    shipmentId: props.shipmentId,
  });

  function onDone() {
    props.onDone({ referenceNumber, boothNumber, notes });
  }

  const displayName =
    locationContext === LocationContext.Pickup ? "Pickup" : "Delivery";
  return (
    <div style={{ width: "420px" }}>
      <ModalTitle>Change Reference and Notes</ModalTitle>
      {props.locationContext === LocationContext.Pickup && (
        <Legalese>
          Please include any notes or reference numbers that would help the
          driver complete this pickup
        </Legalese>
      )}
      {props.locationContext === LocationContext.Delivery && (
        <Legalese>
          Please include any notes or reference numbers that would help the
          driver complete this delivery
        </Legalese>
      )}

      <Spacer height={16} />

      <ReferenceNumberInput
        validation={validation}
        label={
          <>
            {displayName} Reference Number
            <ReferenceNumberQuestionBubble
              displayName={displayName}
              context={QuestionBubbleContext.VerySmall}
            />
          </>
        }
        width={400}
        value={referenceNumber}
        onChange={(newValue: string) => setReferenceNumber(newValue)}
      />

      {props.locationType === LocationType.TradeShow && (
        <>
          <Spacer height={16} />
          <Input
            label={
              <>
                {displayName} Booth Number
                <BoothNumberQuestionBubble
                  displayName={displayName}
                  context={QuestionBubbleContext.VerySmall}
                />
              </>
            }
            type="text"
            width={400}
            value={boothNumber}
            onChange={(newValue: string) => setBoothNumber(newValue)}
          />
        </>
      )}

      <Spacer height={16} />
      <Input
        label={
          <>
            Notes
            <NotesQuestionBubble
              displayName={displayName}
              context={QuestionBubbleContext.VerySmall}
            />
          </>
        }
        placeholder="eg. Blue dock door, or ring bell"
        type="text"
        width={400}
        value={notes}
        onChange={(newValue: string) => setNotes(newValue)}
        errorMessage={undefined}
      />

      <Spacer height={24} />
      <HorizontalStack width="100%" align="spread">
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>

        <Button onClick={onDone}>Done</Button>
      </HorizontalStack>
    </div>
  );
}

interface ReferenceNumberAndNotes {
  referenceNumber: string;
  boothNumber: string;
  notes: string;
}
