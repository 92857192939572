import { ErrorMessageType } from "../../../Cargo/Validation";
import {
  errorMessageForContactName,
  errorMessageForPhoneNumber,
} from "../../Contacts/Validators/errorMessagesForContact";
import { EmergencyContactDetails } from "@freightsimple/generated-dashboard-openapi-client";

export interface EmergencyContactDetailsErrors {
  contactName: ErrorMessageType;
  contactPhoneNumber: ErrorMessageType;
  contactPhoneNumberExtension: ErrorMessageType;
}

export function errorMessagesForEmergencyContact(
  emergencyContactDetails: Partial<EmergencyContactDetails> | undefined,
): EmergencyContactDetailsErrors {
  if (emergencyContactDetails === undefined) {
    return {
      contactName: undefined,
      contactPhoneNumber: undefined,
      contactPhoneNumberExtension: undefined,
    };
  }

  const { contactName, contactPhoneNumber } = emergencyContactDetails;
  return {
    contactName: errorMessageForContactName(contactName),
    contactPhoneNumber: errorMessageForPhoneNumber(contactPhoneNumber),
    contactPhoneNumberExtension: undefined,
  };
}
