import { ReactElement } from "react";
import { FirstToMatchEnum } from "./FirstToMatchEnum";

interface FirstToMatchElement {
  when?: boolean | FirstToMatchEnum;
  show: ReactElement;
}

interface FirstToMatchProps {
  components: Array<FirstToMatchElement>;
}
function FirstToMatch(props: FirstToMatchProps): ReactElement {
  function toFind(c: FirstToMatchElement) {
    if (c.when === undefined) {
      return false;
    }

    if (c.when === FirstToMatchEnum.Default) {
      return true;
    }

    return c.when;
  }
  return props.components.find(toFind)?.show ?? <></>;
}
export default FirstToMatch;
