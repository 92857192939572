import lottie, { AnimationConfigWithPath, AnimationItem } from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { CSSProperties } from "styled-components";

interface AnimationProps {
  pathToAnimation: string;
  lottieFilesUrl: string;
  style?: CSSProperties;
  loop?: boolean;
  speed?: number;
}

const Animation: React.FC<AnimationProps> = (props: AnimationProps) => {
  const [, setLottieInstance] = useState<AnimationItem | undefined>();
  const lottieContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = lottieContainer.current;
    if (element === null) {
      return;
    }

    const lottieConfig: AnimationConfigWithPath = {
      renderer: "svg",
      loop: props.loop ? true : false,
      autoplay: true,
      path: props.pathToAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMinYMin slice",
      },
      container: element,
    };

    const newInstance = lottie.loadAnimation(lottieConfig);
    newInstance.setSpeed(props.speed || 1.0);
    setLottieInstance(newInstance);
  }, []);

  return <div style={props.style} ref={lottieContainer} />;
};
export default Animation;
