import { Invoice } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { SelectPaymentMethodAndConfirmPaymentModal } from "./SelectPaymentMethodAndConfirmPaymentModal";

export function useSelectPaymentMethodAndConfirmPaymentModal() {
  const showModal = useModal<void>();

  async function show(selectedInvoices: Invoice[]): Promise<void> {
    return new Promise<void>((resolve) => {
      showModal(
        (done) => {
          return (
            <SelectPaymentMethodAndConfirmPaymentModal
              onFinished={() => {
                done();
              }}
              selectedInvoices={selectedInvoices}
            />
          );
        },
        async (data) => {
          resolve(data);
        },
        {
          preventBackdropClick: true,
        },
      );
    });
  }
  return show;
}
