import Input from "../../../Cargo/Controls/Input";
import PhoneInput from "../../../Cargo/Controls/PhoneInput";
import Form from "../../../Cargo/Layout/Form";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import { ContactErrors } from "../Validators/errorMessagesForContact";
import { Contact } from "@freightsimple/generated-dashboard-openapi-client";

interface ContactInputProps {
  contactChanged: (_: Partial<Contact>) => void;
  contact: Partial<Contact>;
  errorMessages: ContactErrors | undefined;
  forceValidation: boolean;
}

const LargeContactInput: React.FC<ContactInputProps> = (
  props: ContactInputProps,
) => {
  return (
    <>
      <Form.Group>
        <HorizontalStack width="420px" verticalAlign="top">
          <Input
            type="text"
            name="contact_contact_name"
            label={`Contact Name`}
            value={props.contact?.contactName || ""}
            onChange={(value: string) =>
              props.contactChanged({
                contactName: value,
              })
            }
            required
            errorMessage={props.errorMessages?.contactName}
            forceValidation={props.forceValidation}
            width={420}
          ></Input>
        </HorizontalStack>
        <HorizontalStack width="420px" verticalAlign="top">
          <PhoneInput
            phoneNumber={props.contact.phoneNumber}
            extension={props.contact.phoneNumberExtension}
            onPhoneNumberChange={(value: string) => {
              props.contactChanged({ phoneNumber: value });
            }}
            onExtensionChange={(value: string) => {
              props.contactChanged({
                phoneNumberExtension: value,
              });
            }}
            phoneNumberErrorMessage={props.errorMessages?.phoneNumber}
            extensionErrorMessage={undefined}
            forceValidation={props.forceValidation}
          />
        </HorizontalStack>
        <HorizontalStack width="420px" verticalAlign="top">
          <Input
            type="email"
            name="contact_email_address"
            label={`Email Address`}
            value={props.contact?.emailAddress || ""}
            required
            onChange={(value: string) =>
              props.contactChanged({
                emailAddress: value,
              })
            }
            errorMessage={props.errorMessages?.emailAddress}
            forceValidation={props.forceValidation}
            width={420}
          ></Input>
        </HorizontalStack>
      </Form.Group>
    </>
  );
};
export default LargeContactInput;
