import { useWindowSize } from "../Helpers/useWindowSize";

export function useDynamicWidth(minWidth: number, maxWidth: number) {
  const { width } = useWindowSize();

  let elementHeight = minWidth;

  if (width !== undefined) {
    const m = (maxWidth - minWidth) / (1480 - 1280);
    const c = minWidth - 1280 * m;

    elementHeight = m * width + c;
  }

  if (elementHeight < minWidth) {
    return minWidth;
  }

  if (elementHeight > maxWidth) {
    return maxWidth;
  }

  return Math.floor(elementHeight);
}
