import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading3, Legalese } from "../../../Cargo/Text/Text";
import { NmfcSubItemChoice } from "@freightsimple/generated-dashboard-openapi-client";
import { ReactNode, useState } from "react";
import styled from "styled-components";

interface SelectNmfcSubItemModalProps {
  subItems: Array<NmfcSubItemChoice>;
  onConfirm: (subItem: string) => void;
  onCancel: () => void;
}

interface ListBoxProps<T> {
  items: Array<T>;
  element: (_: T, isSelected: boolean) => ReactNode;
  height: number;
  selectedItem: T | undefined;
  onSelect: (_: T) => void;
}

interface ListBoxRowBodyProps {
  isSelected: boolean;
}

const ListBoxRowBody = styled.div<ListBoxRowBodyProps>`
  cursor: pointer;
  padding: 16px;
  margin: 16px 0;
  &:hover {
    background-color: ${Colors.VeryVeryLightBlue};
  }

  ${(props) =>
    props.isSelected
      ? `border: 1px solid ${Colors.Blue};`
      : "1px solid transparent"}
`;

function ListBox<T>(props: ListBoxProps<T>) {
  return (
    <div
      style={{
        width: `600px`,
        height: `${props.height}px`,
        overflowY: "scroll",
      }}
    >
      {props.items.map(function (item, index) {
        const isSelected = props.selectedItem === item;
        return (
          <ListBoxRowBody
            key={index}
            // TODO: Should this be a deep equal?
            isSelected={isSelected}
            onClick={function () {
              props.onSelect(item);
            }}
          >
            <HorizontalStack verticalAlign="middle" align="spread">
              <div>{props.element(item, isSelected)}</div>
              <div style={{ width: "32px", marginLeft: "64px" }}>
                {isSelected && (
                  <Icon
                    name="check-circle"
                    color={Colors.Green}
                    size={16}
                    solid
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </div>
            </HorizontalStack>
          </ListBoxRowBody>
        );
      })}
    </div>
  );
}

const NmfcSubItemTitle = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  color: var(--freightsimple-color-normal-text);
  margin-right: 16px;
  font-size: 20px;
`;

const NmfcSubItemDescription = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  color: var(--freightsimple-color-light-text);
`;

export function SelectNmfcSubItemModal(props: SelectNmfcSubItemModalProps) {
  const [selectedSubItem, setSelectedSubItem] = useState<
    undefined | NmfcSubItemChoice
  >();
  return (
    <Stack
      id="SelectNmfcSubItemModal"
      align="left"
      style={{ padding: "16px", width: "640px" }}
    >
      <Spacer height={16} />
      <Heading3>Select the applicable NMFC Subitem</Heading3>
      <Legalese>
        Please select the description most relevant to your shipment
      </Legalese>
      <Spacer height={16} />
      <ListBox<NmfcSubItemChoice>
        items={props.subItems}
        element={function (subItem) {
          return (
            <HorizontalStack>
              <NmfcSubItemTitle>{subItem.nmfcCodeWithSubItem}</NmfcSubItemTitle>
              <NmfcSubItemDescription>
                {subItem.description}
              </NmfcSubItemDescription>
            </HorizontalStack>
          );
        }}
        height={300}
        selectedItem={selectedSubItem}
        onSelect={setSelectedSubItem}
      ></ListBox>

      <Spacer height={16} />
      <HorizontalStack>
        <Button secondary onClick={props.onCancel}>
          Cancel
        </Button>
        <Spacer width={16} />
        <Button
          disabled={selectedSubItem === undefined}
          onClick={function () {
            if (selectedSubItem) {
              props.onConfirm(selectedSubItem.nmfcCodeWithSubItem);
            }
          }}
        >
          Confirm
        </Button>
      </HorizontalStack>
      <Spacer height={16} />
    </Stack>
  );
}
