import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface PickupContactPageProp {
  shipment: Shipment;
}

export function PickupContactPage(_props: PickupContactPageProp) {
  return <Heading1>Pickup Cosntact</Heading1>;
}
