import { useModal } from "../../../Cargo/Modal/useModal";

import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { ModifyReferenceAndNotesModal } from "./ModifyReferenceAndNotesAfterBooking";

interface ReferenceNumberAndNotes {
  referenceNumber: string;
  boothNumber: string;
  notes: string;
}

export function useModifyReferenceAndNotesAfterBookingModalImplementation(
  locationContext: LocationContext,
  locationType: LocationType,
) {
  const showChangeContactModal = useModal<ReferenceNumberAndNotes>();

  async function show(
    shipmentId: string,
    referenceNumber: string,
    boothNumber: string,
    notes: string,
  ) {
    const p = new Promise<ReferenceNumberAndNotes | undefined>((resolve) => {
      showChangeContactModal(
        (done) => {
          return (
            <ModifyReferenceAndNotesModal
              shipmentId={shipmentId}
              referenceNumber={referenceNumber}
              boothNumber={boothNumber}
              notes={notes}
              onDone={done}
              onCancel={done}
              locationContext={locationContext}
              locationType={locationType}
            ></ModifyReferenceAndNotesModal>
          );
        },
        (data) => {
          resolve(data);
        },
      );
    });

    return p;
  }

  return show;
}
