import {
  ShipmentState,
  Broker,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useModalsSlice } from "../../../Cargo/Modal/modalsSlice";
import { UUID } from "../../../Cargo/Types/types";
import { useShipmentService } from "../../../Services/ShipmentService";
import { useSwitchBrokerModal } from "./useSwitchBrokerModal";
import { useUpdatedBOLModal } from "./useUpdatedBOLModal";

export function useChangeBrokerAfterBookingModal(
  shipmentId: UUID,
  shipmentState: ShipmentState,
  currentBroker: Broker | undefined,
) {
  const showSwitchBrokerModal = useSwitchBrokerModal();
  const showUpdatedBOLModal = useUpdatedBOLModal();
  const shipmentService = useShipmentService();
  const { setLoading } = useModalsSlice();

  if (currentBroker === undefined) {
    return () => false;
  }

  // Returns if the broker has changed, hence we should reload
  async function show(): Promise<boolean> {
    if (currentBroker === undefined) {
      return false;
    }

    // We need to show a screen that allows them to select from any of the saved brokers,
    // and optionally modify one of them or add a new one
    const newSavedBrokerId = await showSwitchBrokerModal(currentBroker);

    if (newSavedBrokerId === undefined) {
      // Cancelled
      return false;
    }

    setLoading(true);
    // Make an API call to change the broker
    await shipmentService.setBroker(shipmentId, newSavedBrokerId);
    setLoading(false);

    // If the shipment is in transit, then it's too late to give the driver
    // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
    if (shipmentState !== ShipmentState.InTransit) {
      await showUpdatedBOLModal();
    }

    // Do we need to do something with the custom documents?
    // CS: Jan 2022: I'm currently taking the stance that we do nothing
    // - If they're not yet uploaded then nothing to do anyway
    // - If they have been uploaded, then they're still probably mostly valid, and hopefully
    //   the carrier can work with them
    // We should at some point give the customer the ability to upload replacement customs docs
    return true;
  }

  return show;
}
