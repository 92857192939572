import { CountryCode } from "@freightsimple/generated-dashboard-openapi-client";
import { DatePicker } from "../../../Cargo/Dates/DatePicker";
import moment from "moment";
import { styled } from "styled-components";
import { ReactNode, useState } from "react";
import { pickupDateTooltips } from "./pickupDateTooltips";
import { DatePickerTooltipType } from "../../../Cargo/Dates/DatePickerTooltipType";

interface PickupDateControlProps {
  country?: CountryCode;
  date: string;
  onDateChange: (date: moment.Moment) => void;
}

const CalendarDescription = styled.div`
  color: var(--freightsimple-color-normal-text);
  font-size: 16px;
  margin-top: 16px;
  height: 16px;
`;

export function PickupDateControl(props: PickupDateControlProps) {
  const today = moment().startOf("day");
  const firstAllowedDate = today;
  const lastAllowedDate = today.clone().add(1, "month");
  const [tooltip, setTooltip] = useState<string | ReactNode>(undefined);
  return (
    <>
      <DatePicker
        selectedDate={props.date}
        onInitialize={setTooltip}
        setSelectedDate={function (
          date: string | undefined,
          tooltip: ReactNode | undefined,
        ) {
          if (date) {
            props.onDateChange(moment(date));
          }
          setTooltip(tooltip);
        }}
        firstAllowedDate={firstAllowedDate.format("YYYY-MM-DD")}
        lastAllowedDate={lastAllowedDate.format("YYYY-MM-DD")}
        showHolidayTooltipsForCountry={props.country}
        dateTooltips={{
          ...pickupDateTooltips,
          [DatePickerTooltipType.BeyondLastAllowedDate]:
            "⚠️ FreightSimple only supports booking shipments within the next month",
        }}
      />
      {tooltip && <CalendarDescription>{tooltip}</CalendarDescription>}
    </>
  );
}
