import {
  ShipmentState,
  Quote,
} from "@freightsimple/generated-dashboard-openapi-client";
import { ReactNode } from "react";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";

export function nameForShipmentState(
  state: ShipmentState,
  selectedQuote: Quote | undefined,
  cheapestQuote: Quote | undefined,
  manualQuotingOpen: boolean,
): ReactNode {
  switch (state) {
    case ShipmentState.Quoted:
    case ShipmentState.QuoteRequested: {
      if (manualQuotingOpen) {
        return (
          <HorizontalStack>
            <div>Preparing Quotes</div>
            <div>🦸‍♀️</div>
          </HorizontalStack>
        );
      } else {
        if (selectedQuote) {
          return `Quote`;
        } else if (cheapestQuote) {
          return `Quotes`;
        } else {
          return "No Quotes";
        }
      }
    }

    case ShipmentState.BookingConfirmed:
      return "Booked";
    case ShipmentState.BookingRequested:
    case ShipmentState.BookingFailed:
      return "Preparing";
    case ShipmentState.InTransit:
      return "In Transit";
    case ShipmentState.Delivered:
      return "Delivered";
    case ShipmentState.Cancelled:
      return "Cancelled";
    case ShipmentState.Lost:
      return `Lost`;
    case ShipmentState.OnHold:
      return `On Hold`;
  }
}
