import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

const BookShipmentSectionHeader = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 18px;
  color: var(--freightsimple-color-normal-text);
`;
const BookShipmentSectionDescription = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 14px;
  color: var(--freightsimple-color-light-text);
  max-width: 618px;
`;

interface BookShipmentSectionProps {
  header: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  index: number;
  id?: string;
  minHeight?: string;
}
function BookShipmentSmallSection(props: BookShipmentSectionProps) {
  const style: CSSProperties = {};
  if (props.minHeight) {
    style.minHeight = props.minHeight;
  }
  style.marginBottom = "64px";
  return (
    <HorizontalStack verticalAlign="top" style={style} id={props.id}>
      <Stack align="left">
        {props.header && (
          <BookShipmentSectionHeader>{props.header}</BookShipmentSectionHeader>
        )}
        {props.description && (
          <BookShipmentSectionDescription>
            {props.description}
          </BookShipmentSectionDescription>
        )}
        <Spacer height={12} />
        {props.children}
      </Stack>
    </HorizontalStack>
  );
}
export default BookShipmentSmallSection;
