import LinkButton from "../../../../Cargo/Controls/LinkButton";
import { Units } from "../../../../Helpers/units";

interface ConvertUnitsButtonProps {
  units: Units;
  setUnits: (_: Units) => void;
}

export function ConvertUnitsButton(props: ConvertUnitsButtonProps) {
  const text =
    props.units === Units.Imperial ? "Switch to cm/kg" : "Switch to in/lb";
  return (
    <LinkButton
      regularWeight
      onClick={function () {
        props.setUnits(
          props.units === Units.Imperial ? Units.Metric : Units.Imperial,
        );
      }}
      style={{ fontSize: "12px" }}
    >
      {text}
    </LinkButton>
  );
}
