import { ReactNode } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { isProduction } from "../../../environment";

const ErrorContainer = styled.div`
  padding: 2.5rem;
  border: 1px solid #ff0000;
  border-radius: 8px;
  margin: 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(255, 0, 0, 0.1);
`;

const ErrorHeading = styled.h2`
  color: #ff0000;
  margin: 0 0 0.2rem 0;
  font-size: 2rem;
  font-weight: 600;
`;

const ErrorSubHeading = styled.div`
  color: #444444;
  margin: 0 0 2rem 0;
  font-size: 1rem;
  font-weight: 400;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 500;
`;

const ErrorText = styled.pre`
  color: #ff0000;
  margin: 0.5rem 0 1.5rem 0;
  padding: 1rem;
  background-color: #fff5f5;
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-all;
  font-family: "Consolas", "Monaco", monospace;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const RetryButton = styled.button`
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #cc0000;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
  }
`;

const ProductionErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  min-height: 60vh;
  text-align: center;
`;

const ProductionErrorHeading = styled.h1`
  color: #2d3748;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const ProductionErrorMessage = styled.p`
  color: #4a5568;
  font-size: 1.125rem;
  max-width: 36rem;
  margin: 0 auto 2rem;
  line-height: 1.6;
`;

const ProductionRetryButton = styled.button`
  background-color: #3182ce;
  color: white;
  font-weight: 600;
  padding: 0.75rem 2rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;

  &:hover {
    background-color: #2c5282;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.5);
  }
`;

const ReferenceId = styled.div`
  color: #718096;
  font-size: 0.875rem;
  margin-top: 2rem;
`;

interface ErrorBoundaryViewProps {
  children: ReactNode;
}

export function ErrorBoundaryView(props: ErrorBoundaryViewProps) {
  const formatStack = (stack: string) => {
    if (!stack) return "";

    return stack
      .split("\n")
      .map((line) => {
        // Match the component name, file path, and line/column numbers
        const match = line.match(/at\s+(\w+)\s+\((.*?):(\d+):(\d+)\)/);
        if (!match) return null;

        const [, component, file, lineNum] = match;

        // Skip node_modules and internal components
        if (file.includes("node_modules") || component.startsWith("_")) {
          return null;
        }

        // Clean up the filename
        const fileName = file.split("/").pop()?.split("?")[0] ?? "unknown";

        return `${component} (${fileName} at line ${lineNum})`;
      })
      .filter(Boolean)
      .join("\n");
  };

  return (
    <Sentry.ErrorBoundary
      fallback={(errorData: {
        error: unknown;
        componentStack: string;
        eventId: string;
        resetError: () => void;
      }) => (
        <>
          {isProduction() ? (
            <ProductionErrorContainer role="alert">
              <ProductionErrorHeading>
                Oops! Something's not quite right
              </ProductionErrorHeading>
              <ProductionErrorMessage>
                We've hit a temporary snag. Our team has been notified and is
                working to get things back on track. Try refreshing the page or
                come back in a few minutes.
              </ProductionErrorMessage>
              <ProductionRetryButton onClick={errorData.resetError}>
                Try Again
              </ProductionRetryButton>
              {errorData.eventId && (
                <ReferenceId>Reference ID: {errorData.eventId}</ReferenceId>
              )}
            </ProductionErrorContainer>
          ) : (
            <ErrorContainer role="alert">
              <ErrorHeading>Something went wrong</ErrorHeading>
              <ErrorSubHeading>
                Please make sure to create a Jam / Linear to report this issue
              </ErrorSubHeading>

              <SectionHeading>Error Message:</SectionHeading>
              <ErrorText>
                {errorData.error instanceof Error
                  ? errorData.error.message
                  : "An unknown error occurred"}
              </ErrorText>

              {errorData.error instanceof Error && errorData.error.stack && (
                <>
                  <SectionHeading>Stack Trace:</SectionHeading>
                  <ErrorText>{formatStack(errorData.error.stack)}</ErrorText>
                </>
              )}

              <SectionHeading>Component Stack:</SectionHeading>
              <ErrorText>{formatStack(errorData.componentStack)}</ErrorText>

              <RetryButton onClick={errorData.resetError}>
                Try Again
              </RetryButton>
            </ErrorContainer>
          )}
        </>
      )}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
