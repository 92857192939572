import axios from "axios";

export class AuthorizationApi2 {
  baseUrl: string;
  accessToken: string;

  constructor(baseUrl: string, accessToken: string) {
    this.baseUrl = baseUrl;
    this.accessToken = accessToken;
  }

  async postAuthorizationVerifyToken(): Promise<number> {
    const options = {
      headers: { Authorization: `Bearer ${this.accessToken}` },
      validateStatus: () => true,
    };

    const response = await axios.post(
      `${this.baseUrl}/authorization/verify-token`,
      undefined,
      options,
    );

    return response.status;
  }
}
