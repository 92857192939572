import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading1, Legalese } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { PickupDetailsPage } from "../Pages/PickupDetailsPage";
import { PickupAddressPage } from "../Pages/PickupAddressPage";
import { PickupContactPage } from "../Pages/PickupContactPage";
import { PickupNotesPage } from "../Pages/PickupNotesPage";
import { DeliveryDetailsPage } from "../Pages/DeliveryDetailsPage";
import { DeliveryAddressPage } from "../Pages/DeliveryAddressPage";
import { DeliveryContactPage } from "../Pages/DeliveryContactPage";
import { DeliveryNotesPage } from "../Pages/DeliveryNotesPage";
import { LineItemsPage } from "../Pages/LineItemsPage";

interface CurrentPageProps {
  currentPage: number;
  shipment: Shipment;
}

function CurrentPage(props: CurrentPageProps) {
  switch (props.currentPage) {
    case 1:
      return <PickupDetailsPage shipment={props.shipment} />;
    case 2:
      return <PickupAddressPage shipment={props.shipment} />;
    case 3:
      return <PickupContactPage shipment={props.shipment} />;
    case 4:
      return <PickupNotesPage shipment={props.shipment} />;
    case 5:
      return <DeliveryDetailsPage shipment={props.shipment} />;
    case 6:
      return <DeliveryAddressPage shipment={props.shipment} />;
    case 7:
      return <DeliveryContactPage shipment={props.shipment} />;
    case 8:
      return <DeliveryNotesPage shipment={props.shipment} />;
    case 9:
      return <LineItemsPage shipment={props.shipment} />;
    default:
      throw new Error(`Unhandled page ${props.currentPage}`);
  }
}

interface ModifyShipmentModalProps {
  onDone: () => void;
  shipment: Shipment;
}

export function ModifyShipmentModal(props: ModifyShipmentModalProps) {
  const [currentPage, setCurrentPage] = useState(1);

  function onPrevious() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function onNext() {
    if (currentPage <= 9) {
      setCurrentPage(currentPage + 1);
    }
  }
  return (
    <Stack align="left" style={{ padding: "16px", width: "800px" }}>
      <Spacer height={16} />
      <Heading1>Modify Shipment : {currentPage}</Heading1>
      <Legalese>
        If any of the details of your shipment are incorrect, please modify them
        here
      </Legalese>
      <Spacer height={32} />
      <CurrentPage currentPage={currentPage} shipment={props.shipment} />
      <Spacer height={64} />
      <HorizontalStack align="spread" width="100%">
        <Button secondary onClick={props.onDone}>
          Cancel
        </Button>
        <HorizontalStack>
          <Button onClick={onPrevious}>Previous</Button>
          <Spacer width={16} />
          <Button onClick={onNext}>Next</Button>
        </HorizontalStack>
      </HorizontalStack>
      <Spacer height={16} />
    </Stack>
  );
}
