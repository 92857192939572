import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { modalsSlice } from "./Cargo/Modal/modalsSlice";
import {
  authenticationSlice,
  writeAuthenticationSliceToLocalStorage,
} from "./Features/Authentication/Slices/authenticationSlice";
import { bookShipmentSlice } from "./Features/BookShipment/Slices/bookShipmentSlice";
import {
  nmfcDescriptionCacheSlice,
  writeNmfcDescriptionCacheSliceToLocalStorage,
} from "./Features/LineItems/Slices/nmfcDescriptionCacheSlice";
import { Action, combineReducers, Store } from "redux";
import { couponSlice } from "./Features/Coupons/Slices/couponSlice";
import { reactionsSlice } from "./Cargo/Hooks/useReaction";

export const rootReducer = combineReducers({
  authentication: authenticationSlice.reducer,
  modals: modalsSlice.reducer,
  reactions: reactionsSlice.reducer,
  bookShipment: bookShipmentSlice.reducer,
  nmfcDesciptionCache: nmfcDescriptionCacheSlice.reducer,
  coupon: couponSlice.reducer,
});

export const store: Store = configureStore({
  reducer: rootReducer,
});

store.subscribe(() => {
  const state = store.getState();

  writeAuthenticationSliceToLocalStorage(state.authentication);
  writeNmfcDescriptionCacheSliceToLocalStorage(state.nmfcDesciptionCache);
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export function clearSlices() {
  localStorage.clear();
}
