import Button from "../Controls/Button";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import Stack from "../Layout/Stack";
import { Heading2, Microcopy } from "../Text/Text";
import { ReactElement, ReactNode } from "react";
import { MultiOptionConfirmModalOptions } from "./MultiOptionConfirmModalOptions";

interface MultiOptionConfirmModalProps {
  onDone: (index: number | undefined) => void;
  message: ReactNode;
  title: ReactNode;
  options: MultiOptionConfirmModalOptions;
}

export function MultiOptionConfirmModal(
  props: MultiOptionConfirmModalProps,
): ReactElement {
  return (
    <Stack align="center" id="ConfirmModal">
      <Heading2>{props.title}</Heading2>
      <Microcopy>{props.message}</Microcopy>
      <Spacer height={24} />
      <HorizontalStack spacing={16}>
        <Button
          id="cancelButton"
          size="large"
          secondary
          onClick={() => {
            props.onDone(undefined);
          }}
        >
          Cancel
        </Button>
        {props.options.buttons.map(function (buttonText, index) {
          return (
            <Button
              key={index}
              id="doneButton"
              size="large"
              onClick={() => {
                props.onDone(index);
              }}
            >
              {buttonText}
            </Button>
          );
        })}
      </HorizontalStack>
    </Stack>
  );
}
