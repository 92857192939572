import Banner, {
  BannerBody,
  BannerHeader,
} from "../../../Cargo/Controls/Banner";
import { BannerStyle } from "../../../Cargo/Controls/BannerStyle";
import Button from "../../../Cargo/Controls/Button";
import Stack from "../../../Cargo/Layout/Stack";
import { Currency } from "@freightsimple/generated-dashboard-openapi-client";
import { useNavigate } from "react-router-dom";
import { generateCreditBannerMessage } from "./generateCreditBannerMessage";

interface CreditBannerProps {
  paymentTermsDays: number | undefined;
  creditAmount: number | undefined;
  creditCurrency: Currency | undefined;
  issuedInvoicesTotal: number | undefined;
  overdueIssuedInvoicesTotal: number | undefined;
  isBookingSuspended: boolean;
  showMakeAPaymentButton: boolean;
}

function generateBookingSuspendedCreditBannerMessage(
  paymentTermsDays: number | undefined,
  creditAmount: number | undefined,
  issuedInvoicesTotal: number | undefined,
  creditCurrency: Currency | undefined,
  overdueIssuedInvoicesTotal: number | undefined,
) {
  return `Payment terms are ${paymentTermsDays} days. Your
credit limit is $${creditAmount} ${creditCurrency}
. You currently have $${issuedInvoicesTotal} ${creditCurrency} in unpaid invoices. There are $${overdueIssuedInvoicesTotal} ${creditCurrency} in overdue
invoices.`;
}

export function CreditBanner(props: CreditBannerProps) {
  const navigate = useNavigate();
  const {
    paymentTermsDays,
    creditAmount,
    creditCurrency,
    issuedInvoicesTotal,
    isBookingSuspended,
    overdueIssuedInvoicesTotal,
  } = props;

  function showCreditBanner() {
    return paymentTermsDays !== undefined && paymentTermsDays > 0;
  }

  if (!showCreditBanner() || overdueIssuedInvoicesTotal === undefined) {
    return <></>;
  }

  if (isBookingSuspended) {
    if (overdueIssuedInvoicesTotal > 0) {
      return (
        <Banner
          bannerStyle={BannerStyle.Error}
          message={
            <Stack align="left" style={{ marginLeft: "8px" }}>
              <BannerHeader>
                Booking suspended as you have overdue invoices
              </BannerHeader>
              <BannerBody></BannerBody>
            </Stack>
          }
          right={
            <Button onClick={() => navigate("/payments")}>
              Make a payment
            </Button>
          }
        />
      );
    } else {
      return (
        <Banner
          bannerStyle={BannerStyle.Error}
          message={
            <Stack align="left" style={{ marginLeft: "8px" }}>
              <BannerHeader>
                Booking suspended as you are over your credit limit
              </BannerHeader>
              <div>
                {generateBookingSuspendedCreditBannerMessage(
                  paymentTermsDays,
                  creditAmount,
                  issuedInvoicesTotal,
                  creditCurrency,
                  overdueIssuedInvoicesTotal,
                )}
              </div>
            </Stack>
          }
          right={
            <Button onClick={() => navigate("/payments")}>
              Make a payment
            </Button>
          }
        />
      );
    }
  }

  return (
    <Banner
      bannerStyle={BannerStyle.Info}
      message={
        <>
          <BannerHeader>Credit Terms</BannerHeader>{" "}
          {generateCreditBannerMessage(
            paymentTermsDays,
            creditAmount,
            creditCurrency,
            issuedInvoicesTotal,
          )}
        </>
      }
      right={
        <>
          {props.showMakeAPaymentButton && (
            <Button onClick={() => navigate("/payments")}>
              Make a payment
            </Button>
          )}
        </>
      }
    />
  );
}
