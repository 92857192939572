import Box from "../../../Cargo/Layout/Box";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Label } from "../../../Cargo/Text/Label";
import { ErrorMessageType } from "../../../Cargo/Validation";
import BranchesDropdown from "../../Branches/Components/BranchesDropdown";
import { SavedLocation } from "@freightsimple/generated-dashboard-openapi-client";
import BookShipmentSection from "./BookShipmentSection";

interface BranchesSectionProps {
  branchId: string | undefined;
  onBranchIdChanged: (newValue: string | undefined) => void;
  index: number;
  branches: SavedLocation[];
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
}
function BranchesSection(props: BranchesSectionProps) {
  // If there aren't any branches, then it makes no sense to pick a branch
  if (props.branches.length === 0) {
    return <></>;
  }

  return (
    <BookShipmentSection
      index={props.index}
      header={<>Branch</>}
      description="Your company has multiple branches, and you are not coded to a branch. Please select the branch you are booking this for."
    >
      <Spacer height={32} />
      <Box width={824}>
        <Spacer height={16} />
        <Label>Branch</Label>
        <BranchesDropdown
          branchId={props.branchId}
          setBranchId={function (value) {
            props.onBranchIdChanged(
              value === "no_branch_assigned" ? undefined : value,
            );
          }}
          branches={props.branches}
          errorMessage={props.errorMessage}
          forceValidation={props.forceValidation}
        />
      </Box>
    </BookShipmentSection>
  );
}
export default BranchesSection;
