import styled, { CSSProperties } from "styled-components";

type AlignOptions = "center" | "left" | "right";

interface StackProps {
  children: React.ReactNode;
  align?: AlignOptions;
  width?: string;
  style?: CSSProperties;
  id?: string;
}

function alignToAlignItems(align?: AlignOptions): string {
  if (align === undefined) {
    return "center";
  }
  if (align === "center") {
    return "center";
  }

  if (align === "left") {
    return "flex-start";
  }

  if (align === "right") {
    return "flex-end";
  }

  // should not be here
  throw new Error("Invalid align");
}

interface StackBodyProps {
  $align?: AlignOptions;
}

const StackBody = styled.div<StackBodyProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: ${(props) => alignToAlignItems(props.$align) || "center"};
`;

const Stack: React.FC<StackProps> = (props: StackProps) => {
  const style = props.style || {};

  if (props.align) {
    style.alignItems = alignToAlignItems(props.align);
  }

  if (props.width) {
    style.width = props.width;
  }

  return (
    <StackBody $align={props.align} style={style} id={props.id}>
      {props.children}
    </StackBody>
  );
};
export default Stack;
