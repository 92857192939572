import LinkButton from "../../../Cargo/Controls/LinkButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { UUID } from "../../../Cargo/Types/types";
import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Scrollchor } from "react-scrollchor";
import styled from "styled-components";
import { useShareTrackingModal } from "../Modals/useShareTrackingModal";

interface JumpBarProps {
  claimsJump?: boolean;
  shipmentState: ShipmentState;
  trackingId: UUID;
}

const Label = styled.span`
  font-weight: var(--nhu-font-weight-light);
  font-size: 12px;
  color: var(--freightsimple-color-light-text);
`;

function JumpBar(props: JumpBarProps) {
  const showShareModal = useShareTrackingModal();

  return (
    <HorizontalStack width="100%" align="spread">
      <HorizontalStack>
        <Label>Jump to:</Label>
        {props.claimsJump && (
          <>
            <Spacer width={8} />
            <LinkButton>
              <Scrollchor to="#claims-section">Claims</Scrollchor>
            </LinkButton>
          </>
        )}
        <Spacer width={8} />
        <LinkButton>
          <Scrollchor to="#details-section">Details</Scrollchor>
        </LinkButton>
        <Spacer width={8} />
        <LinkButton>
          <Scrollchor to="#documents-section">Documents</Scrollchor>
        </LinkButton>
        <Spacer width={8} />
        <LinkButton>
          <Scrollchor to="#billing-section">Billing</Scrollchor>
        </LinkButton>
        <Spacer width={8} />
        {props.shipmentState === ShipmentState.InTransit ||
          (props.shipmentState === ShipmentState.Delivered && (
            <LinkButton>
              <Scrollchor to="#tracking-section">Tracking</Scrollchor>
            </LinkButton>
          ))}
      </HorizontalStack>
      <HorizontalStack>
        <LinkButton onClick={() => showShareModal(props)}>Share</LinkButton>
      </HorizontalStack>
    </HorizontalStack>
  );
}
export default JumpBar;
