import { Centered } from "../../Cargo/Layout/Container";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import { useOnce } from "../../Hooks/useOnce";
import useQuery from "../../Hooks/useQuery";
import { useState } from "react";
import { useSignInDirectly } from "./Hooks/useSignIn";

/*
    It's the job of this screen to take login credentials from url parameters, then use them to log in and create a session
*/
export function QuickLoginScreen() {
  const query = useQuery();
  const { signIn, continueAfterReset } = useSignInDirectly();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const email = query.email as string;
  const password = query.password as string;

  useOnce(async function () {
    try {
      const { accessToken, idToken } = await signIn(email, password);
      continueAfterReset(accessToken, idToken);
    } catch (e: unknown) {
      setErrorMessage(e as string);
    }
  });

  if (errorMessage) {
    return (
      <Centered>
        Unfortunately there was an error logging in : {errorMessage}
      </Centered>
    );
  } else {
    return <LoadingShipment></LoadingShipment>;
  }
}
