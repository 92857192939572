import { ReactionType } from "./ReactionType";
import Animation from "../Animations/Animation";

export function reactionContent(currentReaction: ReactionType) {
  switch (currentReaction) {
    case ReactionType.Success:
      return (
        <div
          style={{
            width: "128px",
            height: "128px",
            margin: "auto",
            position: "relative",
            top: "calc(50vh - 64px)",
          }}
        >
          <Animation
            pathToAnimation="/animations/success-overlay.json"
            lottieFilesUrl="https://lottiefiles.com/645-success"
            loop={false}
            speed={1.0}
          />
        </div>
      );
  }
}
