import Button from "../../Cargo/Controls/Button";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { Heading1, Microcopy } from "../../Cargo/Text/Text";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import { useCoupon } from "../Coupons/Hooks/useCoupon";
import { useDeleteQuotedShipmentModal } from "../ViewShipments/Modals/useDeleteQuotedShipmentModal";
import {
  PreBookingShipment,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { Quote } from "@freightsimple/generated-dashboard-openapi-client";
import { groupBy } from "../../Helpers/groupBy";
import { useOnce } from "../../Hooks/useOnce";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShipmentService } from "../../Services/ShipmentService";
import styled from "styled-components";
import DashboardRow from "./Components/DashboardRow";
import { QuoteScreenGroupHeader } from "./Components/QuoteScreenGroupHeader";
import { generateQuoteScreenShipmentGroupKey } from "./Components/generateQuoteScreenShipmentGroupKey";
import { BranchFilterOption } from "./Components/BranchFilterOption";
import { UserDropdownType, useUserDropdown } from "./Hooks/useUserDropdown";

export const Subtitle = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  margin: 0 67px 9px 67px;
  color: var(--freightsimple-color-normal-text);
`;

interface DashboardRowForQuotedShipmentProps {
  shipment: PreBookingShipment;
  onClick: () => void;
  onDelete: () => Promise<void>;
}

function DashboardRowForQuotedShipment(
  props: DashboardRowForQuotedShipmentProps,
) {
  const { shipment, onClick, onDelete } = props;
  const { discountQuote } = useCoupon();

  let carrierIdentifier = undefined;

  if (shipment.selectedQuote) {
    carrierIdentifier = shipment.selectedQuote.carrierIdentifier;
  }

  function discount(q: Quote | undefined) {
    if (q === undefined) return undefined;

    return discountQuote(q);
  }

  return (
    <DashboardRow
      key={shipment.shipmentId}
      lineItems={shipment.lineItems}
      shipmentState={ShipmentState.Quoted}
      pickupLocation={shipment.pickupLocation}
      deliveryLocation={shipment.deliveryLocation}
      pickupDate={shipment.pickupDate}
      pickupHours={shipment.pickupLocation.hours}
      appointmentDate={undefined}
      expectedDeliveryDate={shipment.selectedQuote?.expectedDeliveryDate}
      expectedDeliveryHours={undefined}
      actualDeliveryDate={undefined}
      actualDeliveryTime={undefined}
      carrierIdentifier={carrierIdentifier}
      selectedQuote={discount(shipment.selectedQuote)}
      cheapestQuote={discount(shipment.cheapestQuote)}
      onClick={onClick}
      pickupReference={shipment.pickupReferenceNumber || ""}
      deliveryReference={shipment.deliveryReferenceNumber || ""}
      onDelete={onDelete}
      manualQuotingOpen={shipment.manualQuotingOpen}
    />
  );
}

interface SectionForQuotedShipmentStateProps {
  shipments: Array<PreBookingShipment>;
  reloadShipments: () => Promise<void>;
}

function SectionForQuotedShipmentState(
  props: SectionForQuotedShipmentStateProps,
) {
  const navigate = useNavigate();
  const showDeleteModal = useDeleteQuotedShipmentModal();
  const shipmentsService = useShipmentService();
  const { shipments } = props;

  function onClick(shipment: PreBookingShipment) {
    let url;

    if (shipment.isReviewingPriorToBookingShipment) {
      url = `/book/review?shipmentId=${shipment.shipmentId}`;
    } else {
      if (shipment.selectedQuote) {
        url = `/book/pickup-address?shipmentId=${shipment.shipmentId}`;
      } else {
        url = `/book/quotes?shipmentId=${shipment.shipmentId}`;
      }
    }

    navigate(url);
  }

  async function onDelete(shipment: PreBookingShipment) {
    const reason = await showDeleteModal(shipment.cheapestQuote);

    if (reason !== undefined) {
      await shipmentsService.deleteQuotedShipment(shipment.shipmentId, reason);
      navigate("/");
    }
  }

  if (shipments.length === 0) {
    return <></>;
  }

  const grouped = groupBy(shipments, generateQuoteScreenShipmentGroupKey);

  return (
    <>
      {grouped.map(function (o) {
        return (
          <>
            <QuoteScreenGroupHeader group={o.key} />
            {o.value.map(function (s) {
              return (
                <DashboardRowForQuotedShipment
                  key={s.shipmentId}
                  shipment={s}
                  onClick={function () {
                    onClick(s);
                  }}
                  onDelete={async function () {
                    await onDelete(s);
                  }}
                />
              );
            })}
          </>
        );
      })}
    </>
  );
}

function AddShipmentButton() {
  const navigate = useNavigate();

  function onClick() {
    navigate("/book/details");
  }

  return (
    <Button size="large" onClick={onClick}>
      Get Instant Quotes
    </Button>
  );
}

const QuotesScreen: React.FC = () => {
  const shipmentsService = useShipmentService();
  const [quotedShipments, setQuotedShipments] = useState<
    Array<PreBookingShipment>
  >([]);
  const [loading, setLoading] = useState(false);

  const {
    element: UserDropdownElement,
    filterByUserId,
    filterByBranchId,
  } = useUserDropdown(UserDropdownType.Quoting);

  async function loadShipments() {
    setLoading(true);
    const response = await shipmentsService.getShipments();

    setQuotedShipments(response.quoted);
    setLoading(false);
  }

  useOnce(async () => {
    await loadShipments();
  });

  if (loading) {
    return <LoadingShipment />;
  }

  const filteredShipments = quotedShipments
    .filter(function (shipment) {
      if (shipment.quotedBy === undefined) {
        return true;
      }

      if (filterByUserId === undefined || filterByUserId === "") {
        return true;
      }

      return shipment.quotedBy == filterByUserId;
    })
    .filter(function (shipment) {
      if (filterByBranchId === undefined || filterByBranchId === "") {
        return true;
      }

      if (filterByBranchId === BranchFilterOption.Uncoded) {
        return shipment.branchId === undefined;
      }

      return shipment.branchId == filterByBranchId;
    });

  const noShipments = quotedShipments.length === 0;

  const showUncodedOption = quotedShipments.some(
    (s) => s.branchId === undefined,
  );

  return (
    <>
      <FullWidthLayout
        header={<>Quotes</>}
        microcopy={
          noShipments ? "" : <>These are the recent quotes you have run</>
        }
        loading={loading}
        showEmptyState={noShipments}
        emptyState={
          <Stack>
            <Heading1>You don&apos;t have any recent quotes</Heading1>
            <Microcopy>
              Get quotes to start booking your next shipment.
            </Microcopy>
            <Spacer height={32} />
            <AddShipmentButton />
          </Stack>
        }
        rightContent={UserDropdownElement(showUncodedOption)}
        content={
          <>
            <SectionForQuotedShipmentState
              shipments={filteredShipments}
              reloadShipments={loadShipments}
            />
          </>
        }
      />
    </>
  );
};

export default QuotesScreen;
