import Button from "../../Cargo/Controls/Button";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { UUID } from "../../Cargo/Types/types";

import { useNavigate } from "react-router-dom";
import ShipmentError from "./Components/ShipmentError";

interface BookShipmentNearlyDoneScreenProps {
  shipmentId: UUID;
}

export function BookShipmentNearlyDoneScreen(
  props: BookShipmentNearlyDoneScreenProps,
) {
  const navigate = useNavigate();

  function navigateToShipment() {
    navigate("/view-shipment?shipmentId=" + props.shipmentId);
  }
  return (
    <Stack align="center">
      <ShipmentError />
      <Spacer height={32} />
      <Button onClick={navigateToShipment}>View Shipment</Button>
    </Stack>
  );
}
