import SlideRight from "../../../../Cargo/Animations/SlideRight";
import Colors from "../../../../Cargo/Colors";
import Button from "../../../../Cargo/Controls/Button";
import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import { useReferralCodeModal } from "../../../Referral/Hooks/useReferralCodeModal.1";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SubNavPill,
  SubNavPillMenuDivider,
  SubNavPillMenuItem,
} from "./SubNavPill";

export function SubNavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const showReferralCodeModal = useReferralCodeModal();

  function showGetQuotesButton() {
    if (location.pathname.startsWith("/book/")) {
      return false;
    }

    if (location.pathname === "/booking-success") {
      return false;
    }

    return true;
  }

  function onGetQuotes() {
    setTimeout(function () {
      navigate("/book/details");
    }, 300);
  }

  function isManageCompanySelected(): boolean {
    if (
      [
        "/address-book",
        "/brokers",
        "/saved-commodities",
        "/view-company",
      ].includes(location.pathname)
    ) {
      return true;
    }

    return false;
  }

  function isFinancialMenuSelected(): boolean {
    if (
      [
        "/credit-cards",
        "/bank-accounts",
        "/view-invoices",
        "/payments",
      ].includes(location.pathname)
    ) {
      return true;
    }

    return false;
  }

  function isQuotesSelected(): boolean {
    if (location.pathname == "/quotes") {
      return true;
    }

    return false;
  }

  return (
    <HorizontalStack
      style={{
        borderBottom: `1px solid ${Colors.VeryVeryLightBlue}`,
        padding: "2px 0",
      }}
      align="spread"
    >
      <HorizontalStack>
        <SlideRight show={showGetQuotesButton()}>
          <Button label="Get Instant Quotes" onClick={onGetQuotes} />
          <Spacer width={32} />
        </SlideRight>

        <SubNavPill
          title="Active Shipments"
          selected={location.pathname === "/"}
          onClick={function () {
            navigate("/");
          }}
        />
        <SubNavPill
          title="Recent Quotes"
          selected={isQuotesSelected()}
          onClick={function () {
            navigate("/quotes");
          }}
        />
        <SubNavPill
          title="Past Shipments"
          selected={location.pathname === "/view-all-shipments"}
          onClick={function () {
            navigate("/view-all-shipments");
          }}
        />
        <SubNavPill title="Manage Company" selected={isManageCompanySelected()}>
          <SubNavPillMenuItem
            title="Address Book"
            onClick={function () {
              navigate("/address-book");
            }}
          />
          <SubNavPillMenuItem
            title="Customs Brokers"
            onClick={function () {
              navigate("/brokers");
            }}
          />
          <SubNavPillMenuItem
            title="Saved Commodities"
            onClick={function () {
              navigate("/saved-commodities");
            }}
          />
          <SubNavPillMenuItem
            title="Manage Users"
            onClick={function () {
              navigate("/manage-users");
            }}
          />
          <SubNavPillMenuItem
            title="Referral Code"
            onClick={function () {
              showReferralCodeModal();
            }}
          />
        </SubNavPill>
        <SubNavPill title="Financial" selected={isFinancialMenuSelected()}>
          <SubNavPillMenuItem
            title="Invoices"
            onClick={function () {
              navigate("/view-invoices");
            }}
          />
          <SubNavPillMenuDivider />
          <SubNavPillMenuItem
            title="Make Payments"
            onClick={function () {
              navigate("/payments");
            }}
          />
          <SubNavPillMenuDivider />
          <SubNavPillMenuItem
            title="Credit Cards"
            onClick={function () {
              navigate("/credit-cards");
            }}
          />
          <SubNavPillMenuItem
            title="Bank Accounts"
            onClick={function () {
              navigate("/bank-accounts");
            }}
          />
        </SubNavPill>
        <SubNavPill
          title="Help Center"
          selected={false}
          onClick={function () {
            const url = "https://help.freightsimple.com";
            window.open(url, "_new");
          }}
        />
      </HorizontalStack>
    </HorizontalStack>
  );
}
