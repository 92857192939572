import Colors from "../../../Cargo/Colors";
import Banner from "../../../Cargo/Controls/Banner";
import { BannerStyle } from "../../../Cargo/Controls/BannerStyle";
import { QuestionBubbleLabel } from "../../../Cargo/Controls/QuestionBubble";
import ThreeDotMenu, {
  ThreeDotMenuItem,
} from "../../../Cargo/Controls/ThreeDotMenu";
import Tooltip from "../../../Cargo/Controls/Tooltip";
import Icon from "../../../Cargo/Icons/Icon";
import LargeCreditCardLogo from "../../../Cargo/Icons/LargeCreditCardLogo";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "../../../Cargo/Layout/Table";
import {
  BankAccount,
  CreditCard,
} from "@freightsimple/generated-dashboard-openapi-client";
import styled from "styled-components";

interface CreditCardsSectionProps {
  creditCards: Array<CreditCard>;
  bankAccounts: Array<BankAccount>;
  setDefaultCard: (stripePaymentMethodId: string) => void;
  removeCard: (stripePaymentMethodId: string) => void;
  allowRemoveCardButton: boolean;
}

const DefaultPill = styled.div`
  padding: 4px 16px;
  background-color: ${Colors.DarkGreen};
  border-radius: 4px;
  width: 90px;
`;

const DefaultLabel = styled.div`
  color: ${Colors.White};
  font-size: 12px;
  font-weight: var(--nhu-font-weight-medium);
`;

const AccountNumberLabel = styled.div`
  color: var(--freightsimple-color-light-text);
  font-size: 16px;
  font-weight: var(--nhu-font-weight-light);
`;

function CreditCardsSection(props: CreditCardsSectionProps) {
  const {
    creditCards,
    bankAccounts,
    setDefaultCard,
    removeCard,
    allowRemoveCardButton,
  } = props;

  function itemsForPaymentMethod(creditCard: CreditCard) {
    const { stripePaymentMethodId } = creditCard;
    const items: Array<ThreeDotMenuItem> = [];

    items.push({
      name: "Make Default",
      action: function () {
        setDefaultCard(stripePaymentMethodId);
      },
      disabled: creditCard.isDefault,
      disabledExplanation: "This is already default",
    });

    items.push({
      name: "Remove Credit Card",
      action: function () {
        removeCard(stripePaymentMethodId);
      },
      disabled: !allowRemoveCardButton,
      disabledExplanation: "Cannot remove last payment method",
    });

    return items;
  }

  const defaultCreditCard = creditCards.some(function (cc) {
    return cc.isDefault;
  });
  const defaultBankAccount = bankAccounts.some(function (ba) {
    return ba.isDefault;
  });
  const showBanner = !defaultCreditCard && defaultBankAccount;

  return (
    <>
      {showBanner && (
        <>
          <Spacer height={16} />
          <Banner
            bannerStyle={BannerStyle.Warning}
            message="Your current default payment method is set to a bank account"
          />
        </>
      )}
      <Spacer height={16} />
      <Table>
        <TableHeaderRow>
          <TableHeaderCell style={{ width: "200px" }}>
            Card Type
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "160px" }}>
            Cardholder
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "100px" }}>Expiry</TableHeaderCell>

          <TableHeaderCell style={{ width: "120px" }}>Last 4</TableHeaderCell>
          <TableHeaderCell style={{ width: "80px" }}></TableHeaderCell>
          <TableHeaderCell style={{ width: "100px" }}></TableHeaderCell>
        </TableHeaderRow>
        {creditCards.map((creditCard, index) => {
          return (
            <TableRow key={index}>
              <TableCell>
                <HorizontalStack verticalAlign="middle">
                  <LargeCreditCardLogo brand={creditCard.brand} />
                </HorizontalStack>
              </TableCell>
              <TableCell>
                <AccountNumberLabel>{creditCard.name}</AccountNumberLabel>
              </TableCell>
              <TableCell>
                <AccountNumberLabel>{creditCard.expiry}</AccountNumberLabel>
              </TableCell>

              <TableCell>
                <AccountNumberLabel>
                  Ending {creditCard.lastFourDigits}
                </AccountNumberLabel>
              </TableCell>
              <TableCell>
                {creditCard.isDefault && (
                  <Tooltip
                    tooltip={
                      <div style={{ padding: "10px" }}>
                        <QuestionBubbleLabel>
                          This is the default payment method that will be used
                          for shipments.
                        </QuestionBubbleLabel>
                      </div>
                    }
                  >
                    <DefaultPill>
                      <HorizontalStack align="spread">
                        <Icon
                          name="check"
                          color={Colors.White}
                          size={14}
                          solid
                        />
                        <Spacer width={4} />
                        <DefaultLabel>Default</DefaultLabel>
                      </HorizontalStack>
                    </DefaultPill>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <HorizontalStack align="right" style={{ paddingRight: "40px" }}>
                  <ThreeDotMenu
                    color={Colors.MidGray}
                    items={itemsForPaymentMethod(creditCard)}
                  />
                </HorizontalStack>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}
export default CreditCardsSection;
