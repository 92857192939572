import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ConfirmCardSetupData } from "@stripe/stripe-js";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { errorMessagesForAddress } from "../../Locations/Validators/errorMessagesForAddress";
import { Address } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { extractPaymentMethodId } from "../../../Shared/Utils/stripeUtils";

type SetupIntent = {
  id: string;
  paymentMethod: string | null;
};

type StoreCreditCardError = {
  type: string;
  code?: string;
  message?: string;
};

export type StoreCreditCardResult =
  | { setupIntent: SetupIntent; error?: undefined }
  | { setupIntent?: undefined; error: StoreCreditCardError };

const useStoreCreditCard = function () {
  const stripe = useStripe();
  const elements = useElements();
  const [billingAddress, setBillingAddress] = useState<Partial<Address>>({});
  const [billingName, setBillingName] = useState("");

  const [errorMessageForCreditCardNumber, setErrorMessageForCreditCardNumber] =
    useState<ErrorMessageType>("Required");
  const [
    errorMessageForCreditCardExpiration,
    setErrorMessageForCreditCardExpiration,
  ] = useState<ErrorMessageType>("Required");
  const [errorMessageForCreditCardCVC, setErrorMessageForCreditCardCVC] =
    useState<ErrorMessageType>("Required");

  const errorMessageForBillingName =
    billingName === "" ? "Required" : undefined;

  const storeCreditCard = async function (
    clientSecret: string,
  ): Promise<StoreCreditCardResult> {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return { error: { type: "load_error", message: "Loading error" } };
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      throw new Error("Missing element");
    }

    // TODO:
    const stripeCountryCode =
      billingAddress?.countryCode === "Canada" ? "CA" : "US";
    const request: ConfirmCardSetupData = {
      payment_method: {
        card: cardElement,

        billing_details: {
          name: billingName,
          address: {
            city: billingAddress?.city,
            country: stripeCountryCode,
            line1: billingAddress?.addressLine,
            line2: billingAddress?.addressLine2,
            state: billingAddress?.stateOrProvinceCode,
            postal_code: billingAddress?.postalCode,
          },
        },
      },
    };

    const result = await stripe.confirmCardSetup(clientSecret, request);

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.error(result.error.message);
      return {
        error: {
          type: result.error.type,
          code: result.error.code,
          message: result.error.message,
        },
      };
    } else {
      const paymentMethod = result.setupIntent?.payment_method;
      if (paymentMethod) {
        return {
          setupIntent: {
            id: result.setupIntent.id,
            paymentMethod: extractPaymentMethodId(paymentMethod) ?? "",
          },
        };
      } else {
        console.error("Missing payment method");
        return {
          error: {
            type: "missing_payment_method",
            message: "Missing payment method",
          },
        };
      }
    }
  };

  const addressErrors = errorMessagesForAddress(billingAddress);
  const errorMessageForAddress = addressErrors.addressLine;
  const errorMessageForCity = addressErrors.city;
  const errorMessageForStateOrProvinceCode = addressErrors.stateOrProvinceCode;
  const errorMessageForPostalCode = addressErrors.postalCode;
  const errorMessageForCountry = addressErrors.country;

  const isValid = [
    errorMessageForBillingName,
    errorMessageForCreditCardNumber,
    errorMessageForCreditCardExpiration,
    errorMessageForCreditCardCVC,
    errorMessageForAddress,
    errorMessageForCity,
    errorMessageForStateOrProvinceCode,
    errorMessageForPostalCode,
    errorMessageForCountry,
  ].every((o) => o === undefined);

  return {
    billingAddress,
    billingName,
    setBillingAddress,
    setBillingName,
    storeCreditCard,
    errorMessageForBillingName,
    errorMessageForCreditCardNumber,
    errorMessageForCreditCardExpiration,
    errorMessageForCreditCardCVC,
    setErrorMessageForCreditCardNumber,
    setErrorMessageForCreditCardExpiration,
    setErrorMessageForCreditCardCVC,
    errorMessageForAddress,
    errorMessageForCity,
    errorMessageForStateOrProvinceCode,
    errorMessageForPostalCode,
    errorMessageForCountry,
    isValid,
  };
};

export default useStoreCreditCard;
