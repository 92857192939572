import styled from "styled-components";

//export const TextArea = styled(Form.Control).attrs({ as: 'textarea' })`
export const TextArea = styled.textarea`
  font-weight: var(--nhu-font-weight-bold) !important;
  font-size: 20px !important;
  color: #495057 !important;

  /* This should be #858585 but it looks too dark compared to the input fields */
  border: 1px solid #aaaaaa !important;
  padding: 10px;

  &:hover {
    border: 2px solid var(--freightsimple-color-blue) !important;
    padding: 9px;
  }

  &:focus {
    border: 2px solid var(--freightsimple-color-blue) !important;
    padding: 9px;
  }
`;
