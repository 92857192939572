import { ErrorMessageType } from "../../../Cargo/Validation";
import { CountryCode } from "@freightsimple/generated-dashboard-openapi-client";

function isCanadianPostalCode(postalCode: string) {
  // TODO: Why do we specify this regex in multiple places?
  const regex = new RegExp(/^(?:[A-Z]\d[A-Z][ ]?\d[A-Z]\d)$/i);
  return regex.test(postalCode);
}

function isUsZipCode(postalCode: string) {
  const regex = new RegExp(/^\d{5}$/);
  return regex.test(postalCode);
}

export function isValidPostalCodeFormat(postalCode: string) {
  return isCanadianPostalCode(postalCode) || isUsZipCode(postalCode);
}

export function countryCodeFromPostalCode(
  postalCode: string,
): CountryCode | undefined {
  if (isUsZipCode(postalCode)) {
    return CountryCode.UnitedStates;
  }

  if (isCanadianPostalCode(postalCode)) {
    return CountryCode.Canada;
  }

  return undefined;
}

export function errorMessageForPostalCode(
  postalCode: string | undefined,
  city: string | undefined,
): ErrorMessageType {
  if (postalCode === undefined || postalCode === "") {
    return "Required";
  }

  if (!isValidPostalCodeFormat(postalCode)) {
    return "This doesn't look like a valid Postal Code or Zip Code";
  }

  if (city === undefined) {
    return "Postal code was not found";
  }

  return undefined;
}
