import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";
import moment from "moment";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import Stack from "../Layout/Stack";
import { DateRangePickerProps } from "./DateRangePicker";
import Colors from "../Colors";
import { DateRangePickerBase } from "./DateRangePickerBase";

const DateLabel = styled.div`
  margin-top: 7px;
  font-size: 12px;
  color: ${Colors.LightText};
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 260px;
`;

const DateDisplay = styled.div`
  font-size: 16px;
  min-height: 24px;
  font-weight: 600;
  width: 120px;
  padding: 4px 0 3px 0;
  color: var(--freightsimple-color-normal-text);
  border-bottom: 1px solid var(--freightsimple-color-light-text);
  margin-bottom: 1px;
  transition: border-bottom 0.2s ease;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid var(--freightsimple-color-blue);
    margin-bottom: 0;
  }

  &:focus {
    border-bottom: 2px solid var(--freightsimple-color-blue);
    margin-bottom: 0;
  }
`;

const AnimatedContent = styled(animated.div)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 8px;
`;

interface DateRangePickerDropdownProps extends DateRangePickerProps {
  fromText: string;
  toText: string;
}

export function DateRangePickerDropdown(props: DateRangePickerDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const springProps = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0px)" : "translateY(-8px)",
    config: {
      tension: 300,
      friction: 20,
    },
    onRest: () => {
      if (!isOpen) {
        setShowContent(false);
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      setShowContent(true);
    }
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      console.log("clicked outside", {
        dropdownRef: dropdownRef.current,
        eventTarget: event.target,
      });
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        console.log("closing");
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <DropdownContainer ref={dropdownRef}>
      <Stack align="left">
        <HorizontalStack verticalAlign="middle">
          <Stack align="left">
            <DateLabel>{props.fromText} </DateLabel>
            <DateDisplay onClick={toggleDropdown}>
              {props.startDate
                ? moment(props.startDate).format("Do MMM YYYY")
                : "Any"}
            </DateDisplay>
          </Stack>
          <Spacer width={16} />
          <Stack align="left">
            <DateLabel>{props.toText} </DateLabel>
            <DateDisplay onClick={toggleDropdown}>
              {props.endDate
                ? moment(props.endDate).format("Do MMM YYYY")
                : "Any"}
            </DateDisplay>
          </Stack>
        </HorizontalStack>
      </Stack>

      <AnimatedContent
        style={{
          ...springProps,
          display: showContent ? "block" : "none",
        }}
      >
        <DateRangePickerBase {...props} onSuggestClose={toggleDropdown} />
      </AnimatedContent>
    </DropdownContainer>
  );
}
