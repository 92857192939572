import ProgressSpinner from "../../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../../Cargo/Icons/ProgressSpinnerSizes";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading2 } from "../../../Cargo/Text/Text";

function BookingInProgress() {
  return (
    <Stack align="center">
      <Heading2>Completing Booking</Heading2>
      <ProgressSpinner size={ProgressSpinnerSizes.Medium} />
    </Stack>
  );
}
export default BookingInProgress;
