import {
  NormalQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../Cargo/Controls/QuestionBubble";

interface LocationTypeQuestionBubbleProps {
  displayName?: string;
}

const LocationTypeQuestionBubble: React.FC<LocationTypeQuestionBubbleProps> = (
  props: LocationTypeQuestionBubbleProps,
) => {
  if (props.displayName === undefined) {
    return (
      <NormalQuestionBubble
        description={
          <>
            <QuestionBubbleHeader>
              {`What type of location is this address?`}
            </QuestionBubbleHeader>
            <QuestionBubbleLabel>
              Make sure you enter the correct location type, as it affects the
              quote.
            </QuestionBubbleLabel>
            <QuestionBubbleLabel>
              {`If when the carrier arrives at the location, the location type is
                        different than the type you specified, you may incur an
                        additional charge.`}
            </QuestionBubbleLabel>
          </>
        }
        learnMoreUrl="https://help.freightsimple.com/freight-101/location-types"
      />
    );
  } else {
    const displayName = props.displayName.toLowerCase();
    return (
      <NormalQuestionBubble
        description={
          <>
            <QuestionBubbleHeader>
              {`What type of location is the ${displayName} address?`}
            </QuestionBubbleHeader>
            <QuestionBubbleLabel>
              Make sure you enter the correct location type, as it affects the
              quote.
            </QuestionBubbleLabel>
            <QuestionBubbleLabel>
              {`If at ${displayName}, the location type is
                        different than the type you specified, you may incur an
                        additional charge.`}
            </QuestionBubbleLabel>
          </>
        }
        learnMoreUrl="https://help.freightsimple.com/freight-101/location-types"
      />
    );
  }
};
export default LocationTypeQuestionBubble;
