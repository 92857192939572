import {
  QuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../../Cargo/Controls/QuestionBubble";
import { QuestionBubbleContext } from "../../../../../Cargo/Controls/QuestionBubbleContext";

interface ReferenceNumberQuestionBubbleProps {
  displayName?: string;
  context?: QuestionBubbleContext;
}

export function ReferenceNumberQuestionBubble(
  props: ReferenceNumberQuestionBubbleProps,
) {
  return (
    <QuestionBubble
      context={props.context ?? QuestionBubbleContext.Normal}
      description={
        <>
          <QuestionBubbleHeader>
            {props.displayName
              ? `(Optionally) Enter a reference number related to your ${props.displayName.toLowerCase()}`
              : `(Optionally) Enter a reference number`}
          </QuestionBubbleHeader>
          <QuestionBubbleLabel>
            {`This information will be added to your generated documents.`}
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/pickup-and-delivery-reference-numbers"
    />
  );
}

interface BoothNumberQuestionBubbleProps {
  displayName?: string;
  context?: QuestionBubbleContext;
}

export function BoothNumberQuestionBubble(
  props: BoothNumberQuestionBubbleProps,
) {
  return (
    <QuestionBubble
      context={props.context ?? QuestionBubbleContext.Normal}
      description={
        <>
          <QuestionBubbleHeader>
            {props.displayName
              ? `(Required) Enter the trade show booth number related to your ${props.displayName.toLowerCase()}`
              : `(Required) Enter the trade show booth number`}
          </QuestionBubbleHeader>
          <QuestionBubbleLabel>
            {`This information will be added to your generated documents.`}
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/pickup-and-delivery-reference-numbers"
    />
  );
}
