import Colors from "../../Cargo/Colors";
import Button from "../../Cargo/Controls/Button";
import { CircleButton } from "../../Cargo/Controls/CircleButton";
import ThreeDotMenu from "../../Cargo/Controls/ThreeDotMenu";
import Icon from "../../Cargo/Icons/Icon";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "../../Cargo/Layout/Table";
import useConfirmModal from "../../Cargo/Modal/useConfirmModal";
import PageTitle from "../../Cargo/Text/PageTitle";
import { Heading1, Microcopy } from "../../Cargo/Text/Text";
import { UUID } from "../../Cargo/Types/types";
import { SavedCommodity } from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../Hooks/useOnce";
import { useState } from "react";
import styled from "styled-components";
import { useAddModifySavedCommodityModal } from "./Modals/useAddModifySavedCommodityModal";
import { useSavedCommoditiesService } from "./Services/SavedCommoditiesService";

interface AddCommodityButtonProps {
  showAddCommodityModal: () => void;
}

const UNNumberLabel = styled.div`
  font-size: 14px;
  color: var(--freightsimple-color-normal-text);
  font-weight: var(--nhu-font-weight-light);
`;

const DescriptionLabel = styled.div`
  font-weight: var(--nhu-font-weight-bold);
`;

function AddCommodityButton(props: AddCommodityButtonProps) {
  return (
    <Button size="large" onClick={props.showAddCommodityModal}>
      <Icon
        name="plus"
        color={Colors.White}
        size={20}
        solid
        style={{
          position: "relative",
          top: "2px",
          marginRight: "16px",
        }}
      />{" "}
      Add New Saved Commodity
    </Button>
  );
}

interface SavedCommodityRowProps {
  savedCommodity: SavedCommodity;
  onModify: () => void;
  onDelete: () => void;
}

function SavedCommodityRow(props: SavedCommodityRowProps) {
  const { savedCommodity } = props;
  return (
    <TableRow key={savedCommodity.savedCommodityId}>
      <TableCell style={{ paddingTop: "16px", paddingBottom: "16px" }}>
        <DescriptionLabel>{savedCommodity.description}</DescriptionLabel>
      </TableCell>
      <TableCell>{savedCommodity.handlingUnitType}</TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {savedCommodity.nmfcItemNumber ? savedCommodity.nmfcItemNumber : "-"}
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {savedCommodity.isStackable ? (
          <Icon name="check" color={Colors.DarkGreen} size={16} solid />
        ) : (
          <Icon name="times" color={Colors.Red} size={16} solid />
        )}
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {savedCommodity.isDangerous ? (
          <div>
            <Icon name="check" color={Colors.DarkGreen} size={16} solid />
            <Spacer width={8} />
            <UNNumberLabel>
              {props.savedCommodity.dangerousUnNumber}
            </UNNumberLabel>
          </div>
        ) : (
          <Icon name="times" color={Colors.Red} size={16} solid />
        )}
      </TableCell>
      <TableCell>
        <ThreeDotMenu
          color={Colors.MidGray}
          items={[
            {
              name: "Modify",
              action: function () {
                props.onModify();
              },
            },
            {
              name: "Divider",
              action: function () {},
            },

            {
              name: "Delete",
              action: function () {
                if (props.onDelete !== undefined) {
                  props.onDelete();
                }
              },
            },
          ]}
        />
      </TableCell>

      <Spacer height={32} />
    </TableRow>
  );
}

export function SavedCommoditiesScreen() {
  const savedCommoditiesService = useSavedCommoditiesService();
  const [loading, setLoading] = useState(false);
  const [savedCommodities, setSavedCommodities] = useState<
    undefined | Array<SavedCommodity>
  >();

  const confirmDelete = useConfirmModal(
    "Confirm delete",
    "Are you sure you want to delete this commodity?",
  );

  async function loadSavedCommodities() {
    setLoading(true);
    const response = await savedCommoditiesService.getAllSavedCommodities();
    setSavedCommodities(
      response.sort((a, b) => {
        return a.description.localeCompare(b.description);
      }),
    );

    setLoading(false);
  }

  useOnce(loadSavedCommodities);

  async function onNewCommodity(savedCommodity: SavedCommodity) {
    await savedCommoditiesService.addSavedCommodity(savedCommodity);
    await loadSavedCommodities();
  }

  async function onModifyCommodity(updatedCommodity: SavedCommodity) {
    await savedCommoditiesService.modifySavedCommodity(updatedCommodity);
    await loadSavedCommodities();
  }

  const showAddCommodityModal = useAddModifySavedCommodityModal();

  const showModifySavedCommodityModal = useAddModifySavedCommodityModal();

  async function onDelete(savedCommodityId: UUID) {
    const confirmed = await confirmDelete();

    if (confirmed) {
      await savedCommoditiesService.deleteSavedCommodity(savedCommodityId);
      await loadSavedCommodities();
    }
  }

  async function onAdd() {
    const newSavedCommodity = await showAddCommodityModal();
    if (newSavedCommodity !== undefined) {
      await onNewCommodity(newSavedCommodity);
      await loadSavedCommodities();
    }
  }

  return (
    <>
      <PageTitle>Saved Commodities</PageTitle>
      <FullWidthLayout
        header={
          <>
            Saved Commodities
            <span
              style={{
                position: "relative",
                top: "-3px",
                marginLeft: "6px",
              }}
            >
              <CircleButton id="add-new-saved-commodity" onClick={onAdd} />
            </span>
          </>
        }
        microcopy="Save commodity information to speed up future bookings"
        loading={loading}
        showEmptyState={savedCommodities?.length === 0}
        emptyState={
          <Stack>
            <Heading1>You have no saved commodities</Heading1>
            <Microcopy>
              Save commodity information to speed up future bookings.
            </Microcopy>
            <Spacer height={32} />
            <AddCommodityButton showAddCommodityModal={onAdd} />
          </Stack>
        }
        content={
          <>
            <Spacer height={16} />
            <Table>
              <TableHeaderRow>
                <TableHeaderCell>Description</TableHeaderCell>
                <TableHeaderCell>Handling Unit</TableHeaderCell>
                <TableHeaderCell style={{ textAlign: "center" }}>
                  NMFC Code
                </TableHeaderCell>
                <TableHeaderCell style={{ textAlign: "center" }}>
                  Is Stackable
                </TableHeaderCell>
                <TableHeaderCell style={{ textAlign: "center" }}>
                  Dangerous
                </TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {savedCommodities &&
                savedCommodities.map((savedCommodity) => {
                  return (
                    <SavedCommodityRow
                      key={savedCommodity.savedCommodityId}
                      savedCommodity={savedCommodity}
                      onModify={async function () {
                        const modifiedSavedCommodity =
                          await showModifySavedCommodityModal(savedCommodity);

                        if (modifiedSavedCommodity !== undefined) {
                          await onModifyCommodity(modifiedSavedCommodity);
                        }
                      }}
                      onDelete={function () {
                        onDelete(savedCommodity.savedCommodityId);
                      }}
                    />
                  );
                })}
            </Table>
          </>
        }
      />
    </>
  );
}
