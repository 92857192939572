import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import Icon from "../../../Cargo/Icons/Icon";

interface AddBankAccountButtonProps {
  onClick: () => void;
}

export function AddBankAccountButton(props: AddBankAccountButtonProps) {
  return (
    <Button size="large" onClick={props.onClick}>
      <Icon
        name="plus"
        color={Colors.White}
        size={20}
        solid
        style={{
          position: "relative",
          top: "2px",
          marginRight: "16px",
        }}
      />{" "}
      Add New Bank Account
    </Button>
  );
}
