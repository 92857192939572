import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../../../Cargo/Colors";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import styled from "styled-components";

const Body = styled.div`
  color: white;
  background-color: var(--freightsimple-color-blue);
  border-radius: 8px;
  min-width: 155px;
  padding: 10px 22px;
  text-align: center;
`;

export function SmallUploadPhotoButton() {
  return (
    <Body>
      <Stack width="100%">
        <HorizontalStack>
          <FontAwesomeIcon
            icon={["fas", "camera"]}
            style={{
              width: "19px",
              height: "19px",
              color: Colors.White,
            }}
          />
          <Spacer width={8} />
          <div>Take another photo</div>
        </HorizontalStack>
      </Stack>
    </Body>
  );
}
