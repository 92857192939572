// This import must remain first for proper error tracking initialization
import "./setup/sentry";

import { App } from "./App";
import { initializeAnalytics } from "./Integrations/analytics";

import "./setup/setup";

import { createRoot } from "react-dom/client";

initializeAnalytics();

const container = document.getElementById("fs-root");
const root = createRoot(container!);

root.render(<App />);
