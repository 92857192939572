import { SavedLocation } from "@freightsimple/generated-dashboard-openapi-client";

export function errorMessageForBranches(
  branches: SavedLocation[],
  branchId: undefined | string,
) {
  // No branches, so can't be an error
  if (branches.length === 0) {
    return undefined;
  }

  if (branches.length > 0 && branchId === undefined) {
    return "Required";
  }

  return undefined;
}
