import Colors from "../Colors";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
  CSSProperties,
  HTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
} from "react";
import styled from "styled-components";
import Icon from "../Icons/Icon";

interface StyledCardContainerProps
  extends LabelHTMLAttributes<HTMLLabelElement> {
  $isChecked: boolean;
}

const StyledCardContainer = styled((props: StyledCardContainerProps) => (
  <label {...props} />
))<StyledCardContainerProps>`
  flex: 1;
  cursor: ${(props) => (props.$isChecked ? "unset" : "pointer")};
  border: 1px solid
    ${(props) => (props.$isChecked ? Colors.Blue : Colors.LightGray)};
  background: ${(props) => (props.$isChecked ? "#F6F7FE" : "unset")};
  border-radius: 4px;
  &:hover {
    filter: ${(props) => (props.$isChecked ? "unset" : "brightness(0.75)")};
  }
`;

interface CardRadioButtonProps extends HTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  isChecked: boolean;
  value: string | number;
  description?: ReactNode;
  icon?: IconName;
  style?: CSSProperties;
}

export function CardRadioButton({
  icon,
  label,
  description,
  isChecked,
  value,
  style,
  ...props
}: CardRadioButtonProps) {
  return (
    <StyledCardContainer
      $isChecked={isChecked}
      htmlFor={value.toString()}
      style={style}
    >
      <main
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "24px",
          padding: "20px 24px",
        }}
      >
        {icon && (
          <aside>
            <Icon
              name={icon}
              size={40}
              solid
              color={isChecked ? Colors.Blue : Colors.LightGray}
            />
          </aside>
        )}
        <section style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "4px",
            }}
          >
            <h5
              style={{
                fontWeight: isChecked ? 500 : 400,
                margin: 0,
              }}
            >
              {label}
            </h5>
            <input
              {...props}
              type="radio"
              id={value.toString()}
              checked={isChecked}
              value={value}
            />
          </div>
          <div
            style={{
              color: "#707070",
            }}
          >
            {description}
          </div>
        </section>
      </main>
    </StyledCardContainer>
  );
}
