import Colors from "../../../Cargo/Colors";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Label } from "../../../Cargo/Text/Label";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { isDemo } from "../../../environment";
import CardholderName from "./CardholderName";
import CreditCardCVC from "./CreditCardCVC";
import CreditCardExpiration from "./CreditCardExpiration";
import CreditCardNumber from "./CreditCardNumber";

import styled from "styled-components";

interface CardSectionProps {
  onBillingNameChanged: (_: string) => void;
  onBrandChange?: (brandName: string) => void;
  billingName: string;
  forceValidation: boolean;
  errorMessageForBillingName: ErrorMessageType;
  onCreditCardNumberError: (message: ErrorMessageType) => void;
  onCreditCardExpirationError: (message: ErrorMessageType) => void;
  onCreditCardCVCError: (message: ErrorMessageType) => void;
  errorMessageForCreditCardNumber: ErrorMessageType;
  errorMessageForCreditCardExpiration: ErrorMessageType;
  errorMessageForCreditCardCVC: ErrorMessageType;
  onValidate: () => void;
}

const DemoCreditCardInfoText = styled.div`
  color: var(--freightsimple-color-light-text);
  margin-bottom: 8px;
`;

function DemoCreditCardInfo() {
  if (!isDemo()) {
    return <></>;
  }

  return (
    <DemoCreditCardInfoText>
      <Icon
        name="info-circle"
        color={Colors.LightText}
        size={16}
        solid
        style={{ marginRight: "8px" }}
      />
      <span>For demo purposes you can enter 4242 4242 4242 4242</span>
    </DemoCreditCardInfoText>
  );
}

const CardSection: React.FC<CardSectionProps> = (props: CardSectionProps) => {
  return (
    <>
      <Stack align="left">
        <HorizontalStack>
          <Stack align="left">
            <Label>Cardholder Name</Label>
            <CardholderName
              cardholderName={props.billingName}
              onCardholderNameChanged={props.onBillingNameChanged}
              errorMessage={props.errorMessageForBillingName}
              validated={props.forceValidation}
            />
          </Stack>
        </HorizontalStack>
        <HorizontalStack>
          <Stack align="left">
            <Label>Card Number</Label>
            <DemoCreditCardInfo />
            <CreditCardNumber
              onBrandChange={props.onBrandChange}
              onError={props.onCreditCardNumberError}
              errorMessage={props.errorMessageForCreditCardNumber}
              validated={props.forceValidation}
            />

            <Spacer height={22} />
          </Stack>
        </HorizontalStack>
        <Spacer height={22} />
        <HorizontalStack>
          <Stack align="left">
            <Label>Expiry Date</Label>
            <CreditCardExpiration
              onError={props.onCreditCardExpirationError}
              errorMessage={props.errorMessageForCreditCardExpiration}
              forceValidation={props.forceValidation}
            />
          </Stack>
          <Spacer width={32} />
          <Stack align="left">
            <Label>CVC</Label>
            <CreditCardCVC
              onError={props.onCreditCardCVCError}
              errorMessage={props.errorMessageForCreditCardCVC}
              forceValidation={props.forceValidation}
            />
          </Stack>
        </HorizontalStack>
      </Stack>
    </>
  );
};
export default CardSection;
