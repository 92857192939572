import Colors from "../../../Cargo/Colors";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Stack from "../../../Cargo/Layout/Stack";
import styled from "styled-components";

const Company = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 12px;
  color: var(--freightsimple-color-normal-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 196px;
`;
const City = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 12px;
  color: var(--freightsimple-color-light-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 196px;
`;
const Reference = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 11px;
  color: var(--freightsimple-color-light-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 196px;
`;

interface SmallFromToProps {
  pickupBusinessName: string;
  pickupCity: string;
  pickupReference: string;
  deliveryBusinessName: string;
  deliveryCity: string;
  deliveryReference: string;
}
function SmallFromTo(props: SmallFromToProps) {
  const {
    pickupBusinessName,
    pickupCity,
    pickupReference,
    deliveryBusinessName,
    deliveryCity,
    deliveryReference,
  } = props;
  return (
    <div style={{ width: "440px" }}>
      <HorizontalStack
        style={{
          width: "440px",
          marginRight: "16px",
          marginLeft: "16px",
        }}
      >
        <HorizontalStack align="right" style={{ width: "196px" }}>
          <Stack align="right">
            <Company>{pickupBusinessName}</Company>
            <City>{pickupCity}</City>
            {pickupReference !== "" && <Reference>{pickupReference}</Reference>}
          </Stack>
        </HorizontalStack>
        <Icon
          name="arrow-right"
          color={Colors.NormalText}
          size={16}
          solid
          style={{
            marginRight: "16px",
            marginLeft: "16px",
          }}
        />
        <Stack align="left" style={{ width: "196px" }}>
          <Company>{deliveryBusinessName}</Company>
          <City>{deliveryCity}</City>
          {deliveryReference !== "" && (
            <Reference>{deliveryReference}</Reference>
          )}
        </Stack>
      </HorizontalStack>
    </div>
  );
}
export default SmallFromTo;
