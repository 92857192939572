import RedExclamationAnimatedIcon from "../../../Cargo/Icons/RedExclamationAnimatedIcon";
import { ReactNode } from "react";

interface BookShipmentDetailsErrorWarningProps {
  children: ReactNode;
}

export function BookShipmentDetailsErrorWarning({
  children,
}: BookShipmentDetailsErrorWarningProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        paddingBottom: "30px",
      }}
    >
      <RedExclamationAnimatedIcon />
      {children}
    </div>
  );
}
