import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { ModalTitle } from "../../../Cargo/Text/Text";
import { Label } from "../../../Cargo/Text/Label";
import ContactQuestionBubble from "../../BookShipment/Components/Addresses/QuestionBubbles/ContactQuestionBubble";
import {
  LocationContext,
  SavedContact,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import LargeContactInput from "../Components/LargeContactInput";
import { AddContactContactType } from "../Types/contactTypes";
import {
  EmailRequired,
  errorMessagesForContact,
} from "../Validators/errorMessagesForContact";

interface ModifyContactProps {
  contactToModify: SavedContact;
  onDone: (modifiedContact: SavedContact) => void;
  onCancel: () => void;
}

function ModifyContact(props: ModifyContactProps) {
  const { onCancel } = props;
  const [contact, setContact] = useState<SavedContact>(props.contactToModify);
  const [forceValidation, setForceValidation] = useState(false);

  const errorMessages = errorMessagesForContact(
    contact.contact,
    EmailRequired.EmailNotRequired,
  );

  function isValid() {
    if (
      errorMessages.contactName ||
      errorMessages.emailAddress ||
      errorMessages.phoneNumber
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function onAdd() {
    if (!isValid()) {
      setForceValidation(true);
    }

    props.onDone(contact);
  }

  return (
    <>
      <Label>
        Contact Details
        <ContactQuestionBubble context={LocationContext.Any} />
      </Label>
      <LargeContactInput
        contact={contact.contact}
        contactChanged={function (updatedContact: AddContactContactType) {
          setContact({
            ...contact,
            contact: {
              ...contact.contact,
              ...updatedContact,
            },
          });
        }}
        forceValidation={forceValidation}
        errorMessages={errorMessages}
      ></LargeContactInput>
      <Spacer height={32} />
      <HorizontalStack width="100%" align="spread">
        <Button onClick={onCancel} secondary>
          Cancel
        </Button>
        <Button onClick={onAdd}>Save</Button>
      </HorizontalStack>
    </>
  );
}

interface ModifyContactModalProps {
  contactToModify: SavedContact;
  onDone: (modifiedContact: SavedContact) => void;
  onCancel: () => void;
}

export function ModifyContactModal(props: ModifyContactModalProps) {
  return (
    <>
      <ModalTitle>Modify contact</ModalTitle>

      <ModifyContact
        contactToModify={props.contactToModify}
        onDone={props.onDone}
        onCancel={props.onCancel}
      />
    </>
  );
}
