import { useModal } from "../../../Cargo/Modal/useModal";
import {
  FilterShipmentsDescription,
  FilterShipmentsModal,
} from "./FilterShipmentsModal";
import { emptyFilterShipmentsDescription } from "./emptyFilterShipmentsDescription";

export function useFilterShipmentsModal() {
  const showModal = useModal<FilterShipmentsDescription>();
  function show(filterDescription: FilterShipmentsDescription) {
    const p = new Promise<FilterShipmentsDescription>((resolve) => {
      showModal(
        (done) => {
          return (
            <FilterShipmentsModal
              filterDescription={filterDescription}
              onConfirm={done}
              onCancel={function () {
                done();
              }}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed || emptyFilterShipmentsDescription());
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
