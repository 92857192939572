import Button from "../../../Cargo/Controls/Button";
import UpdatedBillOfLadingAnimation from "../../../Cargo/Icons/UpdatedBillOfLadingAnimation";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ModalTitle } from "../../../Cargo/Text/Text";
import styled from "styled-components";

interface UpdatedBOLModalProps {
  onDone: () => void;
}

export const ModalBody = styled.div`
  color: var(--freightsimple-color-light-text);
  font-size: 14px;
  text-align: center;
`;

export function UpdatedBOLModal(props: UpdatedBOLModalProps) {
  return (
    <>
      <Stack align="center" style={{ padding: "16px", width: "400px" }}>
        <ModalTitle>Updated Shipment Documents</ModalTitle>
        <Spacer height={8} />
        <ModalBody>
          Details of the shipment have been updated, and a new bill of lading
          has been generated, and emailed to the shipper.
        </ModalBody>
        <Spacer height={16} />
        <UpdatedBillOfLadingAnimation />
        <ModalBody>
          To avoid delays in shipment, please make sure the new bill of lading
          is given to the driver for pickup
        </ModalBody>
        <Spacer height={32} />
        <HorizontalStack align="center">
          <Button onClick={props.onDone}>Done</Button>
        </HorizontalStack>
      </Stack>
    </>
  );
}
