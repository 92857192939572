import { useModal } from "../../../Cargo/Modal/useModal";
import { InviteModal } from "./InviteModal";

export function useInviteModal() {
  const showModal = useModal<boolean>();

  function show(callback: (shouldReload: boolean) => void) {
    showModal(
      (done) => {
        return (
          <InviteModal
            onCancel={() => done(false)}
            onInviteSent={() => done(true)}
          ></InviteModal>
        );
      },
      function (inviteSent: boolean | undefined) {
        callback(inviteSent ?? false);
      },
      {
        preventBackdropClick: true,
      },
    );
  }

  return show;
}
