import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface DeliveryContactPageProp {
  shipment: Shipment;
}

export function DeliveryContactPage(_props: DeliveryContactPageProp) {
  return <Heading1>Delivery Contact</Heading1>;
}
