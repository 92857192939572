import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import { UploadCustomsDocsModal } from "./UploadCustomsDocsModal";

export function useUploadCustomsDocsModal(trackingId: UUID) {
  const showModal = useModal<boolean>();

  async function show(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      showModal(
        (done) => {
          return (
            <UploadCustomsDocsModal
              onCancel={() => done(false)}
              onFinished={() => {
                done(true);
              }}
              trackingId={trackingId}
            />
          );
        },
        async (data) => {
          if (data !== undefined) {
            resolve(data);
          }
        },
        {
          preventBackdropClick: false,
        },
      );
    });
  }
  return show;
}
