import Colors from "../../../Cargo/Colors";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";

import { FilterShipmentsDescription } from "../Modals/FilterShipmentsModal";
import {
  descriptionForSortShipmentOption,
  SortShipmentOptions,
} from "../Modals/useSortShipmentsModal";
import { FilterPills } from "./FilterPills";

interface FilterBarProps {
  onFilter: () => Promise<void>;
  onSort: () => Promise<void>;
  filter: FilterShipmentsDescription;
  sort: SortShipmentOptions;
  setFilter: (newFilter: FilterShipmentsDescription) => void;
}

export function FilterBar(props: FilterBarProps) {
  return (
    <HorizontalStack align="left">
      <HorizontalStack align="left" verticalAlign="top">
        <LinkButton onClick={props.onFilter}>
          <Icon
            name="filter"
            color={Colors.Blue}
            size={16}
            solid
            style={{
              position: "relative",
              top: "2px",
              marginRight: "2px",
            }}
          />
          Filter Shipments
        </LinkButton>
        <Spacer width={16} />
        <LinkButton onClick={props.onSort}>
          <Icon
            name="sort"
            color={Colors.Blue}
            size={16}
            solid
            style={{
              position: "relative",
              top: "2px",
              marginRight: "2px",
            }}
          />
          Sort Shipments: {descriptionForSortShipmentOption(props.sort)}
        </LinkButton>
      </HorizontalStack>

      <FilterPills filter={props.filter} setFilter={props.setFilter} />
    </HorizontalStack>
  );
}
