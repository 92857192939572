import { AnimatedSizeContianer } from "../../../Cargo/Animations/AnimatedSizeContainer";
import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import { UpdateCustomsDocsModal } from "./UpdateCustomsDocsModal";

export function useUpdateCustomDocsModal(
  shipmentId: UUID | undefined,
  onReload?: () => Promise<void>,
): () => void | null {
  const showModal = useModal<UUID>();

  function show() {
    if (shipmentId === undefined) {
      return null;
    }

    showModal(
      (done) => {
        return (
          <AnimatedSizeContianer skipOnFirstRender>
            <UpdateCustomsDocsModal shipmentId={shipmentId} onDone={done} />
          </AnimatedSizeContianer>
        );
      },
      onReload,
      {
        preventBackdropClick: true,
      },
    );
    // This smells odd
    return undefined;
  }

  return show;
}
