import { datadogRum } from "@datadog/browser-rum";
import Colors from "../../../Cargo/Colors";
import { useSiteMargin } from "../../../Cargo/Layout/useSiteMargin";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { useAuthentication } from "../../Authentication/Slices/authenticationSlice";
import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { NavLeftSide } from "./LeftSide/NavLeftSide";
import { NavRightSide } from "./RightSide/NavRightSide";
import { SubNavBar } from "./SubNavBar/SubNavBar";
import { isPreprod, isProduction } from "../../../environment";
import { isDemo } from "../../../environment";
import * as Sentry from "@sentry/react";

const NavBar: React.FC = () => {
  const { boot } = useIntercom();
  const { isAuthenticated, user } = useAuthentication();

  const paddingWidth = useSiteMargin();

  useEffect(() => {
    setTimeout(() => {
      // Intercom
      if (isPreprod() || isProduction() || isDemo()) {
        if (isAuthenticated && user) {
          if (!user.email.endsWith("@freightsimple.com")) {
            boot({
              email: user.email,
              name: user.name,
              company: {
                companyId: user.companyId || "",
              },
            });
          }
        }
      }

      // Datadog
      if (isProduction()) {
        if (isAuthenticated && user) {
          if (!user.email.endsWith("@freightsimple.com")) {
            try {
              datadogRum.setUser({
                id: user.userId,
                name: user.name,
                email: user.email,
              });
            } catch (e) {
              console.error("Error identifying datadog", e);
            }
          }
        } else {
          boot();
        }
      }

      // Sentry
      if (isProduction()) {
        if (isAuthenticated && user) {
          try {
            // Example user session information
            const sentryUser = {
              id: user.userId,
              username: user.name,
              email: user.email,
            };

            // Set the user information in Sentry
            Sentry.setUser(sentryUser);
          } catch (e) {
            console.error("Error identifying sentry", e);
          }
        } else {
          boot();
        }
      }
    }, 1000);
  }, [isAuthenticated]);

  function getBackgroundColor() {
    if (isAuthenticated) {
      return Colors.SubtleBlueBackground;
    } else {
      return Colors.White;
    }
  }

  function getBorderStyle() {
    if (isAuthenticated) {
      return `1px solid ${Colors.VeryVeryLightBlue}`;
    } else {
      return `none`;
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: getBackgroundColor(),
          borderBottom: getBorderStyle(),
        }}
      >
        <Spacer height={4} />
        <HorizontalStack
          align="spread"
          width="100%"
          padding={`0 ${paddingWidth}px 0 ${paddingWidth}px`}
        >
          <NavLeftSide />
          <NavRightSide />
        </HorizontalStack>
        <Spacer height={4} />
      </div>

      {isAuthenticated && (
        <div
          style={{
            paddingLeft: `${paddingWidth}px`,
            paddingRight: `${paddingWidth}px`,
          }}
        >
          <SubNavBar />
        </div>
      )}
    </>
  );
};

export default NavBar;
