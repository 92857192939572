import { UUID } from "../Types/types";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import ModalFocusTrap from "./ModalFocusTrap";

interface ModalProps {
  id: UUID;
  onBackdropClick: () => void;
  children: ReactNode;
  index: number;
  // TODO: MAke required
  closing?: boolean;
  showLoading: boolean;
  padding?: string;
  simpleModal?: boolean;
}

const animateIn = keyframes`
  0%   { opacity: 0;  }
  100% { opacity: 1;  }
`;

const animateOut = keyframes`
  0%   { opacity: 1;  }
  100% { opacity: 0;  }
`;

const ModalBackdrop = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px) brightness(90%);
  z-index: ${(props) => (props.index + 1) * 1000};
  overflow-y: auto;

  animation: ${animateIn} 0.3s ease-in;
  animation-fill-mode: both;

  &.closing {
    animation: ${animateOut} 0.2s ease-in !important;
    opacity: 0;
  }
`;

interface ModalContainerProps {
  showLoading: boolean;
  padding?: string;
}

const ModalContainer = styled.div<ModalContainerProps>`
  background-color: white;

  padding: ${(props) => props.padding ?? "32px"};
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  border: 1px solid #bbb;
  max-width: 100vw;

  border-radius: 16px;

  /*This cuts off dropdowns, so removing it for now */
  /*overflow: scroll;*/

  ${(props) => (props.showLoading ? "opacity:0;" : "")}
`;

const SimpleModalContainer = styled.div<ModalContainerProps>`
  background-color: white;
  border: 1px solid #bbb;
  max-width: 100vw;
  ${(props) => (props.showLoading ? "opacity:0;" : "")}
`;

function Modal(props: ModalProps) {
  const Container = props.simpleModal ? SimpleModalContainer : ModalContainer;
  return createPortal(
    <ModalBackdrop
      className={props.closing ? "closing" : ""}
      onClick={function () {
        props.onBackdropClick();
      }}
      {...props}
    >
      <div style={{ margin: "auto" }}>
        <Container
          showLoading={props.showLoading}
          onClick={(e) => e.stopPropagation()}
          padding={props.padding}
        >
          <ModalFocusTrap>{props.children}</ModalFocusTrap>
        </Container>
      </div>
    </ModalBackdrop>,
    document.getElementById("fs-modal") ?? document.body,
  );
}

// interface ModalsProviderProps {
//     children: ReactNode;
// }

// interface ManageModals {
//     showModal: (
//         contentProvider: ContentProviderFunction,
//         onHide?: () => void
//     ) => void;
//     ModalsProvider: (props: ModalsProviderProps) => ReactElement;
// }

// export function useModal(): ManageModals {
//     const { modals, closingSet } = useSelector(
//         (state: RootState) => state.modals
//     );
//     const dispatch = useDispatch();

//     function closeModal(modalInstanceId: UUID) {
//         const modal = modals.find(m => m.id === modalInstanceId);
//         const onHide = modal?.onHide;

//         setTimeout(function() {
//             dispatch(startClosingModalById(modalInstanceId));

//             setTimeout(function() {
//                 dispatch(removeModal(modalInstanceId));

//                 if (onHide) {
//                     onHide();
//                 }
//             }, 1000);
//         }, 100);
//     }

//     function showModal(
//         contentProvider: ContentProviderFunction,
//         onHide?: () => void
//     ) {
//         const modalInstanceId = uuid.v4();

//         function removeModal() {
//             closeModal(modalInstanceId);
//         }

//         const contents = contentProvider(removeModal);
//         const modal = {
//             id: modalInstanceId,
//             contents,
//             onHide,
//             close: removeModal
//         };

//         dispatch(addModal(modal));
//     }
//     interface ModalsProviderProps {
//         children: ReactNode;
//     }

//     ////////////////////////////////////////////////////////////
//     function ModalsProvider(props: ModalsProviderProps) {
//         return (
//             <>
//                 {props.children}
//                 {modals.map((modalInstance, index) => {
//                     return (
//                         <Modal
//                             id={modalInstance.id}
//                             index={index}
//                             key={modalInstance.id}
//                             hide={() => modalInstance.close()}
//                             closing={closingSet.has(modalInstance.id)}
//                         >
//                             {modalInstance.contents}
//                         </Modal>
//                     );
//                 })}
//             </>
//         );
//     }

//     return { showModal, ModalsProvider };
// }

// TODO: Do not export
export default Modal;
