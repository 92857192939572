import {
  AddLocationAddressType,
  AddLocationLocationType,
} from "../../../../Locations/Types/locationTypes";
import {
  Address,
  Location,
  PreQuoteLocation,
} from "@freightsimple/generated-dashboard-openapi-client";
import { formatPostalCode } from "../../../../../Helpers/formatPostalCode";

export function formatAddress(address: Address | AddLocationAddressType) {
  if (address.postalCode === undefined) {
    throw new Error("Missing postalCode");
  }
  return `${address.city}, ${address.stateOrProvinceCode}, ${formatPostalCode(
    address.postalCode,
  )}`;
}

export function formatLocation(
  location: Location | AddLocationLocationType | PreQuoteLocation,
) {
  return formatAddress(location.address);
}
