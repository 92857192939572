import { Currency } from "@freightsimple/generated-dashboard-openapi-client";
import { ErrorMessageType } from "../Validation";
import Dropdown from "./Dropdown";

interface CurrencyDropdownProps {
  currency: Currency | undefined;
  onCurrencyChange: (newValue: Currency) => void;
  enabled: boolean;
  menuWidth?: number;
  width?: number;
  label?: string;
  errorMessage?: ErrorMessageType;
  forceValidation?: boolean;
  id?: string;
}

export function CurrencyDropdown(props: CurrencyDropdownProps) {
  const options = [
    {
      item: Currency.Cad,
      description: "CAD",
    },
    {
      item: Currency.Usd,
      description: "USD",
    },
  ];

  return (
    <Dropdown<Currency>
      selectedItem={props.currency}
      onOptionSelected={function (newState: Currency) {
        props.onCurrencyChange(newState);
      }}
      unselectedDescription="Select"
      options={options}
      menuWidth={props.width ?? 100}
      width={props.width ?? 100}
      errorMessage={props.errorMessage}
      placeholder={props.label}
      disabled={!props.enabled}
      forceValidation={props.forceValidation}
      id={props.id ?? "currency_dropdown"}
    />
  );
}
