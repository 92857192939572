import { LocationErrors } from "./errorMessagesForLocation";

export function emptyLocationErrorMessages(): LocationErrors {
  return {
    businessName: undefined,
    address: {
      addressLine: undefined,
      addressLine2: undefined,
      city: undefined,
      stateOrProvinceCode: undefined,
      postalCode: undefined,
      country: undefined,
    },
    locationType: undefined,
    hours: undefined,
  };
}
