import { useModal } from "../../../Cargo/Modal/useModal";
import { ShowReferralCodeModal } from "./useReferralCodeModal";

export function useReferralCodeModal() {
  const showModal = useModal<void>();

  async function show(): Promise<void> {
    return new Promise<void>((resolve) => {
      showModal(
        (done) => {
          return <ShowReferralCodeModal onFinished={done} />;
        },
        async (data) => {
          if (data !== undefined) {
            resolve(data);
          }
        },
        {
          preventBackdropClick: true,
        },
      );
    });
  }
  return show;
}
