import { useModal } from "../../../Cargo/Modal/useModal";
import {
  LocationContext,
  SavedLocation,
} from "@freightsimple/generated-dashboard-openapi-client";

import ModifyLocationModal, {
  ModifyLocationModalCallbackData,
} from "../Modals/ModifyLocationModal";

function useModifyLocationModal(
  context: LocationContext,
  callback: (data: ModifyLocationModalCallbackData) => void,
) {
  const showModal = useModal<ModifyLocationModalCallbackData>();

  function show(locationToModify: SavedLocation) {
    showModal(
      (done) => {
        return (
          <ModifyLocationModal
            context={context}
            onCancel={() => done()}
            onFinished={done}
            locationToModify={locationToModify}
          />
        );
      },
      (data) => {
        if (data !== undefined) {
          callback(data);
        }
      },
      {
        preventBackdropClick: true,
      },
    );
  }

  return show;
}

export default useModifyLocationModal;
