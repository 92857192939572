import {
  QuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../../Cargo/Controls/QuestionBubble";
import { QuestionBubbleContext } from "../../../../../Cargo/Controls/QuestionBubbleContext";

interface NotesQuestionBubbleProps {
  displayName?: string;
  context?: QuestionBubbleContext;
}

const NotesQuestionBubble: React.FC<NotesQuestionBubbleProps> = (
  props: NotesQuestionBubbleProps,
) => {
  return (
    <QuestionBubble
      context={props.context ?? QuestionBubbleContext.Normal}
      description={
        <>
          <QuestionBubbleHeader>
            {props.displayName
              ? `Enter notes related to your ${props.displayName.toLowerCase()}`
              : `Enter notes for this location`}
          </QuestionBubbleHeader>
          <QuestionBubbleLabel>
            {`This information will be added to your generated documents.`}
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/pickup-and-delivery-notes"
    />
  );
};
export default NotesQuestionBubble;
