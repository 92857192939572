import NewLocationDetails from "../../BookShipment/Components/Location/NewLocationDetails";
import { warningMessagesForDeliveryLocation } from "../../Locations/Validators/errorMessagesForDeliveryLocation";
import { errorMessagesForLocation } from "../../Locations/Validators/errorMessagesForLocation";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface DeliveryDetailsPageProp {
  shipment: Shipment;
}

export function DeliveryDetailsPage(props: DeliveryDetailsPageProp) {
  const errorMessages = errorMessagesForLocation(
    props.shipment.deliveryLocation,
    false,
  );
  const warningMessages = warningMessagesForDeliveryLocation(
    props.shipment.pickupLocation,
    props.shipment.deliveryLocation,
  );

  return (
    <NewLocationDetails
      locationErrorMessages={errorMessages}
      locationWarningMessages={warningMessages}
      context={LocationContext.Delivery}
      setLocation={() => {}}
      location={props.shipment.deliveryLocation}
      forceValidation={false}
      disablePostalCodeEntry={false}
      pickupDate={props.shipment.pickupDate}
    />
  );
}
