import { useRandomCreateAccountBackgroundImage } from "../../../Helpers/randomBackgroundImage";
import { trackAnalyticsEvent } from "../../../Integrations/analytics";
import { CreateAccountForm2 } from "../../Authentication/Components/CreateAccountForm2";
import { AuthenticationPaneContainer } from "./AuthenticationPaneContainer";
import { CreateAccountPaneProps } from "./useAuthenticateForQuotesModal";

export function CreateAccountPane(props: CreateAccountPaneProps) {
  const { onSignInInstead } = props;
  const backgroundImage = useRandomCreateAccountBackgroundImage();

  async function onSuccess() {
    trackAnalyticsEvent("Get Started/Account Created");
    props.onDone();
  }

  return (
    <AuthenticationPaneContainer image={backgroundImage}>
      <CreateAccountForm2
        onSignInInstead={onSignInInstead}
        onSuccess={onSuccess}
      />
    </AuthenticationPaneContainer>
  );
}
