import HorizontalStack from "../Layout/HorizontalStack";
import { formatPrice } from "../../Helpers/formatPrice";
import { symbolForCurrency } from "../../Helpers/symbolForCurrency";

import styled from "styled-components";

interface LargePriceProps {
  currency: string;
  price: number;
}

const CurrencySymbol = styled.div`
  line-height: 1;
  font-weight: var(--nhu-font-weight-bold);
  font-size: 24px;
  color: var(--freightsimple-color-normal-text);
  position: relative;
  top: 2px;
`;
const Price = styled.div`
  line-height: 1;
  font-weight: var(--nhu-font-weight-bold);
  font-size: 48px;
  color: "#545454";
`;
const CurrencyLabel = styled.div`
  line-height: 1;
  font-weight: var(--nhu-font-weight-bold);
  font-size: 24px;
  color: var(--freightsimple-color-light-text);
  position: relative;
  top: 20px;
  margin-left: 2px;
`;

const LargePrice: React.FC<LargePriceProps> = (props: LargePriceProps) => {
  return (
    <HorizontalStack verticalAlign="top" align="right">
      <CurrencySymbol>{symbolForCurrency(props.currency)}</CurrencySymbol>
      <Price>{formatPrice(props.price)}</Price>
      <CurrencyLabel>{props.currency}</CurrencyLabel>
    </HorizontalStack>
  );
};
export default LargePrice;
