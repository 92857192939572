import { ErrorMessageType } from "../../../Cargo/Validation";
import { AddContactContactType } from "../../Contacts/Types/contactTypes";
import {
  ContactErrors,
  EmailRequired,
  errorMessagesForContact,
} from "../../Contacts/Validators/errorMessagesForContact";
import { errorMessageForBusinessName } from "../../Locations/Validators/errorMessageForBusinessName";
import {
  AddressErrors,
  errorMessagesForAddress,
} from "../../Locations/Validators/errorMessagesForAddress";
import { Broker } from "@freightsimple/generated-dashboard-openapi-client";
import { AddBrokerBrokerType } from "../Types/brokerTypes";

export interface BrokerErrors {
  businessName: ErrorMessageType;
  address: AddressErrors;
  contact: ContactErrors;
}

export function errorMessagesForBroker(
  broker: AddBrokerBrokerType | Broker,
  comparisonContacts?: Map<string, AddContactContactType>,
): BrokerErrors {
  return {
    businessName: errorMessageForBusinessName(broker.businessName),
    address: errorMessagesForAddress(broker.address),
    contact: errorMessagesForContact(
      broker.contact,
      EmailRequired.EmailRequired,
      comparisonContacts,
    ),
  };
}
