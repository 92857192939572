import Dropdown from "../../../Cargo/Controls/Dropdown";
import { DropdownElement } from "../../../Cargo/Controls/DropdownElement";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { UUID } from "../../../Cargo/Types/types";
import {
  Broker,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";

import styled from "styled-components";

const LinkHolder = styled.div`
  margin-left: 16px;
  margin-top: 8px;
`;

interface SavedBrokersDropdownProps {
  savedBrokerId: UUID | undefined;
  onSetSavedBrokerId: (brokerId: UUID) => void;
  savedBrokers: Array<SavedBroker>;
  width?: number;
  onAddBroker?: () => void;
}

const SavedBrokersDropdownItemTitle = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 16px;
`;

const SavedBrokersDropdownItemDescription = styled.div`
  color: var(--freightsimple-color-light-text);
  font-weight: var(--nhu-font-weight-light);
  font-size: 12px;
`;

const SavedBrokersDropdown: React.FC<SavedBrokersDropdownProps> = (
  props: SavedBrokersDropdownProps,
) => {
  function summarizeAddress(broker: Broker) {
    if (!broker.address) {
      throw new Error("Missing broker.address");
    }
    return (
      <DropdownElement>
        <div
          style={{
            minWidth: "300px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
        >
          <HorizontalStack align="left" verticalAlign="middle">
            <SavedBrokersDropdownItemTitle>
              {broker.businessName}
            </SavedBrokersDropdownItemTitle>
            <Spacer width={8} />
            <SavedBrokersDropdownItemDescription>
              {broker.address.city}, {broker.address.stateOrProvinceCode}
            </SavedBrokersDropdownItemDescription>
          </HorizontalStack>
        </div>
      </DropdownElement>
    );
  }

  function dropdownLocationOptions(brokers: Array<SavedBroker>) {
    if (!brokers) {
      return [];
    }

    const options = brokers.map((i) => ({
      item: i.savedBrokerId,
      description: summarizeAddress(i.broker),
    }));

    return options;
  }

  function onOptionSelected(item: string) {
    const broker = props.savedBrokers.find((l) => l.savedBrokerId === item);
    if (broker === undefined) {
      throw new Error("Missing broker");
    }

    props.onSetSavedBrokerId(item);
  }

  return (
    <>
      <HorizontalStack>
        <Dropdown
          selectedItem={props.savedBrokerId}
          onOptionSelected={onOptionSelected}
          options={dropdownLocationOptions(props.savedBrokers)}
          unselectedDescription="Select an customs broker"
          width={420}
        />
        {props.onAddBroker && (
          <LinkHolder>
            <LinkButton onClick={props.onAddBroker}>
              or add new customs broker
            </LinkButton>
          </LinkHolder>
        )}
      </HorizontalStack>
    </>
  );
};
export default SavedBrokersDropdown;
