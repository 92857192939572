import Stack from "../../../Cargo/Layout/Stack";
import { ModalTitle } from "../../../Cargo/Text/Text";
import { UUID } from "../../../Cargo/Types/types";
import {
  Location,
  LocationContext,
  SavedLocation,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import ModifyLocationModalAddressPage from "../Components/ModifyLocationModalAddressPage";
import ModifyLocationModalDetailsPage from "../Components/ModifyLocationModalDetailsPage";
import ModifyLocationModalOtherPage from "../Components/ModifyLocationModalOtherPage";

export interface ModifyLocationModalCallbackData {
  location: Location;
  savedLocationId: UUID;
}

interface LocationModalProps {
  locationToModify: SavedLocation;
  onFinished: (params: ModifyLocationModalCallbackData) => void;
  onCancel: () => void;
  context: LocationContext;
}

function ModifyLocationModal(props: LocationModalProps) {
  const { onCancel, onFinished } = props;

  const [location, setLocation] = useState<SavedLocation>(
    props.locationToModify,
  );

  enum Page {
    Details,
    Address,
    Other,
  }

  const [currentPage, setCurrentPage] = useState(Page.Details);

  function onSwitchToDetailsPage() {
    setCurrentPage(Page.Details);
  }

  function onSwitchToAddressPage() {
    setCurrentPage(Page.Address);
  }

  function onSwitchToOtherPage() {
    setCurrentPage(Page.Other);
  }

  async function onDone() {
    onFinished({
      location: location.location,
      savedLocationId: location.savedLocationId,
    });
  }

  return (
    <Stack align="center">
      <Stack align="left">
        <ModalTitle>Modify Location</ModalTitle>

        <div style={{ minHeight: "330px" }}>
          {currentPage === Page.Details && (
            <ModifyLocationModalDetailsPage
              onSwitchToAddressPage={onSwitchToAddressPage}
              setLocation={function (updatedLocation) {
                setLocation({
                  ...location,
                  location: updatedLocation,
                });
              }}
              location={location.location}
              onCancel={onCancel}
              microcopy="Please enter the details of this location"
              context={props.context}
            />
          )}

          {currentPage === Page.Address && (
            <ModifyLocationModalAddressPage
              setLocation={function (updatedLocation) {
                setLocation({
                  ...location,
                  location: updatedLocation,
                });
              }}
              location={location.location}
              onPrevious={onSwitchToDetailsPage}
              onDone={onSwitchToOtherPage}
            />
          )}

          {currentPage === Page.Other && (
            <ModifyLocationModalOtherPage
              setLocation={function (updatedLocation) {
                setLocation({
                  ...location,
                  location: updatedLocation,
                });
              }}
              location={location.location}
              onPrevious={onSwitchToAddressPage}
              onDone={onDone}
            />
          )}
        </div>
      </Stack>
    </Stack>
  );
}

export default ModifyLocationModal;
