import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Stack from "../../../Cargo/Layout/Stack";
import { getSingularOrPluralFormForHandlingUnitType } from "../../../Data/HandlingUnitTypes";
import { groupBy } from "../../../Helpers/groupBy";
import { sumOf } from "../../../Helpers/sumOf";
import { LineItem } from "@freightsimple/generated-dashboard-openapi-client";
import styled from "styled-components";

const LeftHeader = styled.div`
  width: 90px;
  text-align: right;
  margin-right: 16px;
`;
const RightHeader = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 16px;
  color: var(--freightsimple-color-normal-text);
`;
const RightSubHeader = styled.div`
  font-weight: var(--nhu-font-weight-normal);
  font-size: 14px;
  color: var(--freightsimple-color-light-text);
  margin-bottom: 16px;
`;

interface UploadPhotosShipmentDescriptionProps {
  pickupCompanyName: string;
  pickupCity: string;
  pickupState: string;
  deliveryCompanyName: string;
  deliveryCity: string;
  deliveryState: string;
  lineItems: LineItem[];
}

function countHandlingUnits(lineItems: LineItem[]): number {
  return sumOf(lineItems, (li) => li.numberHandlingUnits);
}

function describeHandlingUnits(lineItems: LineItem[]): string {
  const grouped = groupBy(lineItems, (li) => li.handlingUnitType);
  if (grouped.length > 1) {
    return "Handling Units";
  } else {
    return getSingularOrPluralFormForHandlingUnitType(
      lineItems[0].handlingUnitType,
      countHandlingUnits(lineItems),
    );
  }
}

function describeLineItems(lineItems: LineItem[]): string {
  const count = countHandlingUnits(lineItems);
  const description = describeHandlingUnits(lineItems);
  const totalWeight = sumOf(
    lineItems,
    (li) => li.numberHandlingUnits * li.weightPerHandlingUnit,
  );
  return `${count} x ${description} (Total ${totalWeight}lb)`;
}

function describeLineItemDescriptions(lineItems: LineItem[]): string {
  const grouped = groupBy(lineItems, (li) => li.description);

  if (grouped.length > 1) {
    return `${lineItems[0].description}...`;
  } else {
    return lineItems[0].description;
  }
}

export function UploadPhotosShipmentDescription(
  props: UploadPhotosShipmentDescriptionProps,
) {
  return (
    <div style={{ width: "100%", paddingLeft: "40px", paddingRight: "40px" }}>
      <Stack align="left" width="100%">
        <HorizontalStack verticalAlign="top">
          <LeftHeader>From:</LeftHeader>
          <Stack align="left">
            <RightHeader>{props.pickupCompanyName}</RightHeader>
            <RightSubHeader>
              {props.pickupCity}, {props.pickupState}
            </RightSubHeader>
          </Stack>
        </HorizontalStack>
        <HorizontalStack verticalAlign="top">
          <LeftHeader>To:</LeftHeader>
          <Stack align="left">
            <RightHeader>{props.deliveryCompanyName}</RightHeader>
            <RightSubHeader>
              {props.deliveryCity}, {props.deliveryState}
            </RightSubHeader>
          </Stack>
        </HorizontalStack>
        <HorizontalStack verticalAlign="top">
          <LeftHeader>Contents:</LeftHeader>
          <Stack align="left">
            <RightHeader>{describeLineItems(props.lineItems)}</RightHeader>
            <RightSubHeader>
              {describeLineItemDescriptions(props.lineItems)}
            </RightSubHeader>
          </Stack>
        </HorizontalStack>
      </Stack>
    </div>
  );
}
