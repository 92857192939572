import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotationContainer } from "../../../../Cargo/Animations/RotationContainer";
import Colors from "../../../../Cargo/Colors";
import Tooltip from "../../../../Cargo/Controls/Tooltip";
import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import Stack from "../../../../Cargo/Layout/Stack";
import { ReactNode, useState } from "react";

import styled from "styled-components";

interface ButtonContentProps {
  show: boolean;
  selected: boolean;
}

function ButtonContent(props: ButtonContentProps) {
  return (
    <HorizontalStack verticalAlign="middle" align="spread">
      <RotationContainer open={props.show}>
        <FontAwesomeIcon
          icon={["fas", "chevron-down"]}
          style={{
            width: "14px",
            height: "14px",
            color: props.selected ? Colors.White : Colors.NormalText,
            margin: "auto",
          }}
        />
      </RotationContainer>
    </HorizontalStack>
  );
}

interface SubNavPillMenuProps {
  selected: boolean;
  title: string;
  children: ReactNode;
}

function SubNavPillMenu(props: SubNavPillMenuProps) {
  const [isInButton, setIsInButton] = useState(false);

  function enterButton() {
    setIsInButton(true);
  }
  function leaveButton() {
    // setTimeout(function () {
    setIsInButton(false);
    // }, 300);
  }

  return (
    <Tooltip
      placement="bottom"
      hideArrow={true}
      tooltip={<Stack align="left">{props.children}</Stack>}
      noPadding
    >
      <div onMouseEnter={enterButton} onMouseLeave={leaveButton}>
        <SubNavPillBody selected={props.selected}>
          <HorizontalStack align="spread">
            {props.title}
            <Spacer width={6} />
            <ButtonContent show={isInButton} selected={props.selected} />
          </HorizontalStack>
        </SubNavPillBody>
      </div>
    </Tooltip>
  );
}

interface SubNavPillProps {
  title: string;
  selected: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

interface SubNavPillBodyProps {
  selected: boolean;
}

const SubNavPillBody = styled.div<SubNavPillBodyProps>`
  margin-right: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${Colors.NormalText};
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  padding: 4px 16px;
  transition: color 0.6s;
  transition: background-color 0.6s;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: ${Colors.VeryLightGray};
    ${(props) => (props.selected ? `background-color:${Colors.DarkGray};` : "")}
  }

  ${(props) => (props.selected ? `background-color:#999;` : "")}
  ${(props) => (props.selected ? `border-radius: 16px;` : "")}
    ${(props) => (props.selected ? `color:white;` : "")}
`;

const MenuItemBody = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  color: ${Colors.NormalText};
  width: 100%;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.VeryLightGray};
  }
`;

export const SubNavPillMenuDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ddd;
`;

interface MenuItemProps {
  title: string;
  href?: string | undefined;
  onClick?: () => void | Promise<void>;
}

export function SubNavPillMenuItem(props: MenuItemProps) {
  return (
    <a href={props.href} onClick={props.onClick} style={{ width: "100%" }}>
      <MenuItemBody>{props.title}</MenuItemBody>
    </a>
  );
}

export function SubNavPill(props: SubNavPillProps) {
  if (props.children) {
    return (
      <SubNavPillMenu
        title={props.title}
        selected={props.selected}
        children={props.children}
      />
    );
  } else {
    return (
      <SubNavPillBody selected={props.selected} onClick={props.onClick}>
        {props.title}
      </SubNavPillBody>
    );
  }
}
