import { formatPhoneNumber } from "./formatPhoneNumber";

export function formatPhoneNumberWithExtension(
  phoneNumber: string | undefined,
  extension: string | undefined,
) {
  if (extension === undefined || extension.trim() === "") {
    return formatPhoneNumber(phoneNumber);
  } else {
    return `${formatPhoneNumber(phoneNumber)} Ext. ${extension}`;
  }
}
