export function UploadPhotoButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="229"
      height="229"
      viewBox="0 0 229 229"
    >
      <defs>
        <filter id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-82 -177)">
          <g transform="translate(82 177)">
            <circle
              cx="114.5"
              cy="114.5"
              r="111"
              fill="#4C62EA"
              stroke="#BEC4ED"
              strokeWidth="7"
            ></circle>
            <text
              fill="#FFF"
              fontFamily="NHaasGroteskTXPro-65Md, Neue Haas Grotesk Text Pro"
              fontSize="16"
              fontWeight="400"
              letterSpacing="0.267"
            >
              <tspan x="95.111" y="146">
                Take{" "}
              </tspan>
              <tspan x="76.081" y="166">
                Shipment{" "}
              </tspan>
              <tspan x="90.193" y="186">
                Photo
              </tspan>
            </text>
            <g filter="url(#filter-1)">
              <g transform="translate(74 42.875)">
                <path
                  fill="#000"
                  fillRule="nonzero"
                  d="M23.588 5.323l-1.645 4.945H10.125C4.54 10.268 0 14.816 0 20.41v40.571c0 5.595 4.54 10.143 10.125 10.143h60.75C76.46 71.125 81 66.577 81 60.982V20.411c0-5.595-4.54-10.143-10.125-10.143H59.057l-1.645-4.945A7.574 7.574 0 0050.214.125H30.786a7.574 7.574 0 00-7.198 5.198zM40.5 25.483c8.388 0 15.188 6.81 15.188 15.213 0 8.403-6.8 15.215-15.188 15.215-8.388 0-15.188-6.812-15.188-15.215 0-8.402 6.8-15.214 15.188-15.214z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
