import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { ModifyShipmentModal } from "./useModifyShipment";

export function useModifyShipment(shipment: Shipment) {
  const showModal = useModal();

  function show() {
    const p = new Promise<void>((resolve) => {
      showModal(
        (done) => {
          return <ModifyShipmentModal onDone={done} shipment={shipment} />;
        },
        () => {
          resolve();
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
