import Colors from "../Colors";
import Icon from "../Icons/Icon";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";

import styled from "styled-components";
import Input from "./Input";

interface Tab<T> {
  type: T;
  title: string;
}

interface TabBarProps<T> {
  selectedTab: T;
  onTabSelected: (newTab: T) => void;
  tabs: Array<Tab<T>>;
  filter: string;
  onFilterChange: (newFilterValue: string) => void;
  showFilter: boolean;
}

interface TabBarButtonProps {
  $isSelected: boolean;
}

const TabBarButton = styled.div<TabBarButtonProps>`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 18px;
  color: ${Colors.Blue};
  padding-bottom: 8px;
  cursor: pointer;
  border-bottom: 3px solid
    ${(props) => (props.$isSelected ? Colors.Blue : "transparent")};

  &:hover {
    // If it's not selected, show a light blue line
    border-bottom: 3px solid
      ${(props) => (props.$isSelected ? Colors.Blue : Colors.LightBlue)};
  }

  margin-top: 12px;
`;

function TabBar<T>(props: TabBarProps<T>) {
  return (
    <HorizontalStack width="100%" align="spread" verticalAlign="top">
      <HorizontalStack>
        {props.tabs.map((tab, index) => {
          return (
            <>
              <TabBarButton
                key={`tab-${index}`}
                $isSelected={props.selectedTab === tab.type}
                onClick={function () {
                  props.onTabSelected(tab.type);
                }}
              >
                {tab.title}
              </TabBarButton>
              <Spacer width={32} />
            </>
          );
        })}
      </HorizontalStack>
      {props.showFilter && (
        <HorizontalStack>
          <div style={{ marginRight: "16px" }}>
            <Input
              type="text"
              placeholder="Filter locations..."
              value={props.filter}
              onChange={props.onFilterChange}
              inset={
                <Icon
                  name="search"
                  color={Colors.LightGray}
                  size={16}
                  solid
                  style={{ marginBottom: "12px" }}
                />
              }
            />
          </div>
        </HorizontalStack>
      )}
    </HorizontalStack>
  );
}
export default TabBar;
