import HorizontalStack, { AlignOptions } from "../Layout/HorizontalStack";
import { displayNameForCarrier } from "../../Data/Carriers";
import * as React from "react";

interface CarrierLogoProps {
  carrierIdentifier: string;
  width: number;
  height: number;
  align?: AlignOptions;
}

const CarrierLogo: React.FC<CarrierLogoProps> = (props: CarrierLogoProps) => {
  const carrierIdentifier = props.carrierIdentifier;

  const displayName = displayNameForCarrier(carrierIdentifier);

  return (
    <HorizontalStack
      align={props.align || "center"}
      style={{ width: props.width, height: props.height }}
      verticalAlign="middle"
    >
      <img
        style={{
          maxHeight: props.height + "px",
          maxWidth: props.width + "px",
        }}
        alt={displayName}
        src={`/carrierLogos/${carrierIdentifier}.png`}
      />
    </HorizontalStack>
  );
};

export default CarrierLogo;
