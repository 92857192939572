import { useModal } from "../../../Cargo/Modal/useModal";

import AddBrokerModal, {
  AddBrokerModalCallbackData,
} from "../Modals/AddBrokerModal";

function useAddBrokerModal() {
  const showModal = useModal<AddBrokerModalCallbackData>();

  async function show(): Promise<AddBrokerModalCallbackData> {
    return new Promise<AddBrokerModalCallbackData>((resolve) => {
      showModal(
        (done) => {
          return (
            <AddBrokerModal
              onCancel={() => done()}
              onFinished={(params) => {
                done(params);
              }}
            />
          );
        },
        async (data) => {
          if (data !== undefined) {
            resolve(data);
          }
        },
        {
          preventBackdropClick: true,
        },
      );
    });
  }
  return show;
}

export default useAddBrokerModal;
