import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface DeliveryAddressPageProp {
  shipment: Shipment;
}

export function DeliveryAddressPage(_props: DeliveryAddressPageProp) {
  return <Heading1>Delivery Address</Heading1>;
}
