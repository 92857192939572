import {
  QuestionBubbleHeader,
  QuestionBubbleLabel,
  SmallQuestionBubble,
} from "../../../../../Cargo/Controls/QuestionBubble";

export function PackagingTypeQuestionBubble() {
  return (
    <SmallQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>Packaging Type</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            <div>
              A carrier requires to know how a dangerous item is packaged.
              Please enter the number of packages within each handling unit
            </div>
            <div>
              eg. If you are shipping a crate containing 50 bags, enter bag. If
              you are shipping a drum that is not further subdivided, enter drum
            </div>
          </QuestionBubbleLabel>
        </>
      }
    />
  );
}
