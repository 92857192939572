import Button from "../Controls/Button";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import Stack from "../Layout/Stack";
import { Heading2, Microcopy } from "../Text/Text";
import { ReactElement, ReactNode } from "react";
import { AlertModalOptions } from "./AlertModalOptions";

interface AlertModalProps {
  onDone: () => void;
  message: ReactNode;
  title: ReactNode;
  options?: AlertModalOptions;
}

export function AlertModal(props: AlertModalProps): ReactElement {
  return (
    <Stack align="center">
      <Heading2>{props.title}</Heading2>
      <Microcopy>{props.message}</Microcopy>
      <Spacer height={24} />
      <HorizontalStack>
        <Button
          id="doneButton"
          size="large"
          onClick={() => {
            props.onDone();
          }}
        >
          {props.options?.buttons?.primary || `Ok!`}
        </Button>
      </HorizontalStack>
    </Stack>
  );
}
