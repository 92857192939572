import Colors from "../../Cargo/Colors";
import Button from "../../Cargo/Controls/Button";
import { CurrencyDropdown } from "../../Cargo/Controls/CurrencyDropdown";
import { TextArea } from "../../Cargo/Controls/Textarea";
import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import PageTitle from "../../Cargo/Text/PageTitle";
import { ErrorMessage, Heading1, Microcopy } from "../../Cargo/Text/Text";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import ShipmentNotFound from "../BookShipment/Components/ShipmentNotFound";
import { useOnce } from "../../Hooks/useOnce";
import useQuery from "../../Hooks/useQuery";
import { useShipmentService } from "../../Services/ShipmentService";
import {
  OpenClaimLineItem,
  Currency,
  FreightClaimItemCondition,
  FreightClaimType,
  OpenFreightClaimRequest,
  Shipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { ChangeEvent, useState } from "react";

import Banner from "../../Cargo/Controls/Banner";
import { BannerStyle } from "../../Cargo/Controls/BannerStyle";
import { useReaction } from "../../Cargo/Hooks/useReaction";
import { ReactionType } from "../../Cargo/Hooks/ReactionType";
import { sumOf } from "../../Helpers/sumOf";
import { useFreightClaimsApi } from "../../apis";
import { useNavigate } from "react-router-dom";
import { ClaimSummary } from "./Components/ClaimSummary";
import { DamageClaimLineItemsSection } from "./Components/DamageClaimLineItemsSection";
import { SubmitClaimSection } from "./Components/SubmitClaimSection";
import { UploadClaimDocumentsSection } from "./Components/UploadClaimDocumentsSection";
import { ClaimDocuments } from "./Components/ClaimDocuments";
import axios from "axios";

function emptyClaimLineItems(shipment: Shipment): OpenClaimLineItem[] {
  return shipment.lineItems.map(function () {
    return {
      claimType: FreightClaimType.NoDamageOrLoss,
      condition: FreightClaimItemCondition.New,
    };
  });
}

export function SubmitClaimScreen() {
  const query = useQuery();
  const shipmentId = query.shipmentId as string;

  const shipmentsService = useShipmentService();
  const freightClaimsApi = useFreightClaimsApi();

  const [shipment, setShipment] = useState<Shipment | undefined>();
  const [shipmentNotFound, setShipmentNotFound] = useState(false);

  const [forceValidation, setForceValidation] = useState(false);

  const [originalInvoiceFiles, setOriginalInvoiceFiles] = useState<Array<File>>(
    [],
  );
  const [photosFiles, setPhotosFiles] = useState<Array<File>>([]);
  const [otherDocumentsFiles, setOtherDocumentsFiles] = useState<Array<File>>(
    [],
  );
  const [currency, setCurrency] = useState<Currency>(Currency.Cad);
  const [claimLineItems, setClaimLineItems] = useState<OpenClaimLineItem[]>([]);
  const [otherNotes, setOtherNotes] = useState("");
  const [description, setDescription] = useState("");
  const [openClaimErrorMessage, setOpenClaimErrorMessage] = useState<
    string | undefined
  >();
  const [isUploading, setIsUploading] = useState(false);
  const { showReaction } = useReaction();
  const navigate = useNavigate();

  async function loadShipment() {
    try {
      const response = await shipmentsService.getShipment(shipmentId);

      setShipment(response);
      setClaimLineItems(emptyClaimLineItems(response));
    } catch (e) {
      if (e instanceof Response && e.status === 404) {
        setShipmentNotFound(true);
      }
      console.warn({ e });
    }
  }

  function isClaimLineItemValid(claimLineItem: OpenClaimLineItem): boolean {
    if (claimLineItem.claimType === FreightClaimType.NoDamageOrLoss) {
      return true;
    }

    if (
      claimLineItem.amountPerPiece === undefined ||
      claimLineItem.amountPerPiece < 0.01
    ) {
      return false;
    }

    if (
      claimLineItem.affectedPieces === undefined ||
      claimLineItem.affectedPieces === 0
    ) {
      return false;
    }

    if (
      claimLineItem.totalPieces === undefined ||
      claimLineItem.totalPieces === 0
    ) {
      return false;
    }

    if (claimLineItem.affectedPieces > claimLineItem.totalPieces) {
      return false;
    }

    return true;
  }

  function isValid(): boolean {
    if (originalInvoiceFiles.length === 0) {
      return false;
    }

    if (
      claimLineItems.every(
        (o) => o.claimType === FreightClaimType.NoDamageOrLoss,
      )
    ) {
      return false;
    }

    if (claimLineItems.some((o) => !isClaimLineItemValid(o))) {
      return false;
    }

    if (description.trim() === "") {
      return false;
    }
    return true;
  }

  const generalErrorMessage = !isValid()
    ? "Please correct the above errors"
    : undefined;

  const descriptionErrorMessage = description.trim() === "" ? "Required" : "";

  const originalInvoiceErrorMessage =
    originalInvoiceFiles.length === 0 ? "Required" : undefined;

  async function onSubmitClaim() {
    setIsUploading(true);
    setForceValidation(true);
    setOpenClaimErrorMessage(undefined);

    if (!isValid()) {
      setIsUploading(false);
      return;
    }

    try {
      const request: OpenFreightClaimRequest = {
        shipmentId,
        description,
        otherNotes,
        claimLineItems,
        currency,
      };

      await freightClaimsApi.upload(
        request,
        originalInvoiceFiles,
        photosFiles,
        otherDocumentsFiles,
      );

      showReaction(ReactionType.Success);
      navigate(`/view-shipment?shipmentId=${shipmentId}`);
    } catch (e) {
      if (!axios.isAxiosError(e)) {
        setOpenClaimErrorMessage("Sorry. Something went wrong");
        return;
      }
      if (e.response?.status === 413) {
        setOpenClaimErrorMessage(
          "One or more files you are trying to upload are too large. Please try uploading fewer or smaller files, or contact support for assistance. ",
        );
      }
    }
    setIsUploading(false);
  }

  useOnce(async () => {
    loadShipment();
  });

  if (!shipment || claimLineItems.length === 0) {
    return <LoadingShipment />;
  }

  if (shipmentNotFound) {
    return <ShipmentNotFound />;
  }

  function calculateTotalClaim() {
    if (!isValid()) {
      return undefined;
    }

    return sumOf(claimLineItems, (o) =>
      o.amountPerPiece === undefined || o.affectedPieces === undefined
        ? 0
        : o.amountPerPiece * o.affectedPieces,
    );
  }

  const totalClaim = calculateTotalClaim();

  return (
    <>
      <PageTitle>Submit Claim</PageTitle>
      <HorizontalStack verticalAlign="top">
        <Stack
          align="left"
          width="100%"
          style={{ height: "100%", paddingTop: "48px" }}
        >
          <HorizontalStack
            align="spread"
            style={{
              borderBottom: `1px solid ${Colors.VeryVeryLightBlue}`,
              paddingBottom: "8px",
            }}
            verticalAlign="top"
          >
            <Stack align="left">
              <Heading1 style={{ marginBottom: "2px" }}>Submit Claim</Heading1>
              <Microcopy>
                We're sorry to hear there were problems. We're here to make
                getting compensation for your loss easy and efficient.
              </Microcopy>
            </Stack>
            <Spacer width={64} />
            <ClaimSummary shipment={shipment} />
          </HorizontalStack>
          <Spacer height={32} />
          <Banner
            bannerStyle={BannerStyle.Info}
            title="Notes on submitting a claim"
            message={
              <ul
                style={{
                  paddingInlineStart: "16px",
                  marginBottom: "4px",
                  marginTop: "8px",
                }}
              >
                <li>
                  An inspection may be required. Please do not dispose of the
                  freight to allow for an inspection to take place.
                </li>
                <li>
                  Please enter detailed and correct information. This will help
                  ensure the claim is settled quickly.
                </li>
              </ul>
            }
          />
          <Spacer height={64} />
          <>
            <SubmitClaimSection
              index="1"
              title="Claim Currency"
              description="What currency are they goods valued in?"
              content={
                <>
                  <CurrencyDropdown
                    currency={currency}
                    onCurrencyChange={setCurrency}
                    enabled={true}
                    width={200}
                  />
                </>
              }
            />
            <DamageClaimLineItemsSection
              shipment={shipment}
              currency={currency}
              claimLineItems={claimLineItems}
              setClaimLineItems={setClaimLineItems}
              forceValidation={forceValidation}
            />

            <SubmitClaimSection
              index="4"
              title="Original Invoice"
              description="Vendor’s original invoice for the shipment showing the price of the claimed goods"
              content={
                <>
                  <UploadClaimDocumentsSection
                    id="original-invoices-file-input"
                    type={ClaimDocuments.OriginalInvoice}
                    files={originalInvoiceFiles}
                    setFiles={setOriginalInvoiceFiles}
                    errorMessage={originalInvoiceErrorMessage}
                    forceValidation={forceValidation}
                  />
                </>
              }
            />
            <SubmitClaimSection
              index="3"
              title="Photos"
              description="If you have any photos of the freight or the damage, please upload them here. You can upload a maximum of 10 images."
              content={
                <>
                  <UploadClaimDocumentsSection
                    id="photos-file-input"
                    type={ClaimDocuments.Photos}
                    files={photosFiles}
                    setFiles={setPhotosFiles}
                    errorMessage={undefined}
                    forceValidation={forceValidation}
                  />
                </>
              }
            />
            <SubmitClaimSection
              index="4"
              title="Other Documents"
              description="Please upload any useful documents. Examples include an inspection report, a repair bill, "
              content={
                <>
                  <UploadClaimDocumentsSection
                    id="other-documents-file-input"
                    type={ClaimDocuments.Documents}
                    files={otherDocumentsFiles}
                    setFiles={setOtherDocumentsFiles}
                    errorMessage={undefined}
                    forceValidation={forceValidation}
                  />
                </>
              }
            />
            <SubmitClaimSection
              index="5"
              title="Description"
              description="Please tell us any relevant information that we should pass along to the carrier"
              content={
                <>
                  <TextArea
                    id="freight-claim-description-input"
                    rows={4}
                    style={{ width: "520px" }}
                    value={description}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setDescription(e.currentTarget.value);
                    }}
                    name="description"
                    autoComplete="off"
                  />
                  {forceValidation && descriptionErrorMessage && (
                    <ErrorMessage style={{ marginTop: "8px" }}>
                      {descriptionErrorMessage}
                    </ErrorMessage>
                  )}
                </>
              }
            />
            <SubmitClaimSection
              index="6"
              title="Other"
              description="If there is anything else we should know to assist making the claim. This information will NOT be shared with the carrier"
              content={
                <>
                  <TextArea
                    id="freight-claim-other-notes-input"
                    rows={4}
                    style={{ width: "520px" }}
                    value={otherNotes}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setOtherNotes(e.currentTarget.value);
                    }}
                    name="otherNotes"
                    autoComplete="off"
                  />
                </>
              }
            />
            {totalClaim && (
              <div style={{ fontSize: "20px" }}>
                Total Claim ${totalClaim.toLocaleString()} {currency}
              </div>
            )}
            <Button
              id="submit-claim-button"
              onClick={onSubmitClaim}
              size="xl"
              disabled={isUploading}
              loading={isUploading}
              icon="arrow-right"
            >
              Submit Claim
            </Button>
            <Spacer height={16} />
            {forceValidation && generalErrorMessage && (
              <ErrorMessage>{generalErrorMessage}</ErrorMessage>
            )}
            {forceValidation && openClaimErrorMessage && (
              <ErrorMessage>{openClaimErrorMessage}</ErrorMessage>
            )}
          </>
        </Stack>
      </HorizontalStack>
    </>
  );
}
