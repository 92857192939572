import { AddLineItemType } from "../../LineItems/Types/lineItemTypes";
import { AddLocationLocationType } from "../../Locations/Types/locationTypes";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import {
  HandlingUnitType,
  LocationType,
} from "@freightsimple/generated-dashboard-openapi-client";

interface UseBookShipmentWarningMessagesProps {
  pickupDate: string;
  lineItems: AddLineItemType[];
  pickupLocation: AddLocationLocationType;
  deliveryLocation: AddLocationLocationType;
}

export function useBookShipmentWarningMessages({
  pickupDate,
  lineItems,
  pickupLocation,
  deliveryLocation,
}: UseBookShipmentWarningMessagesProps) {
  const [warnings, setWarnings] = useState<ReactNode[]>([]);

  function WarningMessage({ lines }: { lines: string[] }) {
    return (
      <>
        {lines.map((line, i) => (
          <p style={{ padding: 0, margin: 0 }} key={i}>
            {line}
          </p>
        ))}
      </>
    );
  }

  useEffect(() => {
    const momentPickupDate = moment(pickupDate);

    const newWarnings: ReactNode[] = [];

    if (!momentPickupDate.weekday) {
      newWarnings.push(
        <WarningMessage
          lines={[`This is a weekend pickup. Did you mean a different date?`]}
        />,
      );
    }

    const suspiciouslySmallPallets = lineItems.some(function (lineItem) {
      return (
        lineItem.handlingUnitType === HandlingUnitType.Pallet &&
        lineItem.width &&
        lineItem.width < 35 &&
        lineItem.height &&
        lineItem.height < 35
      );
    });

    if (suspiciouslySmallPallets) {
      newWarnings.push(
        <WarningMessage
          lines={[
            `One of your pallets seems much smaller than standard size. Make sure to include the pallet in your measurements.`,
          ]}
        />,
      );
    }

    if (moment(pickupDate).subtract(4, "days").isAfter(moment())) {
      newWarnings.push(
        <WarningMessage
          lines={[
            `For some lanes, the best rates are only available if pickup is within 3-4 days from today`,
          ]}
        />,
      );
    }

    if (
      pickupLocation.locationType === LocationType.Residential &&
      !pickupLocation.accessorials.includes("LOGISTICS_LIFT_GATE_REQUIRED")
    ) {
      newWarnings.push(
        <WarningMessage
          lines={[
            `Most residential pickups require a lift gate. Please double-check you do not need one.`,
          ]}
        />,
      );
    }

    if (
      deliveryLocation.locationType === LocationType.Residential &&
      !deliveryLocation.accessorials.includes("LOGISTICS_LIFT_GATE_REQUIRED")
    ) {
      newWarnings.push(
        <WarningMessage
          lines={[
            `Most residential deliveries require a lift gate. Please double-check you do not need one.`,
          ]}
        />,
      );
    }

    if (
      deliveryLocation.locationType === LocationType.Residential &&
      !deliveryLocation.accessorials.includes("SCHEDULING_APPOINTMENT_REQUIRED")
    ) {
      newWarnings.push(
        <WarningMessage
          lines={[
            `Many residential deliveries require an appointment. Please double-check you do not need one.`,
          ]}
        />,
      );
    }

    const pickupRequiresLiftGate = pickupLocation.accessorials.includes(
      "LOGISTICS_LIFT_GATE_REQUIRED",
    );
    const deliveryRequiresLiftGate = deliveryLocation.accessorials.includes(
      "LOGISTICS_LIFT_GATE_REQUIRED",
    );
    const requiresLiftGate = pickupRequiresLiftGate || deliveryRequiresLiftGate;

    const possiblyTooBigForLiftgate = lineItems.some(function (lineItem) {
      if (lineItem.length && lineItem.length > 90) {
        return true;
      }

      if (lineItem.width && lineItem.width > 90) {
        return true;
      }

      if (lineItem.height && lineItem.height > 90) {
        return true;
      }

      if (
        lineItem.weightPerHandlingUnit &&
        lineItem.weightPerHandlingUnit > 2500
      ) {
        return true;
      }

      return false;
    });

    if (requiresLiftGate && possiblyTooBigForLiftgate) {
      newWarnings.push(
        <WarningMessage
          lines={[
            "Freight may be too large for some carrier's lift gates to handle",
          ]}
        />,
      );
    }

    function checkLotsOfBoxes(lineItem: AddLineItemType): boolean {
      return (
        lineItem.handlingUnitType === HandlingUnitType.Box &&
        lineItem.numberHandlingUnits > 5
      );
    }

    if (lineItems.some((li) => checkLotsOfBoxes(li))) {
      newWarnings.push(
        <WarningMessage
          lines={[
            "This seems like a lot of boxes. They should probably be assembled onto a pallet for proper shipping",
          ]}
        />,
      );
    }

    setWarnings(newWarnings);
  }, [pickupDate, lineItems, pickupLocation, deliveryLocation]);

  return { warnings, setWarnings };
}
