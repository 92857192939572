import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Stack from "../../../Cargo/Layout/Stack";

import { useNavigate } from "react-router-dom";

interface BookShipmentNavigationFooter2Props {
  next?: string;
  onPrevious?: () => Promise<void>;
  nextUrl?: string;
  nextOnClick?: () => void;
  nextIsValid: () => Promise<boolean> | boolean;
  nextIsEnabled: () => boolean;
  nextIdentifier: string;
  hidePreviousButton?: boolean;
}

const BookShipmentNavigationFooter2: React.FC<
  BookShipmentNavigationFooter2Props
> = (props: BookShipmentNavigationFooter2Props) => {
  const navigate = useNavigate();

  function shouldShowNextButton() {
    return (props.nextUrl || props.nextOnClick) && props.next;
  }

  function shouldShowPreviousButton() {
    return !props.hidePreviousButton;
  }

  async function onNext() {
    if (!props.nextIsEnabled()) {
      return;
    }

    const isValid = await props.nextIsValid();

    if (!isValid) {
      return false;
    }

    if (props.nextOnClick) {
      props.nextOnClick();
    } else if (props.nextUrl) {
      navigate(props.nextUrl);
    }

    return undefined;
  }

  return (
    <div style={{ width: "100%", maxWidth: "608px" }}>
      <Stack align="left">
        <HorizontalStack
          width="100%"
          style={{ maxWidth: "680px" }}
          align="spread"
        >
          {shouldShowPreviousButton() && (
            <Button
              label="Previous"
              arrow="left"
              secondary
              onClick={props.onPrevious}
            />
          )}
          {!shouldShowPreviousButton() && <div></div>}

          {shouldShowNextButton() && (
            <Button
              id="nextButton"
              label={props.next || ""}
              arrow="right"
              onClick={onNext}
              disabled={!props.nextIsEnabled()}
            />
          )}
        </HorizontalStack>
      </Stack>
    </div>
  );
};
export default BookShipmentNavigationFooter2;
