import moment from "moment";

export interface TrackingLine {
  time: moment.Moment;
  message: string;
}

interface TrackingDayLine {
  timeDescription: string;
  message: string;
}

interface TrackingDay {
  dateDescription: string;
  lines: Array<TrackingDayLine>;
}

export function groupLinesIntoDates(
  input: Array<TrackingLine>,
): Array<TrackingDay> {
  const groups = new Map<string, Array<TrackingDayLine>>();

  input.forEach((line) => {
    // Monday, 30th July 2020
    const dateDescription = line.time.format("dddd, Do MMMM YYYY");

    if (!groups.get(dateDescription)) {
      groups.set(dateDescription, []);
    }

    groups.get(dateDescription)?.push({
      timeDescription: line.time.format("h:mma z"),
      message: line.message,
    });
  });

  const output: Array<TrackingDay> = [];

  groups.forEach((value, key) => {
    output.push({
      dateDescription: key,
      lines: value,
    });
  });

  return output;
}
