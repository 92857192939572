import LinkButton from "../../../Cargo/Controls/LinkButton";
import { Legalese } from "../../../Cargo/Text/Text";

function AddCreditCardLegalease() {
  return (
    <>
      <Legalese>
        Your credit card will not be billed until the shipment is finalized. If
        the shipment differs from the quote, this may result in additional
        charges.{" "}
        <LinkButton regularWeight={true} to="todo" newTab>
          Learn More
        </LinkButton>
      </Legalese>
    </>
  );
}
export default AddCreditCardLegalease;
