import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface PickupAddressPageProp {
  shipment: Shipment;
}

export function PickupAddressPage(_props: PickupAddressPageProp) {
  return <Heading1>Pickup Address</Heading1>;
}
