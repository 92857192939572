import { useState } from "react";
import styled, { keyframes } from "styled-components";

interface ParameterizedSwitchProps {
  on: boolean;
}

const circleOn = keyframes`
0% {
  fill:#B9B9B9;
  cx: 22.5;
}

100%{
  fill:#4C62EA;
  cx: 45;
}
`;

const circleOff = keyframes`
0%{
  fill:#4C62EA;
  cx: 45;
}

100% {
    fill:#B9B9B9;
    cx: 22.5;
  }
`;

const rectOn = keyframes`
0% {
  fill:#ECECEC;
  stroke:#E1E1E1;
}

100%{
  fill:#C5CAEC;
  stroke:#9AA5E8;
}
`;

const rectOff = keyframes`
0%{
  fill:#C5CAEC;
  stroke:#9AA5E8;
}

100% {
    fill:#ECECEC;
    stroke:#E1E1E1;
  }
`;

interface StyledCircleProps {
  on: boolean;
}

const StyledCircle = styled.circle<StyledCircleProps>`
  animation: ${(props) => (props.on ? circleOn : circleOff)} 1 0.3s ease-in-out;
`;

const StyledRect = styled.rect<StyledCircleProps>`
  animation: ${(props) => (props.on ? rectOn : rectOff)} 1 0.5s ease-in-out;
`;

function ParameterizedSwitch(props: ParameterizedSwitchProps) {
  const [hovering, setHovering] = useState(false);

  function onEnter() {
    setHovering(true);
  }

  function onLeave() {
    setHovering(false);
  }
  const positionX = props.on ? 55 : 22.5;
  const fillColor = props.on ? "#4C62EA" : "#B9B9B9";

  const backgroundFillColour = props.on ? "#C5CAEC" : "#ECECEC";
  const backgroundBorderColour = props.on ? "#9AA5E8" : "#E1E1E1";

  const radius = hovering ? "20" : "18.5";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="33"
      viewBox="0 0 76 33"
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(0 -68)">
          <StyledRect
            on={props.on}
            width="51"
            height="28"
            x="14.5"
            y="70.5"
            fill={backgroundFillColour}
            stroke={backgroundBorderColour}
            strokeWidth="5"
            rx="16"
          ></StyledRect>
          <StyledCircle
            on={props.on}
            cx={positionX}
            cy="84.5"
            r={radius}
            fill={fillColor}
          ></StyledCircle>
        </g>
      </g>
    </svg>
  );
}

interface SwitchProps {
  on: boolean;
  onChange: (newValue: boolean) => void;
}

export function LargeSwitch(props: SwitchProps) {
  function onClick() {
    props.onChange(!props.on);
  }

  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <ParameterizedSwitch on={props.on} />
    </div>
  );
}
