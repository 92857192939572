import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Simple component to make sure we always scroll to the top when
// route changes. Specifically this was for the jump between Pickup/Delivery Address
// screens in Get Started, but should apply anywhre
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <></>;
}
export default ScrollToTop;
