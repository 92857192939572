import { LineItem } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../../Cargo/Modal/useModal";
import { SelectUNNumberModal } from "./useDangerousGoodsDetailsModal";

export function useDangerousGoodsDetailsModal() {
  const showModal = useModal<Partial<LineItem>>();

  function show(lineItem: Partial<LineItem>) {
    const p = new Promise<Partial<LineItem> | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <SelectUNNumberModal
              lineItem={lineItem}
              onDone={done}
              onCancel={() => done()}
            ></SelectUNNumberModal>
          );
        },
        (data) => {
          resolve(data);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
