import Button from "../../Cargo/Controls/Button";
import { Centered, CenteredContainer } from "../../Cargo/Layout/Container";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { Heading1, Microcopy } from "../../Cargo/Text/Text";
import { UUID } from "../../Cargo/Types/types";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import DashboardRow from "../Dashboard/Components/DashboardRow";
import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../Hooks/useOnce";
import useQuery from "../../Hooks/useQuery";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShipmentService } from "../../Services/ShipmentService";
import styled from "styled-components";
import { FilterBar } from "./Components/FilterBar";
import { FilterShipmentsDescription } from "./Modals/FilterShipmentsModal";
import { renderFilterShipmentsDescriptionAsQueryParams } from "./Modals/renderFilterShipmentsDescriptionAsQueryParams";
import { filterDescriptionFromQueryParams } from "./Modals/filterDescriptionFromQueryParams";
import { emptyFilterShipmentsDescription } from "./Modals/emptyFilterShipmentsDescription";
import { useFilterShipmentsModal } from "./Modals/useFilterShipmentsModal";
import { assertNever } from "../../Helpers/assertNever";
import {
  SortShipmentOptions,
  useSortShipmentsModal,
} from "./Modals/useSortShipmentsModal";

export const Subtitle = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  margin: 0 67px 9px 67px;
  color: var(--freightsimple-color-normal-text);
`;

function generateLastColumnOverrideLabel(
  sort: SortShipmentOptions,
): string | undefined {
  if (sort === SortShipmentOptions.BookingDate) {
    return "Booking Date";
  }

  if (sort === SortShipmentOptions.DeliveryDate) {
    return "Delivery Date";
  }

  if (sort === SortShipmentOptions.EstimatedDeliveryDate) {
    return "Estimated Delivery Date";
  }

  if (sort === SortShipmentOptions.PickupDate) {
    return "Pickup Date";
  }

  return undefined;
}

function generateLastColumnOverrideDescription(
  shipment: Shipment,
  sort: SortShipmentOptions,
): string | undefined {
  function handleDate(dateString: string | undefined): string {
    if (dateString === undefined) {
      return "Unknown";
    }

    return moment(dateString).format("Do MMMM YYYY");
  }

  if (sort === SortShipmentOptions.BookingDate) {
    return handleDate(shipment.bookedAt);
  }

  if (sort === SortShipmentOptions.DeliveryDate) {
    return handleDate(shipment.actualDeliveryDate);
  }

  if (sort === SortShipmentOptions.EstimatedDeliveryDate) {
    return handleDate(shipment.expectedDeliveryDate);
  }

  if (sort === SortShipmentOptions.PickupDate) {
    return handleDate(shipment.pickupDate);
  }

  return undefined;
}

interface DashboardRowForShipmentProps {
  shipment: Shipment;
  onClick: () => void;
  sort: SortShipmentOptions;
}

function DashboardRowForShipment(props: DashboardRowForShipmentProps) {
  const { shipment, onClick, sort } = props;
  const quote = shipment.selectedQuote;

  return (
    <DashboardRow
      key={shipment.shipmentId}
      lineItems={shipment.lineItems}
      shipmentState={shipment.shipmentState}
      pickupLocation={shipment.pickupLocation}
      deliveryLocation={shipment.deliveryLocation}
      pickupDate={shipment.pickupDate}
      pickupHours={shipment.pickupLocation.hours}
      appointmentDate={shipment.appointmentDate}
      expectedDeliveryDate={shipment.expectedDeliveryDate}
      expectedDeliveryHours={shipment.expectedDeliveryHours}
      actualDeliveryDate={shipment.actualDeliveryDate}
      actualDeliveryTime={shipment.actualDeliveryTime}
      carrierIdentifier={quote?.carrierIdentifier}
      onClick={onClick}
      pickupReference={shipment.pickupReferenceNumber}
      deliveryReference={shipment.deliveryReferenceNumber}
      overrideLastColumnLabel={generateLastColumnOverrideLabel(sort)}
      overrideLastColumnDescription={generateLastColumnOverrideDescription(
        shipment,
        sort,
      )}
      manualQuotingOpen={false}
      freightClaims={shipment.hasFreightClaims}
    />
  );
}

export function GetQuotesForFirstShipmentButton() {
  const navigate = useNavigate();

  function onClick() {
    navigate("/book/details");
  }

  return (
    <Button size="large" onClick={onClick}>
      Get Instant Quotes
    </Button>
  );
}

interface ViewAllShipmentScreenBodyProps {
  onFilter: () => Promise<void>;
  onSort: () => Promise<void>;
  filter: FilterShipmentsDescription;
  sort: SortShipmentOptions;
  setFilter: (newFilter: FilterShipmentsDescription) => void;
  filteredShipments: Array<Shipment>;
  onClick: (shipmentId: UUID) => void;
}

function ViewAllShipmentScreenBody(props: ViewAllShipmentScreenBodyProps) {
  const {
    onFilter,
    onSort,
    filter,
    sort,
    setFilter,
    filteredShipments,
    onClick,
  } = props;

  return (
    <>
      <FilterBar
        onFilter={onFilter}
        onSort={onSort}
        filter={filter}
        sort={sort}
        setFilter={setFilter}
      />
      <Spacer height={16} />
      {filteredShipments.length === 0 && (
        <CenteredContainer>
          <Centered>No shipments match the applied filters</Centered>
        </CenteredContainer>
      )}
      {filteredShipments.map((s) => {
        return (
          <DashboardRowForShipment
            key={s.shipmentId}
            shipment={s}
            onClick={function () {
              onClick(s.shipmentId);
            }}
            sort={sort}
          />
        );
      })}
    </>
  );
}

function ViewAllShipmentsScreen() {
  const shipmentsService = useShipmentService();
  const [bookedShipments, setBookedShipments] = useState<Array<Shipment>>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filter, setFilter] = useState(emptyFilterShipmentsDescription());
  const [sort, setSort] = useState(SortShipmentOptions.BookingDate);

  async function loadShipments() {
    setLoading(true);
    const response = await shipmentsService.getShipments();

    setBookedShipments(response.booked);
    setLoading(false);
  }

  useOnce(async () => {
    await loadShipments();
  });

  const showFilterModal = useFilterShipmentsModal();
  const sortSortModal = useSortShipmentsModal(sort);
  const query = useQuery();

  useEffect(
    function () {
      const output = filterDescriptionFromQueryParams(query);
      setFilter(output.filter);
      setSort(output.sort);
    },

    [window.location.search],
  );

  const noShipments = bookedShipments.length === 0;

  if (loading) {
    return <LoadingShipment />;
  }

  function updateFilter(newFilter: FilterShipmentsDescription) {
    setFilter(newFilter);
    navigate(
      `/view-all-shipments?${renderFilterShipmentsDescriptionAsQueryParams(
        newFilter,
        sort,
      )}`,
    );
  }

  async function onFilter() {
    const filterDescription = await showFilterModal(filter);
    updateFilter(filterDescription);
  }

  async function onSort() {
    const selectedSortOption = await sortSortModal();
    if (selectedSortOption !== undefined) {
      setSort(selectedSortOption);
      navigate(
        `/view-all-shipments?${renderFilterShipmentsDescriptionAsQueryParams(
          filter,
          selectedSortOption,
        )}`,
      );
    }
  }

  function onClick(shipmentId: UUID) {
    const url = `/view-shipment?shipmentId=${shipmentId}`;
    navigate(url);
  }

  function compareString(
    stringA: string | undefined,
    stringB: string | undefined,
  ) {
    if (stringB === undefined) {
      return -1;
    }

    if (stringA === undefined) {
      return -1;
    }

    return stringA.localeCompare(stringB);
  }

  function compareDates(
    dateA: string | undefined,
    dateB: string | undefined,
  ): number {
    if (dateA === undefined && dateB === undefined) {
      return -1;
    }

    if (dateA === undefined && dateB !== undefined) {
      return -1;
    }

    if (dateA !== undefined && dateB === undefined) {
      return -1;
    }

    if (moment(dateA).isBefore(moment(dateB))) {
      return -1;
    } else {
      return 1;
    }
  }

  function doSort(shipmentA: Shipment, shipmentB: Shipment): number {
    switch (sort) {
      case SortShipmentOptions.PickupReferenceNumber:
        return compareString(
          shipmentA.pickupReferenceNumber,
          shipmentB.pickupReferenceNumber,
        );
      case SortShipmentOptions.DeliveryReferenceNumber:
        return compareString(
          shipmentA.deliveryReferenceNumber,
          shipmentB.deliveryReferenceNumber,
        );
      case SortShipmentOptions.PickupBusinessName:
        return compareString(
          shipmentA.pickupLocation.businessName,
          shipmentB.pickupLocation.businessName,
        );
      case SortShipmentOptions.DeliveryBusinessName:
        return compareString(
          shipmentA.deliveryLocation.businessName,
          shipmentB.deliveryLocation.businessName,
        );
      case SortShipmentOptions.BookingDate:
        return compareDates(shipmentB.bookedAt, shipmentA.bookedAt);
      case SortShipmentOptions.DeliveryDate:
        return compareDates(
          shipmentB.actualDeliveryDate,
          shipmentA.actualDeliveryDate,
        );
      case SortShipmentOptions.EstimatedDeliveryDate:
        return compareDates(
          shipmentB.expectedDeliveryDate,
          shipmentA.expectedDeliveryDate,
        );
      case SortShipmentOptions.PickupDate:
        return compareDates(shipmentB.pickupDate, shipmentA.pickupDate);
      default:
        assertNever(sort);
    }
  }

  function doFilter(shipment: Shipment) {
    if (filter.proNumber !== undefined) {
      if (filter.proNumber !== shipment.proNumber) {
        return false;
      }
    }

    if (filter.carrierIdentifier !== undefined) {
      if (
        filter.carrierIdentifier !== shipment.selectedQuote?.carrierIdentifier
      ) {
        return false;
      }
    }

    if (filter.minPriceDollars !== undefined) {
      if (shipment.selectedQuote?.price === undefined) {
        return false;
      }

      if (filter.minPriceDollars >= shipment.selectedQuote?.price) {
        return false;
      }
    }

    if (filter.maxPriceDollars !== undefined) {
      if (shipment.selectedQuote?.price === undefined) {
        return false;
      }

      if (filter.maxPriceDollars <= shipment.selectedQuote?.price) {
        return false;
      }
    }

    if (filter.shipmentState !== undefined && filter.shipmentState.length > 0) {
      if (filter.shipmentState !== shipment.shipmentState) {
        return false;
      }
    }

    if (filter.pickupReferenceNumber !== undefined) {
      if (filter.pickupReferenceNumber !== shipment.pickupReferenceNumber) {
        return false;
      }
    }

    if (filter.pickupBusinessName !== undefined) {
      if (filter.pickupBusinessName !== shipment.pickupLocation.businessName) {
        return false;
      }
    }

    if (filter.pickupPostalCode !== undefined) {
      if (
        filter.pickupPostalCode !== shipment.pickupLocation.address.postalCode
      ) {
        return false;
      }
    }
    if (filter.pickupStateOrProvinceCode !== undefined) {
      if (
        filter.pickupStateOrProvinceCode !==
        shipment.pickupLocation.address.stateOrProvinceCode
      ) {
        return false;
      }
    }

    if (filter.deliveryReferenceNumber !== undefined) {
      if (filter.deliveryReferenceNumber !== shipment.deliveryReferenceNumber) {
        return false;
      }
    }

    if (filter.deliveryBusinessName !== undefined) {
      if (
        filter.deliveryBusinessName !== shipment.deliveryLocation.businessName
      ) {
        return false;
      }
    }

    if (filter.deliveryPostalCode !== undefined) {
      if (
        filter.deliveryPostalCode !==
        shipment.deliveryLocation.address.postalCode
      ) {
        return false;
      }
    }
    if (filter.deliveryStateOrProvinceCode !== undefined) {
      if (
        filter.deliveryStateOrProvinceCode !==
        shipment.deliveryLocation.address.stateOrProvinceCode
      ) {
        return false;
      }
    }

    if (filter.bookedAtStartRange !== undefined) {
      if (
        moment(filter.bookedAtStartRange).isSameOrAfter(
          moment(shipment.bookedAt),
        )
      ) {
        return false;
      }
    }

    if (filter.bookedAtEndRange !== undefined) {
      if (
        moment(filter.bookedAtEndRange).isSameOrBefore(
          moment(shipment.bookedAt),
        )
      ) {
        return false;
      }
    }

    if (filter.pickupDateStartRange !== undefined) {
      if (
        moment(filter.pickupDateStartRange).isSameOrAfter(
          moment(shipment.pickupDate),
        )
      ) {
        return false;
      }
    }

    if (filter.pickupDateEndRange !== undefined) {
      if (
        moment(filter.pickupDateEndRange).isSameOrBefore(
          moment(shipment.pickupDate),
        )
      ) {
        return false;
      }
    }

    if (filter.deliveredAtStartRange !== undefined) {
      if (shipment.shipmentState !== ShipmentState.Delivered) {
        return false;
      }

      if (
        moment(filter.deliveredAtStartRange).isSameOrAfter(
          moment(shipment.actualDeliveryDate),
        )
      ) {
        return false;
      }
    }

    if (filter.deliveredAtEndRange !== undefined) {
      if (shipment.shipmentState !== ShipmentState.Delivered) {
        return false;
      }

      if (
        moment(filter.deliveredAtEndRange).isSameOrBefore(
          moment(shipment.actualDeliveryDate),
        )
      ) {
        return false;
      }
    }

    if (filter.onlyShowFreightClaims !== undefined) {
      return shipment.hasFreightClaims === true;
    }

    return true;
  }

  function filterShipments() {
    return bookedShipments.filter(doFilter).sort(doSort);
  }

  const filteredShipments = filterShipments();

  return (
    <FullWidthLayout
      header="View All Shipments"
      microcopy={
        noShipments
          ? ""
          : "Access all of your shipments here. Select a filter to dive deeper into your data"
      }
      loading={loading}
      showEmptyState={noShipments}
      emptyState={
        <Stack>
          <Heading1>You don&apos;t have any shipments yet</Heading1>
          <Microcopy>
            Once you start running quotes and booking shipments, they will be
            displayed here
          </Microcopy>
          <Spacer height={32} />
          <GetQuotesForFirstShipmentButton />
        </Stack>
      }
      content={
        <ViewAllShipmentScreenBody
          onSort={onSort}
          onFilter={onFilter}
          filter={filter}
          sort={sort}
          onClick={onClick}
          setFilter={updateFilter}
          filteredShipments={filteredShipments}
        />
      }
    />
  );
}

export default ViewAllShipmentsScreen;
