import { useModal } from "../../../Cargo/Modal/useModal";
import { SortShipmentsModal } from "./SortShipmentsModal";

export enum SortShipmentOptions {
  PickupReferenceNumber = "PickupReferenceNumber",
  DeliveryReferenceNumber = "DeliveryReferenceNumber",
  BookingDate = "BookingDate",
  PickupDate = "PickupDate",
  EstimatedDeliveryDate = "EstimatedDeliveryDate",
  DeliveryDate = "DeliveryDate",
  PickupBusinessName = "PickupBusinessName",
  DeliveryBusinessName = "DeliveryBusinessName",
}

export function useSortShipmentsModal(currentSortOption: SortShipmentOptions) {
  const showModal = useModal<SortShipmentOptions>();
  function show() {
    const p = new Promise<SortShipmentOptions | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <SortShipmentsModal
              currentSortOption={currentSortOption}
              onSelect={done}
              onCancel={done}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed);
        },
      );
    });

    return p;
  }

  return show;
}

export function descriptionForSortShipmentOption(
  option: SortShipmentOptions,
): string {
  switch (option) {
    case SortShipmentOptions.PickupReferenceNumber:
      return "Pickup Reference Number";
    case SortShipmentOptions.DeliveryReferenceNumber:
      return "Delivery Reference Number";
    case SortShipmentOptions.BookingDate:
      return "Booking Date";
    case SortShipmentOptions.PickupDate:
      return "Pickup Date";
    case SortShipmentOptions.EstimatedDeliveryDate:
      return "Estimated Delivery Date";
    case SortShipmentOptions.DeliveryDate:
      return "Delivery Date";
    case SortShipmentOptions.PickupBusinessName:
      return "Pickup Business Name";
    case SortShipmentOptions.DeliveryBusinessName:
      return "Delivery Business Name";
  }
}
