import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faCalendar,
  faCamera,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faCircle,
  faClipboard,
  faClock,
  faCreditCard,
  faDownload,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faFile,
  faFileImage,
  faFilePdf,
  faFilter,
  faGlobeAmericas,
  faIndustry,
  faInfoCircle,
  faLock,
  faMagnifyingGlass,
  faMap,
  faMinusCircle,
  faMoneyCheck,
  faPerson,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPrint,
  faQuestionCircle,
  faSearch,
  faSearchPlus,
  faSort,
  faStar,
  faTimes,
  faTimesCircle,
  faTrash,
  faTruck,
  faTruckLoading,
  faUser,
  faWarehouse,
  faWindowClose,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowLeft,
  faArrowRight,
  faCheckSquare,
  faCheckCircle,
  faMap,
  faCalendar,
  faPlusSquare,
  faCircle,
  faPlus,
  faTruck,
  faClock,
  faCamera,
  faCreditCard,
  faExclamationTriangle,
  faPlusCircle,
  faMinusCircle,
  faChevronDown,
  faArrowCircleRight,
  faArrowCircleLeft,
  faCheck,
  faTimesCircle,
  faFilePdf,
  faTimes,
  faTrash,
  faQuestionCircle,
  faLock,
  faIndustry,
  faTruckLoading,
  faFile,
  faFileImage,
  faSearchPlus,
  faPrint,
  faWindowClose,
  faEllipsisV,
  faInfoCircle,
  faSearch,
  faStar,
  faBuilding,
  faGlobeAmericas,
  faExclamationCircle,
  faFilter,
  faSort,
  faWarehouse,
  faUser,
  faMagnifyingGlass,
  faMoneyCheck,
  faClipboard,
  faDownload,
  faPrint,
  faPerson,
  faArrowsRotate,
);
