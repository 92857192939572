import { Quote } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { DeleteQuotedShipmentModal } from "./DeleteQuotedShipmentModal";

export function useDeleteQuotedShipmentModal() {
  const showModal = useModal<string>();
  function show(cheapestQuote: Quote | undefined) {
    const p = new Promise<string | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <DeleteQuotedShipmentModal
              onConfirm={done}
              onCancel={function () {
                done();
              }}
              cheapestQuote={cheapestQuote}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
