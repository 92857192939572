import moment from "moment";
import {
  describeDateRelative,
  describeDateRelativeWithYear,
} from "./describeDateRelative";

export function describeDateRelativeWithTime(
  date: string | undefined,
  timeDescription: string | undefined,
  unknownIfInThePast: boolean,
) {
  if (date === undefined) {
    return undefined;
  }

  const m = moment(date);
  const now = moment().startOf("day");

  // If the expect
  if (unknownIfInThePast && m.isBefore(now)) {
    return undefined;
  }

  if (m.isoWeek() === now.isoWeek() && timeDescription !== undefined) {
    return describeDateRelative(date) + ", " + timeDescription;
  } else {
    return describeDateRelative(date);
  }
}

export function describeDateRelativeWithYearWithTime(
  date: string | undefined,
  timeDescription: string | undefined,
  unknownIfInThePast: boolean,
) {
  if (date === undefined) {
    return undefined;
  }

  const m = moment(date);
  const now = moment().startOf("day");

  // If the expect
  if (unknownIfInThePast && m.isBefore(now)) {
    return undefined;
  }

  if (m.isoWeek() === now.isoWeek() && timeDescription !== undefined) {
    return describeDateRelativeWithYear(date) + ", " + timeDescription;
  } else {
    return describeDateRelativeWithYear(date);
  }
}
