import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import Stack from "../../../../Cargo/Layout/Stack";
import DeliveryColumn from "../../../Quotes/Components/QuoteRow/DeliveryColumn";
import PickupColumn from "../../../Quotes/Components/QuoteRow/PickupColumn";
import Progress from "../../../Quotes/Components/QuoteRow/Progress";
import {
  LineItem,
  PreQuoteLocation,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { EquipmentType } from "@freightsimple/generated-dashboard-openapi-client";
import { isDomesticCanada } from "../../../../Helpers/isDomesticCanada";
import moment from "moment";

interface TwoColumnShipmentViewProps {
  pickupDeadline: string | undefined;
  lineItems: LineItem[];
  pickupDate: string;
  pickupLocation: PreQuoteLocation;
  deliveryLocation: PreQuoteLocation;
  deliveryDeadline: string | undefined;
  equipmentType: EquipmentType | undefined;
  exclusiveUse: boolean;
  tarpRequired: boolean;
  linearFeet: number | undefined;
}

export function TwoColumnShipmentView(props: TwoColumnShipmentViewProps) {
  const {
    lineItems,
    pickupDate,
    pickupLocation,
    deliveryLocation,
    deliveryDeadline,
    equipmentType,
    exclusiveUse,
    tarpRequired,
    linearFeet,
    pickupDeadline,
  } = props;

  return (
    <Stack style={{ width: "600px" }}>
      <Progress shipmentState={ShipmentState.Quoted} twoColumns />
      <Spacer height={16}></Spacer>
      <HorizontalStack width="100%" verticalAlign="top">
        <PickupColumn
          pickupDeadline={pickupDeadline}
          lineItems={lineItems}
          pickupDate={moment(pickupDate)}
          pickupLocation={pickupLocation}
          pickupContact={undefined}
          quote={undefined}
          shipmentState={ShipmentState.Quoted}
          equipmentType={equipmentType}
          exclusiveUseNeeded={exclusiveUse}
          tarpRequired={tarpRequired}
          linearFeet={linearFeet}
          isDomesticCanada={isDomesticCanada(pickupLocation, deliveryLocation)}
          showNotesSection={false}
          twoColumn
          hidePickupHours
        />
        <DeliveryColumn
          quote={undefined}
          deliveryHours={undefined}
          deliveryLocation={deliveryLocation}
          deliveryContact={undefined}
          shipmentState={ShipmentState.Quoted}
          actualDeliveryDate={undefined}
          actualDeliveryTime={undefined}
          deliveryDeadline={deliveryDeadline}
          expectedDeliveryDate={undefined}
          latestExpectedDeliveryDate={undefined}
          showNotesSection={false}
          twoColumn
          hideExpectedDelivery
        />
      </HorizontalStack>
    </Stack>
  );
}
