import { useCustomsDocsApi } from "../../../apis";
import Colors from "../../../Cargo/Colors";
import Banner from "../../../Cargo/Controls/Banner";
import { BannerStyle } from "../../../Cargo/Controls/BannerStyle";
import Button from "../../../Cargo/Controls/Button";
import Link from "../../../Cargo/Controls/Link";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import {
  Description,
  Heading1,
  Heading3,
  Legalese,
} from "../../../Cargo/Text/Text";
import { DocumentType } from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../../Hooks/useOnce";
import { useState } from "react";
import { useShipmentService } from "../../../Services/ShipmentService";
import { useCustomsDocsDropzone } from "../Hooks/useCustomsDocsDropzone";
import { CustomsDocsDropzone } from "./CustomsDocsDropzone";
import { UpdateCustomsDocsList } from "./UpdateCustomsDocsList";
import { UpdateCustomsDocsStageProps } from "./UpdateCustomsDocsModal";
import { UpdateCustomsDocsStage } from "./UpdateCustomsDocsStage";
import { Document } from "@freightsimple/generated-dashboard-openapi-client";
import { CustomsDocsUploadMode } from "../../../Apis/CustomsDocsApi";

export function UpdateCustomsDocsSubmitStage({
  shipmentId,
  onDone,
  setStage,
  mode,
}: UpdateCustomsDocsStageProps) {
  const customsDocsApi = useCustomsDocsApi();
  const [loading, setLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [loadingFailure, setLoadingFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { files, setFiles, dropzoneState } = useCustomsDocsDropzone();

  const [documents, setDocuments] = useState<Document[]>([]);

  const { getDocuments, downloadDocument } = useShipmentService();

  async function refresh() {
    try {
      const response = await getDocuments(shipmentId);
      const customsDocs = response.filter(
        (d) => d.documentType === DocumentType.CustomsDocument,
      );
      setDocuments(customsDocs);
    } catch (e) {
      console.error(e);
    }
  }

  useOnce(() => {
    refresh();
  });

  async function onUpload() {
    if (mode === undefined) {
      setErrorMessage("Something went wrong! Pleasy try later.");
      return;
    }
    setLoadingSuccess(false);
    setLoadingFailure(false);
    setLoading(true);
    try {
      await customsDocsApi.upload(shipmentId, mode, files);
      setLoadingSuccess(true);
      setTimeout(function () {}, 1000);
    } catch (e) {
      console.error(`Failure uploading customs docs`, { e });
      setLoadingFailure(true);
    } finally {
      setLoading(false);
      onDone();
    }
  }

  function acceptedFilesCount() {
    if (files.length === 0) {
      return "";
    }

    if (files.length === 1) {
      return " (1 file)";
    }

    if (files.length > 1) {
      return ` (${files.length} files)`;
    }

    // should not be here
    throw new Error("Invalid files count");
  }

  return (
    <>
      <button
        style={{
          background: "none",
          border: "none",
          color: Colors.LightText,
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
        onClick={() => setStage(UpdateCustomsDocsStage.SELECT_MODE)}
      >
        <Icon
          name="arrow-left"
          size={12}
          solid={true}
          color={Colors.LightText}
        />
        Go back
      </button>
      <Spacer height={12} />
      <Heading1>
        {mode === CustomsDocsUploadMode.Replace
          ? "Replace existing documents"
          : "Add new documents"}
      </Heading1>
      <Description style={{ textAlign: "left" }}>
        If you're not sure what documents you need, or to learn more please see
        our{" "}
        <Link
          to="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/uploading-your-customs-documents"
          newTab
        >
          Help Center
        </Link>
      </Description>
      <Spacer height={20} />
      <Heading3>Current documents</Heading3>
      <Stack>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            flexWrap: "wrap",
            gap: "24px",
            width: "100%",
          }}
        >
          <div style={{ minWidth: 180 }}>
            {mode === CustomsDocsUploadMode.Replace && (
              <Legalese>This documents will be deleted and replaced</Legalese>
            )}
            <UpdateCustomsDocsList
              documents={documents}
              onDownload={(id) =>
                downloadDocument(id, DocumentType.CustomsDocument)
              }
            />
          </div>
          <CustomsDocsDropzone
            setFiles={setFiles}
            dropzoneState={dropzoneState}
            files={files}
          />
        </div>
        {files.length !== 0 && (
          <>
            <Spacer height={24} />
            <Banner
              title="Verify Your Files Before Uploading"
              message="Please ensure your files are accurate and complete before uploading!"
              bannerStyle={BannerStyle.Info}
            />
          </>
        )}
      </Stack>
      <Spacer height={24} />
      <Stack align="right">
        {errorMessage && (
          <span style={{ color: Colors.DarkRed, paddingBlock: "4px" }}>
            {errorMessage}
          </span>
        )}
        <HorizontalStack align="right">
          <Button secondary onClick={onDone}>
            Cancel
          </Button>
          {files.length !== 0 && (
            <>
              <Spacer width={12} />
              <Button
                onClick={onUpload}
                loadingSuccess={loadingSuccess}
                loading={loading}
                loadingFailure={loadingFailure}
                disabled={loading || loadingSuccess}
              >
                Update Customs Docs {acceptedFilesCount()}
              </Button>
              <Spacer width={4} />
            </>
          )}
        </HorizontalStack>
      </Stack>
    </>
  );
}
