import { AnimatedContainer } from "../../../Cargo/Animations/AnimatedContainer";
import {
  FreightClaim,
  FreightClaimType,
} from "@freightsimple/generated-dashboard-openapi-client";
import RevealButton from "../../../Cargo/Controls/RevealButton";
import { ReactNode, useState } from "react";
import Stack from "../../../Cargo/Layout/Stack";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import moment from "moment";
import styled from "styled-components";
import Spacer from "../../../Cargo/Layout/Spacer";
import { capitalize } from "lodash";
import { calculateTotalFreightClaim } from "../../../Helpers/calculateTotalFreightClaim";
import { Divider } from "../../LineItems/Components/LineItem/Divider";
import { AffectedFreightClaimItem } from "./AffectedFreightClaimItem";
import { DocumentsSectionItem } from "./DocumentsSection";
import { FreightClaimRowDocumentsTable } from "./FreightClaimRowDocumentsTable";

interface FreightClaimSectionRowProps {
  freightClaim: FreightClaim;
  documents: DocumentsSectionItem[];
  last: boolean;
  disableReveal: boolean;
}

export function FreightClaimSectionRow({
  freightClaim,
  last = false,
  disableReveal = false,
  documents,
}: FreightClaimSectionRowProps) {
  const [open, setOpen] = useState(disableReveal);
  const date = moment(freightClaim.createdAt);
  const damagedItems =
    freightClaim.freightClaimLineItems?.filter(
      (item) => item.claimType === FreightClaimType.Damage,
    ) ?? [];

  const lostItems =
    freightClaim.freightClaimLineItems?.filter(
      (item) => item.claimType === FreightClaimType.Loss,
    ) ?? [];

  const totalLostPieces = lostItems.reduce(
    (acc, item) => acc + (item.piecesAffected ?? 0),
    0,
  );

  const totalDamagedPieces = damagedItems.reduce(
    (acc, item) => acc + (item.piecesAffected ?? 0),
    0,
  );

  const totalFilesCount =
    (freightClaim.originalInvoiceFiles?.length ?? 0) +
    (freightClaim.otherDocumentFiles?.length ?? 0) +
    (freightClaim.photoFiles?.length ?? 0);

  const lostDamagedColumns = [lostItems.length, damagedItems.length].filter(
    (c) => c !== 0,
  ).length;

  return (
    <>
      <Stack>
        <HorizontalStack align="spread" width="100%">
          <div
            style={{
              display: "grid",
              gap: "24px",
              gridTemplateColumns: `80px 250px 140px 80px repeat(${lostDamagedColumns}, 110px)`,
            }}
          >
            <StackedProp
              label="Status"
              value={capitalize(freightClaim.freightClaimState)}
            />
            <StackedProp
              label="Submitted"
              value={date.format("dddd Do MMMM YYYY")}
            />
            {freightClaim.freightClaimLineItems && (
              <StackedProp
                label="Total Amount"
                value={
                  <span>
                    $
                    {calculateTotalFreightClaim(
                      freightClaim.freightClaimLineItems,
                    )}{" "}
                    {freightClaim.claimCurrency}
                  </span>
                }
              />
            )}
            <StackedProp label="Documents" value={totalFilesCount} />
            {damagedItems.length > 0 && (
              <StackedProp label="Damaged Pieces" value={totalDamagedPieces} />
            )}
            {lostItems.length > 0 && (
              <StackedProp label="Lost Pieces" value={totalLostPieces} />
            )}
          </div>
          {!disableReveal && (
            <RevealButton
              open={open}
              onToggle={() => setOpen((prev) => !prev)}
            />
          )}
        </HorizontalStack>
        <AnimatedContainer open={open} style={{ border: "unset" }}>
          <Spacer height={16} />
          <Stack align="left">
            <StackedPropLabel>Details</StackedPropLabel>
            <div style={{ display: "flex", gap: "80px" }}>
              {[...damagedItems, ...lostItems].map((item, index) => (
                <AffectedFreightClaimItem
                  key={index}
                  item={item}
                  freightClaim={freightClaim}
                />
              ))}
            </div>
          </Stack>
          <Spacer height={16} />
          <StackedProp
            bold={false}
            label="Description"
            value={freightClaim.claimDescription}
          />
          {freightClaim.claimInternalNotes && (
            <>
              <Spacer height={16} />
              <StackedProp
                bold={false}
                label="Other Notes"
                value={freightClaim.claimInternalNotes}
              />
            </>
          )}
          <Spacer height={16} />
          <StackedProp
            label="Documents"
            value={
              <FreightClaimRowDocumentsTable
                documents={documents}
                freightClaim={freightClaim}
              />
            }
          />
        </AnimatedContainer>
      </Stack>
      {!last && (
        <>
          <Divider
            style={{
              marginBlock: "24px",
              width: "100%",
            }}
          />
        </>
      )}
    </>
  );
}

const StackedPropLabel = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 14px;
  color: #858585;
`;

const StackedPropValue = styled.div`
  font-size: 16px;
  color: #545454;
`;

function StackedProp({
  label,
  value,
  bold = true,
}: {
  label: ReactNode;
  value: ReactNode;
  bold?: boolean;
}) {
  return (
    <Stack align="left">
      <StackedPropLabel>{label}</StackedPropLabel>
      <StackedPropValue style={{ fontWeight: bold ? 600 : "unset" }}>
        {value}
      </StackedPropValue>
    </Stack>
  );
}
