import Dropdown from "../../../../Cargo/Controls/Dropdown";
import { ErrorMessageType } from "../../../../Cargo/Validation";
import { EquipmentType } from "@freightsimple/generated-dashboard-openapi-client";

interface EquipmentTypeDropdownProps {
  equipmentType: EquipmentType | undefined;
  onEquipmentTypeChange: (newValue: EquipmentType | undefined) => void;
  enabled: boolean;
  forceValidation: boolean;
  errorMessage: ErrorMessageType;
}

export function EquipmentTypeDropdown(props: EquipmentTypeDropdownProps) {
  function mapper(value: EquipmentType) {
    switch (value) {
      case EquipmentType.DryVan:
        return "Dry Van";
      case EquipmentType.FlatBed:
        return "Flatbed";
      case EquipmentType.Reefer:
        return "Temperature Controlled / Reefer";
    }
  }

  const options = Object.entries(EquipmentType).map(function (value) {
    return {
      item: value[1],
      description: mapper(value[1]),
    };
  });

  return (
    <Dropdown<EquipmentType>
      selectedItem={props.equipmentType}
      onOptionSelected={function (newState: EquipmentType) {
        props.onEquipmentTypeChange(newState);
      }}
      options={options}
      width={400}
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      unselectedDescription="Please Select"
      disabled={!props.enabled}
    />
  );
}
