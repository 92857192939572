import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import { useAuthentication } from "../../../Authentication/Slices/authenticationSlice";

import SignedInRightNavMenu from "./SignedInRightNavMenu";
import SignedOutRightNavMenu from "./SignedOutRightNavMenu";

export function NavRightSide() {
  const { isAuthenticated } = useAuthentication();
  return (
    <HorizontalStack align="right">
      {isAuthenticated ? <SignedInRightNavMenu /> : <SignedOutRightNavMenu />}
    </HorizontalStack>
  );
}
