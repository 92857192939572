import Flip from "../Animations/Flip";
import { useState } from "react";

interface RevealButtonProps {
  open: boolean;
  onToggle: () => void;
}

function Icon() {
  const [showBackground, setShowBackground] = useState(false);

  const backgroundOpacity = showBackground ? 1.0 : 0.0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="37"
      viewBox="0 0 38 37"
      onMouseEnter={() => setShowBackground(true)}
      onMouseLeave={() => setShowBackground(false)}
      style={{ transform: "scaleY(-1)" }}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1015 -1102)">
          <g transform="translate(251 688)">
            <g transform="translate(0 395)">
              <path fill="#F5F5F5" d="M5 5H820V377H5z"></path>
              <path
                fill="#FFF"
                stroke="#E9E9E9"
                d="M0.5 0.5H814.5V371.5H0.5z"
              ></path>
            </g>
          </g>
          <g transform="translate(251 1083)">
            <g>
              <path fill="#F5F5F5" d="M5 6.061H820V556.061H5z"></path>
              <path
                fill="#FFF"
                stroke="#E9E9E9"
                d="M0.5 0.5H814.5V549.5H0.5z"
              ></path>
            </g>
            <g transform="translate(21 19)">
              <circle
                cx="762"
                cy="18.5"
                r="18.5"
                fill="#D8D8D8"
                opacity={backgroundOpacity}
              ></circle>
              <path
                fill="#858585"
                d="M771.272 10L774 12.778l-9.273 9.438.007.006L762.005 25l-.005-.007-.005.007-2.729-2.778.006-.006L750 12.778 752.728 10 762 19.439 771.272 10z"
                transform="rotate(180 762 17.5)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

function RevealButton(props: RevealButtonProps) {
  return (
    <div style={{ cursor: "pointer" }} onClick={props.onToggle}>
      <Flip upright={!props.open}>
        <Icon></Icon>
      </Flip>
    </div>
  );
}
export default RevealButton;
