import Dropdown from "../../../../Cargo/Controls/Dropdown";
import { ErrorMessageType } from "../../../../Cargo/Validation";
import { SharedDedicated } from "@freightsimple/generated-dashboard-openapi-client";

interface SharedDedicatedDropdownProps {
  value: SharedDedicated | undefined;
  onChange: (newValue: SharedDedicated | undefined) => void;
  enabled: boolean;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
}

export function SharedDedicatedDropdown(props: SharedDedicatedDropdownProps) {
  const options = [
    {
      item: SharedDedicated.Shared,
      description: "Shared (LTL/Volume)",
    },
    {
      item: SharedDedicated.Dedicated,
      description: "Dedicated / Exclusive Use",
    },
    {
      item: SharedDedicated.Either,
      description: "Either",
    },
  ];

  return (
    <Dropdown<SharedDedicated>
      selectedItem={props.value}
      onOptionSelected={function (newState: SharedDedicated) {
        props.onChange(newState);
      }}
      options={options}
      width={300}
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      unselectedDescription="Please Select"
      disabled={!props.enabled}
    />
  );
}
