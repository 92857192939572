import { AddLineItemType } from "../Types/lineItemTypes";
import {
  HandlingUnitType,
  SavedCommodity,
  TemperatureHandling,
} from "@freightsimple/generated-dashboard-openapi-client";
import { v4 as uuidv4 } from "uuid";

export function emptyLineItem(): AddLineItemType {
  return {
    lineItemId: uuidv4(),
    numberHandlingUnits: 1,
    temperatureHandling: TemperatureHandling.NoSpecialHandling,
    isStackable: false,
    isDangerous: false,
    handlingUnitType: HandlingUnitType.Pallet,
  };
}

export function lineItemFromSavedCommodity(
  sc: SavedCommodity,
): AddLineItemType {
  return {
    lineItemId: uuidv4(),
    numberHandlingUnits: 1,
    nmfcItemNumber: sc.nmfcItemNumber,
    temperatureHandling: sc.temperatureHandling,
    isStackable: sc.isStackable,
    isDangerous: sc.isDangerous,
    handlingUnitType: sc.handlingUnitType,
    dangerousUnNumber: sc.dangerousUnNumber,
    dangerousClassification: sc.dangerousClassification,
    description: sc.description,
  };
}
