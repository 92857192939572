import LinkButton from "../../../Cargo/Controls/LinkButton";
import { useReaction } from "../../../Cargo/Hooks/useReaction";
import { ReactionType } from "../../../Cargo/Hooks/ReactionType";
import Icon from "../../../Cargo/Icons/Icon";
import ProgressSpinner from "../../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../../Cargo/Icons/ProgressSpinnerSizes";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "../../../Cargo/Layout/Table";
import useAlertModal from "../../../Cargo/Modal/useAlertModal";
import useConfirmModal from "../../../Cargo/Modal/useConfirmModal";
import { Heading2 } from "../../../Cargo/Text/Text";
import { Invitation } from "@freightsimple/generated-dashboard-openapi-client";
import { isDateInThePast } from "../../../Helpers/isDateInThePast";
import moment from "moment";
import { useEffect, useState } from "react";
import useClipboard from "react-use-clipboard";
import { useUserService } from "../../../Services/UserService";

interface CopyLinkButtonProps {
  link: string | undefined;
}

function CopyLinkButton(props: CopyLinkButtonProps) {
  const [isCopied, setCopied] = useClipboard(props.link || "");
  const { showReaction } = useReaction();

  useEffect(
    function () {
      if (isCopied) {
        showReaction(ReactionType.Success);
      }
    },

    [isCopied],
  );

  if (props.link === undefined) {
    return <></>;
  }

  return <LinkButton onClick={() => setCopied()}>Copy Link</LinkButton>;
}

interface InvitationsSectionProps {
  invitations: Invitation[];
  loadInvitations: () => Promise<void>;
  onInvite: () => void;
}

function InvitationsSection(props: InvitationsSectionProps) {
  const service = useUserService();
  const { invitations, loadInvitations } = props;

  const confirmRevoke = useConfirmModal(
    "Revoke",
    "Are you sure you want to revoke the invitation?",
  );

  const alert = useAlertModal();

  function formatDate(date: Date): string {
    if (moment(date).diff(moment.now(), "days") < 7) {
      return moment(date).fromNow();
    } else {
      return moment(date).format("dddd Do MMMM YYYY h:mma z");
    }
  }

  async function onRevoke(inviteeEmailAddress: string) {
    const confirmed = await confirmRevoke();

    if (confirmed) {
      try {
        await service.revokeInvitation(inviteeEmailAddress);

        await loadInvitations();
        alert("Revoke", "The invitation was revoked");
      } catch (e) {
        console.error("Something went wrong revoking", { e });
        alert(
          "Error",
          "Something went wrong. Please try again or ask us for help",
        );
      }
    }
  }

  async function onResend(inviteeEmailAddress: string) {
    try {
      await service.resendInvitation(inviteeEmailAddress);

      await loadInvitations();
      alert("Resent", "The invitation was successfully resent");
    } catch (e) {
      console.error("Something went wrong resending", { e });
      alert(
        "Error",
        "Something went wrong. Please try again or ask us for help",
      );
    }
  }

  return (
    <>
      <Heading2>Invitations</Heading2>
      <Spacer height={16} />
      <Table>
        <TableHeaderRow>
          <TableHeaderCell style={{ width: "300px" }}>Email</TableHeaderCell>
          <TableHeaderCell style={{ width: "120px" }}>Name</TableHeaderCell>
          <TableHeaderCell style={{ width: "120px" }}>
            Last sent
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "220px" }}>Actions</TableHeaderCell>
        </TableHeaderRow>
        {invitations.map((i, index) => {
          const isInvitationExpired = isDateInThePast(i.expiresAt?.toString());
          const delta = moment(i.expiresAt).fromNow();
          return (
            <TableRow key={index}>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "8px",
                  }}
                >
                  {isInvitationExpired && (
                    <>
                      <Icon
                        size={20}
                        solid
                        color="orange"
                        name="triangle-exclamation"
                      />
                      <span
                        style={{
                          color: "darkorange",
                          fontWeight: 500,
                          marginRight: "8px",
                        }}
                      >
                        Expired {delta}
                      </span>
                    </>
                  )}
                  {i.email}
                </div>
              </TableCell>
              <TableCell>{i.name}</TableCell>
              <TableCell>{formatDate(i.lastSent)}</TableCell>
              <TableCell>
                <HorizontalStack>
                  {!isInvitationExpired && (
                    <>
                      <CopyLinkButton link={i.link} />
                      <Spacer width={24} />
                    </>
                  )}
                  <ResendButton onClick={() => onResend(i.email)} />
                  <Spacer width={24} />
                  <LinkButton onClick={() => onRevoke(i.email)}>
                    Revoke
                  </LinkButton>
                </HorizontalStack>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}

function ResendButton({ onClick }: { onClick: () => Promise<void> }) {
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return (
    <LinkButton
      disabled={loading}
      style={{
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleClick}
    >
      Resend
      {loading && <ProgressSpinner size={ProgressSpinnerSizes.Small} />}
    </LinkButton>
  );
}

export default InvitationsSection;
