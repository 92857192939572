import { useAuthentication } from "../Authentication/Slices/authenticationSlice";
import { useEffect } from "react";
import { clearSlices } from "../../store";

function ResetScreen() {
  const { signOut } = useAuthentication();
  useEffect(function () {
    clearSlices();
    signOut();
  }, []);

  return <></>;
}

export default ResetScreen;
