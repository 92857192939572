import Stack from "../../../Cargo/Layout/Stack";

interface LoadingShipmentErrorProps {
  shipmentError: string;
}

export function LoadingShipmentError(props: LoadingShipmentErrorProps) {
  return (
    <Stack align="center" style={{ marginTop: "300px" }}>
      <div>❌ {props.shipmentError}</div>
    </Stack>
  );
}
