export default {
  Blue: "#4C62EA",
  LightBlue: "#8f9df2",
  VeryLightBlue: "#bec4ed",
  VeryVeryLightBlue: "#ebedf9",
  DarkBlue: "#1636EB",
  Gold: "#facc0b",
  LightGold: "#FEE993", // hsl(48,98,79)
  Green: "#4CEA63",
  Red: "#EA554C",
  DarkRed: "#E4251B",
  LightRed: "#fec5c2", // hsl(3,98,88)

  Black: "#000",
  DarkGray: "#777",
  MidGray: "#aaa",
  LightGray: "#ddd",
  VeryLightGray: "#ededed",
  PageBackground: "#1b4480",
  LightBackground: "#f0f2f8",
  SubtleBlueBackground: "#F6F7FD",

  VeryLightText: "#D8D8D8",
  LightText: "#858585",
  NormalText: "#212838",

  White: "#ffffff",

  DarkGreen: "#108922",
};
