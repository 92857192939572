import { AddLocationLocationType } from "../Types/locationTypes";
import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import { emptyLocationErrorMessages } from "./emptyErrorMessages";
import {
  errorMessagesForLocation,
  LocationErrors,
} from "./errorMessagesForLocation";

export function errorMessagesForPickupLocation(
  pickupDate: string,
  pickupLocation: AddLocationLocationType,
): LocationErrors {
  const checkHoursAreValidForAPickupToday =
    pickupDate == moment().format("YYYY-MM-DD");

  const errorMessages = errorMessagesForLocation(
    pickupLocation,
    checkHoursAreValidForAPickupToday,
  );

  return errorMessages;
}

export function warningMessagesForPickupLocation(
  pickupLocation: AddLocationLocationType,
): LocationErrors {
  const errorMessages = emptyLocationErrorMessages();

  if (
    pickupLocation.locationType === LocationType.Residential &&
    !pickupLocation.accessorials.includes("LOGISTICS_LIFT_GATE_REQUIRED")
  ) {
    return {
      ...errorMessages,
      locationType: "Typically residental pickups require lift gates",
    };
  }

  return errorMessages;
}
