import Input, { InputProps } from "../../../Cargo/Controls/Input";
import ProgressSpinner from "../../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../../Cargo/Icons/ProgressSpinnerSizes";
import { useOnce } from "../../../Hooks/useOnce";
import { FormEvent } from "react";
import { UseValidateReferenceNumberReturn } from "../Hooks/useValidateReferenceNumber";

interface ReferenceNumberInputProps
  extends Omit<
    InputProps,
    "type" | "inset" | "warningMessage" | "errorMessage"
  > {
  validation: UseValidateReferenceNumberReturn;
  value: string | undefined;
}

export function ReferenceNumberInput({
  validation,
  onChange,
  forceValidation = true,
  value,
  ...rest
}: ReferenceNumberInputProps) {
  const { result, validate, isLoading } = validation;

  useOnce(() => {
    validate(value);
  });

  function handleChange(
    value: string,
    e: FormEvent<HTMLInputElement> | undefined,
  ) {
    if (onChange) {
      onChange(value, e);
      validate(value);
    }
  }

  return (
    <Input
      {...rest}
      type="text"
      value={value}
      forceValidation={forceValidation}
      onChange={handleChange}
      warningMessage={
        result.message && !result.isValid && <span>⚠️ {result.message}</span>
      }
      inset={
        isLoading && (
          <div style={{ marginBottom: "4px" }}>
            <ProgressSpinner size={ProgressSpinnerSizes.Small} />
          </div>
        )
      }
    />
  );
}
