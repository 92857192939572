import { useModal } from "../../../Cargo/Modal/useModal";
import { StripeBankAdviceModal } from "./StripeBankAdviceModal";

export function useStripeBankAdviceModal() {
  const showModal = useModal<string>();
  function show() {
    const p = new Promise<string | undefined>((resolve) => {
      showModal(
        (done) => {
          return <StripeBankAdviceModal onDone={done} />;
        },
        (confirmed) => {
          resolve(confirmed);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
