import {
  QuestionBubbleHeader,
  QuestionBubbleLabel,
  SmallQuestionBubble,
} from "../../../../../Cargo/Controls/QuestionBubble";

export function IsStackableQuestionBubble() {
  return (
    <SmallQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>{`Stackable`}</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            <div>
              Is your freight stackable? Allowing freight to be stacked can
              greatly increase the risk of damages, however in some
              circumstances it might lead to lower prices.
            </div>
            <div>If in doubt, we recommend marking it as not stackable.</div>
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/shipment-contents#stackable"
    />
  );
}
