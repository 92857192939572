import { ErrorMessageType } from "../../../Cargo/Validation";
import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";
import { isEmptyOrUndefined } from "../../../Helpers/isNotEmptyOrUndefined";

export function errorMessageForBoothNumber(
  boothNumber: string,
  locationType: LocationType,
): ErrorMessageType {
  if (locationType !== LocationType.TradeShow) {
    return undefined;
  }

  if (isEmptyOrUndefined(boothNumber)) {
    return `Required`;
  } else {
    return undefined;
  }
}
