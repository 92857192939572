import LinkButton from "../../../../Cargo/Controls/LinkButton";

import { useIntercom } from "react-use-intercom";

function IntercomButton() {
  const { show } = useIntercom();
  return (
    <>
      <LinkButton label="Live Chat &amp; Support" onClick={() => show()} />
    </>
  );
}
export default IntercomButton;
