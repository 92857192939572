import { useModal } from "../../../Cargo/Modal/useModal";
import AddLocationModal, {
  AddLocationModalCallbackData,
} from "../Modals/AddLocationModal";
import { AddLocationModalSavingBehaviour } from "../Modals/AddLocationModalSavingBehaviour";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";

function useAddLocationModal(
  context: LocationContext,
  savingBehaviour: AddLocationModalSavingBehaviour,
  callback: (data: AddLocationModalCallbackData) => void,
): () => void {
  const showModal = useModal<AddLocationModalCallbackData>();

  function show() {
    showModal(
      (done) => {
        return (
          <AddLocationModal
            context={context}
            onCancel={() => done()}
            savingBehaviour={savingBehaviour}
            onFinished={(params) => {
              done(params);
            }}
          />
        );
      },
      (data) => {
        if (data !== undefined) {
          callback(data);
        }
      },
      {
        preventBackdropClick: true,
      },
    );
  }
  return show;
}

export default useAddLocationModal;
