import { isCrossBorder } from "../../../Helpers/isCrossBorder";
import { isDangerous } from "../../../Helpers/isDangerous";

import { BookShipmentShipmentType } from "../Slices/bookShipmentSlice";
import { FlowItem } from "../Types/FlowItem";

export function useFinishBookingFlow(shipment: BookShipmentShipmentType) {
  const finishBookingFlow: Array<FlowItem> = [];

  finishBookingFlow.push({
    description: "Details",
    identifier: "details",
    baseUrl: "",
  });
  finishBookingFlow.push({
    description: "Select Quote",
    identifier: "quotes",
    baseUrl: "",
  });
  finishBookingFlow.push({
    description: "Pickup Address",
    identifier: "pickup-address",
    baseUrl: "",
  });
  finishBookingFlow.push({
    description: "Delivery Address",
    identifier: "delivery-address",
    baseUrl: "",
  });

  finishBookingFlow.push({
    description: "Customs Broker",
    identifier: "broker",
    baseUrl: "",
  });

  finishBookingFlow.push({
    description: "Dangerous Goods",
    identifier: "dangerous-goods",
    baseUrl: "",
  });
  finishBookingFlow.push({
    description: "Payment",
    identifier: "payment",
    baseUrl: "",
  });

  finishBookingFlow.push({
    description: "Review & Book",
    identifier: "review",
    baseUrl: "",
    adournment: <img src="/shootingstaremoji.png" />,
  });

  const finalizeBookingStages = [...shipment.finalizeBookingStages];

  if (finalizeBookingStages.length == 0) {
    // We can remove this path after 1st July 2021 as no current shipments will be missing
    // the stages
    finalizeBookingStages.push("details");
    finalizeBookingStages.push("quotes");
    finalizeBookingStages.push("pickup-address");
    finalizeBookingStages.push("delivery-address");

    if (
      shipment?.broker === undefined &&
      isCrossBorder(
        shipment.pickupLocation.address.countryCode,
        shipment.deliveryLocation.address.countryCode,
      )
    ) {
      finalizeBookingStages.push("broker");
    }

    if (isDangerous(shipment.lineItems)) {
      finalizeBookingStages.push("dangerous-goods");
    }

    if (shipment?.stripePaymentMethodId === undefined) {
      finalizeBookingStages.push("payment");
    }

    finalizeBookingStages.push("review");
  }

  return finishBookingFlow.filter((flowItem) =>
    finalizeBookingStages.includes(flowItem.identifier),
  );
}
