import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";

// // TODO: This can be factored out
const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  position: relative;
  top: 3px;
`;

const Value = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 24px;
  color: val(--freightsimple-color-normal-text);
  width: 46px;
  text-align: center;
  cursor: default;
`;

const Button = styled(FontAwesomeIcon).attrs({
  style: { color: "var(--freightsimple-color-blue)" },
})`
  width: "18px";
  height: "18px";

  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }

  &.disabled:hover {
    filter: grayscale(100%) brightness(2);
  }

  &.disabled {
    filter: grayscale(100%) brightness(2);
  }
`;

interface StepperProps {
  value?: number;
  onChange: (value: number) => void;
}

const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
  const value = props.value || 1;

  function onMinus() {
    if (value > 1) {
      props.onChange(value - 1);
    }
  }

  function onPlus() {
    props.onChange(value + 1);
  }

  return (
    <Container>
      <Button
        onClick={onMinus}
        icon="minus-circle"
        className={props.value === 1 ? "disabled" : ""}
      />
      <Value>{props.value}</Value>
      <Button onClick={onPlus} icon="plus-circle" />
    </Container>
  );
};
export default Stepper;
