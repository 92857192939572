import CarrierLogo from "../../../../Cargo/Icons/CarrierLogo";
import Spacer from "../../../../Cargo/Layout/Spacer";
import Stack from "../../../../Cargo/Layout/Stack";
import { BookShipmentShipmentType } from "../../Slices/bookShipmentSlice";
import { useCoupon } from "../../../Coupons/Hooks/useCoupon";
import { formatPrice } from "../../../../Helpers/formatPrice";

interface DescribeConfirmQuoteProps {
  shipment: BookShipmentShipmentType;
}

// TODO: This is largely copied from getting started code
export function DescribeConfirmQuote({ shipment }: DescribeConfirmQuoteProps) {
  const { discountQuote } = useCoupon();

  const selectedQuote = shipment?.selectedQuote;

  if (!selectedQuote) {
    return <></>;
  }

  const discountedQuote = discountQuote(selectedQuote);

  const price = formatPrice(discountedQuote.price);

  return (
    <Stack align="left">
      <Spacer height={8} />
      <CarrierLogo
        carrierIdentifier={selectedQuote.carrierIdentifier}
        width={100}
        height={30}
        align="left"
      />
      <Spacer height={2} />
      <div>
        ${price} {discountedQuote.currency}
      </div>
    </Stack>
  );
}
