import { styled } from "styled-components";

export const ReactionOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000000;
`;
