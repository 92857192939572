export const knownInstitutionNumbers = [
  "001",
  "002",
  "003",
  "004",
  "006",
  "010",
  "016",
  "030",
];
