import { useAuthentication } from "../Features/Authentication/Slices/authenticationSlice";
import { useEffect, useState } from "react";

export function useVerifyToken() {
  const { verifyToken } = useAuthentication();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();

  useEffect(
    function () {
      verifyToken();

      // And every 5 minutes verify the token
      if (intervalId === undefined) {
        const iid = setInterval(
          () => {
            verifyToken();
          },
          1000 * 60 * 5,
        );
        setIntervalId(iid);
      }
    },
    // Since intervalId is modified ONLY in the effect, we don't want it as a dep

    [],
  );
}
