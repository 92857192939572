import HorizontalStack from "../Layout/HorizontalStack";
import { formatPrice } from "../../Helpers/formatPrice";
import { symbolForCurrency } from "../../Helpers/symbolForCurrency";

import styled from "styled-components";

interface MediumPriceProps {
  currency: string;
  price: number;
}

const CurrencySymbol = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  color: var(--freightsimple-color-normal-text);
  position: relative;
  top: -3px;
`;
const Price = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 28px;
  color: "#545454";
`;
const CurrencyLabel = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 20px;
  color: var(--freightsimple-color-light-text);
  position: relative;
  top: 3px;
  margin-left: 2px;
`;

const MediumPrice: React.FC<MediumPriceProps> = (props: MediumPriceProps) => {
  return (
    <HorizontalStack verticalAlign="middle" align="right">
      <CurrencySymbol>{symbolForCurrency(props.currency)}</CurrencySymbol>
      <Price>{formatPrice(props.price)}</Price>
      <CurrencyLabel>{props.currency}</CurrencyLabel>
    </HorizontalStack>
  );
};
export default MediumPrice;
