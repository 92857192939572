import Colors from "../Colors";
import { ToolTipWrapper } from "./QuestionBubble";
import Icon from "../Icons/Icon";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IconButton } from "./IconButton";

const NavSearchInboxBox = styled.input`
  border: none;
  border-bottom: 1px solid ${Colors.LightGray};
  width: 200px;
  caret-color: ${Colors.LightText};

  &:focus {
    border: none;
    border-bottom: 1px solid ${Colors.MidGray};
    outline: none;
  }

  &:hover {
    border: none;
    border-bottom: 1px solid ${Colors.MidGray};
  }

  ::placeholder {
    color: ${Colors.VeryLightText};
  }
`;

const Inset = styled.div`
  position: absolute; /* to align it to right and positon it over the input */
  bottom: 4px;
  right: 0;
`;

const InsetContainer = styled.div`
  position: relative; /* for absolute child element */
  display: inline-block; /* to take the width of the input */
`;

interface SearchBoxProps {
  placeholder: string;
  tooltip: string;
  onApplyFilter: (filter: string | undefined) => void;
}

export function SearchBox(props: SearchBoxProps) {
  const [value, setValue] = useState("");

  function applyFilter() {
    if (value === "") {
      props.onApplyFilter(undefined);
    } else {
      props.onApplyFilter(value);
    }
  }

  useEffect(
    function () {
      applyFilter();
    },

    [value],
  );

  function onClear() {
    setValue("");
  }

  return (
    <HorizontalStack>
      <ToolTipWrapper description={props.tooltip}>
        <Icon name="magnifying-glass" color={Colors.MidGray} size={16} solid />
      </ToolTipWrapper>
      <Spacer width={4} />
      <InsetContainer>
        <NavSearchInboxBox
          placeholder={props.placeholder}
          type="text"
          value={value}
          onChange={function (e) {
            setValue(e.currentTarget.value);
          }}
          onKeyDown={function (e) {
            if (e.code === "Enter") {
              applyFilter();
            }
          }}
        />
        {value !== "" && (
          <Inset>
            <IconButton icon="times" onClick={onClear} />
          </Inset>
        )}
      </InsetContainer>
    </HorizontalStack>
  );
}
