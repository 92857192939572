import HorizontalStack, { AlignOptions } from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import styled from "styled-components";
import { knownInstitutionNumbers } from "./knownInstitutionNumbers";

interface BankLogoProps {
  institutionNumber: string;
  stripeBankName: string;
  align?: AlignOptions;
}

const GenericBankLabel = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 20px;
`;

function displayNameForInstitutionNumber(
  institutionNumber: string,
  stripeBankName: string,
): string {
  if (institutionNumber === "001") {
    return "BMO";
  }

  return stripeBankName;
}

export function BankLogo(props: BankLogoProps) {
  const { institutionNumber, stripeBankName } = props;

  const displayName = displayNameForInstitutionNumber(
    institutionNumber,
    stripeBankName,
  );

  if (knownInstitutionNumbers.includes(institutionNumber)) {
    return (
      <HorizontalStack
        align={props.align || "left"}
        style={{ width: "250px", height: "100px" }}
        verticalAlign="middle"
      >
        <img
          style={{
            maxHeight: "250px",
            maxWidth: "100px",
          }}
          alt={displayName}
          src={`/images/bankLogos/${institutionNumber}.png`}
        />
      </HorizontalStack>
    );
  } else {
    return (
      <HorizontalStack style={{ paddingTop: "8px", paddingBottom: "8px" }}>
        <img
          src={`/images/credit-card-brands/generic-bank.png`}
          style={{ width: "24px", height: "24px" }}
        />
        <Spacer width={12} />
        <GenericBankLabel>{displayName}</GenericBankLabel>
      </HorizontalStack>
    );
  }
}
