import { User } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { ChangeBranchModal } from "./ChangeBranchModal";

export function useChangeBranchModal(user: User) {
  const showModal = useModal<boolean>();

  function show(callback: (shouldReload: boolean) => void) {
    showModal(
      (done) => {
        return (
          <ChangeBranchModal
            user={user}
            onCancel={() => done(false)}
            onDone={() => done(true)}
          ></ChangeBranchModal>
        );
      },
      function (inviteSent: boolean | undefined) {
        callback(inviteSent ?? false);
      },
      {
        preventBackdropClick: true,
      },
    );
  }

  return show;
}
