import Animation from "../Animations/Animation";
import { ProgressSpinnerSizes } from "./ProgressSpinnerSizes";

interface ProgressSpinerProps {
  size: ProgressSpinnerSizes;
}

function baseDimension(size: ProgressSpinnerSizes) {
  switch (size) {
    case ProgressSpinnerSizes.Small:
      return 32;
    case ProgressSpinnerSizes.Medium:
      return 64;
    case ProgressSpinnerSizes.Large:
      return 128;
  }
}

function containerDimension(size: ProgressSpinnerSizes) {
  return baseDimension(size) + "px";
}

function animationDimension(size: ProgressSpinnerSizes) {
  return baseDimension(size) * 2 + "px";
}

function offsetDimension(size: ProgressSpinnerSizes) {
  return "-" + baseDimension(size) / 2 + "px";
}

function ProgressSpinner(props: ProgressSpinerProps) {
  return (
    <div
      style={{
        width: containerDimension(props.size),
        height: containerDimension(props.size),
        overflow: "clip",
      }}
    >
      <div
        style={{
          width: animationDimension(props.size),
          height: animationDimension(props.size),
          position: "relative",
          top: offsetDimension(props.size),
          left: offsetDimension(props.size),
        }}
      >
        <Animation
          pathToAnimation="/animations/progress-animation.json"
          lottieFilesUrl="https://lottiefiles.com/8311-loading"
          loop={true}
        />
      </div>
    </div>
  );
}

export default ProgressSpinner;
