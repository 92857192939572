import { User } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { ManageUserModal } from "./ManageUserModal";

export function useManageUserModal(user: User) {
  const showModal = useModal<void>();

  async function show(): Promise<void> {
    return new Promise<void>((resolve) => {
      showModal(
        (done) => {
          return (
            <ManageUserModal
              onCancel={() => done()}
              onFinished={async () => {
                done();
              }}
              user={user}
            />
          );
        },
        async () => {
          resolve();
        },
        {
          preventBackdropClick: true,
        },
      );
    });
  }
  return show;
}
