import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface PickupNotesPageProp {
  shipment: Shipment;
}

export function PickupNotesPage(_props: PickupNotesPageProp) {
  return <Heading1>Pickup Notes</Heading1>;
}
