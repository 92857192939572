export function describeHazardClass(fullHazardClass: string) {
  const hazardClass = fullHazardClass[0];

  if (hazardClass === "") {
    return "";
  }

  if (hazardClass === "1") {
    return "Class 1 - Explosives";
  }

  if (hazardClass === "2") {
    return "Class 2 - Gases";
  }

  if (hazardClass === "3") {
    return "Class 3 - Flammable Liquids";
  }

  if (hazardClass === "4") {
    return "Class 4 - Flammable Solids";
  }

  if (hazardClass === "5") {
    return "Class 5 - Oxidizing Substances";
  }

  if (hazardClass === "6") {
    return "Class 6 - Toxic and Infectious Substances";
  }

  if (hazardClass === "7") {
    return "Class 7 - Radioactive Materials";
  }

  if (hazardClass === "8") {
    return "Class 8 - Corrosives";
  }

  if (hazardClass === "9") {
    return "Class 9 - Miscellaneous";
  }

  if (hazardClass === "F") {
    return "Forbidden";
  }

  return "";
}
