import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import { useLayoutEffect, useRef, useState } from "react";

interface UseHeightProps {
  on?: boolean;
}

export function useHeight(props: UseHeightProps = {}) {
  const on = props.on === undefined ? true : props.on;
  const ref = useRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);
  const heightRef = useRef(height);
  const [ro] = useState(() => {
    const callback = () => {
      // Using requestAnimationFrame supposedly quiets 'ResizeObserver loop limit exceeded' error that showed up in Cypress
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      window.requestAnimationFrame(() => {
        if (ref.current && heightRef.current !== ref.current.offsetHeight) {
          heightRef.current = ref.current.offsetHeight;
          setHeight(ref.current.offsetHeight);
        }
      });
    };

    try {
      return new ResizeObserver(callback);
    } catch {
      return new ResizeObserverPolyfill(callback);
    }
  });
  useLayoutEffect(() => {
    if (on && ref.current) {
      setHeight(ref.current.offsetHeight);
      ro.observe(ref.current, {});
    }
    return () => ro.disconnect();
  }, [on, ref.current]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return [ref, height as any];
}
