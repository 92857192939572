import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import { Page } from "../../Cargo/Layout/Page";
import Spacer from "../../Cargo/Layout/Spacer";
import { ShipmentMap } from "../../Cargo/Maps/ShipmentMap";
import useConfirmModal from "../../Cargo/Modal/useConfirmModal";
import { UUID } from "../../Cargo/Types/types";
import { useSavedLocations } from "../Locations/Hooks/useSavedLocations";
import { useSavedCommodities } from "../SavedCommodities/Hooks/useSavedCommodities";
import { LoadingShipmentError } from "../ViewShipments/Components/LoadingShipmentError";
import useQuery from "../../Hooks/useQuery";
import { useShipmentService } from "../../Services/ShipmentService";
import {
  PreBookingShipment,
  SavedCommodity,
  SavedLocation,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BookShipmentBrokerScreen from "./BookShipmentBrokerScreen";
import { BookShipmentDangerousGoodsScreen } from "./BookShipmentDangerousGoodsScreen";
import BookShipmentDeliveryAddressScreen from "./BookShipmentDeliveryAddressScreen";
import BookShipmentDetailsScreen from "./BookShipmentDetailsScreen";
import { BookShipmentNearlyDoneScreen } from "./BookShipmentNearlyDoneScreen";
import BookShipmentPaymentScreen from "./BookShipmentPaymentScreen";
import BookShipmentPickupAddressScreen from "./BookShipmentPickupAddressScreen";
import { BookShipmentProcessingScreen } from "./BookShipmentProcessingScreen";
import BookShipmentQuotesScreen from "./BookShipmentQuotesScreen";
import BookShipmentReviewScreen from "./BookShipmentReviewScreen";
import BookShipmentNavigation from "./Components/BookShipmentNavigation";
import LoadingShipment from "./Components/LoadingShipment";
import { getNextInFlow } from "./Helpers/getNextInFlow";
import { getPreviousInFlow } from "./Helpers/getPreviousInFlow";
import { isInFlow } from "./Helpers/isInFlow";
import { useAdditionalContentProvider } from "./Hooks/useAdditionalContentProvider";
import { useCompletedFlowItems } from "./Hooks/useCompletedFlowItems";
import { useFinishBookingFlow } from "./Hooks/useFinishBookingFlow";
import {
  loadFromShipment,
  loadFromShipmentToModify,
  markFlowItemCompleted,
  resetShipment,
  setCreditCardBrand,
  setCurrentScreenIsNotDirty,
  setMakeDeliveryLocationAFavourite,
  setMakePickupLocationAFavourite,
  setShipmentError,
  setShipmentLoading,
  useBookShipment2Slice,
} from "./Slices/bookShipmentSlice";

interface BookShipmentRouterWithSavedLocationsProps {
  savedLocations: Array<SavedLocation>;
  savedCommodities: Array<SavedCommodity>;
  defaultPickupLocationId: UUID | undefined;
  defaultDeliveryLocationId: UUID | undefined;
}

export const BookShipmentPageContainer = styled.div`
  width: 1024px;
`;

export const BookShipmentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
`;

export const BookShipmentFullScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  min-height: 640px;
`;

export const BookShipmentCard = styled.div`
  width: 1218px;
  background-color: white;
  min-height: 714px;
  margin-top: -122px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 64px 16px 16px 16px;
  position: relative;
  display: inline-block;
`;

function BookShipmentRouterWithSavedLocations(
  props: BookShipmentRouterWithSavedLocationsProps,
) {
  const {
    savedLocations,
    savedCommodities,
    defaultPickupLocationId,
    defaultDeliveryLocationId,
  } = props;
  const location = useLocation();

  // Basically try to match a url scheme like /book/:identifier
  const matches = location.pathname.match(/\/book\/([\w-]*)/);
  const pathIdentifier = matches?.length == 2 ? matches[1] : undefined;
  const navigate = useNavigate();
  const shipmentsService = useShipmentService();
  const query = useQuery();
  const shipmentId = query.shipmentId as string;

  const dispatch = useDispatch();

  const {
    shipment,
    loadingShipment,
    shipmentError,
    creditCardBrand,
    isModifying,
    isCurrentScreenDirty,
    completedFlowItems,
    makePickupLocationAFavourite,
    makeDeliveryLocationAFavourite,
  } = useBookShipment2Slice();

  const finishBookingFlow = useFinishBookingFlow(shipment);

  const { additionalContentProvider } = useAdditionalContentProvider(
    shipment.pickupDate,
    shipment.pickupLocation,
    shipment.deliveryLocation,
    shipment.lineItems,
    creditCardBrand,
    shipment,
  );

  const { unlockedFlowItems } = useCompletedFlowItems(
    shipment,
    completedFlowItems,
  );

  const confirmModify = useConfirmModal(
    "Confirm modify",
    "Modifying this requires getting new quotes from carriers. The previous quotes will be discarded. Is this ok?",
  );

  const confirmDirty = useConfirmModal(
    "Confirm",
    "Navigating away will lose any information you entered on this screen. Is this ok?",
  );

  useEffect(
    function () {
      async function getShipment() {
        dispatch(setShipmentLoading(true));
        try {
          const shipment =
            await shipmentsService.getPreBookingShipment(shipmentId);
          if (
            shipment.shipmentState === ShipmentState.BookingConfirmed ||
            shipment.shipmentState === ShipmentState.Cancelled ||
            shipment.shipmentState === ShipmentState.Lost ||
            shipment.shipmentState === ShipmentState.InTransit ||
            shipment.shipmentState === ShipmentState.Delivered
          ) {
            // We should NOT be in a booking flow
            navigate("/view-shipment?shipmentId=" + shipmentId);
            return;
          }
          dispatch(loadFromShipment({ shipment, savedLocations }));
        } catch (e) {
          const response = e as Response;
          const statusText = response.statusText;
          console.error(`Error loading shipment : ${response.status}`);
          dispatch(setShipmentError(statusText));
        }
        dispatch(setShipmentLoading(false));
      }

      if (pathIdentifier) {
        if (shipmentId) {
          getShipment();
        } else {
          if (!isModifying) {
            dispatch(
              resetShipment({
                savedLocations,
                defaultPickupLocationId,
                defaultDeliveryLocationId,
              }),
            );
          }
        }
      }

      dispatch(setCurrentScreenIsNotDirty());
    },

    [pathIdentifier, shipmentId],
  );

  if (pathIdentifier === undefined) {
    return <></>;
  }

  function showNavigation() {
    if (
      pathIdentifier === "details" ||
      pathIdentifier === "quotes" ||
      pathIdentifier === "processing" ||
      pathIdentifier === "nearlydone"
    ) {
      return false;
    } else {
      return true;
    }
  }

  function showMap() {
    if (pathIdentifier === "processing" || pathIdentifier === "nearlydone") {
      return false;
    } else {
      return true;
    }
  }

  function showFullScreen() {
    if (pathIdentifier === "processing" || pathIdentifier === "nearlydone") {
      return true;
    } else {
      return false;
    }
  }

  function getRoutes() {
    if (!savedLocations) {
      return [];
    }

    if (shipmentId) {
      const routes = [];

      routes.push({
        identifier: "details",
        path: `/book/details`,
        Component: (
          <BookShipmentDetailsScreen
            savedLocations={savedLocations}
            savedCommodities={savedCommodities}
            defaultPickupLocationId={defaultPickupLocationId}
            defaultDeliveryLocationId={defaultDeliveryLocationId}
          />
        ),
      });

      routes.push({
        identifier: "quotes",
        path: `/book/quotes`,
        Component: (
          <BookShipmentQuotesScreen
            shipment={shipment as PreBookingShipment}
            savedLocations={savedLocations}
            defaultPickupLocationId={defaultPickupLocationId}
            defaultDeliveryLocationId={defaultDeliveryLocationId}
          />
        ),
      });
      routes.push({
        identifier: "pickup-address",
        path: "/book/pickup-address",
        Component: (
          <BookShipmentPickupAddressScreen
            shipment={shipment as PreBookingShipment}
            savedLocations={savedLocations}
            loading={loadingShipment}
            onPrevious={async function () {
              await onNavigate("pickup-address", "quotes");
            }}
            onComplete={() => dispatch(markFlowItemCompleted("pickup-address"))}
            markLocationAsFavourite={makePickupLocationAFavourite}
            onMarkLocationAsFavourite={function (newValue: boolean) {
              dispatch(setMakePickupLocationAFavourite(newValue));
            }}
            onModify={onModify}
          />
        ),
      });

      routes.push({
        identifier: "delivery-address",
        path: "/book/delivery-address",
        Component: (
          <BookShipmentDeliveryAddressScreen
            shipment={shipment as PreBookingShipment}
            savedLocations={savedLocations}
            loading={loadingShipment}
            onComplete={() =>
              dispatch(markFlowItemCompleted("delivery-address"))
            }
            onPrevious={async function () {
              await onPrevious("delivery-address");
            }}
            nextIdentifier={getNextInFlow(
              finishBookingFlow,
              "delivery-address",
            )}
            markLocationAsFavourite={makeDeliveryLocationAFavourite}
            onMarkLocationAsFavourite={function (newValue: boolean) {
              dispatch(setMakeDeliveryLocationAFavourite(newValue));
            }}
            onModify={onModify}
          />
        ),
      });

      if (isInFlow(finishBookingFlow, "broker")) {
        routes.push({
          identifier: "broker",
          path: "/book/broker",
          Component: (
            <BookShipmentBrokerScreen
              shipment={shipment as PreBookingShipment}
              onComplete={() => dispatch(markFlowItemCompleted("broker"))}
              onPrevious={async function () {
                await onPrevious("broker");
              }}
              nextIdentifier={getNextInFlow(finishBookingFlow, "broker")}
            />
          ),
        });
      }

      if (isInFlow(finishBookingFlow, "dangerous-goods")) {
        routes.push({
          identifier: "dangerous-goods",
          path: "/book/dangerous-goods",
          Component: (
            <BookShipmentDangerousGoodsScreen
              shipment={shipment as PreBookingShipment}
              onComplete={() =>
                dispatch(markFlowItemCompleted("dangerous-goods"))
              }
              onPrevious={async function () {
                await onPrevious("dangerous-goods");
              }}
              nextIdentifier={getNextInFlow(
                finishBookingFlow,
                "dangerous-goods",
              )}
            />
          ),
        });
      }

      if (isInFlow(finishBookingFlow, "payment")) {
        routes.push({
          identifier: "payment",
          path: "/book/payment",
          Component: (
            <BookShipmentPaymentScreen
              shipment={shipment as PreBookingShipment}
              onComplete={() => dispatch(markFlowItemCompleted("payment"))}
              onBrandChange={function (brand) {
                dispatch(setCreditCardBrand(brand));
              }}
              onPrevious={async function () {
                await onPrevious("payment");
              }}
              nextIdentifier={getNextInFlow(finishBookingFlow, "payment")}
            />
          ),
        });
      }
      routes.push({
        identifier: "review",
        path: "/book/review",
        Component: (
          <BookShipmentReviewScreen
            shipment={shipment as PreBookingShipment}
            onModifyDetails={onModify}
            onModifyPickupAddress={function () {
              navigate(`/book/pickup-address?shipmentId=${shipmentId}`);
            }}
            onModifyDeliveryAddress={function () {
              navigate(`/book/delivery-address?shipmentId=${shipmentId}`);
            }}
            onSelectAnotherQuote={function () {
              navigate(`/book/quotes?shipmentId=${shipmentId}`);
            }}
          />
        ),
      });

      routes.push({
        identifier: "processing",
        path: "/book/processing",
        Component: <BookShipmentProcessingScreen shipmentId={shipmentId} />,
      });

      routes.push({
        identifier: "nearlydone",
        path: "/book/nearlydone",
        Component: <BookShipmentNearlyDoneScreen shipmentId={shipmentId} />,
      });

      return routes;
    } else {
      const routes = [
        {
          identifier: "details",
          path: `/book/details`,
          Component: (
            <BookShipmentDetailsScreen
              savedLocations={savedLocations}
              savedCommodities={savedCommodities}
              defaultPickupLocationId={defaultPickupLocationId}
              defaultDeliveryLocationId={defaultDeliveryLocationId}
            />
          ),
        },
      ];
      return routes;
    }
  }

  async function onModify() {
    const confirmed = await confirmModify();

    const shipmentIdToDelete = shipmentId;
    if (confirmed) {
      dispatch(loadFromShipmentToModify({ shipment, savedLocations }));
      await shipmentsService.deleteQuotedShipment(
        shipmentIdToDelete,
        "Modified for new quotes",
      );
      navigate("/book/details");
    }
  }

  async function onPrevious(flowIdentifier: string) {
    const previousIdentifier = getPreviousInFlow(
      finishBookingFlow,
      flowIdentifier,
    );
    await onNavigate(flowIdentifier, previousIdentifier);
  }

  async function onNavigate(
    _currentFlowIdentifier: string,
    proposedFlowIdentifier: string,
  ) {
    /*
            The flow is
                details
                quotes
                pickup-address
                delivery-address
                broker
                payment
                review
        */

    if (proposedFlowIdentifier === "details") {
      onModify();
      return;
    }

    function doNavigation() {
      const newUrl =
        "/book/" + proposedFlowIdentifier + "?shipmentId=" + shipmentId;
      navigate(newUrl);
    }

    if (isCurrentScreenDirty) {
      const confirmed = await confirmDirty();
      if (confirmed) {
        doNavigation();
        return;
      }
    } else {
      doNavigation();
      return;
    }
  }

  if (loadingShipment) {
    return <LoadingShipment />;
  }
  if (!loadingShipment && shipmentError !== undefined) {
    return <LoadingShipmentError shipmentError={shipmentError} />;
  }
  return (
    <>
      {showMap() && (
        <>
          <ShipmentMap
            pickup={shipment.pickupLocation}
            delivery={shipment.deliveryLocation}
            shipmentState={ShipmentState.Quoted}
          />
          <Spacer height={48} />
        </>
      )}

      {showNavigation() && (
        <>
          <HorizontalStack verticalAlign="top" align="left" width="100%">
            <BookShipmentNavigation
              currentFlowIdentifier={pathIdentifier}
              completedFlowItems={completedFlowItems}
              unlockedFlowItems={unlockedFlowItems}
              finishBookingFlow={finishBookingFlow}
              additionalContentProvider={additionalContentProvider}
              alwaysShowRequestQuoteFlow={true}
              onNavigate={onNavigate}
            />
            <BookShipmentPageContainer>
              {getRoutes().map(({ path, Component, identifier }) => (
                <Page key={path} path={path} id={identifier} exact={true}>
                  {() => Component}
                </Page>
              ))}
            </BookShipmentPageContainer>
          </HorizontalStack>
        </>
      )}

      {!showNavigation() && !showFullScreen() && (
        <BookShipmentCardContainer>
          <BookShipmentCard>
            {getRoutes().map(({ path, Component, identifier }) => (
              <Page key={path} path={path} id={identifier} exact={true}>
                {() => Component}
              </Page>
            ))}
          </BookShipmentCard>
        </BookShipmentCardContainer>
      )}

      {showFullScreen() && (
        <BookShipmentFullScreenContainer>
          {getRoutes().map(({ path, Component, identifier }) => (
            <Page key={path} path={path} id={identifier} exact={true}>
              {() => Component}
            </Page>
          ))}
        </BookShipmentFullScreenContainer>
      )}
    </>
  );
}

// This itself is just a wrapper to make sure the main router component is
// guaranteed to have saved locations by the time it mounts
function BookShipmentRouter() {
  const {
    savedLocations,
    loadingSavedLocations,
    defaultPickupLocationId,
    defaultDeliveryLocationId,
  } = useSavedLocations();

  const { savedCommodities, loadingSavedCommodities } = useSavedCommodities();

  if (loadingSavedLocations || loadingSavedCommodities) {
    return <LoadingShipment />;
  }

  return (
    <BookShipmentRouterWithSavedLocations
      savedLocations={savedLocations}
      savedCommodities={savedCommodities}
      defaultPickupLocationId={defaultPickupLocationId}
      defaultDeliveryLocationId={defaultDeliveryLocationId}
    />
  );
}

export default BookShipmentRouter;
