import { useModal } from "../../../Cargo/Modal/useModal";
import { PreBookingShipment } from "@freightsimple/generated-dashboard-openapi-client";
import { ChangePickupDatePreBookingModal } from "./ChangePickupDatePreBookingModal";

export function useChangePickupDatePreBooking(shipment: PreBookingShipment) {
  const showModal = useModal<string>();
  function show() {
    const p = new Promise<string | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <ChangePickupDatePreBookingModal
              onDone={done}
              shipment={shipment}
            />
          );
        },
        (newPickupDate) => {
          resolve(newPickupDate);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
