import { FilterShipmentsDescription } from "./FilterShipmentsModal";
import { SortShipmentOptions } from "./useSortShipmentsModal";

export function renderFilterShipmentsDescriptionAsQueryParams(
  filterShipmentDescription: FilterShipmentsDescription,
  sort: SortShipmentOptions,
): string {
  const parts: Array<string> = [];
  Object.entries(filterShipmentDescription).forEach(([key, value]) => {
    if (value !== undefined) {
      parts.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  parts.push(`sort=${sort}`);

  return parts.join("&");
}
