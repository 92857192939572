import { AlertModal } from "./AlertModal";
import { AlertModalOptions } from "./AlertModalOptions";
import { useModal } from "./useModal";
import { ReactNode } from "react";

function useAlertModal(): (
  title: ReactNode,
  message: ReactNode,
  options?: AlertModalOptions,
) => Promise<void> {
  const showModal = useModal<void>();

  function confirm(
    title: ReactNode,
    message: ReactNode,
    options?: AlertModalOptions,
  ): Promise<void> {
    const p = new Promise<void>((resolve) => {
      showModal(
        (done) => {
          return (
            <AlertModal
              onDone={() => {
                done();
              }}
              message={message}
              title={title}
              options={options}
            />
          );
        },
        () => {
          resolve();
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }
  return confirm;
}

export default useAlertModal;
