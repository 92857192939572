import Colors from "../../../Cargo/Colors";
import { CurrencyDropdown } from "../../../Cargo/Controls/CurrencyDropdown";
import { CurrencyInput } from "../../../Cargo/Controls/CurrencyInput";
import { LargeSwitch } from "../../../Cargo/Controls/LargeSwitch";
import Box from "../../../Cargo/Layout/Box";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { Currency } from "@freightsimple/generated-dashboard-openapi-client";
import styled from "styled-components";
import BookShipmentSection from "./BookShipmentSection";
import InsuranceQuestionBubble from "./QuestionBubbles/InsuranceQuestionBubble";

interface ShipmentValueLabelProps {
  addInsuranceToShipment: boolean;
}

const ShipmentValueLabel = styled.div<ShipmentValueLabelProps>`
  color: ${(props) =>
    props.addInsuranceToShipment ? Colors.NormalText : Colors.LightText};
`;

interface InsuranceSectionProps {
  addInsuranceToShipment: boolean;
  onAddInsuranceToShipmentChanged: (newValue: boolean) => void;

  insuranceAmount: number | undefined;
  onSetInsuranceAmount: (newValue: number) => void;

  insuranceCurrency: Currency;
  onSetInsuranceCurrency: (newValue: Currency) => void;

  errorMessageForInsurance: ErrorMessageType;
  forceValidation: boolean;

  index: number;
}

const InsuranceLabel = styled.div`
  font-size: 18px;
  color: ${Colors.LightText};
  position: relative;
  top: -2px;
`;

export function InsuranceSection(props: InsuranceSectionProps) {
  return (
    <BookShipmentSection
      index={props.index}
      header={
        <>
          Insurance{" "}
          <span
            style={{
              color: Colors.LightText,
              fontWeight: 300,
              fontSize: "20px",
            }}
          >
            Optional
          </span>
        </>
      }
      description={
        <>
          Protect your freight with additional insurance coverage
          <InsuranceQuestionBubble />
        </>
      }
    >
      <Spacer height={32} />

      <Box width={600}>
        <Stack align="center">
          <Spacer height={32} />
          <HorizontalStack verticalAlign="middle">
            <InsuranceLabel>Add insurance to this shipment?</InsuranceLabel>
            <Spacer width={8} />
            <LargeSwitch
              on={props.addInsuranceToShipment}
              onChange={props.onAddInsuranceToShipmentChanged}
            />
          </HorizontalStack>
          <Spacer height={64} />
          <Stack
            align="center"
            style={{
              opacity: props.addInsuranceToShipment ? "1.0" : "0.2",
            }}
          >
            <ShipmentValueLabel
              addInsuranceToShipment={props.addInsuranceToShipment}
            >
              Enter Shipment Value
            </ShipmentValueLabel>
            <HorizontalStack style={{ position: "relative", top: "-4px" }}>
              <CurrencyInput
                value={props.insuranceAmount}
                onChange={function (newValue: number | undefined) {
                  props.onSetInsuranceAmount(newValue ?? 0);
                }}
                errorMessage={props.errorMessageForInsurance}
                forceValidation={props.forceValidation}
                width={100}
                label=""
                placeholder="Value"
                enabled={props.addInsuranceToShipment}
              />
              <Spacer width={32} />
              <div style={{ position: "relative", top: "-4px" }}>
                <CurrencyDropdown
                  currency={props.insuranceCurrency}
                  onCurrencyChange={props.onSetInsuranceCurrency}
                  enabled={props.addInsuranceToShipment}
                />
              </div>
            </HorizontalStack>
          </Stack>
        </Stack>
        <Spacer height={32} />
      </Box>
    </BookShipmentSection>
  );
}
