import Pill from "../../../Cargo/Controls/Pill";
import { PillStyle } from "../../../Cargo/Controls/PillStyle";
import { ToolTipWrapper } from "../../../Cargo/Controls/QuestionBubble";
import Spacer from "../../../Cargo/Layout/Spacer";
import useInterval from "../../../Hooks/useInterval";
import { useOnce } from "../../../Hooks/useOnce";
import { useCoupon } from "../Hooks/useCoupon";

export function PotentialCoupon() {
  const { couponAmount, refreshCoupon } = useCoupon();

  // Refresh every 20 minutes
  useInterval(
    function () {
      refreshCoupon();
    },
    20 * 60 * 1000,
  );

  useOnce(async () => {
    refreshCoupon();
  });

  if (couponAmount === undefined) {
    return <></>;
  }

  return (
    <>
      <ToolTipWrapper description="You have a coupon that will automatically be applied to your next shipment. The prices you see listed include the coupon discount.">
        <Pill pillStyle={PillStyle.Yellow}>
          <strong>Next Shipment: ${couponAmount} Off</strong>
        </Pill>
      </ToolTipWrapper>
      <Spacer width={8} />
    </>
  );
}
