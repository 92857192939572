// eslint-disable-next-line no-restricted-imports
import { DayPicker } from "react-day-picker";
import { styled } from "styled-components";
import moment from "moment";
import { CountryCode } from "@freightsimple/generated-dashboard-openapi-client";
import { MomentHolidays } from "@freightsimple/shared";
import { HolidayMessageAlert } from "../Controls/DateHolidayMessage";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DatePickerTooltipType } from "./DatePickerTooltipType";
import { StyledDayPicker } from "./StyledDayPicker";

interface DateTooltips {
  [DatePickerTooltipType.Today]?: ReactNode | undefined;
  [DatePickerTooltipType.Weekend]?: ReactNode | undefined;
  [DatePickerTooltipType.BeforeFirstAllowedDate]?: ReactNode | undefined;
  [DatePickerTooltipType.BeyondLastAllowedDate]?: ReactNode | undefined;
  [DatePickerTooltipType.InThePast]?: ReactNode | undefined;
}

interface DatePickerTooltipProps {
  firstAllowedDate: string | undefined;
  lastAllowedDate: string | undefined;

  dateTooltips: DateTooltips;

  showHolidayTooltipsForCountry: CountryCode | undefined;
}

export interface DatePickerBaseProps extends DatePickerTooltipProps {
  onInitialize: (tooltip: ReactNode | undefined) => void;

  selectedDate: string | undefined;
  setSelectedDate: (
    selectedDate: string | undefined,
    tooltip: ReactNode | undefined,
  ) => void;
}

const TooltipContent = styled.div`
  position: fixed;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  z-index: 9999;
  width: max-content;
  min-width: 200px;
  max-width: 300px;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  pointer-events: none;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 12px;
    height: 12px;
    background: white;
    border-left: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
  }

  span[role="img"] {
    margin-right: 8px;
    color: var(--freightsimple-color-error);
  }
`;

function tooltipForDate(
  date: string,
  props: DatePickerTooltipProps,
): ReactNode | undefined {
  const momentDate = moment(date).clone().startOf("day");
  const today = moment().startOf("day");

  if (momentDate.isBefore(today)) {
    return props.dateTooltips[DatePickerTooltipType.InThePast];
  }

  if (
    props.firstAllowedDate &&
    momentDate.isBefore(moment(props.firstAllowedDate))
  ) {
    return props.dateTooltips[DatePickerTooltipType.BeforeFirstAllowedDate];
  }

  if (
    props.lastAllowedDate &&
    momentDate.isAfter(moment(props.lastAllowedDate))
  ) {
    return props.dateTooltips[DatePickerTooltipType.BeyondLastAllowedDate];
  }

  if (momentDate.isoWeekday() === 6 || momentDate.isoWeekday() === 7) {
    return props.dateTooltips[DatePickerTooltipType.Weekend];
  }

  if (props.showHolidayTooltipsForCountry) {
    if (
      MomentHolidays.isHolidayForCountry(
        momentDate,
        props.showHolidayTooltipsForCountry,
      )
    ) {
      return (
        <HolidayMessageAlert
          date={momentDate}
          country={props.showHolidayTooltipsForCountry}
        />
      );
    }
  }

  if (momentDate.isSame(today)) {
    return props.dateTooltips[DatePickerTooltipType.Today];
  }

  return undefined;
}

export function DatePickerBase(props: DatePickerBaseProps) {
  function handleDaySelect(date: Date | undefined) {
    if (!date) return;

    const isoSelectedDate = moment(date).format("YYYY-MM-DD");
    const tooltipContent = tooltipForDate(isoSelectedDate, props);

    props.setSelectedDate(isoSelectedDate, tooltipContent);
  }

  // Initialize the tooltip first time this is rendered
  useEffect(() => {
    if (props.selectedDate !== undefined) {
      const tooltipContent = tooltipForDate(props.selectedDate, props);

      props.onInitialize(tooltipContent);
    }
  }, []);

  const firstAllowedDate = props.firstAllowedDate
    ? moment(props.firstAllowedDate).toDate()
    : undefined;
  const lastAllowedDate = props.lastAllowedDate
    ? moment(props.lastAllowedDate).toDate()
    : undefined;

  const disabledDays = [
    // Weekend predicate
    { dayOfWeek: [0, 6] }, // 0 is Sunday, 6 is Saturday
    // Date range restrictions - only add if defined
    ...(firstAllowedDate ? [{ before: firstAllowedDate }] : []),
    ...(lastAllowedDate ? [{ after: lastAllowedDate }] : []),
  ];

  return (
    <StyledDayPicker>
      <DayPicker
        mode="single"
        selected={
          props.selectedDate ? moment(props.selectedDate).toDate() : undefined
        }
        onSelect={handleDaySelect}
        disabled={disabledDays}
        defaultMonth={
          props.selectedDate ? moment(props.selectedDate).toDate() : undefined
        }
        numberOfMonths={2}
        formatters={{
          formatCaption: (date: Date) => moment(date).format("MMMM YYYY"),
        }}
        modifiers={{
          holiday: (date: Date) => {
            return props.showHolidayTooltipsForCountry
              ? MomentHolidays.isHolidayForCountry(
                  moment(date),
                  props.showHolidayTooltipsForCountry,
                )
              : false;
          },
          weekend: (date: Date) => [6, 0].includes(date.getDay()),
          disabledHoliday: (date: Date) => {
            const isHoliday = props.showHolidayTooltipsForCountry
              ? MomentHolidays.isHolidayForCountry(
                  moment(date),
                  props.showHolidayTooltipsForCountry,
                )
              : false;
            const isDisabled =
              moment(date) < moment(firstAllowedDate) ||
              moment(date) > moment(lastAllowedDate);
            return isHoliday && isDisabled;
          },
        }}
        modifiersStyles={{
          weekend: {
            backgroundColor: "#f0f0f0",
            color: "var(--freightsimple-color-warning)",
          },
          holiday: {
            backgroundColor: "var(--freightsimple-color-gold)",
            color: "var(--freightsimple-color-normal-text)",
            borderRadius: "8px",
            fontWeight: "500",
            opacity: "0.8",
          },
          disabledHoliday: {
            backgroundColor: "rgba(250, 204, 11, 0.15)", // Very faint gold
            color: "#ccc", // Same as other disabled dates
            borderRadius: "8px",
            fontWeight: "500",
          },
        }}
        components={{
          Day: (dayProps) => {
            const [showTooltip, setShowTooltip] = useState(false);
            const [tooltipPosition, setTooltipPosition] = useState({
              top: 0,
              left: 0,
            });
            const cellRef = useRef<HTMLTableCellElement>(null);

            const tooltipContent = tooltipForDate(
              moment(dayProps.day.date).format("YYYY-MM-DD"),
              props,
            );

            const updateTooltipPosition = () => {
              if (cellRef.current) {
                const rect = cellRef.current.getBoundingClientRect();
                setTooltipPosition({
                  top: rect.bottom + 5,
                  left: rect.left + rect.width / 2,
                });
              }
            };

            const handleMouseEnter = () => {
              updateTooltipPosition();
              setShowTooltip(true);
            };

            return (
              <td
                {...dayProps}
                ref={cellRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => setShowTooltip(false)}
              >
                {dayProps.children}
                {showTooltip &&
                  tooltipContent &&
                  createPortal(
                    <TooltipContent
                      style={{
                        top: `${tooltipPosition.top}px`,
                        left: `${tooltipPosition.left}px`,
                        transform: "translateX(-50%)",
                      }}
                    >
                      {tooltipContent}
                    </TooltipContent>,
                    document.body,
                  )}
              </td>
            );
          },
        }}
      />
    </StyledDayPicker>
  );
}
