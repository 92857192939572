import Colors from "../../../Cargo/Colors";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading3, Legalese, Microcopy } from "../../../Cargo/Text/Text";
import { Dispatch, SetStateAction } from "react";
import { DropzoneState } from "react-dropzone";
import styled from "styled-components";

interface DropContainerProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
}

function getColor(props: DropContainerProps) {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#ccc";
}

const DropContainer = styled.div<DropContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 5px;
  border-radius: 20px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 480px;
`;

export interface CustomsDocsDropzoneProps {
  files: Array<File>;
  setFiles: Dispatch<SetStateAction<Array<File>>>;
  allowRemove?: boolean;
  dropzoneState: DropzoneState;
}

export function CustomsDocsDropzone({
  files,
  setFiles,
  allowRemove = true,
  dropzoneState,
}: CustomsDocsDropzoneProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = dropzoneState;

  function handleRemoveFile(index: number) {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  }

  return (
    <DropContainer
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
    >
      <input {...getInputProps()} />

      <Stack>
        <Spacer height={32} />
        <Heading3>Customs Documents PDF files</Heading3>
        <Microcopy>Drag and drop or click to choose files to upload</Microcopy>
        <Spacer height={16} />
        {files.length === 0 && (
          <Icon name="file-pdf" color="#ccc" size={64} solid />
        )}

        <Stack align="left">
          {files.map(function (af, index) {
            return (
              <HorizontalStack key={index}>
                <Icon
                  name="file-pdf"
                  color={Colors.Blue}
                  size={16}
                  solid
                  style={{
                    marginRight: "4px",
                  }}
                />

                <Legalese>{af.name}</Legalese>
                {allowRemove && (
                  <button
                    title="Remove file"
                    style={{
                      position: "relative",
                      margin: 0,
                      padding: 0,
                      background: "unset",
                      height: 12,
                      width: 12,
                      marginLeft: 4,
                      border: 0,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveFile(index);
                    }}
                  >
                    <Icon
                      name="xmark"
                      color={Colors.Red}
                      size={12}
                      solid
                      style={{
                        position: "absolute",
                        top: "1px",
                        left: "0",
                      }}
                    />
                  </button>
                )}
              </HorizontalStack>
            );
          })}
        </Stack>
        <Spacer height={16} />
        <Legalese style={{ textAlign: "center" }}>
          Multiple files allowed <br />
          <span style={{ fontSize: "12px" }}>(10 files maximum)</span>
        </Legalese>
        <Spacer height={16} />
      </Stack>
    </DropContainer>
  );
}
