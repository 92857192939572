import { useGetStartedApi } from "../../../../apis";
import Stack from "../../../../Cargo/Layout/Stack";
import { UUID } from "../../../../Cargo/Types/types";
import { updateBroker } from "../../Slices/bookShipmentSlice";
import BrokerBox from "../../../Brokers/Components/BrokerBox";
import useModifyBrokerModal from "../../../Brokers/Hooks/useModifyBrokerModal";
import { Broker } from "@freightsimple/generated-dashboard-openapi-client";

import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

interface BookShipmentBrokerScreenAlreadySavedProps {
  broker: Broker;
  shipmentId: UUID;
  // savedBrokers: Array<SavedBroker>;
}

export function BookShipmentBrokerScreenAlreadySaved(
  props: BookShipmentBrokerScreenAlreadySavedProps,
) {
  const showModifyBrokerModal = useModifyBrokerModal();
  const getStartedApi = useGetStartedApi();
  const dispatch = useDispatch();
  //const savedBrokersApi = useSavedBrokersApi();

  // const [loading, setLoading] = useState(false);
  // const [brokers, setBrokers] = useState<undefined | Array<SavedBroker>>();

  // async function loadSavedBrokers() {
  //     setLoading(true);
  //     const savedBrokersResponse = await savedBrokersApi.getAllSavedBrokers();
  //     setBrokers(
  //         savedBrokersResponse.items.sort((a, b) => {
  //             return a.broker.businessName.localeCompare(
  //                 b.broker.businessName
  //             );
  //         })
  //     );

  //     setLoading(false);
  // }

  // useOnce(loadSavedBrokers);

  // function findSavedBrokerId() {
  //     const broker = props.broker;

  //     const matchingSavedBroker = props.savedBrokers.find(function (b) {
  //         return (
  //             b.broker.businessName === broker?.businessName &&
  //             b.broker.address.postalCode === broker.address.postalCode
  //         );
  //     });

  //     console.log(`!!! findSavedBrokerId`, { broker, matchingSavedBroker });

  //     return matchingSavedBroker?.savedBrokerId;
  // }

  //const savedBrokerId = findSavedBrokerId();

  // if (loading) {
  //     return <>Loading...</>;
  // }

  return (
    <Stack align="left">
      <BrokerBox
        key={"unnecessarykey"}
        broker={props.broker}
        onModify={async function () {
          // We don't particularly care which saved broker we are actually
          // using here
          const data = await showModifyBrokerModal({
            savedBrokerId: uuidv4(),
            broker: props.broker,
          });
          const updatedBroker = data?.broker;

          if (updatedBroker !== undefined) {
            await getStartedApi.postGetStartedSetBrokerDetails({
              setBrokerDetailsRequest: {
                shipmentId: props.shipmentId,
                broker: updatedBroker,
              },
            });
            dispatch(updateBroker(updatedBroker));
          }
        }}
        showMenu={false}
        onDelete={undefined}
        preferredNorthbound={false}
        preferredSouthbound={false}
        onSelectPreferred={undefined}
      />
    </Stack>
  );
}
