import { CustomsDocsUploadMode } from "../../../Apis/CustomsDocsApi";
import Button from "../../../Cargo/Controls/Button";
import { CardRadioButton } from "../../../Cargo/Controls/CardRadioButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Description, Heading1 } from "../../../Cargo/Text/Text";
import { UpdateCustomsDocsStageProps } from "./UpdateCustomsDocsModal";
import { UpdateCustomsDocsStage } from "./UpdateCustomsDocsStage";

export function UpdateCustomsDocsSelectModeStage({
  setMode,
  onDone,
  setStage,
  mode,
}: UpdateCustomsDocsStageProps) {
  function handleNextClick() {
    if (mode === undefined) {
      return;
    }

    setStage(UpdateCustomsDocsStage.SUBMIT);
  }

  return (
    <>
      <Heading1>Update Customs Documents</Heading1>
      <Description style={{ textAlign: "left" }}>
        Do you want to replace or add documents?
      </Description>
      <Spacer height={24} />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <CardRadioButton
          icon="arrows-rotate"
          description="Replace existing documents with new ones. All current documents will be deleted and replaced."
          label="Replace"
          onChange={() => setMode(CustomsDocsUploadMode.Replace)}
          value={CustomsDocsUploadMode.Replace}
          isChecked={mode === CustomsDocsUploadMode.Replace}
        />
        <CardRadioButton
          icon="plus"
          label="Add"
          description="Keep existing documents and add new ones without removing or altering any current documents."
          onChange={() => setMode(CustomsDocsUploadMode.Add)}
          isChecked={mode === CustomsDocsUploadMode.Add}
          value={CustomsDocsUploadMode.Add}
        />
      </div>
      <Spacer height={24} />
      <HorizontalStack align="right">
        <Button secondary onClick={onDone}>
          Cancel
        </Button>
        <Spacer width={12} />
        <Button onClick={handleNextClick} disabled={mode === undefined}>
          Next
        </Button>
      </HorizontalStack>
    </>
  );
}
