import { PaymentMethod } from "@stripe/stripe-js";

export function extractPaymentMethodId(
  paymentMethod: string | PaymentMethod | null,
): string | undefined {
  if (!paymentMethod) {
    console.error("Payment method is null or undefined");
    return undefined;
  }

  const id =
    typeof paymentMethod === "string" ? paymentMethod : paymentMethod.id;

  if (!id) {
    console.error("Failed to extract payment method ID from:", paymentMethod);
  }

  return id;
}
