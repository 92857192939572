import {
  LocationContext,
  PreBookingShipment,
  SavedLocation,
} from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";

import AddressScreenComponent from "./Components/AddressScreenComponent";

interface BookShipmentPickupAddressScreenProps {
  shipment: PreBookingShipment;
  savedLocations: Array<SavedLocation>;
  loading: boolean;
  onComplete: () => void;
  onPrevious: () => Promise<void>;
  markLocationAsFavourite: boolean;
  onMarkLocationAsFavourite: (newValue: boolean) => void;
  onModify: () => void;
}

function BookShipmentPickupAddressScreen(
  props: BookShipmentPickupAddressScreenProps,
) {
  const isSameDayPickup =
    props.shipment.pickupDate === moment().format("YYYY-MM-DD");

  return (
    <AddressScreenComponent
      loading={props.loading}
      identifier={"pickup-address"}
      savedLocations={props.savedLocations}
      context={LocationContext.Pickup}
      shipment={props.shipment}
      nextIdentifier="delivery-address"
      onPrevious={props.onPrevious}
      onComplete={props.onComplete}
      markLocationAsFavourite={props.markLocationAsFavourite}
      onMarkLocationAsFavourite={props.onMarkLocationAsFavourite}
      onModify={props.onModify}
      isSameDayPickup={isSameDayPickup}
    />
  );
}

export default BookShipmentPickupAddressScreen;
