import {
  MicroErrorQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../Cargo/Controls/QuestionBubble";
import Spacer from "../../../../Cargo/Layout/Spacer";

function NeedsAfterHoursQuestionBubble() {
  return (
    <MicroErrorQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>{`After hours needed`}</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            <div>
              The ability to schedule shipments with pickups or deliveries
              outside of normal business hours is only available when the{" "}
              <strong>After Hours</strong> special service is selected prior to
              quoting.
            </div>
            <Spacer height={8} />
            <div>
              If you need this, please go back and modify the details and
              request another quote.
            </div>
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl=""
    />
  );
}
export default NeedsAfterHoursQuestionBubble;
