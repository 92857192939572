import Colors from "../../Cargo/Colors";
import { SearchBox } from "../../Cargo/Controls/SearchBox";
import Switch from "../../Cargo/Controls/Switch";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import PageTitle from "../../Cargo/Text/PageTitle";
import { Heading1, Legalese, Microcopy } from "../../Cargo/Text/Text";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import { BranchFilterOption } from "../Dashboard/Components/BranchFilterOption";
import {
  UserDropdownType,
  useUserDropdown,
} from "../Dashboard/Hooks/useUserDropdown";
import { GetQuotesForFirstShipmentButton } from "../ViewShipments/ViewAllShipmentsScreen";
import { groupBy } from "../../Helpers/groupBy";
import { sumOf } from "../../Helpers/sumOf";
import { useOnce } from "../../Hooks/useOnce";
import { useShipmentService } from "../../Services/ShipmentService";
import { useInvoicesApi } from "../../apis";
import {
  InvoiceState,
  InvoiceWarningState,
  Shipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { Invoice } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import { CSSProperties, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CreditBanner } from "./Components/CreditBanner";
import InvoiceRow from "./Components/InvoiceRow";
import InvoiceSection from "./Components/InvoiceSection";
import { useInvoicesStats } from "./Hooks/useInvoicesStats";

interface StatisticProps {
  label: ReactNode;
  children: number;
  hideIf?: boolean;
  childrenStyle?: CSSProperties;
}

function Statistic(props: StatisticProps) {
  if (props.hideIf) {
    return <></>;
  }

  if (props.children === 0) {
    return <></>;
  }

  const childStyle = { color: Colors.NormalText, ...props.childrenStyle };

  return (
    <Stack align="left">
      <div
        style={{
          marginBottom: "-4px",
          fontSize: "14px",
          color: Colors.LightText,
        }}
      >
        {props.label}
      </div>
      <div style={childStyle}>${props.children.toLocaleString()}</div>
    </Stack>
  );
}

interface OnlyShowUnpaidInvoicesSwitchProps {
  on: boolean;
  onChange: (newValue: boolean) => void;
}

const OnlyShowUnpaidLabel = styled.div`
  font-weight: var(--nhu-font-weight-normal);
  font-size: 14px;
  color: var(--freightsimple-color-light-text);
`;

function OnlyShowUnpaidInvoicesSwitch(
  props: OnlyShowUnpaidInvoicesSwitchProps,
) {
  return (
    <HorizontalStack>
      <OnlyShowUnpaidLabel>Only show unpaid</OnlyShowUnpaidLabel>
      <Switch on={props.on} onChange={props.onChange} />
    </HorizontalStack>
  );
}

function ViewInvoicesScreen() {
  const shipmentsService = useShipmentService();
  const [invoices, setInvoices] = useState<Array<Invoice>>([]);
  const invoicesStats = useInvoicesStats();

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [bookedShipments, setBookedShipments] = useState<Array<Shipment>>([]);
  const invoicesApi = useInvoicesApi();
  const navigate = useNavigate();

  const {
    element: UserDropdownElement,
    filterByUserId,
    filterByBranchId,
  } = useUserDropdown(UserDropdownType.Booking);

  function isInvoiceUnpaid(invoice: Invoice): boolean {
    return (
      invoice.invoiceState !== InvoiceState.Settled &&
      invoice.invoiceState !== InvoiceState.Voided
    );
  }

  const [onlyShowUnpaidInvoices, setOnlyShowUnpaidInvoices] = useState(false);

  async function loadInvoices() {
    const response = await invoicesApi.getInvoicesList();
    setInvoices(response.invoices);
    setOnlyShowUnpaidInvoices(
      response.invoices.some((invoice) => isInvoiceUnpaid(invoice)),
    );
  }

  useOnce(async () => {
    setLoading(true);
    await loadInvoices();
    await loadShipments();
    setLoading(false);
  });

  function applyFilters(_invoices: Invoice[]) {
    let filteredInvoices = _invoices;

    if (onlyShowUnpaidInvoices) {
      filteredInvoices = filteredInvoices.filter(isInvoiceUnpaid);
    }
    if (filter) {
      filteredInvoices = filteredInvoices.filter(function (invoice) {
        return invoice.invoiceIdentifier.includes(filter);
      });
    }

    if (filterByUserId !== undefined && filterByUserId !== "") {
      filteredInvoices = filteredInvoices.filter(function (invoice) {
        return invoice.bookedBy === filterByUserId;
      });
    }

    if (filterByBranchId !== undefined && filterByBranchId !== "") {
      filteredInvoices = filteredInvoices.filter(function (invoice) {
        if (filterByBranchId === BranchFilterOption.Uncoded) {
          return invoice.branchId === undefined;
        }
        return invoice.branchId === filterByBranchId;
      });
    }

    return filteredInvoices;
  }

  function filterInvoices() {
    return applyFilters(invoices);
  }

  function calculateUnpaid() {
    return sumOf(invoices.filter(isInvoiceUnpaid), (invoice) => invoice.amount);
  }

  function calculatePending() {
    return sumOf(
      invoices.filter(
        (invoice) => invoice.invoiceState == InvoiceState.SettlementPending,
      ),
      (invoice) => invoice.amount,
    );
  }

  function calculateOverdue() {
    return sumOf(
      invoices.filter(isInvoiceUnpaid).filter(function (invoice) {
        const invoiceDate = moment(invoice.dueDate).startOf("day");
        const today = moment().startOf("day");
        return invoiceDate.isBefore(today);
      }),
      (invoice) => invoice.amount,
    );
  }

  const showUncodedOption = invoices.some((s) => s.branchId === undefined);

  const nothingToShow = filterInvoices().length === 0;
  const monthFormat = "MMMM YYYY";
  const groupedInvoices = groupBy(filterInvoices(), function (invoice) {
    const m = moment(invoice.invoiceDate);
    return m.format(monthFormat);
  }).sort(function (d1, d2) {
    // Descending - newer invoices should be at the top
    return (
      moment(d2.key, monthFormat).valueOf() -
      moment(d1.key, monthFormat).valueOf()
    );
  });

  async function loadShipments() {
    const response = await shipmentsService.getShipments();

    setBookedShipments(response.booked);
  }

  if (loading || invoicesStats === undefined) {
    return <LoadingShipment />;
  }

  const showMakeAPaymentButton = filterInvoices().some(
    (invoice) => invoice.invoiceState === InvoiceState.Issued,
  );

  return (
    <>
      <PageTitle>Invoices</PageTitle>
      <FullWidthLayout
        header="Invoices"
        microcopy="Here you can quickly browse through all of your FreightSimple invoices"
        showEmptyState={bookedShipments.length === 0}
        rightContent={
          <HorizontalStack>
            <Statistic label="Overdue" childrenStyle={{ color: Colors.Red }}>
              {calculateOverdue()}
            </Statistic>
            <Spacer width={24} />
            <Statistic
              label="Unpaid"
              hideIf={calculateOverdue() === calculateUnpaid()}
            >
              {calculateUnpaid()}
            </Statistic>
            <Spacer width={24} />
            <Statistic label="Pending">{calculatePending()}</Statistic>

            <Spacer width={64} />
            <OnlyShowUnpaidInvoicesSwitch
              on={onlyShowUnpaidInvoices}
              onChange={setOnlyShowUnpaidInvoices}
            />
            <Spacer width={32} />
            <SearchBox
              placeholder="Enter Invoice Number"
              tooltip="Search for a shipment"
              onApplyFilter={setFilter}
            />
            <Spacer width={32} />
            {UserDropdownElement(showUncodedOption)}
          </HorizontalStack>
        }
        emptyState={
          <Stack>
            <Heading1>You don&apos;t have any invoices yet</Heading1>
            <Microcopy>
              Once you have booked shipments, you will be able to quickly browse
              all invoices here.
            </Microcopy>
            <Spacer height={32} />
            <GetQuotesForFirstShipmentButton />
          </Stack>
        }
        content={
          <>
            <CreditBanner
              paymentTermsDays={invoicesStats.paymentTermsDays}
              creditAmount={invoicesStats.creditAmount}
              creditCurrency={invoicesStats.creditCurrency}
              issuedInvoicesTotal={invoicesStats.issuedInvoicesTotal}
              isBookingSuspended={
                invoicesStats.invoiceWarningState ===
                  InvoiceWarningState.BookingSuspendedDueToOverCreditLimit ||
                invoicesStats.invoiceWarningState ===
                  InvoiceWarningState.BookingSuspendedDueToOverdueInvoices
              }
              overdueIssuedInvoicesTotal={
                invoicesStats.overdueIssuedInvoicesTotal
              }
              showMakeAPaymentButton={showMakeAPaymentButton}
            />
            {nothingToShow && (
              <Legalese>
                No invoices match your filter. Please contact our customer
                support team if you believe this is an error.
              </Legalese>
            )}
            {groupedInvoices.map(function (monthOfInvoices) {
              const formattedMonth = monthOfInvoices.key;
              const totalCost = sumOf(
                monthOfInvoices.value,
                (invoice) => invoice.amount,
              );
              // Basically group by shipment, then count
              const shipmentCount = groupBy(
                monthOfInvoices.value,
                (i) => i.shipmentId,
              ).length;
              return (
                <>
                  <InvoiceSection
                    sectionTitle={formattedMonth}
                    totalCost={totalCost}
                    currency={invoicesStats.creditCurrency}
                    shipmentCount={shipmentCount}
                    key={formattedMonth}
                  />
                  {applyFilters(monthOfInvoices.value)
                    // Within each month, invoices should be sorted newest first
                    .sort(function (d1, d2) {
                      return (
                        moment(d2.invoiceDate).valueOf() -
                        moment(d1.invoiceDate).valueOf()
                      );
                    })
                    .map(function (invoice) {
                      const shipment = bookedShipments.find(function (s) {
                        return s.shipmentId === invoice.shipmentId;
                      });

                      if (shipment === undefined) {
                        console.error("Shipment not found");
                        return <></>;
                      }

                      const shipmentId = shipment.shipmentId;

                      function onViewShipment() {
                        const url = `/view-shipment?shipmentId=${shipmentId}`;
                        navigate(url);
                      }

                      function onDownloadInvoice() {
                        shipmentsService.downloadInvoice(
                          shipmentId,
                          invoice.invoiceIdentifier,
                          invoice.invoicePdfKey,
                        );
                      }

                      return (
                        <InvoiceRow
                          key={invoice.invoiceIdentifier}
                          invoice={invoice}
                          shipment={shipment}
                          onViewShipment={onViewShipment}
                          onDownloadInvoice={onDownloadInvoice}
                        />
                      );
                    })}
                </>
              );
            })}
          </>
        }
      />
    </>
  );
}

export default ViewInvoicesScreen;
