function TestErrorComponent() {
  // Randomly throw error 50% of the time
  if (Math.random() < 0.5) {
    throw new Error("This is a test error");
  }

  return <div>Test Error Component : Refresh to trigger an error</div>;
}

export function TestErrorScreen() {
  return <TestErrorComponent />;
}
