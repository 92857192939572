export function isNotEmptyOrUndefined(s: string | undefined): boolean {
  if (s === undefined) {
    return false;
  }

  if (s.trim() === "") {
    return false;
  }

  return true;
}

export function isEmptyOrUndefined(s: string | undefined): boolean {
  if (s === undefined || s.trim() === "") {
    return true;
  }

  return false;
}
