import { Heading1 } from "../../../Cargo/Text/Text";
import { Shipment } from "@freightsimple/generated-dashboard-openapi-client";

interface DeliveryNotesPageProp {
  shipment: Shipment;
}

export function DeliveryNotesPage(_props: DeliveryNotesPageProp) {
  return <Heading1>Delivery Notes</Heading1>;
}
