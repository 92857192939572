import { usePaymentMethodsApi } from "../../../apis";
import { useModal } from "../../../Cargo/Modal/useModal";
import { ShareCreditCardModal } from "./ShareCreditCardModal";

export function useShareCreditCardModal() {
  const showModal = useModal();
  const creditCardsApi = usePaymentMethodsApi();

  async function showShareModal() {
    const response =
      await creditCardsApi.getCreditCardsGetAddCreditCardAuthorizationId();
    const addCreditCardAuthorizationId = response.addCreditCardAuthorizationId;
    showModal((done) => {
      return (
        <ShareCreditCardModal
          onDone={done}
          addCreditCardAuthorizationId={addCreditCardAuthorizationId}
        />
      );
    });
  }

  return showShareModal;
}
