export function calculateBoundingBox(
  points: Array<[number, number]>,
): mapboxgl.LngLatBoundsLike {
  const lngs = points.map((m) => m[0]);
  const lats = points.map((m) => m[1]);

  const minLng = Math.min(...lngs);
  const minLat = Math.min(...lats);
  const maxLng = Math.max(...lngs);
  const maxLat = Math.max(...lats);

  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}
