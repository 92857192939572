import { useModal } from "./useModal";
import { ReactNode } from "react";
import { ConfirmModalOptions } from "./ConfirmModalOptions";
import { ConfirmModal } from "./ConfirmModal";

function useConfirmModal(
  title: ReactNode,
  message?: ReactNode,
  options?: ConfirmModalOptions,
): (_?: ReactNode) => Promise<boolean> {
  const showModal = useModal<boolean>();

  function confirm(messageOverride?: ReactNode): Promise<boolean> {
    const p = new Promise<boolean>((resolve) => {
      showModal(
        (done) => {
          return (
            <ConfirmModal
              onDone={(confirmed: boolean) => {
                done(confirmed);

                //resolve(confirmed);
              }}
              message={messageOverride ?? message}
              title={title}
              options={options}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed || false);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }
  return confirm;
}

export default useConfirmModal;
