import { FlowItem } from "../Types/FlowItem";

export function getPreviousInFlow(
  flow: Array<FlowItem>,
  currentIdentifier: string,
): string {
  const currentIndex = flow.findIndex(function (item) {
    return item.identifier === currentIdentifier;
  });

  if (currentIndex === -1) {
    throw new Error("Item not found");
  }

  if (currentIndex === 0) {
    // First so there is no previous
    throw new Error("Already at start of flow");
  }

  return flow[currentIndex - 1].identifier;
}
