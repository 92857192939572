import { CardCvcElement } from "@stripe/react-stripe-js";
import { StripeCardCvcElementChangeEvent } from "@stripe/stripe-js";
import Spacer from "../../../Cargo/Layout/Spacer";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { useState } from "react";
import styled from "styled-components";
import { StripeElementOptions } from "./StripeElementOptions";

interface CreditCardCVCProps {
  onError: (message: ErrorMessageType) => void;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
}

// Specifying the height of these is important,
// as the error messages can be one or two lines long
const InvalidLabel = styled.div`
  color: #ea554c;
  font-size: 12px;
  margin-top: 4px;
  height: 36px;
`;

function CreditCardCVC(props: CreditCardCVCProps) {
  const [validated, setValidated] = useState(false);
  const showErrorMessage =
    (props.forceValidation || validated) && props.errorMessage !== undefined;
  return (
    <div>
      <div style={{ width: "164px" }}>
        <CardCvcElement
          id="cvc"
          options={StripeElementOptions}
          onChange={(event: StripeCardCvcElementChangeEvent) => {
            props.onError(event.error?.message);
          }}
          onBlur={() => setValidated(true)}
        />
        {showErrorMessage && <InvalidLabel>{props.errorMessage}</InvalidLabel>}
        {!showErrorMessage && <Spacer height={40} />}
      </div>
    </div>
  );
}
export default CreditCardCVC;
