import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Label } from "../../../Cargo/Text/Label";
import LargeContactInput from "../../Contacts/Components/LargeContactInput";
import {
  EmailRequired,
  errorMessagesForContact,
} from "../../Contacts/Validators/errorMessagesForContact";
import { Contact } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface ModifyBrokerModalContactPageProps {
  contact: Contact;
  setContact: (_: Contact) => void;
  onDone: () => void;
  onPrevious: () => void;
}

function ModifyBrokerModalContactPage(
  props: ModifyBrokerModalContactPageProps,
) {
  const { contact, setContact, onDone, onPrevious } = props;
  const [forceValidation, setForceValidation] = useState(false);
  const errorMessages = errorMessagesForContact(
    contact,
    EmailRequired.EmailRequired,
  );

  function updateContact(contactUpdates: Partial<Contact>) {
    const updatedContact = {
      ...contact,
      ...contactUpdates,
    };

    setContact(updatedContact);
  }

  function isValid() {
    if (
      errorMessages.contactName ||
      errorMessages.emailAddress ||
      errorMessages.phoneNumber
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function onNext() {
    if (isValid()) {
      onDone();
    } else {
      setForceValidation(true);
    }
  }

  return (
    <>
      <Label>Contact Details</Label>
      <LargeContactInput
        contact={contact}
        contactChanged={updateContact}
        forceValidation={forceValidation}
        errorMessages={errorMessages}
      ></LargeContactInput>
      <Spacer height={32} />
      <HorizontalStack width="100%" align="spread">
        <Button onClick={onPrevious} secondary>
          Previous
        </Button>
        <Button onClick={onNext}>Save</Button>
      </HorizontalStack>
    </>
  );
}

export default ModifyBrokerModalContactPage;
