import { useHeight } from "../Helpers/useHeight";
import { ReactNode } from "react";
import { animated, config, useSpring } from "@react-spring/web";

interface SlideDownProps {
  children: ReactNode;
  show: boolean;
}

function SlideDown(props: SlideDownProps) {
  const [heightRef, height] = useHeight();
  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: props.show ? 1 : 0,
      height: props.show ? height : 0,
    },
  });
  return (
    <animated.div
      style={{ ...slideInStyles, overflow: "hidden", width: "100%" }}
    >
      <div ref={heightRef}>{props.children}</div>
    </animated.div>
  );
}
export default SlideDown;
