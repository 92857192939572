import { ModalTitle } from "../../../Cargo/Text/Text";
import { AddContact } from "./AddContact";
import { AddContactModalProps } from "./useAddContactModal";

export function AddContactModal(props: AddContactModalProps) {
  return (
    <>
      <ModalTitle>Add another contact</ModalTitle>

      <AddContact
        onAddNewContact={props.onAddNewContact}
        onCancel={props.onCancel}
        savedLocationId={props.savedLocationId}
      />
    </>
  );
}
