import Dropdown from "../../../Cargo/Controls/Dropdown";
import { SortQuotesOptions } from "./SortQuotesOptions";

interface SortQuotesProps {
  sortBy: SortQuotesOptions;
  setSortBy: (_: SortQuotesOptions) => void;
}

export function SortQuotes(props: SortQuotesProps) {
  const { sortBy, setSortBy } = props;
  return (
    <Dropdown
      selectedItem={sortBy}
      onOptionSelected={setSortBy}
      options={[
        {
          item: SortQuotesOptions.SortByLowestPrice,
          description: "Lowest Price",
        },
        {
          item: SortQuotesOptions.SortByFastestTime,
          description: "Fastest Transit Time",
        },
      ]}
      errorMessage={undefined}
      placeholder={"Sort quotes by"}
      width={240}
    />
  );
}
