import { useReactionOverlay } from "../Hooks/useReactionOverlay";
import { useModalsSlice } from "../Modal/modalsSlice";
import LoadingShipment from "../../Features/BookShipment/Components/LoadingShipment";
import { ReactNode } from "react";
import { LoadingBackdrop } from "./LoadingBackdrop";

interface ModalsProviderProps {
  children: ReactNode;
}

export function ModalsProvider(props: ModalsProviderProps) {
  const { renderModals, showLoading } = useModalsSlice();
  const { renderReactions } = useReactionOverlay();

  return (
    <>
      {showLoading && (
        <LoadingBackdrop>
          <LoadingShipment />
        </LoadingBackdrop>
      )}
      {renderModals()}
      {renderReactions()}
      {props.children}
    </>
  );
}
