import { ErrorMessageType } from "../../../Cargo/Validation";
import PasswordStrengthBubble from "../Components/QuestionBubbles/PasswordStrengthBubble";
import { useAuthentication } from "../Slices/authenticationSlice";
import {
  errorMessageForContactName,
  errorMessageForEmail,
  errorMessageForPhoneNumber,
} from "../../Contacts/Validators/errorMessagesForContact";
import NonCorporateEmailDate from "../../../GeneratedFixtures/NonCorporateEmailData.json";
import { useGoogleAdsConversions } from "../../../Hooks/useGoogleAdsConversions";
import { useLinkedInAdsConversions } from "../../../Hooks/useLinkedInAdsConversions";
import auth0 from "auth0-js";
import { Currency } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { useUsersApi2 } from "../../../apis";
import { auth0Creds } from "../../../environment";
import { useMicrosoftAdsConversions } from "../../../Hooks/useMicrosoftAdsConversions";
import { CreateAccountError } from "../../../Apis/UsersApi2";

interface CreateAccountErrors {
  email: ErrorMessageType;
  password: ErrorMessageType;
  name: ErrorMessageType;
  companyName: ErrorMessageType;
  generateErrorMessage: ErrorMessageType;
}

export interface CreateAccountErrorMessages {
  accountType: ErrorMessageType;
  email: ErrorMessageType;
  password: ErrorMessageType;
  name: ErrorMessageType;
  companyName: ErrorMessageType;
  phoneNumber: ErrorMessageType;
  shipmentFrequency: ErrorMessageType;
  companyCurrency: ErrorMessageType;
}

export function isEmailAddressProfessional(email: string): boolean {
  // TODO: Get this list from the server so it's in one place
  return !NonCorporateEmailDate.some((domain) => email.includes(domain));
}

export enum AccountType {
  Personal = "personal",
  Business = "business",
  Broker = "broker",
}

function useCreateAccount(
  prefilledEmail?: string | undefined,
  prefilledName?: string | undefined,
  prefilledCode?: string | undefined,
) {
  const [email, _setEmail] = useState(prefilledEmail ?? "");
  const [accountType, setAccountType] = useState<AccountType>();
  const [password, _setPassword] = useState("");
  const [name, setName] = useState(prefilledName ?? "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyCurrency, setCompanyCurrency] = useState<
    Currency | undefined
  >();
  const [phoneNumberExtension, setPhoneNumberExtension] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [shipmentFrequency, setShipmentFrequency] = useState<
    undefined | string
  >(undefined);
  const [additionalErrorMessages, setAdditionalErrorMessages] = useState<
    Partial<CreateAccountErrors>
  >({});
  const [cookies] = useCookies([
    "utmSource",
    "utmMedium",
    "utmCampaign",
    "utmTerm",
    "gclid",
  ]);
  const { utmSource, utmMedium, utmCampaign, utmTerm, gclid } = cookies;
  const { onAccountCreated: onAccountCreatedGoogle } =
    useGoogleAdsConversions();
  const { onAccountCreated: onAccountCreatedLinkedIn } =
    useLinkedInAdsConversions();
  const { onAccountCreated: onAccountCreatedMicrosoft } =
    useMicrosoftAdsConversions();

  const [generalErrorMessage, setGeneralErrorMessage] =
    useState<ErrorMessageType>();
  const [createAccountInProgress, setCreateAccountInProgress] = useState(false);
  const [checkEmailInProgress, setCheckEmailInProgress] = useState(false);
  const usersApi = useUsersApi2();

  function errorMessageForEmailInCreateAccount(email: string) {
    const baseError = errorMessageForEmail(email);

    if (baseError !== undefined) {
      return baseError;
    }

    return undefined;
  }

  function isCompanyNameObviouslyWrong(companyName: string) {
    const obviouslyWrong = ["na", "none", "n/a", "not applicable"];

    if (obviouslyWrong.includes(companyName.toLowerCase())) return true;

    return false;
  }

  function errorMessageForCompanyName(companyName: string) {
    if (accountType == AccountType.Personal) {
      // If it's personal, then we will just use their name as the company name
      // Otherwise we have found they just enter something like 'Non business'
      // which is confusing
      return undefined;
    }
    if (companyName.length === 0) {
      return "Required";
    }

    if (isCompanyNameObviouslyWrong(companyName)) {
      return "Please enter the correct name";
    }

    return undefined;
  }

  function isPotentiallyLogisticsCompany(companyName: string): boolean {
    const lowerCaseCompanyName = companyName.toLowerCase();
    if (lowerCaseCompanyName.includes("logistics")) {
      return true;
    }

    if (lowerCaseCompanyName.includes("freight")) {
      return true;
    }

    return false;
  }

  function emitGoogleAnalyticsEvent() {
    // Only emit a conversion event for serious shippers
    const isProfessionalEmail = isEmailAddressProfessional(email);
    const isLogisticsCompany = isPotentiallyLogisticsCompany(companyName);

    if (
      shipmentFrequency !== "SingleShipment" &&
      isProfessionalEmail &&
      !isLogisticsCompany
    ) {
      onAccountCreatedGoogle(shipmentFrequency, email, phoneNumber);
      onAccountCreatedMicrosoft(shipmentFrequency, email, phoneNumber);
      onAccountCreatedLinkedIn();
    }
  }

  function errorMessageForShipmentFrequency() {
    if (accountType === AccountType.Personal) {
      // This will always be SingleShipment - they're not asked to select it
      return undefined;
    }

    return shipmentFrequency !== undefined ? undefined : "Required";
  }

  function errorMessageForPassword(password: string) {
    if (password.length === 0) {
      return `Required`;
    }

    if (password.length < 8) {
      return `Must be 8 characters (${8 - password.length} to add)`;
    }

    return undefined;
  }

  const baseErrorMessages: CreateAccountErrorMessages = {
    accountType: accountType === undefined ? "Required" : undefined,
    email: errorMessageForEmailInCreateAccount(email),
    password: errorMessageForPassword(password),
    name: errorMessageForContactName(name),
    companyName: errorMessageForCompanyName(companyName),
    phoneNumber: errorMessageForPhoneNumber(phoneNumber),
    shipmentFrequency: errorMessageForShipmentFrequency(),
    companyCurrency: companyCurrency !== undefined ? undefined : "Required",
  };

  const isValid =
    baseErrorMessages.email === undefined &&
    accountType !== undefined &&
    baseErrorMessages.password === undefined &&
    baseErrorMessages.name === undefined &&
    baseErrorMessages.phoneNumber === undefined &&
    baseErrorMessages.companyName === undefined &&
    baseErrorMessages.shipmentFrequency === undefined &&
    baseErrorMessages.companyCurrency === undefined;

  function shallowMerge(
    obj1: CreateAccountErrorMessages,
    obj2: Partial<CreateAccountErrors>,
  ): CreateAccountErrorMessages {
    const result = { ...obj1 };

    for (const key in obj2) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (obj2[key] !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[key] = obj2[key];
      }
    }

    return result;
  }

  const errorMessages = shallowMerge(
    baseErrorMessages,
    additionalErrorMessages,
  );

  const { authenticate } = useAuthentication();

  async function signIn(email: string, password: string) {
    const auth = new auth0.WebAuth({
      domain: auth0Creds().domain,
      clientID: auth0Creds().clientId,
      scope: "openid profile email",
    });

    return new Promise<void>(function (resolve, reject) {
      auth.client.login(
        {
          username: email,
          password: password,
          realm: "Username-Password-Authentication",
          audience: auth0Creds().audience,
        },
        async function (error, result) {
          if (error) {
            console.error("auth0 error", { error, result });
            setGeneralErrorMessage(error.description);
            reject(new Error(`Login Problem : ${error.description}`));
          } else {
            setGeneralErrorMessage(undefined);

            const accessToken = result.accessToken;
            const idToken = result.idToken;

            emitGoogleAnalyticsEvent();

            try {
              await authenticate(accessToken, idToken);
              resolve();
            } catch {
              setGeneralErrorMessage("Something went wrong");
              reject(new Error("Something went wrong"));
            }
          }
        },
      );
    });
  }

  async function isEmailUsed(): Promise<boolean | undefined> {
    setCheckEmailInProgress(true);
    const response = await usersApi.isEmailUsed(email);
    if (response === undefined) {
      setAdditionalErrorMessages((prev) => ({
        ...prev,
        email:
          "Something went wrong validating your email address, please try another one.",
      }));
    }
    if (response) {
      setAdditionalErrorMessages((prev) => ({
        ...prev,
        email: (
          <>
            {"Email address taken. "}
            <Link
              to={"sign-in"}
              style={{
                color: "#ea554c",
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
            >
              Sign in instead
            </Link>
            ?
          </>
        ),
      }));
    }
    setCheckEmailInProgress(false);
    return response;
  }

  async function createAccount(): Promise<boolean> {
    setAdditionalErrorMessages({});
    setCreateAccountInProgress(true);
    setGeneralErrorMessage(undefined);

    if (companyCurrency === undefined) {
      throw new Error(
        "companyCurrency is undefined. We should never get here.",
      );
    }

    if (accountType === undefined) {
      throw new Error("accountType is undefined. We should never get here.");
    }

    const referralCode = localStorage.getItem("referralCode") ?? undefined;

    function getCompanyName() {
      if (accountType === AccountType.Personal) {
        return name;
      } else {
        return companyName;
      }
    }

    function getShipmentFrequency() {
      if (accountType === AccountType.Personal) {
        return "SingleShipment";
      } else {
        if (shipmentFrequency === undefined) {
          throw new Error(
            "shipmentFrequency is undefined. We should never get here.",
          );
        }

        return shipmentFrequency;
      }
    }

    try {
      await usersApi.postUsersCreateAccount({
        isBrokerSelected: accountType === AccountType.Broker,
        isPersonalSelected: accountType == AccountType.Personal,
        email,
        password,
        name,
        companyName: getCompanyName(),
        phoneNumber,
        phoneNumberExtension,
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        gclid,
        referralCode,
        prefilledCode,
        companyCurrency,
        shipmentFrequency: getShipmentFrequency(),
      });
    } catch (error: unknown) {
      if (error instanceof CreateAccountError) {
        console.error(`#### error creating account`, { error });
        const e = error.data;
        if (e.message.toLowerCase().includes("password")) {
          setAdditionalErrorMessages({
            password: (
              <>
                {e.message}
                <PasswordStrengthBubble />
              </>
            ),
          });
          setGeneralErrorMessage(e.message);
        } else if (e.message.toLowerCase().includes("email")) {
          setAdditionalErrorMessages({
            email: <>{e.message}</>,
          });
          setGeneralErrorMessage(e.message);
        } else {
          console.error("Unknown error in createAccount", { e });
          setGeneralErrorMessage(e.message);
        }
      }

      setCreateAccountInProgress(false);
      return false;
    }

    await signIn(email, password);
    setCreateAccountInProgress(false);
    return true;
  }

  function setEmail(newValue: string) {
    _setEmail(newValue);
    setAdditionalErrorMessages({
      ...additionalErrorMessages,
      email: undefined,
    });
  }

  function setPassword(newValue: string) {
    _setPassword(newValue);
    setAdditionalErrorMessages({
      ...additionalErrorMessages,
      password: undefined,
    });
  }

  return {
    accountType,
    setAccountType,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    phoneNumber,
    setPhoneNumber,
    phoneNumberExtension,
    setPhoneNumberExtension,
    companyCurrency,
    setCompanyCurrency,
    companyName,
    setCompanyName,
    shipmentFrequency,
    setShipmentFrequency,
    generalErrorMessage,
    createAccount,
    createAccountInProgress,
    isValid,
    errorMessages,
    isEmailUsed,
    checkEmailInProgress,
  };
}

export default useCreateAccount;
