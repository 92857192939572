import { isProduction } from "../environment";
import { calculateConversionValueFromShipmentFrequency } from "./calculateConversionValueFromShipmentFrequency";

export function useMicrosoftAdsConversions() {
  function generateConversionFunction() {
    return function (
      shipmentFrequency: string | undefined,
      email: string,
      phoneNumber: string,
    ) {
      // Event snippet for Request quote conversion page
      if (!isProduction()) {
        return;
      }

      try {
        // @ts-expect-error uetq is not defined in the window object
        window.uetq = window.uetq || [];

        // Set user data
        // @ts-expect-error uetq is not defined in the window object
        window.uetq.push("set", {
          pid: {
            em: email,
            ph: phoneNumber,
          },
        });

        // Track conversion event
        // @ts-expect-error uetq is not defined in the window object
        window.uetq.push("event", "request_quote", {
          revenue_value:
            calculateConversionValueFromShipmentFrequency(shipmentFrequency),
          currency: "CAD",
        });
      } catch (e) {
        console.error("Error in microsoft tag", { e });
      }
    };
  }

  const onAccountCreated = generateConversionFunction();

  return { onAccountCreated };
}
