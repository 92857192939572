import CarriersData from "../GeneratedFixtures/CarriersData.json";
import _ from "lodash";

export function displayNameForCarrier(identifier: string | undefined) {
  if (identifier === undefined) {
    throw new Error("Missing identifier");
  }

  const carrier = CarriersData.find((c) => c.carrierIdentifier === identifier);

  if (carrier === undefined) {
    throw new Error(`Missing carrier : ${identifier}`);
  }

  return carrier.carrierDisplayName.replaceAll("&amp;", "&");
}

export function getFirstServiceForCarrier(carrierIdentifier: string) {
  const si = CarriersData.find(
    (c) => c.carrierIdentifier === carrierIdentifier,
  )?.serviceIdentifier;

  if (si === undefined) {
    throw new Error("Unknown carrierIdentifier");
  }

  return si;
}

export function getCarriers() {
  const carrierIdentifiers = CarriersData.map((c) => c.carrierIdentifier);
  const uniqueCarrierIdentifiers = _.uniq(carrierIdentifiers);
  return uniqueCarrierIdentifiers.map((ci) => ({
    identifier: ci,
    displayName: displayNameForCarrier(ci),
  }));
}

export function getServices() {
  return CarriersData;
}
