import Colors from "../Colors";
import CreditCardLogo from "../Icons/CreditCardLogo";
import FirstToMatch from "../Layout/FirstToMatch";
import { FirstToMatchEnum } from "../Layout/FirstToMatchEnum";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import { CreditCard } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import { ReactElement } from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import { DropdownElement } from "./DropdownElement";

const CardNumber = styled.div`
  font-size: 16px;
  font-weight: var(--nhu-font-weight-regular);
`;

const CardExpiry = styled.div`
  font-size: 16px;
  font-weight: var(--nhu-font-weight-light);
  color: ${Colors.LightText};
`;

const CardExpired = styled.div`
  font-size: 16px;
  font-weight: var(--nhu-font-weight-light);
  color: ${Colors.Red};
`;

interface CreditCardsDropdownProps {
  cards: Array<CreditCard> | undefined;
  selectedStripePaymentMethodId: string | undefined;
  onOptionSelected: (_: string) => void;
  width?: number;
}

function CreditCardsDropdown(props: CreditCardsDropdownProps): ReactElement {
  const options = props.cards?.map((cardInfo) => {
    // Year 3000 bug for the win ;)
    const expiryMonth = parseInt(cardInfo.expiry.split("/")[0]);
    const expiryYear = 2000 + parseInt(cardInfo.expiry.split("/")[1]);
    const now = moment();
    const currentYear = now.year();
    const currentMonth = now.month() + 1; // moment is 0-indexed

    function expired() {
      if (currentYear > expiryYear) {
        return true;
      }

      if (currentYear === expiryYear && currentMonth > expiryMonth) {
        return true;
      }

      return false;
    }

    function aboutToExpire() {
      if (currentYear === expiryYear && currentMonth === expiryMonth) {
        return true;
      }

      return false;
    }

    return {
      item: cardInfo.stripePaymentMethodId,
      description: (
        <DropdownElement>
          <HorizontalStack
            width={props.width ? props.width - 20 + "px" : "480px"}
            verticalAlign="top"
          >
            <CreditCardLogo size="regular" brand={cardInfo.brand} />
            <Spacer width={8} />
            <CardNumber>XXXX XXXX XXXX {cardInfo.lastFourDigits}</CardNumber>
            <Spacer width={8} />
            <FirstToMatch
              components={[
                {
                  when: aboutToExpire(),
                  show: (
                    <CardExpired>
                      Too close to expiry: {cardInfo.expiry}
                    </CardExpired>
                  ),
                },
                {
                  when: expired(),
                  show: <CardExpired>Expired: {cardInfo.expiry}</CardExpired>,
                },
                {
                  when: FirstToMatchEnum.Default,
                  show: <CardExpiry>Expiry: {cardInfo.expiry}</CardExpiry>,
                },
              ]}
            />
          </HorizontalStack>
        </DropdownElement>
      ),
    };
  });

  return (
    <Dropdown
      unselectedDescription="Select a credit card"
      selectedItem={props.selectedStripePaymentMethodId}
      onOptionSelected={props.onOptionSelected}
      options={options}
      errorMessage={undefined}
      width={props.width || 500}
    />
  );
}
export default CreditCardsDropdown;
