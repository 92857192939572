import { SavedCommodity } from "@freightsimple/generated-dashboard-openapi-client";
import { useModal } from "../../../Cargo/Modal/useModal";
import { AddModifySavedCommodityModal } from "./AddModifySavedCommodityModal";

export function useAddModifySavedCommodityModal() {
  const showModal = useModal<SavedCommodity>();
  function show(savedCommodityToModify?: SavedCommodity) {
    const p = new Promise<SavedCommodity | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <AddModifySavedCommodityModal
              savedCommodityToModify={savedCommodityToModify}
              onConfirm={done}
              onCancel={function () {
                done();
              }}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed);
        },
        {
          preventBackdropClick: true,
        },
      );
    });

    return p;
  }

  return show;
}
