import Animation from "../Animations/Animation";

const CreditCardSuccessAnimation: React.FC = () => {
  return (
    <Animation
      pathToAnimation="/animations/credit-card-success.json"
      lottieFilesUrl="https://lottiefiles.com/8311-loading"
      loop={true}
      style={{ width: "200px" }}
    />
  );
};
export default CreditCardSuccessAnimation;
