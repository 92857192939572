import {
  QuestionBubbleHeader,
  QuestionBubbleLabel,
  SmallQuestionBubble,
} from "../../../../../Cargo/Controls/QuestionBubble";

export function DangerousGoodsQuestionBubble() {
  return (
    <SmallQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>Dangerous</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            <div>
              If your freight is dangerous/hazardous the carrier needs to know
              about it. Please enter details.
            </div>
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/freight-101/dangerous-goods"
    />
  );
}
