import { FilterShipmentsDescription } from "./FilterShipmentsModal";

export function emptyFilterShipmentsDescription(): FilterShipmentsDescription {
  return {
    proNumber: undefined,
    carrierIdentifier: undefined,
    minPriceDollars: undefined,
    maxPriceDollars: undefined,
    shipmentState: undefined,
    pickupReferenceNumber: undefined,
    pickupBusinessName: undefined,
    pickupPostalCode: undefined,
    pickupStateOrProvinceCode: undefined,
    deliveryReferenceNumber: undefined,
    deliveryBusinessName: undefined,
    deliveryPostalCode: undefined,
    deliveryStateOrProvinceCode: undefined,
    bookedAtStartRange: undefined,
    bookedAtEndRange: undefined,
    pickupDateStartRange: undefined,
    pickupDateEndRange: undefined,
    deliveredAtStartRange: undefined,
    deliveredAtEndRange: undefined,
    onlyShowFreightClaims: undefined,
  };
}
