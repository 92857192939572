import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import { ShareTrackingModal } from "./ShareTrackingModal";

export function useShareTrackingModal() {
  const showModal = useModal<void>();

  function show(props: { trackingId: UUID }) {
    showModal(
      (done) => {
        return (
          <ShareTrackingModal trackingId={props.trackingId} onDone={done} />
        );
      },
      undefined,
      {
        preventBackdropClick: true,
      },
    );
  }

  return show;
}
