import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";
import { colorForShipmentState } from "../../../Helpers/colorForShipmentState";

import styled from "styled-components";
import {
  groupLinesIntoDates,
  TrackingLine,
} from "../Helpers/groupTrackingLinesIntoDates";
import TrackPiece from "./TrackPiece";

interface TrackingTimelineProps {
  lines: Array<TrackingLine>;
  shipmentState: ShipmentState;
}

interface HeaderRowProps {
  color: string;
  date: string;
  first: boolean;
}

const Header = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 18px;
  color: #cacaca;
  margin-bottom: 8px;
`;

const Time = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 18px;
  color: var(--freightsimple-color-light-text);
  width: 112px;
`;

const Message = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 18px;
  color: var(--freightsimple-color-light-text);
  max-width: 540px;
`;

function HeaderRow(props: HeaderRowProps) {
  return (
    <HorizontalStack verticalAlign="bottom" style={{ height: "60px" }}>
      <TrackPiece
        showCircle={false}
        connectTop={!props.first}
        connectBottom={!props.first}
        color={props.color}
        showArrow={false}
      />
      <Header>{props.date}</Header>
    </HorizontalStack>
  );
}

interface TimeRowProps {
  color: string;
  time: string;
  message: string;
  first: boolean;
  last: boolean;
  showArrows: boolean;
}

function TimeRow(props: TimeRowProps) {
  const rows = Math.ceil(props.message.length / 50);
  return (
    <HorizontalStack verticalAlign="top" style={{ height: 40 * rows + "px" }}>
      <TrackPiece
        showCircle={true}
        connectTop={!props.first}
        connectBottom={!props.last}
        color={props.color}
        showArrow={props.showArrows && props.first}
      />
      <Time>{props.time}</Time>
      <Message>{props.message}</Message>
    </HorizontalStack>
  );
}

function TrackingTimeline(props: TrackingTimelineProps) {
  const groupedLines = groupLinesIntoDates(props.lines.reverse());

  const color = colorForShipmentState(props.shipmentState);

  return (
    <>
      {groupedLines.map((date, index) => {
        const firstDate = index === 0;
        const lastDate = index === groupedLines.length - 1;

        return (
          <>
            <HeaderRow
              color={color}
              date={date.dateDescription}
              first={firstDate}
              key={`tracking-header-${index}`}
            ></HeaderRow>
            {date.lines.map((line, lineIndex) => {
              const lastLine = date.lines.length - 1 === lineIndex;
              return (
                <TimeRow
                  key={`tracking-time-${index}-${lineIndex}`}
                  color={color}
                  time={line.timeDescription}
                  message={line.message}
                  first={firstDate && lineIndex == 0}
                  last={lastDate && lastLine}
                  showArrows={props.shipmentState === ShipmentState.InTransit}
                ></TimeRow>
              );
            })}
          </>
        );
      })}
    </>
  );
}
export default TrackingTimeline;
