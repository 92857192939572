import LinkButton from "../../../Cargo/Controls/LinkButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading2, Legalese } from "../../../Cargo/Text/Text";

import styled from "styled-components";
import {
  descriptionForSortShipmentOption,
  SortShipmentOptions,
} from "./useSortShipmentsModal";

const SectionHeader = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  margin-top: 16px;
`;

const SortButton = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  color: var(--freightsimple-color-blue);
  font-size: 20px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface SortShipmentsModalProps {
  onSelect: (selectedSortOption: SortShipmentOptions) => void;
  currentSortOption: SortShipmentOptions;
  onCancel: () => void;
}

interface SortRowProps {
  sortOption: SortShipmentOptions;
  currentSortOption: SortShipmentOptions;
  onClick: (selectedValue: SortShipmentOptions) => void;
}

function SortRow(props: SortRowProps) {
  return (
    <HorizontalStack>
      <SortButton
        onClick={function () {
          props.onClick(props.sortOption);
        }}
      >
        {descriptionForSortShipmentOption(props.sortOption)}
      </SortButton>
    </HorizontalStack>
  );
}

export function SortShipmentsModal(props: SortShipmentsModalProps) {
  function onClick(selectedSortOption: SortShipmentOptions) {
    props.onSelect(selectedSortOption);
  }
  return (
    <Stack align="center" style={{ padding: "16px", width: "400px" }}>
      <Heading2>Sort Shipments</Heading2>
      <Legalese>Select an option to sort shipments by</Legalese>
      <Spacer height={32} />
      <SectionHeader>Pickup</SectionHeader>
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.PickupBusinessName}
        onClick={onClick}
      />
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.PickupReferenceNumber}
        onClick={onClick}
      />

      <SectionHeader>Delivery</SectionHeader>
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.DeliveryBusinessName}
        onClick={onClick}
      />
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.DeliveryReferenceNumber}
        onClick={onClick}
      />

      <SectionHeader>Dates</SectionHeader>
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.BookingDate}
        onClick={onClick}
      />
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.PickupDate}
        onClick={onClick}
      />
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.EstimatedDeliveryDate}
        onClick={onClick}
      />
      <SortRow
        currentSortOption={props.currentSortOption}
        sortOption={SortShipmentOptions.DeliveryDate}
        onClick={onClick}
      />
      <Spacer height={32} />
      <LinkButton onClick={props.onCancel} regularWeight={true}>
        Cancel
      </LinkButton>
    </Stack>
  );
}
