import { ReactNode } from "react";

// Just an empty div
interface DivProps {
  children: ReactNode;
}
function Div(props: DivProps) {
  return <div>{props.children}</div>;
}
export default Div;
