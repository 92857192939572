import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import { useAuthentication } from "../../../Authentication/Slices/authenticationSlice";
import { PotentialCoupon } from "../../../Coupons/Components/PotentialCoupon";
import { NavBarInvoiceWarning } from "../../../Invoices/Components/NavBarInvoiceWarning";
import UserDetailsDropdown from "./UserDetailsDropdown";

function SignedInRightNavMenu() {
  const { user } = useAuthentication();

  const userName = user?.name ?? "";
  const companyName = user?.companyName ?? "";

  return (
    <>
      <HorizontalStack>
        <PotentialCoupon />
        <NavBarInvoiceWarning />
        <UserDetailsDropdown
          name={userName}
          company={companyName}
        ></UserDetailsDropdown>
      </HorizontalStack>
    </>
  );
}
export default SignedInRightNavMenu;
