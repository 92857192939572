import { FreightClaim } from "@freightsimple/generated-dashboard-openapi-client";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import styled from "styled-components";
import Spacer from "../../../Cargo/Layout/Spacer";
import { DocumentsSectionItem } from "./DocumentsSection";
import LinkButton from "../../../Cargo/Controls/LinkButton";

const DocumentDescription = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  color: #545454;
`;
const CreationDate = styled.div`
  font-weight: var(--nhu-font-weight-light);
  color: #858585;
`;

export function FreightClaimRowDocumentsTable({
  documents,
  freightClaim,
}: {
  documents: DocumentsSectionItem[];
  freightClaim: FreightClaim;
}) {
  const claimsDocsIds = [
    ...(freightClaim.otherDocumentFiles ?? []),
    ...(freightClaim.originalInvoiceFiles ?? []),
    ...(freightClaim.photoFiles ?? []),
  ];
  const claimDocuments = documents.filter((doc) =>
    claimsDocsIds.includes(doc.documentId ?? ""),
  );
  return (
    <table>
      <tbody>
        {claimDocuments.map((item, index) => {
          return (
            <tr key={index}>
              <td
                style={{
                  paddingBlock: "4px",
                }}
              >
                <HorizontalStack>
                  <img src="/pdf.png" width={18} />
                  <Spacer width={8} />
                  <DocumentDescription>
                    {item.documentDescription}
                  </DocumentDescription>
                  <Spacer width={32} />
                </HorizontalStack>
              </td>
              <td>
                <CreationDate>
                  {item.existsYet
                    ? item.creationDate?.format("Do MMMM YYYY")
                    : "Not yet available"}
                </CreationDate>
              </td>

              <td>
                {item.existsYet && (
                  <LinkButton
                    regularWeight={true}
                    onClick={item.onDownloadDocument}
                    style={{
                      marginLeft: "40px",
                    }}
                  >
                    Download Document
                  </LinkButton>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
