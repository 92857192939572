import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";
import { ParsedQuery } from "query-string";
import { emptyFilterShipmentsDescription } from "./emptyFilterShipmentsDescription";
import { SortShipmentOptions } from "./useSortShipmentsModal";

export function filterDescriptionFromQueryParams(query: ParsedQuery) {
  const filter = emptyFilterShipmentsDescription();
  let sort = SortShipmentOptions.PickupDate;

  if (query.proNumber) {
    filter.proNumber = query.proNumber as string;
  }

  if (query.carrierIdentifier) {
    filter.carrierIdentifier = query.carrierIdentifier as string;
  }

  if (query.minPriceDollars) {
    filter.minPriceDollars = parseFloat(query.minPriceDollars as string);
  }

  if (query.maxPriceDollars) {
    filter.maxPriceDollars = parseFloat(query.maxPriceDollars as string);
  }

  if (query.shipmentState) {
    filter.shipmentState = query.shipmentState as ShipmentState;
  }

  if (query.pickupReferenceNumber) {
    filter.pickupReferenceNumber = query.pickupReferenceNumber as string;
  }

  if (query.pickupBusinessName) {
    filter.pickupBusinessName = query.pickupBusinessName as string;
  }

  if (query.pickupPostalCode) {
    filter.pickupPostalCode = query.pickupPostalCode as string;
  }

  if (query.pickupStateOrProvinceCode) {
    filter.pickupStateOrProvinceCode =
      query.pickupStateOrProvinceCode as string;
  }

  if (query.deliveryReferenceNumber) {
    filter.deliveryReferenceNumber = query.deliveryReferenceNumber as string;
  }

  if (query.deliveryBusinessName) {
    filter.deliveryBusinessName = query.deliveryBusinessName as string;
  }

  if (query.deliveryPostalCode) {
    filter.deliveryPostalCode = query.deliveryPostalCode as string;
  }

  if (query.deliveryStateOrProvinceCode) {
    filter.deliveryStateOrProvinceCode =
      query.deliveryStateOrProvinceCode as string;
  }

  if (query.bookedAtStartRange) {
    filter.bookedAtStartRange = query.bookedAtStartRange as string;
  }

  if (query.bookedAtEndRange) {
    filter.bookedAtEndRange = query.bookedAtEndRange as string;
  }

  if (query.pickupDateStartRange) {
    filter.pickupDateStartRange = query.pickupDateStartRange as string;
  }

  if (query.pickupDateEndRange) {
    filter.pickupDateEndRange = query.pickupDateEndRange as string;
  }

  if (query.deliveredAtStartRange) {
    filter.deliveredAtStartRange = query.deliveredAtStartRange as string;
  }

  if (query.deliveredAtEndRange) {
    filter.deliveredAtEndRange = query.deliveredAtEndRange as string;
  }

  if (query.sort) {
    sort = query.sort as unknown as SortShipmentOptions;
  }

  if (query.onlyShowFreightClaims) {
    filter.onlyShowFreightClaims = query.onlyShowFreightClaims === "true";
  }

  return { filter, sort };
}
