import Button from "../../../Cargo/Controls/Button";
import CreditCardsDropdown from "../../../Cargo/Controls/CreditCardsDropdown";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Microcopy, ModalTitle } from "../../../Cargo/Text/Text";
import { CreditCard } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface ChangeCreditCardModalProps {
  selectedStripePaymentMethodId: string | undefined;
  cards: Array<CreditCard>;
  onDone: (newStripePaymentMethodId: string) => void;
}

export function ChangeCreditCardModal(props: ChangeCreditCardModalProps) {
  const [selectedStripePaymentMethodId, setSelectedStripePaymentMethodId] =
    useState(props.selectedStripePaymentMethodId);

  return (
    <div style={{ width: "640px" }}>
      <ModalTitle>Select Credit Card</ModalTitle>
      <Microcopy>Select a credit card for this shipment</Microcopy>
      <Spacer height={8} />
      <CreditCardsDropdown
        cards={props.cards}
        selectedStripePaymentMethodId={selectedStripePaymentMethodId}
        onOptionSelected={setSelectedStripePaymentMethodId}
      />
      <Spacer height={48} />
      <HorizontalStack width="100%" align="spread">
        <Button
          disabled={selectedStripePaymentMethodId === undefined}
          onClick={function () {
            if (selectedStripePaymentMethodId !== undefined) {
              props.onDone(selectedStripePaymentMethodId);
            }
          }}
        >
          Done
        </Button>
      </HorizontalStack>
    </div>
  );
}
