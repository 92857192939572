import { useNavigate } from "react-router-dom";

import { useRandomSigninBackgroundImage } from "../../Helpers/randomBackgroundImage";
import { SignInForm } from "./Components/SignInForm";
import { AuthenticationScreenContainer } from "./AuthenticationScreenContainer";

function SignInScreen() {
  const navigate = useNavigate();
  const backgroundImage = useRandomSigninBackgroundImage();

  function onCreateAccountInstead() {
    navigate("/create-account");
  }

  function onForgotPassword() {
    navigate("/request-reset-password");
  }

  return (
    <AuthenticationScreenContainer image={backgroundImage}>
      <SignInForm
        onCreateAccountInstead={onCreateAccountInstead}
        onForgotPassword={onForgotPassword}
        redirectAfterSignin={true}
        onSuccess={function () {}}
      />
    </AuthenticationScreenContainer>
  );
}
export default SignInScreen;
