import { isProduction } from "../environment";
import { calculateConversionValueFromShipmentFrequency } from "./calculateConversionValueFromShipmentFrequency";

export function useGoogleAdsConversions() {
  function generateConversionFunction(tag: string) {
    return function (
      shipmentFrequency: string | undefined,
      email: string,
      phoneNumber: string,
    ) {
      // Event snippet for Request quote conversion page
      if (!isProduction()) {
        return;
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gtag("set", "user_data", {
          email: email,
          phone_number: phoneNumber,
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gtag("event", "conversion", {
          send_to: tag,
          value:
            calculateConversionValueFromShipmentFrequency(shipmentFrequency),
          currency: "CAD",
        });
      } catch (e) {
        console.warn("Error in google tag", { e });
      }
    };
  }

  const onAccountCreated = generateConversionFunction(
    "AW-478855751/gb1rCJLs46cYEMeEq-QB",
  );
  const onShipmentBooked = generateConversionFunction(
    "AW-478855751/PxB3COXt6ukBEMeEq-QB",
  );

  return { onAccountCreated, onShipmentBooked };
}
