import { SmallQuestionBubble } from "../../../../Cargo/Controls/QuestionBubble";

const AdditionalChargesQuestionBubble: React.FC = () => {
  return (
    <SmallQuestionBubble
      description="We
                        require correct information to get accurate quotes,
                        and avoid additional charges during shipping."
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/payments-and-invoices/additional-charges"
    />
  );
};
export default AdditionalChargesQuestionBubble;
