import LinkButton from "../../../../../Cargo/Controls/LinkButton";
import {
  NormalQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../../Cargo/Controls/QuestionBubble";
import { AddLocationLocationType } from "../../../../Locations/Types/locationTypes";
import {
  CountryCode,
  LocationContext,
} from "@freightsimple/generated-dashboard-openapi-client";

interface AddressQuestionBubbleProps {
  displayName?: string;
  context: LocationContext;
  onModify: () => void;
  location: AddLocationLocationType;
}

const AddressQuestionBubble: React.FC<AddressQuestionBubbleProps> = (
  props: AddressQuestionBubbleProps,
) => {
  const { location } = props;
  function zipcodeOrPostalCode() {
    if (location.address.countryCode === CountryCode.UnitedStates) {
      return "zip code";
    }

    if (location.address.countryCode === CountryCode.Canada) {
      return "postal code";
    }

    throw new Error("Unknown country");
  }

  function header() {
    if (props.displayName) {
      const displayName = props.displayName.toLowerCase();
      return `Enter address information for ${displayName}`;
    } else {
      return `Enter address information`;
    }
  }

  const locationString = props.context?.toLowerCase() || "this location";

  return (
    <NormalQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>{header()}</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            Enter contact information for {locationString}. The carrier may need
            to contact this person for various reasons, such as booking an
            appointment or finding the door.
          </QuestionBubbleLabel>
          <QuestionBubbleLabel>
            This quote is only valid for the current {zipcodeOrPostalCode()}. To
            change the {zipcodeOrPostalCode()} and get a new quote,{" "}
            <LinkButton onClick={props.onModify} regularWeight={true}>
              click here
            </LinkButton>
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/pickup-and-delivery-addresses-and-hours"
    />
  );
};
export default AddressQuestionBubble;
