import { CardExpiryElement } from "@stripe/react-stripe-js";
import { StripeCardExpiryElementChangeEvent } from "@stripe/stripe-js";
import Spacer from "../../../Cargo/Layout/Spacer";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { useState } from "react";
import styled from "styled-components";
import { StripeElementOptions } from "./StripeElementOptions";

// Specifying the height of these is important,
// as the error messages can be one or two lines long
const InvalidLabel = styled.div`
  color: #ea554c;
  font-size: 12px;
  margin-top: 4px;
  height: 36px;
`;

interface CreditCardExpirationProps {
  onError: (message: ErrorMessageType) => void;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
}

function CreditCardExpiration(props: CreditCardExpirationProps) {
  const [validated, setValidated] = useState(false);
  const showErrorMessage =
    (props.forceValidation || validated) && props.errorMessage !== undefined;
  return (
    <div>
      <div style={{ width: "164px" }}>
        <CardExpiryElement
          id="expiry"
          options={StripeElementOptions}
          onChange={(event: StripeCardExpiryElementChangeEvent) => {
            props.onError(event.error?.message);
          }}
          onBlur={() => setValidated(true)}
        />
        {showErrorMessage && <InvalidLabel>{props.errorMessage}</InvalidLabel>}
        {!showErrorMessage && <Spacer height={40} />}
      </div>
    </div>
  );
}
export default CreditCardExpiration;
