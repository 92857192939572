import { Placement } from "@popperjs/core";
import { ReactNode } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

interface TooltipProps {
  tooltip: ReactNode;
  children: ReactNode;
  placement?: Placement;
  followCursor?: boolean;
  hideArrow?: boolean;
  id?: string;
  noPadding?: boolean;
  cursor?: "pointer" | "not-allowed";
}

function Tooltip(props: TooltipProps) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: props.placement ?? "right",
    trigger: ["hover", "focus"],
    delayHide: 100,
    interactive: true,
    followCursor: props.followCursor ?? false,
  });

  function computeTooltipContainerStyle() {
    if (props.noPadding === true) {
      return { padding: "0" };
    }
    return {};
  }

  return (
    <span id={props.id}>
      <span
        ref={setTriggerRef}
        style={{
          pointerEvents: "auto",
          cursor: props.cursor ? props.cursor : "pointer",
        }}
      >
        {props.children}
      </span>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
            style: computeTooltipContainerStyle(),
          })}
        >
          {!(props.hideArrow ?? false) && (
            <div {...getArrowProps({ className: "tooltip-arrow" })} />
          )}
          {props.tooltip}
        </div>
      )}
    </span>
  );
}

export default Tooltip;
