import axios from "axios";
import { UUID } from "../Cargo/Types/types";

export class PhotosApi {
  baseUrl: string;
  accessToken: string;

  constructor(baseUrl: string, accessToken: string) {
    this.baseUrl = baseUrl;
    this.accessToken = accessToken;
  }

  async uploadPhotoWithTrackingId(trackingId: UUID, file: File): Promise<void> {
    const options = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      validateStatus: () => true,
    };

    const formData = new FormData();
    formData.append(`file0`, file, file.name);

    const url = `${this.baseUrl}/photos/upload-photo-with-tracking-id?trackingId=${trackingId}`;

    await axios.post(url, formData, options);
  }
}
