import FilterPill from "../../../Cargo/Controls/FilterPill";
import { displayNameForCarrier } from "../../../Data/Carriers";
import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";
import {
  formatPostalCode,
  startsWithNumber,
} from "../../../Helpers/formatPostalCode";
import {
  fullStateOrProvinceName,
  isCanadianProvince,
} from "../../../Helpers/fullStateOrProvinceName";
import moment from "moment";
import { FilterShipmentsDescription } from "../Modals/FilterShipmentsModal";

interface FilterPillsProps {
  filter: FilterShipmentsDescription;
  setFilter: (newfilter: FilterShipmentsDescription) => void;
}

function descriptionForState(state: ShipmentState) {
  if (state === ShipmentState.BookingConfirmed) {
    return "Booked";
  }

  if (state === ShipmentState.InTransit) {
    return "In Transit";
  }

  if (state === ShipmentState.Delivered) {
    return "Delivered";
  }

  if (state === ShipmentState.Cancelled) {
    return "Cancelled";
  }

  if (state === ShipmentState.Lost) {
    return "Lost";
  }

  return "Other";
}

export function FilterPills(props: FilterPillsProps) {
  const pills = [];
  const filter = props.filter;
  const setFilter = props.setFilter;

  if (filter.proNumber !== undefined) {
    pills.push(
      <FilterPill
        key="pro-number"
        title="PRO Number"
        description={filter.proNumber}
        onDelete={function () {
          setFilter({
            ...filter,
            proNumber: undefined,
          });
        }}
      />,
    );
  }

  if (filter.carrierIdentifier !== undefined) {
    pills.push(
      <FilterPill
        key="carrier"
        title="Carrier"
        description={displayNameForCarrier(filter.carrierIdentifier)}
        onDelete={function () {
          setFilter({
            ...filter,
            carrierIdentifier: undefined,
          });
        }}
      />,
    );
  }

  if (filter.minPriceDollars !== undefined) {
    pills.push(
      <FilterPill
        key="min-price"
        title="Min Price"
        description={`$${filter.minPriceDollars}`}
        onDelete={function () {
          setFilter({
            ...filter,
            minPriceDollars: undefined,
          });
        }}
      />,
    );
  }

  if (filter.maxPriceDollars !== undefined) {
    pills.push(
      <FilterPill
        key="max-price"
        title="Max Price"
        description={`$${filter.maxPriceDollars}`}
        onDelete={function () {
          setFilter({
            ...filter,
            maxPriceDollars: undefined,
          });
        }}
      />,
    );
  }

  if (filter.shipmentState !== undefined) {
    pills.push(
      <FilterPill
        key="shipment-state"
        title="States"
        description={descriptionForState(filter.shipmentState)}
        onDelete={function () {
          setFilter({
            ...filter,
            shipmentState: undefined,
          });
        }}
      />,
    );
  }

  if (filter.pickupReferenceNumber !== undefined) {
    pills.push(
      <FilterPill
        key="pickup-reference"
        title="Pickup Reference Number"
        description={filter.pickupReferenceNumber}
        onDelete={function () {
          setFilter({
            ...filter,
            pickupReferenceNumber: undefined,
          });
        }}
      />,
    );
  }

  if (filter.pickupBusinessName !== undefined) {
    pills.push(
      <FilterPill
        key="pickup-business"
        title="Pickup Business Name"
        description={filter.pickupBusinessName}
        onDelete={function () {
          setFilter({
            ...filter,
            pickupBusinessName: undefined,
          });
        }}
      />,
    );
  }

  if (filter.pickupPostalCode !== undefined) {
    pills.push(
      <FilterPill
        key="pickup-postal"
        title={
          startsWithNumber(filter.pickupPostalCode)
            ? "Pickup Zipcode"
            : "Pickup Postal Code"
        }
        description={formatPostalCode(filter.pickupPostalCode)}
        onDelete={function () {
          setFilter({
            ...filter,
            pickupPostalCode: undefined,
          });
        }}
      />,
    );
  }

  if (filter.pickupStateOrProvinceCode !== undefined) {
    pills.push(
      <FilterPill
        key="pickup-state-province"
        title={
          isCanadianProvince(filter.pickupStateOrProvinceCode)
            ? "Pickup Province"
            : "Pickup State"
        }
        description={fullStateOrProvinceName(filter.pickupStateOrProvinceCode)}
        onDelete={function () {
          setFilter({
            ...filter,
            pickupStateOrProvinceCode: undefined,
          });
        }}
      />,
    );
  }

  if (filter.deliveryReferenceNumber !== undefined) {
    pills.push(
      <FilterPill
        key="delivery-reference"
        title="Delivery Reference Number"
        description={filter.deliveryReferenceNumber}
        onDelete={function () {
          setFilter({
            ...filter,
            deliveryReferenceNumber: undefined,
          });
        }}
      />,
    );
  }

  if (filter.deliveryBusinessName !== undefined) {
    pills.push(
      <FilterPill
        key="delivery-business"
        title="Delivery Business Name"
        description={filter.deliveryBusinessName}
        onDelete={function () {
          setFilter({
            ...filter,
            deliveryBusinessName: undefined,
          });
        }}
      />,
    );
  }

  if (filter.deliveryPostalCode !== undefined) {
    pills.push(
      <FilterPill
        key="delivery-postal"
        title={
          startsWithNumber(filter.deliveryPostalCode)
            ? "Delivery Zipcode"
            : "Delivery Postal Code"
        }
        description={formatPostalCode(filter.deliveryPostalCode)}
        onDelete={function () {
          setFilter({
            ...filter,
            deliveryPostalCode: undefined,
          });
        }}
      />,
    );
  }

  if (filter.deliveryStateOrProvinceCode !== undefined) {
    pills.push(
      <FilterPill
        key="delivery-state-province"
        title={
          isCanadianProvince(filter.deliveryStateOrProvinceCode)
            ? "Delivery Province"
            : "Delivery State"
        }
        description={fullStateOrProvinceName(
          filter.deliveryStateOrProvinceCode,
        )}
        onDelete={function () {
          setFilter({
            ...filter,
            deliveryStateOrProvinceCode: undefined,
          });
        }}
      />,
    );
  }

  if (filter.bookedAtStartRange !== undefined) {
    pills.push(
      <FilterPill
        key="booked-start"
        title="Booked From"
        description={moment(filter.bookedAtStartRange).format("Do MMM YYYY")}
        onDelete={function () {
          setFilter({
            ...filter,
            bookedAtStartRange: undefined,
          });
        }}
      />,
    );
  }

  if (filter.bookedAtEndRange !== undefined) {
    pills.push(
      <FilterPill
        key="booked-end"
        title="Booked Until"
        description={moment(filter.bookedAtEndRange).format("Do MMM YYYY")}
        onDelete={function () {
          setFilter({
            ...filter,
            bookedAtEndRange: undefined,
          });
        }}
      />,
    );
  }

  if (filter.pickupDateStartRange !== undefined) {
    pills.push(
      <FilterPill
        key="pickup-date-start"
        title="Picked up from"
        description={moment(filter.pickupDateStartRange).format("Do MMM YYYY")}
        onDelete={function () {
          setFilter({
            ...filter,
            pickupDateStartRange: undefined,
          });
        }}
      />,
    );
  }

  if (filter.pickupDateEndRange !== undefined) {
    pills.push(
      <FilterPill
        key="pickup-date-end"
        title="Picked up until"
        description={moment(filter.pickupDateEndRange).format("Do MMM YYYY")}
        onDelete={function () {
          setFilter({
            ...filter,
            pickupDateEndRange: undefined,
          });
        }}
      />,
    );
  }

  if (filter.deliveredAtStartRange !== undefined) {
    pills.push(
      <FilterPill
        key="delivered-start"
        title="Delivered from"
        description={moment(filter.deliveredAtStartRange).format("Do MMM YYYY")}
        onDelete={function () {
          setFilter({
            ...filter,
            deliveredAtStartRange: undefined,
          });
        }}
      />,
    );
  }

  if (filter.deliveredAtEndRange !== undefined) {
    pills.push(
      <FilterPill
        key="delivered-end"
        title="Delivered until"
        description={moment(filter.deliveredAtEndRange).format("Do MMM YYYY")}
        onDelete={function () {
          setFilter({
            ...filter,
            deliveredAtEndRange: undefined,
          });
        }}
      />,
    );
  }

  if (filter.onlyShowFreightClaims !== undefined) {
    pills.push(
      <FilterPill
        key="freight-claims"
        title="Only Shipments with Freight Claims"
        description=""
        onDelete={function () {
          setFilter({
            ...filter,
            onlyShowFreightClaims: undefined,
          });
        }}
      />,
    );
  }

  return (
    <div style={{ paddingLeft: "8px", marginLeft: "8px", marginTop: "4px" }}>
      {pills}
    </div>
  );
}
