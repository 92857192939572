import { UUID } from "../../../Cargo/Types/types";
import { Dispatch, SetStateAction, useState } from "react";
import { UpdateCustomsDocsSelectModeStage } from "./UpdateCustomsDocsSelectModeStage";
import { UpdateCustomsDocsSubmitStage } from "./UpdateCustomsDocsSubmitStage";
import { CustomsDocsUploadMode } from "../../../Apis/CustomsDocsApi";
import { UpdateCustomsDocsStage } from "./UpdateCustomsDocsStage";

export interface UpdateCustomsDocsModalProps {
  shipmentId: UUID;
  onDone: () => void;
}

export interface UpdateCustomsDocsStageProps
  extends UpdateCustomsDocsModalProps {
  stage: UpdateCustomsDocsStage;
  setStage: Dispatch<SetStateAction<UpdateCustomsDocsStage>>;
  mode: CustomsDocsUploadMode | undefined;
  setMode: Dispatch<SetStateAction<CustomsDocsUploadMode | undefined>>;
}

export function UpdateCustomsDocsModal(props: UpdateCustomsDocsModalProps) {
  const [mode, setMode] = useState<CustomsDocsUploadMode>();
  const [stage, setStage] = useState(UpdateCustomsDocsStage.SELECT_MODE);

  const stageProps: UpdateCustomsDocsStageProps = {
    mode,
    setMode,
    setStage,
    stage,
    ...props,
  };

  return (
    <div style={{ maxWidth: "800px" }}>
      {stage === UpdateCustomsDocsStage.SELECT_MODE && (
        <UpdateCustomsDocsSelectModeStage {...stageProps} />
      )}
      {stage === UpdateCustomsDocsStage.SUBMIT && (
        <UpdateCustomsDocsSubmitStage {...stageProps} />
      )}
    </div>
  );
}
