import { CountryCode } from "@freightsimple/generated-dashboard-openapi-client";
import { MomentHolidays } from "@freightsimple/shared";
import moment from "moment";

export function isWeekend(d: moment.Moment) {
  return d.isoWeekday() === 6 || d.isoWeekday() === 7;
}

export function nextBusinessDay() {
  let d = moment();

  d = d.add(1, "day");

  while (isWeekend(d) || MomentHolidays.isHoliday(d)) {
    d = d.add(1, "day");
  }

  return d;
}

export function nextBusinessDayForCountry(country: CountryCode) {
  let d = moment();

  d = d.add(1, "day");

  while (isWeekend(d) || MomentHolidays.isHolidayForCountry(d, country)) {
    d = d.add(1, "day");
  }

  return d;
}
