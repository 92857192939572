import styled from "styled-components";

export const LoadingBackdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0vh;
  left: 0vw;
  backdrop-filter: blur(6px) brightness(90%);
  z-index: 1000;
`;
