import Colors from "../Colors";
import Icon from "../Icons/Icon";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import { ReactElement, ReactNode, useState } from "react";
import styled from "styled-components";
import zxcvbn from "zxcvbn";
import Input, { InputProps } from "./Input";
import LinkButton from "./LinkButton";
import {
  QuestionBubbleHeader,
  QuestionBubbleLabel,
  SmallQuestionBubble,
} from "./QuestionBubble";

type PasswordInputProps = Omit<InputProps, "type" | "value"> & {
  value: string;
};

const PasswordStrengthDescription = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 14px;
  width: 120px;
`;

export function PasswordQuestionBubble() {
  return (
    <SmallQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>Passwords</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            <div>
              Your password must be at least 8 characters, and must not be a
              common word or phrase
            </div>
            <Spacer height={8} />
            <div>
              We recommend picking something that would be hard to guess and
              using a variety of lowercase letters, capital letters and numbers
            </div>
            <Spacer height={8} />
            <div>We also strongly recommend using a password manager</div>
          </QuestionBubbleLabel>
        </>
      }
    />
  );
}

function PasswordInput(props: PasswordInputProps): ReactElement {
  const [revealed, setRevealed] = useState(false);

  const type = revealed ? "text" : "password";
  const revealIcon = revealed ? "eye" : "eye-slash";

  function toggleRevealed() {
    setRevealed(!revealed);
  }

  //   # Integer from 0-4 (useful for implementing a strength bar)
  //   0 # too guessable: risky password. (guesses < 10^3)
  //   1 # very guessable: protection from throttled online attacks. (guesses < 10^6)
  //   2 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
  //   3 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
  //   4 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
  const score = zxcvbn(props.value).score;

  function descriptionForScore(): ReactNode {
    if (props.value.length < 8) {
      return <></>;
    }
    if (score == 0) {
      return (
        <PasswordStrengthDescription style={{ color: Colors.Red }}>
          Very Weak
        </PasswordStrengthDescription>
      );
    } else if (score == 1) {
      return (
        <PasswordStrengthDescription style={{ color: Colors.Red }}>
          Weak
        </PasswordStrengthDescription>
      );
    } else if (score == 2) {
      return (
        <PasswordStrengthDescription style={{ color: Colors.Blue }}>
          Average Strength
        </PasswordStrengthDescription>
      );
    } else if (score == 3) {
      return (
        <PasswordStrengthDescription style={{ color: Colors.Blue }}>
          Pretty Strong
        </PasswordStrengthDescription>
      );
    } else if (score == 4) {
      return (
        <PasswordStrengthDescription style={{ color: Colors.DarkGreen }}>
          Very Strong!
        </PasswordStrengthDescription>
      );
    } else {
      console.error(`Unknown score : ${score}`);
      return <></>;
    }
  }

  const errorMessage = props.errorMessage;

  return (
    <>
      <HorizontalStack>
        <Input
          type={type}
          {...props}
          errorMessage={errorMessage}
          inset={
            <>
              {props.inset}
              <LinkButton onClick={toggleRevealed}>
                <Icon
                  name={revealIcon}
                  color="#888"
                  size={20}
                  solid={false}
                  style={{
                    marginRight: "4px",
                    marginBottom: "4px",
                  }}
                />
              </LinkButton>
            </>
          }
        />
        <div style={{ marginLeft: "8px", marginBottom: "8px" }}>
          {descriptionForScore()}
        </div>
      </HorizontalStack>
    </>
  );
}
export default PasswordInput;
