import { useWindowSize } from "../Helpers/useWindowSize";

export function useDynamicHeight(minHeight: number, maxHeight: number) {
  const { height } = useWindowSize();

  let elementHeight = minHeight;

  if (height !== undefined) {
    const m = (maxHeight - minHeight) / 224;
    const c = minHeight - 800 * m;

    elementHeight = m * height + c;
  }

  if (elementHeight < minHeight) {
    return minHeight;
  }

  if (elementHeight > maxHeight) {
    return maxHeight;
  }

  return Math.floor(elementHeight);
}
