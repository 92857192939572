import { useDynamicHeight } from "./useDynamicHeight";

interface DynamicSpacerProps {
  minHeight: number;
  maxHeight: number;
}

/**
 *
 * This spacer will be at it's min height when the viewport height is below 800px,
 * and at it's max height when the viewport height is 1024px or above
 */
const DynamicHeightSpacer: React.FC<DynamicSpacerProps> = (
  props: DynamicSpacerProps,
) => {
  const elementHeight = useDynamicHeight(props.minHeight, props.maxHeight);

  return <div style={{ height: `${elementHeight}px` }}></div>;
};
export default DynamicHeightSpacer;
