import { useState, useRef, useEffect, ReactNode } from "react";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";
import { DatePicker, DatePickerProps } from "./DatePicker";
import moment from "moment";
import LinkButton from "../Controls/LinkButton";
import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import Stack from "../Layout/Stack";

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const CalendarDescription = styled.div`
  color: var(--freightsimple-color-normal-text);
  font-size: 12px;
  margin-top: 16px;
  height: 16px;
`;

const DateDisplay = styled.div`
  font-size: 20px;
  font-weight: 600;
  width: 349px;
  padding: 11px 11px 9px 0;
  color: var(--freightsimple-color-normal-text);
  border-bottom: 1px solid var(--freightsimple-color-light-text);
  margin-bottom: 1px;
  transition: border-bottom 0.2s ease;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid var(--freightsimple-color-blue);
    margin-bottom: 0;
  }

  &:focus {
    border-bottom: 2px solid var(--freightsimple-color-blue);
    margin-bottom: 0;
  }
`;

const AnimatedContent = styled(animated.div)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 8px;
`;

export function DatePickerDropdown(props: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState<string | ReactNode>(undefined);

  const springProps = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0px)" : "translateY(-8px)",
    config: {
      tension: 300,
      friction: 20,
    },
    onRest: () => {
      if (!isOpen) {
        setShowContent(false);
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      setShowContent(true);
    }
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <DropdownContainer ref={dropdownRef}>
      <Stack align="left">
        <HorizontalStack verticalAlign="middle">
          <DateDisplay onClick={toggleDropdown}>
            {moment(props.selectedDate).format("dddd, MMMM Do YYYY")}
          </DateDisplay>
          <Spacer width={16} />
          <LinkButton onClick={toggleDropdown}>Change</LinkButton>
        </HorizontalStack>
        {tooltip && <CalendarDescription>{tooltip}</CalendarDescription>}
      </Stack>

      <AnimatedContent
        style={{
          ...springProps,
          display: showContent ? "block" : "none",
        }}
      >
        <DatePicker
          {...props}
          onInitialize={setTooltip}
          setSelectedDate={function (
            date: string | undefined,
            tooltip: ReactNode | undefined,
          ) {
            if (date) {
              props.setSelectedDate(date, tooltip);
            }
            setTooltip(tooltip);
            // Close the dropdown after a date is selected
            toggleDropdown();
          }}
        />
      </AnimatedContent>
    </DropdownContainer>
  );
}
