import { useModal } from "../../../Cargo/Modal/useModal";
import { UUID } from "../../../Cargo/Types/types";
import useModifyBrokerModal from "../../Brokers/Hooks/useModifyBrokerModal";
import {
  Broker,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";
import { v4 as uuidv4 } from "uuid";
import { ChangeBrokerModal } from "./ChangeBrokerModal";

function useSwitchBrokerModal() {
  const showModal = useModal<SavedBroker>();

  async function show(
    selectedSavedBrokerId: UUID,
    savedBrokers: Array<SavedBroker>,
    onBrokerChanged: (updatedBroker: Broker) => void,
  ) {
    return new Promise<Broker | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <ChangeBrokerModal
              savedBrokerId={selectedSavedBrokerId}
              savedBrokers={savedBrokers}
              onDone={done}
              onBrokerChanged={onBrokerChanged}
            ></ChangeBrokerModal>
          );
        },
        (data) => {
          resolve(data?.broker);
        },
      );
    });
  }

  return show;
}

function useChangeBrokerModal() {
  const showSwitchBrokerModal = useSwitchBrokerModal();
  const showModifyBrokerModal = useModifyBrokerModal();

  async function show(
    selectedSavedBrokerId: string | undefined,
    currentBroker: Broker,
    savedBrokers: Array<SavedBroker>,
    onBrokerChanged: (updatedBroker: Broker) => void,
  ) {
    // There's basically two situations we might be in
    // 1) There's only one saved broker or the saved broker info on this shipment doesn't match
    //    any of the saved brokers. In this case we just want to quickly allow them to modify
    //    the broker info
    //
    // 2) There's multiple saved brokers. In this case we need to show a more sophisticated screen
    //    that allows them to select from any of the saved broker, and optionally modify one of them or
    //    add a new one
    if (savedBrokers.length <= 1 || selectedSavedBrokerId === undefined) {
      const savedBroker = savedBrokers.find(function (sb) {
        return sb.savedBrokerId === selectedSavedBrokerId;
      }) ?? { savedBrokerId: uuidv4(), broker: currentBroker };
      const data = await showModifyBrokerModal(savedBroker);
      const broker = data?.broker;
      if (broker !== undefined) {
        onBrokerChanged(broker);
      }
    } else {
      const broker = await showSwitchBrokerModal(
        selectedSavedBrokerId,
        savedBrokers,
        onBrokerChanged,
      );

      if (broker !== undefined) {
        onBrokerChanged(broker);
      }
    }
  }

  return show;
}

export default useChangeBrokerModal;
