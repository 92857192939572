import { ReactNode } from "react";
import { useIsAuthViewHeightToSmall } from "./Hooks/useIsAuthViewHeightTooSmall";

export function AuthenticationScreenContainer({
  children,
  image,
}: {
  children: ReactNode;
  image: string;
}) {
  const isViewHeightTooSmall = useIsAuthViewHeightToSmall();
  return (
    <div
      style={{
        backgroundColor: "#E5E5E5",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <main
        style={{
          margin: "auto",
          minHeight: "520px",
          width: "80vw",
          border: "1px solid #bbb",
          backgroundColor: "white",
          display: "flex",
        }}
      >
        <section
          style={{
            width: "688px", // Fixed width of 560px + 64px padding on each side
            paddingBlock: isViewHeightTooSmall ? "24px" : "64px",
            paddingInline: isViewHeightTooSmall ? "40px" : "64px",
          }}
        >
          {children}
        </section>
        <aside
          style={{
            flex: 1,
            overflow: "hidden",
            backgroundImage: image,
            backgroundPosition: "bottom center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
      </main>
    </div>
  );
}
