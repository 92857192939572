import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type AnimatedSizeContainerProps = {
  children: ReactNode;
  transitionDuration?: number;
  skipOnFirstRender?: boolean;
  animationType?: "center" | "default";
} & Omit<HTMLAttributes<HTMLDivElement>, "style">;

export function AnimatedSizeContianer({
  children,
  transitionDuration = 300,
  skipOnFirstRender = true,
  animationType = "default",
  ...props
}: AnimatedSizeContainerProps) {
  const innerDivRef = useRef<HTMLDivElement>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  const [animation, setAnimation] = useState<CSSProperties>({});

  const resizeObserver = useMemo(() => {
    try {
      return new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setAnimation({
            height: entry.contentRect.height,
          });
        });
      });
    } catch {
      return new ResizeObserverPolyfill((entries) => {
        entries.forEach((entry) => {
          setAnimation({
            height: entry.contentRect.height,
          });
        });
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (!innerDivRef.current) return;

    resizeObserver.observe(innerDivRef.current);

    if (skipOnFirstRender) {
      setAnimation({
        height: innerDivRef.current.clientHeight,
      });
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [innerDivRef]);

  const additionalStyle: CSSProperties = {};

  if (animationType === "center") {
    additionalStyle.display = "flex";
    additionalStyle.justifyContent = "center";
    additionalStyle.alignItems = "center";
  }

  return (
    <div
      ref={wrapperDivRef}
      style={{
        ...additionalStyle,
        ...animation,
        transition: `height ${transitionDuration}ms`,
        overflow: "hidden",
      }}
      {...props}
    >
      <div ref={innerDivRef}>{children}</div>
    </div>
  );
}
