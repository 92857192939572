import Button from "../../../Cargo/Controls/Button";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Microcopy, ModalTitle } from "../../../Cargo/Text/Text";
import { UUID } from "../../../Cargo/Types/types";
import BrokerBox from "../../Brokers/Components/BrokerBox";
import SavedBrokersDropdown from "../../Brokers/Components/SavedBrokersDropdown";
import useAddBrokerModal from "../../Brokers/Hooks/useAddBrokerModal";
import useModifyBrokerModal from "../../Brokers/Hooks/useModifyBrokerModal";
import {
  Broker,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface ChangeBrokerModalProps {
  savedBrokerId: UUID;
  savedBrokers: Array<SavedBroker>;
  onDone: () => void;
  onBrokerChanged: (updatedBroker: Broker) => void;
}

export function ChangeBrokerModal(props: ChangeBrokerModalProps) {
  const showModifyBrokerModal = useModifyBrokerModal();
  const [selectedSavedBrokerId, setSelectedSavedBrokerId] = useState(
    props.savedBrokerId,
  );

  const showAddBrokerModal = useAddBrokerModal();

  async function onAddBroker() {
    const data = await showAddBrokerModal();
    props.onBrokerChanged(data.broker.broker);
    props.onDone();
  }

  const selectedSavedBroker = props.savedBrokers.find(function (sb) {
    return sb.savedBrokerId === selectedSavedBrokerId;
  });

  if (selectedSavedBroker === undefined) {
    throw new Error("Missing selectedSavedBroker - this should not happen");
  }

  function onSwitch(newSavedBrokerId: UUID) {
    setSelectedSavedBrokerId(newSavedBrokerId);
    const newSelectedBroker = props.savedBrokers.find(function (sb) {
      return sb.savedBrokerId === newSavedBrokerId;
    })?.broker;

    if (newSelectedBroker === undefined) {
      throw new Error("Missing saved broker");
    }
    props.onBrokerChanged(newSelectedBroker);
  }

  return (
    <div style={{ width: "640px" }}>
      <ModalTitle>Select Customs Broker</ModalTitle>
      <Microcopy>
        Select from one of your saved customs broker for this shipment
      </Microcopy>
      <Spacer height={8} />
      <SavedBrokersDropdown
        savedBrokerId={selectedSavedBrokerId}
        savedBrokers={props.savedBrokers}
        onSetSavedBrokerId={onSwitch}
      />
      <Spacer height={24} />
      <BrokerBox
        key={selectedSavedBrokerId}
        broker={selectedSavedBroker.broker}
        onModify={async function () {
          const data = await showModifyBrokerModal(selectedSavedBroker);
          if (data !== undefined) {
            props.onBrokerChanged(data.broker);
          }
          props.onDone();
        }}
        showMenu={false}
        onDelete={undefined}
        preferredNorthbound={false}
        preferredSouthbound={false}
        onSelectPreferred={undefined}
      />
      <Spacer height={24} />
      <LinkButton onClick={onAddBroker}>Add new customs broker</LinkButton>
      <Spacer height={24} />
      <HorizontalStack width="100%" align="spread">
        <Button
          onClick={function () {
            props.onDone();
          }}
        >
          Done
        </Button>
      </HorizontalStack>
    </div>
  );
}
