export enum Units {
  Imperial,
  Metric,
}

export enum Dimension {
  Length,
  Weight,
}

function factorForDimension(dimension: Dimension): number {
  switch (dimension) {
    case Dimension.Length:
      return 0.393701;
    case Dimension.Weight:
      return 2.20462;
  }
}

export function convertToUnit(
  value: number | undefined,
  units: Units,
  dimension: Dimension,
): number | undefined {
  if (value === undefined) {
    return undefined;
  }

  let result;
  if (units === Units.Metric) {
    result = Math.ceil(value / factorForDimension(dimension));
  } else {
    result = Math.ceil(value);
  }

  return result;
}

export function convertFromUnit(
  value: number,
  units: Units,
  dimension: Dimension,
): number {
  let result;
  if (units === Units.Metric) {
    result = value * factorForDimension(dimension);
  } else {
    result = value;
  }
  return result;
}

export function describeUnit(unit: Units, dimension: Dimension) {
  switch (dimension) {
    case Dimension.Length:
      switch (unit) {
        case Units.Imperial:
          return "in";
        case Units.Metric:
          return "cm";
        default:
          throw new Error("Should not be here");
      }
    case Dimension.Weight:
      switch (unit) {
        case Units.Imperial:
          return "lb";
        case Units.Metric:
          return "kg";
        default:
          throw new Error("Should not be here");
      }
    default:
      throw new Error("Should not be here");
  }
}
