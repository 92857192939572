import { useModalsSlice } from "../../../Cargo/Modal/modalsSlice";
import { useSavedLocations } from "../../Locations/Hooks/useSavedLocations";
import { useSavedLocationsService } from "../../Locations/Services/SavedLocationsService";
import { useShipmentService } from "../../../Services/ShipmentService";
import {
  SavedLocation,
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import { Location } from "@freightsimple/generated-dashboard-openapi-client";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { useUpdatedBOLModal } from "./useUpdatedBOLModal";
import { findSavedLocationForLocation } from "../../Locations/Helpers/findSavedLocationForLocation";
import { useModifyAddressModal } from "./useModifyAddressModal";

function findSavedLocationId(
  locationContext: LocationContext,
  shipment: Shipment,
  savedLocations: Array<SavedLocation>,
) {
  const location =
    locationContext === LocationContext.Pickup
      ? shipment.pickupLocation
      : shipment.deliveryLocation;

  return findSavedLocationForLocation(savedLocations, location)
    ?.savedLocationId;
}

export function useModifyAddressAfterBookingModal(
  shipment: Shipment,
  locationContext: LocationContext,
) {
  const showChangeAddressModal = useModifyAddressModal(locationContext);
  const showUpdatedBOLModal = useUpdatedBOLModal();
  const shipmentService = useShipmentService();
  const savedLocationsService = useSavedLocationsService();
  const { setLoading } = useModalsSlice();
  const { savedLocations } = useSavedLocations();

  // Returns if the broker has changed, hence we should reload
  async function show(): Promise<boolean> {
    const savedLocationId = findSavedLocationId(
      locationContext,
      shipment,
      savedLocations,
    );

    const originalLocation =
      locationContext === LocationContext.Pickup
        ? shipment.pickupLocation
        : shipment.deliveryLocation;

    if (savedLocationId === undefined) {
      // We should handle this - seems like a real edge case
      // where they have modified the location after booking
      throw new Error("No saved location found");
    }

    // We need to show a screen that allows them to select from any of the saved brokers,
    // and optionally modify one of them or add a new one
    // const newSavedBrokerId = await showSwitchBrokerModal(currentBroker);
    const updatedLocation = await showChangeAddressModal(originalLocation);

    if (updatedLocation === undefined) {
      // Cancel pressed
      return false;
    }

    function areAddressesEqual(location1: Location, location2: Location) {
      // Note the email address is missing here. We don't put the
      // contact's email on BOLs or send to the carrier.
      // So if that's all that has changed
      return (
        location1.businessName === location2.businessName &&
        location1.address.addressLine === location2.address.addressLine &&
        location1.address.addressLine2 === location2.address.addressLine2 &&
        location1.address.city === location2.address.city &&
        location1.address.countryCode === location2.address.countryCode &&
        location1.address.postalCode === location2.address.postalCode &&
        location1.address.stateOrProvinceCode ===
          location2.address.stateOrProvinceCode
      );
    }

    if (areAddressesEqual(updatedLocation, originalLocation)) {
      // Nothing changed
      return false;
    }

    setLoading(true);

    // Make an API call to updated the saved location
    if (savedLocationId !== undefined) {
      await savedLocationsService.modifyLocation(
        savedLocationId,
        updatedLocation,
      );
    } else {
      console.warn(`No savedLocationId`);
    }

    // Make an API call to change the broker
    await shipmentService.updateLocation(
      shipment.shipmentId,
      locationContext,
      updatedLocation,
    );
    setLoading(false);

    // If the shipment is in transit, then it's too late to give the driver
    // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
    if (shipment.shipmentState !== ShipmentState.InTransit) {
      await showUpdatedBOLModal();
    }

    return true;
  }

  return show;
}
