import { InvoiceType } from "@freightsimple/generated-dashboard-openapi-client";

export function invoiceDescription(invoiceType: InvoiceType): string {
  switch (invoiceType) {
    case InvoiceType.ShipmentCharge:
      return "Shipment Charge";
    case InvoiceType.AdditionalCharge:
      return "Additional Charge";
    case InvoiceType.ShipmentRefund:
      return "Refund";
    case InvoiceType.AdditionalChargeRefund:
      return "Refund";
  }
}
