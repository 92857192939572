import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Label } from "../../../Cargo/Text/Label";
import ContactQuestionBubble from "../../BookShipment/Components/Addresses/QuestionBubbles/ContactQuestionBubble";
import LargeContactInput from "../../Contacts/Components/LargeContactInput";
import { AddContactContactType } from "../../Contacts/Types/contactTypes";
import {
  EmailRequired,
  errorMessagesForContact,
} from "../../Contacts/Validators/errorMessagesForContact";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface AddContactPageProps {
  contact: AddContactContactType;
  contactChanged: (_: AddContactContactType) => void;
  onDone: () => void;
  onPrevious: () => void;
}
function AddBrokerModalContactPage(props: AddContactPageProps) {
  const { contact, contactChanged, onPrevious, onDone } = props;
  const [forceValidation, setForceValidation] = useState(false);
  const errorMessages = errorMessagesForContact(
    contact,
    EmailRequired.EmailRequired,
  );

  function isValid() {
    if (
      errorMessages.contactName ||
      errorMessages.emailAddress ||
      errorMessages.phoneNumber
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function onNext() {
    if (!isValid()) {
      setForceValidation(true);
    }

    onDone();
  }

  return (
    <>
      <Label>
        Contact Details
        <ContactQuestionBubble context={LocationContext.Any} />
      </Label>
      <LargeContactInput
        contact={contact}
        contactChanged={contactChanged}
        forceValidation={forceValidation}
        errorMessages={errorMessages}
      ></LargeContactInput>
      <Spacer height={32} />
      <HorizontalStack width="100%" align="spread">
        <Button onClick={onPrevious} secondary>
          Previous
        </Button>
        <Button onClick={onNext}>Save</Button>
      </HorizontalStack>
    </>
  );
}
export default AddBrokerModalContactPage;
