import { PreBookingShipment } from "@freightsimple/generated-dashboard-openapi-client";
import { QuoteScreenShipmentGroup } from "./QuoteScreenGroupHeader";

export function generateQuoteScreenShipmentGroupKey(
  shipment: PreBookingShipment,
): QuoteScreenShipmentGroup {
  return {
    pickupCity: shipment.pickupLocation.address.city,
    pickupStateOrProvinceCode:
      shipment.pickupLocation.address.stateOrProvinceCode,
    deliveryCity: shipment.deliveryLocation.address.city,
    deliveryStateOrProvinceCode:
      shipment.deliveryLocation.address.stateOrProvinceCode,
  };
}
