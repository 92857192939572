export interface QuoteScreenShipmentGroup {
  pickupCity: string;
  pickupStateOrProvinceCode: string;
  deliveryCity: string;
  deliveryStateOrProvinceCode: string;
}

interface QuoteScreenGroupHeaderProps {
  group: QuoteScreenShipmentGroup;
}

export function QuoteScreenGroupHeader(props: QuoteScreenGroupHeaderProps) {
  const { group } = props;
  return (
    <div style={{ marginBottom: "8px", marginTop: "24px" }}>
      {group.pickupCity}, {group.pickupStateOrProvinceCode} to{" "}
      {group.deliveryCity}, {group.deliveryStateOrProvinceCode}
    </div>
  );
}
