import DynamicHeightSpacer from "../../../Cargo/Layout/DynamicSpacer";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Label } from "../../../Cargo/Text/Label";
import { ErrorMessageType } from "../../../Cargo/Validation";
import CardAddress from "../../CreditCards/Components/CardAddress";
import CardSection from "../../CreditCards/Components/CardSection";
import { Address } from "@freightsimple/generated-dashboard-openapi-client";
import React, { ReactNode } from "react";

interface CardEntryFormProps {
  setBillingName: (_: string) => void;
  setBillingAddress: (_: Partial<Address>) => void;
  billingName: string;
  billingAddress: Partial<Address>;
  forceValidation: boolean;
  addresses: Array<Address>;
  onBrandChange?: (brandName: string) => void;
  errorMessageForBillingName: ErrorMessageType;
  onCreditCardNumberError: (message: ErrorMessageType) => void;
  onCreditCardExpirationError: (message: ErrorMessageType) => void;
  onCreditCardCVCError: (message: ErrorMessageType) => void;
  errorMessageForCreditCardNumber: ErrorMessageType;
  errorMessageForCreditCardExpiration: ErrorMessageType;
  errorMessageForCreditCardCVC: ErrorMessageType;
  errorMessageForAddress: ErrorMessageType;
  errorMessageForCity: ErrorMessageType;
  errorMessageForStateOrProvinceCode: ErrorMessageType;
  errorMessageForPostalCode: ErrorMessageType;
  errorMessageForCountry: ErrorMessageType;
  onValidate: () => void;
  sideBySideLayout?: boolean;
}

interface CardEntryFormLayoutProps {
  sideBySideLayout: boolean;
  children: ReactNode;
}

function CardEntryFormLayout(props: CardEntryFormLayoutProps) {
  if (props.sideBySideLayout) {
    return (
      <>
        <HorizontalStack verticalAlign="top">{props.children}</HorizontalStack>
      </>
    );
  } else {
    return <Stack align="left">{props.children}</Stack>;
  }
}

const CardEntryForm: React.FC<CardEntryFormProps> = (
  props: CardEntryFormProps,
) => {
  const billingName = props.billingName;
  const billingAddress = props.billingAddress;
  const setBillingAddress = props.setBillingAddress;
  const setBillingName = props.setBillingName;

  const sideBySideLayout = props.sideBySideLayout === true ? true : false;

  return (
    <>
      <Spacer height={32} />
      <CardEntryFormLayout sideBySideLayout={sideBySideLayout}>
        <CardSection
          onBillingNameChanged={(name: string) => setBillingName(name)}
          forceValidation={props.forceValidation}
          billingName={billingName}
          onBrandChange={props.onBrandChange}
          errorMessageForBillingName={props.errorMessageForBillingName}
          onCreditCardCVCError={props.onCreditCardCVCError}
          onCreditCardExpirationError={props.onCreditCardExpirationError}
          onCreditCardNumberError={props.onCreditCardNumberError}
          errorMessageForCreditCardNumber={
            props.errorMessageForCreditCardNumber
          }
          errorMessageForCreditCardExpiration={
            props.errorMessageForCreditCardExpiration
          }
          errorMessageForCreditCardCVC={props.errorMessageForCreditCardCVC}
          onValidate={props.onValidate}
        />

        <DynamicHeightSpacer minHeight={8} maxHeight={16} />
        <Spacer width={48} />

        <div>
          <Label>Billing Address</Label>
          <CardAddress
            addresses={props.addresses}
            address={billingAddress}
            onAddressChanged={(deltaAddress) => {
              setBillingAddress({
                ...billingAddress,
                ...deltaAddress,
              });
            }}
            onResetAddress={() => {
              setBillingAddress({});
            }}
            validated={props.forceValidation}
            forceValidation={props.forceValidation}
            errorMessageForAddress={props.errorMessageForAddress}
            errorMessageForCity={props.errorMessageForCity}
            errorMessageForStateOrProvinceCode={
              props.errorMessageForStateOrProvinceCode
            }
            errorMessageForPostalCode={props.errorMessageForPostalCode}
            errorMessageForCountry={props.errorMessageForCountry}
          />
        </div>
      </CardEntryFormLayout>
    </>
  );
};

export default CardEntryForm;
