import {
  NormalQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../../Cargo/Controls/QuestionBubble";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";

interface ContactQuestionBubbleProps {
  displayName?: string;
  context: LocationContext;
}

const ContactQuestionBubble: React.FC<ContactQuestionBubbleProps> = (
  props: ContactQuestionBubbleProps,
) => {
  function header() {
    if (props.displayName) {
      const displayName = props.displayName.toLowerCase();
      return `Enter contact information for ${displayName}`;
    } else {
      return `Enter contact information`;
    }
  }

  const locationString = props.context?.toLowerCase() || "this location";

  return (
    <NormalQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>{header()}</QuestionBubbleHeader>
          <QuestionBubbleLabel>
            Enter contact information for {locationString}. The carrier may need
            to contact this person for various reasons, such as booking an
            appointment or finding the door.
          </QuestionBubbleLabel>
          <QuestionBubbleLabel>
            Please list a contact that can be easily reached.
          </QuestionBubbleLabel>
        </>
      }
      learnMoreUrl="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/pickup-and-delivery-contacts"
    />
  );
};
export default ContactQuestionBubble;
