import { useModal } from "../../../Cargo/Modal/useModal";
import { AuthenticateForQuotesModal } from "./AuthenticateForQuotesModal.1";

export interface AuthenticateForQuotesModalProps {
  onDone: () => void;
  onCancel: () => void;
}

export interface SignInPaneProps {
  onDone: () => void;
  onCancel: () => void;
  onCreateAccountInstead: () => void;
}

export interface CreateAccountPaneProps {
  onDone: () => void;
  onCancel: () => void;
  onSignInInstead: () => void;
}

export function useAuthenticateForQuotesModal() {
  const showModal = useModal<boolean>();
  function show() {
    const p = new Promise<boolean | undefined>((resolve) => {
      showModal(
        (done) => {
          return (
            <AuthenticateForQuotesModal
              onDone={() => done(true)}
              onCancel={() => done(false)}
            />
          );
        },
        (confirmed) => {
          resolve(confirmed);
        },
        {
          preventBackdropClick: true,
          simpleModal: true,
        },
      );
    });

    return p;
  }

  return show;
}
