import Badge from "../Controls/Badge";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import { useDynamicHeight } from "./useDynamicHeight";
import { BoxStyle } from "./BoxStyle";

interface MainBoxProps {
  width: number | undefined;
  $reactToHover: boolean;
  $topBottomPadding: number;
  $boxStyle: BoxStyle;
}

function getBoxShadow(boxStyle: BoxStyle): string {
  switch (boxStyle) {
    case BoxStyle.Loose:
      return "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px";
    case BoxStyle.Medium:
      return "rgba(0, 0, 0, 0.09) 0px 3px 12px";
    case BoxStyle.Tight:
      return "rgba(0, 0, 0, 0.1) 0px 4px 4px";
    case BoxStyle.None:
      return "none";
  }
}

const MainBox = styled.div<MainBoxProps>`
  ${(props) => (props.width !== undefined ? `width: ${props.width}px;` : "")}
  box-shadow: ${(props) => getBoxShadow(props.$boxStyle)};

  border: 1px solid #c9c9c9;
  border-radius: 3px;
  padding: ${(props) => props.$topBottomPadding}px 32px;
  position: relative; /* for absolute child element */
  display: inline-block; /* to take the width of the input */

  &:hover {
    ${(props) => (props.$reactToHover ? `border: 1px solid #ddd;` : "")}
  }
`;

const TopLeft = styled.div`
  position: absolute;
  top: -24px;
  left: -24px;
`;

interface BoxProps {
  badge?: string;
  children: ReactNode;
  width?: number;
  style?: CSSProperties;
  colorBar?: string;
  reactToHover?: boolean;
  boxStyle?: BoxStyle;
}

function badge(badge: string) {
  return (
    <TopLeft>
      <Badge value={badge} />
    </TopLeft>
  );
}

function colorBar(colorBar: string) {
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        backgroundColor: colorBar,
        width: "16px",
        height: "100%",
      }}
    ></div>
  );
}

function Box(props: BoxProps) {
  const boxPadding = useDynamicHeight(8, 16);

  return (
    <MainBox
      width={props.width}
      style={props.style}
      $reactToHover={props.reactToHover || false}
      $topBottomPadding={boxPadding}
      $boxStyle={props.boxStyle || BoxStyle.Medium}
    >
      {props.colorBar && colorBar(props.colorBar)}
      {props.badge && badge(props.badge)}
      {props.children}
    </MainBox>
  );
}
export default Box;
