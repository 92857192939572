import { useShipmentsApi } from "../apis";
import { UUID } from "../Cargo/Types/types";
import {
  Contact,
  Document,
  DocumentType,
  EquipmentType,
  GetShipmentsResponse,
  Invoice,
  Location,
  LocationContext,
  PollQuotesResponse,
  PreBookingShipment,
  SharedDedicated,
  Shipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { fileNameForDocumentType } from "../Helpers/fileNameForDocumentType";
import moment from "moment";

interface ShipmentService {
  getShipment: (shipmentId: UUID) => Promise<Shipment>;
  getPreBookingShipment: (shipmentId: UUID) => Promise<PreBookingShipment>;
  getShipments: () => Promise<GetShipmentsResponse>;
  pollForQuotes: (shipmentId: UUID) => Promise<PollQuotesResponse>;
  selectQuote: (shipmentId: UUID, quoteId: UUID) => Promise<void>;
  bookShipment: (shipmentId: UUID) => Promise<void>;
  markReviewingPriorToBookingShipment: (shipmentId: UUID) => Promise<void>;
  downloadPickupPackage: (shipmentId: UUID) => Promise<void>;
  downloadDocument: (
    documentId: UUID,
    documentType: DocumentType,
  ) => Promise<void>;
  downloadInvoice: (
    shipmentId: UUID,
    invoiceIdentifier: string,
    key: string,
  ) => Promise<void>;
  getDocuments: (shipmentId: UUID) => Promise<Document[]>;
  getInvoices: (shipmentId: UUID) => Promise<Invoice[]>;
  deleteQuotedShipment: (shipmentId: UUID, reason: string) => Promise<void>;
  hasBookedOnlyOneShipment: () => Promise<boolean>;
  setBroker: (shipmentId: UUID, savedBrokerId: UUID) => Promise<void>;
  updateContact: (
    shipmentId: UUID,
    locationContext: LocationContext,
    contact: Contact,
  ) => Promise<void>;
  updateLocation: (
    shipmentId: UUID,
    locationContext: LocationContext,
    location: Location,
  ) => Promise<void>;
  updateReferenceAndNotes: (
    shipmentId: UUID,
    locationContext: LocationContext,
    reference: string,
    boothNumber: string,
    notes: string,
  ) => Promise<void>;
  requestManualQuotes: (
    shipmentId: UUID,
    equipmentType: EquipmentType,
    sharedDedicated: SharedDedicated,
    tarpRequired: boolean | undefined,
    linearFeet: number | undefined,
    notes: string,
  ) => Promise<void>;
}

export function useShipmentService(): ShipmentService {
  const shipmentApi = useShipmentsApi();

  return {
    getShipment: async function (shipmentId: UUID): Promise<Shipment> {
      const response = await shipmentApi.getShipmentsGetOne({
        shipmentId,
      });

      return response.shipment;
    },

    getPreBookingShipment: async function (
      shipmentId: UUID,
    ): Promise<PreBookingShipment> {
      const response = await shipmentApi.getShipmentsGetPreBookingShipment({
        shipmentId,
      });

      return response;
    },

    getShipments: async function (): Promise<GetShipmentsResponse> {
      const response = await shipmentApi.getShipments();

      // Sort by pickup date
      response.booked.sort(function (a, b) {
        return moment(a.pickupDate).unix() - moment(b.pickupDate).unix();
      });

      response.quoted.sort(function (a, b) {
        return moment(a.pickupDate).unix() - moment(b.pickupDate).unix();
      });

      return response;
    },

    pollForQuotes: async function (
      shipmentId: UUID,
    ): Promise<PollQuotesResponse> {
      const response = await shipmentApi.getShipmentsPollQuotes({
        shipmentId,
      });

      return response;
    },

    selectQuote: async function (shipmentId: UUID, quoteId: UUID) {
      await shipmentApi.postShipmentsSelectQuote({
        selectQuoteRequest: {
          shipmentId,
          quoteId,
        },
      });
    },

    bookShipment: async function (shipmentId: UUID) {
      await shipmentApi.postBookShipment({
        bookShipmentRequest: {
          shipmentId,
        },
      });
    },

    markReviewingPriorToBookingShipment: async function (shipmentId: UUID) {
      await shipmentApi.postShipmentsMarkReviewingPriorToBookingShipment({
        shipmentId,
      });
    },

    downloadPickupPackage: async function (shipmentId: UUID) {
      const billOfLading = await shipmentApi.getShipmentsPickupPackagePdf({
        shipmentId,
      });

      const linkSource = `data:application/pdf;base64,${billOfLading.base64EncodedPdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "pickupPackage.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    downloadDocument: async function (
      documentId: UUID,
      documentType: DocumentType,
    ) {
      const response = await shipmentApi.getShipmentsDocumentPdf({
        documentId,
      });

      const linkSource = `data:application/pdf;base64,${response.base64EncodedPdf}`;
      const downloadLink = document.createElement("a");
      const fileName = fileNameForDocumentType(documentType);
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    downloadInvoice: async function (
      shipmentId: UUID,
      invoiceIdentifier: string,
      key: string,
    ) {
      const response = await shipmentApi.getShipmentsInvoicePdf({
        shipmentId,
        key,
      });

      const linkSource = `data:application/pdf;base64,${response.base64EncodedPdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `invoice-${invoiceIdentifier}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    getDocuments: async function (shipmentId: UUID) {
      const response = await shipmentApi.getShipmentsListDocuments({
        shipmentId,
      });

      return response.documents;
    },

    getInvoices: async function (shipmentId: UUID) {
      return await shipmentApi.getShipmentsListInvoices({
        shipmentId,
      });
    },

    deleteQuotedShipment: async function (shipmentId: UUID, reason: string) {
      try {
        await shipmentApi.postShipmentsDeleteQuotedShipment({
          shipmentId,
          reason,
        });
      } catch (e) {
        console.warn("deleteQuotedShipment failed", e);
      }
    },

    hasBookedOnlyOneShipment: async function (): Promise<boolean> {
      const response = await shipmentApi.getShipmentsHasBookedOnlyOneShipment();

      return response.hasBookedOnlyOneShipment;
    },

    setBroker: async function (shipmentId: UUID, savedBrokerId: UUID) {
      await shipmentApi.postShipmentsSetBroker({
        shipmentId,
        savedBrokerId,
      });
    },

    updateContact: async function (
      shipmentId: UUID,
      locationContext: LocationContext,
      contact: Contact,
    ) {
      await shipmentApi.postShipmentsSetContact({
        setContactRequest: {
          shipmentId,
          locationContext,
          contact,
        },
      });
    },

    updateLocation: async function (
      shipmentId: UUID,
      locationContext: LocationContext,
      location: Location,
    ) {
      await shipmentApi.postShipmentsSetAddress({
        setAddressRequest: {
          shipmentId,
          locationContext,
          businessName: location.businessName,
          address: location.address,
        },
      });
    },

    updateReferenceAndNotes: async function (
      shipmentId: UUID,
      locationContext: LocationContext,
      reference: string,
      boothNumber: string,
      notes: string,
    ) {
      await shipmentApi.postShipmentSetReferenceAndNotesRaw({
        setReferenceAndNotesRequest: {
          shipmentId,
          locationContext,
          reference,
          boothNumber,
          notes,
        },
      });
    },

    requestManualQuotes: async function (
      shipmentId: UUID,
      equipmentType: EquipmentType,
      sharedDedicated: SharedDedicated,
      tarpRequired: boolean | undefined,
      linearFeet: number | undefined,
      notes: string,
    ) {
      await shipmentApi.postShipmentsRequestManualQuotes({
        requestManualQuotesRequest: {
          shipmentId,
          equipmentType,
          sharedDedicated,
          tarpRequired,
          linearFeet,
          notes,
        },
      });
    },
  };
}
