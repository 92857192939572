import ProgressSpinner from "../../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../../Cargo/Icons/ProgressSpinnerSizes";
import Stack from "../../../Cargo/Layout/Stack";

function LoadingShipment() {
  return (
    <Stack align="center" style={{ marginTop: "300px" }}>
      <ProgressSpinner size={ProgressSpinnerSizes.Small} />
    </Stack>
  );
}
export default LoadingShipment;
