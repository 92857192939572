import { useModal } from "../../../Cargo/Modal/useModal";

import { Location } from "@freightsimple/generated-dashboard-openapi-client";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { ModifyAddressModal } from "./ModifyAddressModal";

export function useModifyAddressModal(locationContext?: LocationContext) {
  const showChangeContactModal = useModal<Location>();

  async function show(location: Location) {
    const p = new Promise<Location | undefined>((resolve) => {
      showChangeContactModal(
        (done) => {
          return (
            <ModifyAddressModal
              onDone={done}
              onCancel={done}
              locationContext={locationContext}
              location={location}
            ></ModifyAddressModal>
          );
        },
        (data) => {
          resolve(data);
        },
      );
    });

    return p;
  }

  return show;
}
