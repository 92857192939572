import Button from "../../../../Cargo/Controls/Button";
import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import Stack from "../../../../Cargo/Layout/Stack";
import { Heading2, Microcopy } from "../../../../Cargo/Text/Text";
import { ReactElement } from "react";
import { ConfirmResidentialResult } from "./ConfirmResidentialResult";

interface ConfirmResidentialModalProps {
  onDone: (result: ConfirmResidentialResult) => void;
}

export function ConfirmResidentialModal(
  props: ConfirmResidentialModalProps,
): ReactElement {
  return (
    <Stack align="center" width="900px">
      <Heading2>⚠️ Warning. Likely Residential Address.</Heading2>
      <Microcopy>
        We checked with our carrier partners and it is likely this will be
        billed as a residential address.
      </Microcopy>
      <Spacer height={8} />
      <Microcopy>
        You did not select 'Residential Area' when quoting for this location.
        Additional charges may occur after delivery if you proceed, and our team
        determines that this was a residential area..
      </Microcopy>
      <Spacer height={24} />
      <HorizontalStack>
        <Button
          size="large"
          onClick={() => {
            props.onDone(ConfirmResidentialResult.AddResidentialToQuote);
          }}
          arrow="left"
        >
          Add Residential and get new quotes
        </Button>
        <Spacer width={16} />
        <Button
          size="large"
          danger
          secondary
          onClick={() => {
            props.onDone(ConfirmResidentialResult.Continue);
          }}
        >
          I am confident this is not residential, continue!
        </Button>
      </HorizontalStack>
    </Stack>
  );
}
