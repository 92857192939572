import { ReactNode } from "react";
import { useIsAuthViewHeightToSmall } from "../../Authentication/Hooks/useIsAuthViewHeightTooSmall";

export function AuthenticationPaneContainer({
  children,
  image,
}: {
  children: ReactNode;
  image: string;
}) {
  const isViewHeightTooSmall = useIsAuthViewHeightToSmall();

  return (
    <main
      style={{
        maxHeight: "883px",
        width: "80vw",
        border: "1px solid #bbb",
        backgroundColor: "white",
        display: "flex",
      }}
    >
      <section
        style={{
          width: "688px", // Fixed width of 560px + 64px padding on each side
          paddingBlock: isViewHeightTooSmall ? "32px" : "64px",
          paddingInline: isViewHeightTooSmall ? "40px" : "64px",
        }}
      >
        {children}
      </section>
      <aside
        style={{
          flex: 1,
          overflow: "hidden",
          backgroundImage: image,
          backgroundPosition: "bottom center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    </main>
  );
}
