import Stack from "../../../Cargo/Layout/Stack";
import { ModalTitle } from "../../../Cargo/Text/Text";
import AddBrokerModalAddressPage from "../Components/AddBrokerModalAddressPage";
import { AddContactContactType } from "../../Contacts/Types/contactTypes";
import {
  Broker,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AddBrokerModalContactPage from "../Components/AddBrokerModalContactPage";
import { AddBrokerBrokerType } from "../Types/brokerTypes";

export interface AddBrokerModalCallbackData {
  broker: SavedBroker;
}

interface BrokerModalProps {
  onFinished: (params: AddBrokerModalCallbackData) => void;
  onCancel: () => void;
}

function AddBrokerModal(props: BrokerModalProps) {
  const { onCancel, onFinished } = props;

  const [broker, setBroker] = useState<AddBrokerBrokerType>({
    businessName: undefined,
    address: {},
    contact: {},
  });

  enum Page {
    Address,
    Contact,
  }

  const [currentPage, setCurrentPage] = useState(Page.Address);

  function onSwitchToAddressPage() {
    setCurrentPage(Page.Address);
  }

  function onSwitchToContactPage() {
    setCurrentPage(Page.Contact);
  }

  async function onDone() {
    const brokerId = uuidv4();

    onFinished({
      broker: {
        savedBrokerId: brokerId,
        broker: broker as Broker,
      },
    });
  }

  return (
    <Stack align="center">
      <Stack align="left">
        <ModalTitle>Add Broker</ModalTitle>

        {currentPage === Page.Address && (
          <AddBrokerModalAddressPage
            setBroker={setBroker}
            broker={broker}
            onCancel={onCancel}
            onSwitchToContactPage={onSwitchToContactPage}
          />
        )}

        {currentPage === Page.Contact && (
          <AddBrokerModalContactPage
            contact={broker.contact}
            contactChanged={function (updatedContact: AddContactContactType) {
              setBroker({
                ...broker,
                contact: {
                  ...broker.contact,
                  ...updatedContact,
                },
              });
            }}
            onPrevious={onSwitchToAddressPage}
            onDone={onDone}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default AddBrokerModal;
