import { styled } from "styled-components";

export const StyledDayPicker = styled.div`
  // Add a box/border/shadow around the date picker
  box-shadow: 6px 6px 0px 0px #e5e5e5 !important;
  border: 1px solid #c9c9c9 !important;
  padding: 32px;

  .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: var(--freightsimple-color-primary);
    --rdp-background-color: var(--freightsimple-color-primary-light);
    margin: 0;
  }

  // Force horizontal layout
  .rdp-months {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 1.5rem;
  }

  // Make disabled dates (out of range) more faint
  .rdp-disabled {
    color: #ccc;
  }

  .rdp-today button {
    background-color: #eff1fa !important;
    color: var(--freightsimple-color-normal-text) !important;
    border-radius: 8px;
  }

  .rdp-selected:not(.rdp-range_middle) button {
    background-color: var(--freightsimple-color-blue) !important;
    color: white !important;
    border-radius: 8px;
  }

  .rdp-cell {
    position: relative;
  }

  // Ensure tooltips don't get cut off
  .rdp-table {
    position: relative;
  }

  // Add styles for the day button to ensure proper layering
  .rdp-button {
    position: relative;
    z-index: 1;
  }
`;
