import Box from "../../../Cargo/Layout/Box";
import { EmergencyContactDetails } from "@freightsimple/generated-dashboard-openapi-client";

import { EmergencyContactDetailsErrors } from "../Validators/errorMessagesForEmergencyContact";
import { EmergencyContactInput } from "./EmergencyContactInput";

interface DangerousGoodsComponentProps {
  emergencyContactDetails: Partial<EmergencyContactDetails>;
  onUpdateEmergencyContactDetails: (
    updates: Partial<EmergencyContactDetails>,
  ) => void;
  forceValidation: boolean;
  emergencyContactDetailsErrors: EmergencyContactDetailsErrors;
}

export function DangerousGoodsComponent(props: DangerousGoodsComponentProps) {
  const {
    emergencyContactDetails,
    onUpdateEmergencyContactDetails,
    forceValidation,
    emergencyContactDetailsErrors,
  } = props;

  return (
    <Box width={608}>
      <EmergencyContactInput
        emergencyContactDetails={emergencyContactDetails}
        onUpdateEmergencyContactDetails={onUpdateEmergencyContactDetails}
        forceValidation={forceValidation}
        errorMessages={emergencyContactDetailsErrors}
      ></EmergencyContactInput>
    </Box>
  );
}
