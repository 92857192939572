import { isProduction } from "../environment";
import ReactGA from "react-ga";

export function initializeAnalytics(): void {
  if (!isProduction()) {
    return;
  }

  try {
    ReactGA.initialize("UA-183113933-1");
  } catch (e) {
    console.warn(`initializeAnalytics exception`, { e });
  }
}

export function trackAnalyticsPageChange(pathName: string): void {
  if (!isProduction()) {
    return;
  }

  try {
    ReactGA.set({ page: pathName }); // Update the user's current page
    ReactGA.pageview(pathName); // Record a pageview for the given page
  } catch (e) {
    console.warn(`trackAnalyticsPageChange exception`, { e });
  }
}

export function trackAnalyticsEvent(eventName: string): void {
  if (!isProduction()) {
    return;
  }

  try {
    const parts = eventName.split("/");
    const category = parts[0];
    const action = parts[1];

    ReactGA.event({
      category,
      action,
    });
  } catch (e) {
    console.warn(`trackAnalyticsEvent exception`, { e });
  }
}

export function identifyAnalyticsUser(userId: string): void {
  if (!isProduction()) {
    return;
  }

  try {
    ReactGA.set({ userId: userId });
  } catch (e) {
    console.warn(`identifyMixpanelUser exception`, { e });
  }
}
