import { Currency } from "@freightsimple/generated-dashboard-openapi-client";

export function generateCreditBannerMessage(
  paymentTermsDays: number | undefined,
  creditAmount: number | undefined,
  creditCurrency: Currency | undefined,
  issuedInvoicesTotalCad: number | undefined,
) {
  return `Payment terms are ${paymentTermsDays} days. Your credit limit is $${creditAmount} ${creditCurrency}. You currently have $${issuedInvoicesTotalCad} ${creditCurrency} in unpaid invoices.`;
}
