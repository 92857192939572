import { usePaymentMethodsApi } from "../../apis";
import Button from "../../Cargo/Controls/Button";
import CreditCardsDropdown from "../../Cargo/Controls/CreditCardsDropdown";
import LinkButton from "../../Cargo/Controls/LinkButton";
import ProgressSpinner from "../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../Cargo/Icons/ProgressSpinnerSizes";
import Box from "../../Cargo/Layout/Box";
import DynamicHeightSpacer from "../../Cargo/Layout/DynamicSpacer";
import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { Microcopy } from "../../Cargo/Text/Text";
import { displayNameForCarrier } from "../../Data/Carriers";
import CardEntryForm from "./Components/CardEntryForm";
import BookShipmentScreenLayout from "./Layout/BookShipmentScreenLayout";
import AddCreditCardLegalease from "../CreditCards/Components/AddCreditCardLegalease";
import StoreCreditCardButton from "../CreditCards/Components/StoreCreditCardButton";
import useStoreCreditCard from "../CreditCards/Hooks/useStoreCreditCard";
import { useShareCreditCardModal } from "../CreditCards/Modals/useShareCreditCardModal";
import {
  Address,
  CreditCard,
  PreBookingShipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../Hooks/useOnce";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShipmentService2 } from "../../Services/ShipmentService2";

interface BookShipmentPaymentScreenProps {
  shipment: PreBookingShipment;
  onComplete: () => void;
  onPrevious: () => Promise<void>;
  nextIdentifier: string;
  onBrandChange: (brandName: string) => void;
}

function BookShipmentPaymentScreen(props: BookShipmentPaymentScreenProps) {
  const { shipment, onBrandChange } = props;
  const { shipmentId } = shipment;
  const {
    storeCreditCard,
    billingAddress,
    billingName,
    setBillingName,
    setBillingAddress,
    errorMessageForBillingName,
    errorMessageForCreditCardNumber,
    errorMessageForCreditCardExpiration,
    errorMessageForCreditCardCVC,
    setErrorMessageForCreditCardNumber,
    setErrorMessageForCreditCardExpiration,
    setErrorMessageForCreditCardCVC,
    errorMessageForAddress,
    errorMessageForCity,
    errorMessageForStateOrProvinceCode,
    errorMessageForPostalCode,
    errorMessageForCountry,
    isValid,
  } = useStoreCreditCard();
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState<
    string | undefined
  >();
  const navigate = useNavigate();
  const [forceValidation, setForceValidation] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [storingInProgress, setStoringInProgress] = useState(false);
  const [storingSuccess, setStoringSuccess] = useState(false);
  const [storingFailure, setStoringFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState<string>();
  const shipmentsService = useShipmentService2();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(true);
  const showShareModal = useShareCreditCardModal();

  const carrierDisplayName = displayNameForCarrier(
    shipment.selectedQuote?.carrierIdentifier,
  );

  if (!carrierDisplayName) {
    throw new Error("Missing carrierDisplayName");
  }

  const pickupAddress = shipment.pickupLocation.address as Address;
  const deliveryAddress = shipment.deliveryLocation.address as Address;

  const paymentMethodsApi = usePaymentMethodsApi();

  const [creditCards, setCreditCards] = useState<
    undefined | Array<CreditCard>
  >();

  const [getCreditCardInterval, setGetCreditCardInterval] = useState<
    undefined | NodeJS.Timeout
  >();

  async function selectNewCard(_stripePaymentMethodId: string) {
    setStripePaymentMethodId(_stripePaymentMethodId);
    await shipmentsService.setStripePaymentMethodId(
      shipmentId,
      _stripePaymentMethodId,
    );
  }

  async function getCreditCards() {
    const creditCardsResponse = await paymentMethodsApi.getAllPaymentMethods();
    const creditCards = creditCardsResponse.creditCards;
    setCreditCards(creditCards);

    const numberOfCreditCards = creditCards?.length;
    setShowForm(numberOfCreditCards && numberOfCreditCards > 0 ? false : true);

    if (
      stripePaymentMethodId === undefined &&
      creditCards !== undefined &&
      creditCards.length > 0
    ) {
      selectNewCard(creditCards[0].stripePaymentMethodId);
    }
  }

  // When we stop rendering the component, clean up the polling
  useEffect(function () {
    return function () {
      if (getCreditCardInterval !== undefined) {
        clearInterval(getCreditCardInterval);
      }
    };
  });

  useOnce(async () => {
    paymentMethodsApi.postGenerateSaveCardIntent().then((response) => {
      setClientSecret(response.clientSecret);
    });

    await getCreditCards();
    setLoading(false);

    const interval = setInterval(getCreditCards, 30000);
    setGetCreditCardInterval(interval);
  });

  function navigateToReview() {
    props.onComplete();
    setTimeout(function () {
      navigate(`/book/${props.nextIdentifier}?shipmentId=${shipmentId}`);
    }, 1000);
  }

  async function onStoreCreditCard() {
    if (!clientSecret) {
      throw new Error("Missing clientSecret");
    }

    setForceValidation(true);

    if (!isValid) {
      return;
    }

    setStoringSuccess(false);
    setStoringFailure(false);
    setStoringInProgress(true);

    const result = await storeCreditCard(clientSecret);

    setStoringInProgress(false);

    if (result.setupIntent && result.setupIntent.paymentMethod) {
      setStoringSuccess(true);
      selectNewCard(result.setupIntent.paymentMethod);
      navigateToReview();
    } else {
      setStoringFailure(true);
      setFailureMessage(result.error?.message);
    }
  }

  if (loading) {
    return (
      <HorizontalStack width="640px" align="center">
        <Stack width="100%" align="center">
          Loading <ProgressSpinner size={ProgressSpinnerSizes.Medium} />
        </Stack>
      </HorizontalStack>
    );
  }

  return (
    <BookShipmentScreenLayout
      pageHeading={`Enter Credit Card Details`}
      pageSubheading={
        <Stack align="left">
          <>{`We will securely store your credit card to make it easier to pay your freight charges. Visa, Mastercard, and American Express are accepted.`}</>
          <Spacer height={8} />
          <LinkButton onClick={() => showShareModal()}>
            Don&apos;t have access to the company credit card? Click here to
            invite someone else
          </LinkButton>
        </Stack>
      }
    >
      <>
        {!showForm && (
          <Stack align="left">
            <Box width={640}>
              <Spacer height={32} />
              <Microcopy>
                Some credit cards have already been added to your account.
                Please select the one you would like to use for this shipment
              </Microcopy>
              <Spacer height={16} />
              <CreditCardsDropdown
                selectedStripePaymentMethodId={stripePaymentMethodId}
                onOptionSelected={selectNewCard}
                cards={creditCards}
              />
              <Spacer height={8} />
              <LinkButton onClick={() => setShowForm(true)}>
                Add a different card?
              </LinkButton>
              <Spacer height={96} />
              <Button
                id="nextButton"
                label="Next"
                size="large"
                onClick={navigateToReview}
              />
            </Box>
          </Stack>
        )}
        {showForm && (
          <Box width={608}>
            <CardEntryForm
              addresses={[pickupAddress, deliveryAddress]}
              billingAddress={billingAddress}
              billingName={billingName}
              setBillingAddress={setBillingAddress}
              setBillingName={setBillingName}
              forceValidation={forceValidation}
              onBrandChange={onBrandChange}
              errorMessageForBillingName={errorMessageForBillingName}
              errorMessageForCreditCardNumber={errorMessageForCreditCardNumber}
              errorMessageForCreditCardExpiration={
                errorMessageForCreditCardExpiration
              }
              errorMessageForCreditCardCVC={errorMessageForCreditCardCVC}
              errorMessageForAddress={errorMessageForAddress}
              errorMessageForCity={errorMessageForCity}
              errorMessageForStateOrProvinceCode={
                errorMessageForStateOrProvinceCode
              }
              errorMessageForPostalCode={errorMessageForPostalCode}
              errorMessageForCountry={errorMessageForCountry}
              onCreditCardCVCError={setErrorMessageForCreditCardCVC}
              onCreditCardExpirationError={
                setErrorMessageForCreditCardExpiration
              }
              onCreditCardNumberError={setErrorMessageForCreditCardNumber}
              onValidate={() => setForceValidation(true)}
            />

            <DynamicHeightSpacer minHeight={8} maxHeight={32} />
            <StoreCreditCardButton
              onClick={onStoreCreditCard}
              storingInProgress={storingInProgress}
              storingSuccess={storingSuccess}
              storingFailure={storingFailure}
              failureMessage={failureMessage}
            />
            <Spacer height={8} />
            <AddCreditCardLegalease />
          </Box>
        )}
      </>
    </BookShipmentScreenLayout>
  );
}

export default BookShipmentPaymentScreen;
