// eslint-disable-next-line no-restricted-imports
import { DateRange, DayPicker } from "react-day-picker";
import { StyledDayPicker } from "./StyledDayPicker";
import moment from "moment";
import styled from "styled-components";
import LinkButton from "../Controls/LinkButton";
import Spacer from "../Layout/Spacer";

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type DateRangePickerBaseProps = {
  startDate: string | undefined;
  endDate: string | undefined;
  onStartDateChange: (date: string | undefined) => void;
  onEndDateChange: (date: string | undefined) => void;
  onSuggestClose?: () => void;
};

export function DateRangePickerBase(props: DateRangePickerBaseProps) {
  const selectedDateRange: DateRange = {
    from: props.startDate ? moment(props.startDate).toDate() : undefined,
    to: props.endDate ? moment(props.endDate).toDate() : undefined,
  };

  const handleClear = () => {
    props.onStartDateChange(undefined);
    props.onEndDateChange(undefined);
    props.onSuggestClose?.();
  };

  return (
    <PickerWrapper>
      <StyledDayPicker>
        <DayPicker
          mode="range"
          selected={selectedDateRange}
          onSelect={function (dateRange) {
            if (!dateRange) {
              props.onStartDateChange(undefined);
              props.onEndDateChange(undefined);
            } else {
              props.onStartDateChange(
                dateRange.from
                  ? moment(dateRange.from).format("YYYY-MM-DD")
                  : undefined,
              );
              props.onEndDateChange(
                dateRange.to
                  ? moment(dateRange.to).format("YYYY-MM-DD")
                  : undefined,
              );
            }
          }}
          numberOfMonths={2}
          formatters={{
            formatCaption: (date: Date) => moment(date).format("MMMM YYYY"),
          }}
          modifiers={{
            weekend: (date: Date) => [6, 0].includes(date.getDay()),
          }}
          modifiersStyles={{
            weekend: {
              backgroundColor: "#f0f0f0",
              color: "var(--freightsimple-color-warning)",
            },
          }}
        />
        {(selectedDateRange.from || selectedDateRange.to) && (
          <>
            <Spacer height={16} />
            <LinkButton onClick={handleClear}>Clear Date Range</LinkButton>
          </>
        )}
      </StyledDayPicker>
    </PickerWrapper>
  );
}
