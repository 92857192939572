import HorizontalStack from "../Layout/HorizontalStack";
import Spacer from "../Layout/Spacer";
import { ErrorMessageType } from "../Validation";
import styled from "styled-components";
import Input from "./Input";

interface CurrencyInputProps {
  id?: string;
  label?: string;
  autoComplete?: string;
  value?: number;
  onChange: (e: number | undefined) => void;
  name?: string;
  enabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  pattern?: string;
  readOnly?: boolean;
  hidden?: boolean;
  width?: number;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
  hideErrorIcon?: boolean;
  labelPadding?: number;
  validateOnTimeoutMs?: number;
  placeholder?: string;
}

const CurrencySymbol = styled.div`
  font-size: 20px;
  color: var(--freightsimple-color-light-text);
  font-weight: var(--nhu-font-weight-bold);
`;

export function CurrencyInput(props: CurrencyInputProps) {
  return (
    <Input
      id={props.id}
      label={props.label}
      autoComplete={props.autoComplete}
      value={props.value?.toString()}
      onChange={(value) => {
        props.onChange(value === "" ? undefined : parseInt(value));
      }}
      name={props.name}
      enabled={props.enabled}
      autoFocus={props.autoFocus}
      required={props.required}
      readOnly={props.readOnly}
      hidden={props.hidden}
      width={props.width}
      type="number"
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      hideErrorIcon={props.hideErrorIcon}
      labelPadding={props.labelPadding}
      placeholder={props.placeholder}
      validateOnTimeoutMs={props.validateOnTimeoutMs}
      leftOutset={<CurrencySymbol>$</CurrencySymbol>}
    />
  );
}

interface MinMaxCurrencyInputProps {
  min: number | undefined;
  max: number | undefined;
  setMin: (newValue: number | undefined) => void;
  setMax: (newValue: number | undefined) => void;
}

export function MinMaxCurrencyInput(props: MinMaxCurrencyInputProps) {
  const { min, setMin, max, setMax } = props;
  return (
    <HorizontalStack>
      <CurrencyInput
        value={min}
        onChange={setMin}
        errorMessage={undefined}
        forceValidation={false}
        width={64}
        label="Min Price"
        placeholder="0"
      />
      <Spacer width={40} />
      <CurrencyInput
        value={max}
        onChange={setMax}
        errorMessage={undefined}
        forceValidation={false}
        width={80}
        label="Max Price"
        placeholder="10,000+"
      />
    </HorizontalStack>
  );
}
