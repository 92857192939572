import { useModal } from "../../../Cargo/Modal/useModal";
import AddCreditCardModal from "../Modals/AddCreditCardModal";

function useAddCreditCardModal(callback: () => void): () => void {
  const showModal = useModal();

  function show() {
    showModal(
      (done) => {
        return (
          <AddCreditCardModal
            onCardAdded={() => {
              done();
            }}
          />
        );
      },
      () => {
        callback();
      },
    );
  }
  return show;
}

export default useAddCreditCardModal;
