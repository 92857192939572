import { useUsersApi } from "../../../apis";
import { Quote } from "@freightsimple/generated-dashboard-openapi-client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { storeCoupon } from "../Slices/couponSlice";

export function useCoupon() {
  const usersApi = useUsersApi();
  const dispatch = useDispatch();
  const couponAmount = useSelector((state: RootState) => state.coupon.amount);

  async function refreshCoupon() {
    const couponResponse = await usersApi.getUsersGetCoupon();

    dispatch(storeCoupon({ amount: couponResponse.amount }));
  }

  function discountQuote(q: Quote): Quote {
    const qCopy = { ...q };
    if (couponAmount !== undefined) {
      const discountFactor = (qCopy.price - couponAmount) / qCopy.price;

      qCopy.price -= couponAmount;
      qCopy.taxAmount = qCopy.taxAmount * discountFactor;
    }

    return qCopy;
  }

  return { couponAmount, discountQuote, refreshCoupon };
}
