import { usePaymentMethodsApi } from "../../apis";
import CreditCardSuccessAnimation from "../../Cargo/Icons/CreditCardSuccessAnimation";
import Logo from "../../Cargo/Icons/Logo";
import ProgressSpinner from "../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../Cargo/Icons/ProgressSpinnerSizes";
import Box from "../../Cargo/Layout/Box";
import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { Heading2, Heading3, Microcopy } from "../../Cargo/Text/Text";
import CardEntryForm from "../BookShipment/Components/CardEntryForm";
import useStoreCreditCard from "./Hooks/useStoreCreditCard";
import { useOnce } from "../../Hooks/useOnce";
import useQuery from "../../Hooks/useQuery";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import AddCreditCardLegalease from "./Components/AddCreditCardLegalease";
import StoreCreditCardButton from "./Components/StoreCreditCardButton";

function ExternalAddCreditCardScreen() {
  const {
    storeCreditCard,
    billingAddress,
    billingName,
    setBillingName,
    setBillingAddress,
    errorMessageForBillingName,
    errorMessageForCreditCardNumber,
    errorMessageForCreditCardExpiration,
    errorMessageForCreditCardCVC,
    setErrorMessageForCreditCardNumber,
    setErrorMessageForCreditCardExpiration,
    setErrorMessageForCreditCardCVC,
    errorMessageForAddress,
    errorMessageForCity,
    errorMessageForStateOrProvinceCode,
    errorMessageForPostalCode,
    errorMessageForCountry,
    isValid,
  } = useStoreCreditCard();

  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  });

  const [storingInProgress, setStoringInProgress] = useState(false);
  const [storingSuccess, setStoringSuccess] = useState(false);
  const [storingFailure, setStoringFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState<string>();
  const [company, setCompany] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [validated, setValidated] = useState(false);

  const paymentMethodsApi = usePaymentMethodsApi();

  const query = useQuery();

  const [loading, setLoading] = useState(true);

  useOnce(async () => {
    const saveCardIntentResponse =
      await paymentMethodsApi.getPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntent(
        {
          addCreditCardAuthorizationId: query.id as string,
        },
      );
    setCompany(saveCardIntentResponse.companyName);
    setClientSecret(saveCardIntentResponse.clientSecret);
    setLoading(false);
  });

  async function onStoreCreditCard() {
    setValidated(true);

    if (!isValid) {
      return;
    }

    setStoringSuccess(false);
    setStoringFailure(false);
    setStoringInProgress(true);

    const result = await storeCreditCard(clientSecret);

    setStoringInProgress(false);

    if (result.setupIntent) {
      setStoringSuccess(true);
    } else {
      setStoringFailure(true);
      setFailureMessage(result.error.message);
    }
  }

  const formVisible = !storingInProgress && !storingSuccess;

  if (loading) {
    return <></>;
  }

  return (
    <HorizontalStack align="center" width="100%">
      <Stack align="center">
        <Spacer height={32} />
        <Logo />
        <Spacer height={32} />
        {storingInProgress && (
          <>
            <Heading3>Securely storing credit card information</Heading3>
            <ProgressSpinner size={ProgressSpinnerSizes.Medium} />
          </>
        )}
        {storingSuccess && (
          <>
            <Heading3>Credit Card Securely Stored!</Heading3>

            <CreditCardSuccessAnimation />
            <Spacer height={64} />
            <Microcopy>
              Your colleague can now book shipments on FreightSimple. You can
              now close this tab.
            </Microcopy>
          </>
        )}
        <div style={{ display: formVisible ? "block" : "none" }}>
          <Heading2>Enter Credit Card Details</Heading2>
          <Microcopy>
            Someone has requested that you provide credit card details for{" "}
            <strong>{company}</strong>.
          </Microcopy>
          <Microcopy>
            Payment information is required in order to book the shipment.
          </Microcopy>
          <Spacer height={32} />
          <Box width={608}>
            <CardEntryForm
              addresses={[]}
              billingAddress={billingAddress}
              billingName={billingName}
              setBillingAddress={setBillingAddress}
              setBillingName={setBillingName}
              forceValidation={validated}
              errorMessageForBillingName={errorMessageForBillingName}
              errorMessageForCreditCardNumber={errorMessageForCreditCardNumber}
              errorMessageForCreditCardExpiration={
                errorMessageForCreditCardExpiration
              }
              errorMessageForCreditCardCVC={errorMessageForCreditCardCVC}
              errorMessageForAddress={errorMessageForAddress}
              errorMessageForCity={errorMessageForCity}
              errorMessageForStateOrProvinceCode={
                errorMessageForStateOrProvinceCode
              }
              errorMessageForPostalCode={errorMessageForPostalCode}
              errorMessageForCountry={errorMessageForCountry}
              onCreditCardCVCError={setErrorMessageForCreditCardCVC}
              onCreditCardExpirationError={
                setErrorMessageForCreditCardExpiration
              }
              onCreditCardNumberError={setErrorMessageForCreditCardNumber}
              onValidate={() => setValidated(true)}
            />
            <Spacer height={32} />
            <StoreCreditCardButton
              onClick={onStoreCreditCard}
              storingInProgress={storingInProgress}
              storingSuccess={storingSuccess}
              storingFailure={storingFailure}
              failureMessage={failureMessage}
            />
            <Spacer height={8} />
            <AddCreditCardLegalease />
          </Box>
        </div>
      </Stack>
    </HorizontalStack>
  );
}
export default ExternalAddCreditCardScreen;
