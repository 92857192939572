import Button from "../../Cargo/Controls/Button";
import Input from "../../Cargo/Controls/Input";
import Box from "../../Cargo/Layout/Box";
import { Centered, CenteredContainer } from "../../Cargo/Layout/Container";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import { Heading3, Microcopy } from "../../Cargo/Text/Text";
import { ErrorMessageType } from "../../Cargo/Validation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTrackingService } from "../../Services/TrackingService";

export function LookUpTrackingScreen() {
  const trackingService = useTrackingService();
  const [proNumber, setProNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function onLookup() {
    setLoading(true);
    const trackingId =
      await trackingService.getTrackingIdForProNumber(proNumber);

    if (trackingId === undefined) {
      setErrorMessage("Sorry, this Pro Number was not found");
    } else {
      navigate(`t?tId=${trackingId}`);
    }
    setLoading(false);
  }

  return (
    <CenteredContainer>
      <Centered>
        <Box>
          <Stack style={{ padding: "32px" }}>
            <Heading3>Look up tracking by Pro Number</Heading3>
            <Microcopy>
              If you&apos;ve been given a FreightSimple Pro Number, you can
              enter it here to get tracking details
            </Microcopy>
            <Spacer height={32} />
            <Input
              type="text"
              placeholder="Enter Pro Number"
              width={240}
              autoComplete={undefined}
              autoFocus={true}
              onChange={setProNumber}
              value={proNumber}
              errorMessage={errorMessage}
              forceValidation={true}
              onKeyDown={function (e) {
                if (e.code === "Enter") {
                  onLookup();
                }
              }}
            />
            <Spacer height={16} />
            <Button
              onClick={onLookup}
              disabled={proNumber.length < 6}
              loading={loading}
            >
              Lookup
            </Button>
          </Stack>
        </Box>
      </Centered>
    </CenteredContainer>
  );
}
