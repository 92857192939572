import { CircleButton } from "../../Cargo/Controls/CircleButton";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import Spacer from "../../Cargo/Layout/Spacer";
import { Heading2 } from "../../Cargo/Text/Text";
import { Invitation } from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../Hooks/useOnce";
import { useState } from "react";
import { useUserService } from "../../Services/UserService";
import InvitationsSection from "./Components/InvitationsSection";
import UsersInCompanySection from "./Components/UsersInCompanySection";
import { useInviteModal } from "./Modals/useInviteModal";

function ViewCompanyScreen() {
  const showModal = useInviteModal();
  const service = useUserService();
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  async function loadInvitations() {
    const _invitations = await service.listInvitationsForCompany();
    setInvitations(_invitations);
  }

  function onInvite() {
    showModal(function () {
      loadInvitations();
    });
  }

  useOnce(function () {
    loadInvitations();
  });

  return (
    <>
      <FullWidthLayout
        header={
          <>
            Manage Users
            <span
              style={{
                position: "relative",
                top: "-3px",
                marginLeft: "6px",
              }}
            >
              <CircleButton onClick={onInvite} id="invite_user" />
            </span>
          </>
        }
        microcopy="Invite colleagues and view who has access to this account"
        content={
          <>
            <Spacer height={16} />
            {invitations.length > 0 && (
              <>
                <InvitationsSection
                  invitations={invitations}
                  loadInvitations={loadInvitations}
                  onInvite={onInvite}
                />
                <Spacer height={32} />
                <Heading2>Existing Users</Heading2>
                <Spacer height={16} />
              </>
            )}
            <UsersInCompanySection />
          </>
        }
      />
    </>
  );
}

export default ViewCompanyScreen;
