import LinkButton from "../../../Cargo/Controls/LinkButton";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-dashboard-openapi-client";
import styled from "styled-components";

interface AdditionalOptionsProps {
  onLiveChat: () => void;
  onChangePickupDate: () => void;
  onCancelShipment: () => void;
  onSubmitClaim: () => void;
  onRepeatShipment: () => void;
  onModifyShipment: () => void;
  onHelpCenter: () => void;

  shipment: Shipment;
}

const AdditionalOptionsLabel = styled.div`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 16px;
  color: var(--freightsimple-color-light-text);
`;

function shouldDisableChangePickupDate(shipment: Shipment): boolean {
  switch (shipment.shipmentState) {
    case ShipmentState.Quoted:
    case ShipmentState.QuoteRequested:
    case ShipmentState.BookingConfirmed:
    case ShipmentState.BookingRequested:
    case ShipmentState.BookingFailed:
    case ShipmentState.OnHold:
      return false;
    case ShipmentState.Cancelled:
    case ShipmentState.Lost:
    case ShipmentState.Delivered:
    case ShipmentState.InTransit:
      return true;
  }
}

function shouldDisableCancelShipment(shipment: Shipment): boolean {
  switch (shipment.shipmentState) {
    case ShipmentState.Quoted:
    case ShipmentState.QuoteRequested:
    case ShipmentState.BookingConfirmed:
    case ShipmentState.BookingRequested:
    case ShipmentState.BookingFailed:
    case ShipmentState.OnHold:
      return false;
    case ShipmentState.Cancelled:
    case ShipmentState.Lost:
    case ShipmentState.Delivered:
    case ShipmentState.InTransit:
      return true;
  }
}

function shouldDisableSubmitClaim(shipment: Shipment): boolean {
  switch (shipment.shipmentState) {
    // Maybe something is lost in transit, or is being returned
    case ShipmentState.Delivered:
    case ShipmentState.InTransit:
    case ShipmentState.Lost:
      return false;
    case ShipmentState.Quoted:
    case ShipmentState.QuoteRequested:
    case ShipmentState.BookingConfirmed:
    case ShipmentState.BookingRequested:
    case ShipmentState.BookingFailed:
    case ShipmentState.Cancelled:
    case ShipmentState.OnHold:
      return true;
  }
}

function shouldDisableModifyShipment(shipment: Shipment): boolean {
  switch (shipment.shipmentState) {
    case ShipmentState.BookingConfirmed:
    case ShipmentState.InTransit:
    case ShipmentState.OnHold:
      return false;
    case ShipmentState.Quoted:
    case ShipmentState.QuoteRequested:
    case ShipmentState.BookingRequested:
    case ShipmentState.BookingFailed:
    case ShipmentState.Cancelled:
    case ShipmentState.Lost:
    case ShipmentState.Delivered:
      return true;
  }
}

function AdditionalOptions(props: AdditionalOptionsProps) {
  const disabledChangePickupDate = shouldDisableChangePickupDate(
    props.shipment,
  );
  const disabledCancelShipment = shouldDisableCancelShipment(props.shipment);
  const disabledSubmitClaim = shouldDisableSubmitClaim(props.shipment);
  const disabledReconsignShipment = shouldDisableModifyShipment(props.shipment);

  return (
    <Stack align="left">
      <AdditionalOptionsLabel>Additional Options</AdditionalOptionsLabel>
      <Spacer height={16} />
      <HorizontalStack align="left">
        <LinkButton onClick={props.onLiveChat}>
          Live Chat &amp; Support
        </LinkButton>
        <Spacer width={32} />
        <LinkButton
          onClick={props.onChangePickupDate}
          disabled={disabledChangePickupDate}
          disabledReason="Cannot change pickup date after shipment has been picked up."
        >
          Change Pickup Date / Hours
        </LinkButton>
        <Spacer width={32} />
        <LinkButton
          onClick={props.onCancelShipment}
          disabled={disabledCancelShipment}
          disabledReason="Cannot cancel shipment after pickup"
        >
          Cancel Shipment
        </LinkButton>
        <Spacer width={32} />
        <LinkButton
          onClick={props.onSubmitClaim}
          disabled={disabledSubmitClaim}
          disabledReason="Cannot submit a claim until delivery has been confirmed"
        >
          Submit Claim
        </LinkButton>
        <Spacer width={32} />
        <LinkButton onClick={props.onRepeatShipment}>
          Repeat Shipment
        </LinkButton>
        <Spacer width={32} />
        <LinkButton
          onClick={props.onModifyShipment}
          disabled={disabledReconsignShipment}
          disabledReason="Shipments can only be modified prior to pickup or in-transit"
        >
          Modify Shipment
        </LinkButton>
        <Spacer width={32} />
        <LinkButton onClick={props.onHelpCenter}>Help Center</LinkButton>
      </HorizontalStack>
    </Stack>
  );
}
export default AdditionalOptions;
