import Colors from "../../../../../Cargo/Colors";
import Input from "../../../../../Cargo/Controls/Input";
import Icon from "../../../../../Cargo/Icons/Icon";
import Box from "../../../../../Cargo/Layout/Box";
import HorizontalStack from "../../../../../Cargo/Layout/HorizontalStack";
import Stack from "../../../../../Cargo/Layout/Stack";
import dangerousGoodsData from "../../../Data/DangerousGoods.json";
import { describeHazardClass } from "../../../Helpers/describeHazardClass";
import FuzzySearch from "fuzzy-search";
import { useEffect, useState } from "react";
import styled from "styled-components";

const SearchResultContainer = styled.div`
  height: 406px;
  overflow-y: scroll;
`;

interface LookupFromDescriptionTabProps {
  initial: string;
  forceValidation: boolean;
  onLookup: (
    _dangerousUnNumber: string,
    _dangerousClassification: string,
    _dangerousDescription: string,
    _dangerousPackingGroup: string | undefined,
  ) => void;

  onResetForceValidation: () => void;
}

interface DangerGoodsDataItem {
  d: string;
  c: string;
  un: string;
  psn: string; // Proper Shipping Name
  p?: string;
}

interface SearchResultRowProps {
  unNumber: string;
  hazardClass: string;
  packingGroup: string | undefined;
  description: string;
  isSelected: boolean;
  onSelect: () => void;
}

interface SearchResultRowBodyProps {
  isSelected: boolean;
}

const SearchResultRowDescription = styled.div<SearchResultRowBodyProps>`
  font-weight: var(--nhu-font-weight-medium);
  color: ${(props) => (props.isSelected ? Colors.White : Colors.NormalText)};
`;

const SearchResultRowSecondary = styled.div<SearchResultRowBodyProps>`
  font-weight: var(--nhu-font-weight-light);
  color: ${(props) =>
    props.isSelected ? Colors.VeryLightGray : Colors.LightText};
`;

const SearchResultRowBody = styled.div<SearchResultRowBodyProps>`
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: ${(props) =>
      props.isSelected ? Colors.Blue : Colors.VeryVeryLightBlue};
  }

  ${(props) => (props.isSelected ? `background-color: ${Colors.Blue};` : "")}
`;

function SearchResultRow(props: SearchResultRowProps) {
  return (
    <SearchResultRowBody onClick={props.onSelect} isSelected={props.isSelected}>
      <HorizontalStack verticalAlign="middle" align="spread">
        <Stack align="left">
          <SearchResultRowDescription isSelected={props.isSelected}>
            {props.description}
          </SearchResultRowDescription>
          <SearchResultRowSecondary isSelected={props.isSelected}>
            {props.unNumber}, {describeHazardClass(props.hazardClass)}
            {props.packingGroup && `, Packing Group ${props.packingGroup}`}
          </SearchResultRowSecondary>
        </Stack>
        <div style={{ width: "32px" }}>
          {props.isSelected && (
            <Icon
              name="check-circle"
              color={Colors.Green}
              size={16}
              solid
              style={{ marginLeft: "8px" }}
            />
          )}
        </div>
      </HorizontalStack>
    </SearchResultRowBody>
  );
}

export function LookupTab(props: LookupFromDescriptionTabProps) {
  const { forceValidation } = props;
  const [search, setSearch] = useState(props.initial);
  const [searchResults, setSearchResults] = useState<
    Array<DangerGoodsDataItem>
  >([]);
  const [selectedDescription, setSelectedDescription] = useState<
    string | undefined
  >();
  const [hazardClass, setHazardClass] = useState<string | undefined>();
  const [unNumber, setUnNumber] = useState<undefined | string>();

  function errorMessageForSearch() {
    if (search === "") {
      return `Enter search term`;
    }

    if (unNumber === undefined) {
      return `Select an item from the list`;
    }

    if (hazardClass === "F") {
      return "These goods are forbidden for transport. Unfortunately you cannot use FreightSimple to move them.";
    }

    return undefined;
  }

  const searcher = new FuzzySearch(dangerousGoodsData, ["d", "un"], {
    sort: true,
  });

  function onSearch(_search: string) {
    if (_search === "") {
      setSearch("");
      setSearchResults([]);
      props.onResetForceValidation();
    } else {
      const result = searcher.search(_search);
      setSearch(_search);
      setSearchResults(result.slice(0, 100));
      props.onResetForceValidation();
    }
  }

  useEffect(
    function () {
      if (search !== "") {
        onSearch(search);
      }
    },

    [props.initial],
  );

  return (
    <>
      <Box width={800} style={{ height: "510px" }}>
        <Input
          label="Search"
          value={search}
          onChange={onSearch}
          placeholder="Enter 'UN Number' or search by name"
          type="text"
          forceValidation={forceValidation}
          errorMessage={errorMessageForSearch()}
          doNotDoTimeBasedValidation
          doNotValidateOnBlur
          name="lookupUnNumber"
        />

        <SearchResultContainer>
          {searchResults.map(function (r, index) {
            return (
              <SearchResultRow
                key={index}
                unNumber={r.un}
                hazardClass={r.c}
                description={r.d}
                packingGroup={r.p}
                isSelected={r.un === unNumber && r.d === selectedDescription}
                onSelect={function () {
                  setUnNumber(r.un);
                  setHazardClass(r.c);
                  setSelectedDescription(r.d);
                  props.onLookup(r.un, r.c, r.psn, r.p);
                }}
              />
            );
          })}
        </SearchResultContainer>
      </Box>
    </>
  );
}
