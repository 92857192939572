import Colors from "../../../Cargo/Colors";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import { formatPhoneNumberWithExtension } from "../../../Cargo/Controls/formatPhoneNumberWithExtension";
import Stack from "../../../Cargo/Layout/Stack";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "../../../Cargo/Layout/Table";
import { useOnce } from "../../../Hooks/useOnce";
import { useUserService } from "../../../Services/UserService";
import { User } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import { useState } from "react";
import { useChangeBranchModal } from "../Modals/useChangeBranchModal";
import { useManageUserModal } from "./useManageUserModal";

interface BranchCellProps {
  user: User;
  reloadUsers: () => void;
}

function BranchCell(props: BranchCellProps) {
  const showModal = useChangeBranchModal(props.user);
  const branchName = props.user.branchName;

  function onChangeBranch() {
    showModal(function () {
      props.reloadUsers();
    });
  }

  return (
    <div>
      <Stack align="left">
        {branchName === undefined && (
          <div style={{ color: Colors.LightText }}>Not assigned to branch</div>
        )}
        {branchName !== undefined && <div>{branchName}</div>}
        <LinkButton regularWeight onClick={onChangeBranch}>
          Change
        </LinkButton>
      </Stack>
    </div>
  );
}

interface UsersInCompanySectionRowProps {
  user: User;
  onRefresh: () => Promise<void>;
}

function UsersInCompanySectionRow(props: UsersInCompanySectionRowProps) {
  const u = props.user;
  const onRefresh = props.onRefresh;

  const showModal = useManageUserModal(u);

  function formatDate(date: Date): string {
    if (moment(date).diff(moment.now(), "days") < 7) {
      return moment(date).fromNow();
    } else {
      return moment(date).format("dddd Do MMMM YYYY h:mma z");
    }
  }

  async function onManageUser() {
    await showModal();

    await onRefresh();
  }

  return (
    <TableRow key={u.userId}>
      <TableCell>{u.email}</TableCell>
      <TableCell>{u.name}</TableCell>
      <TableCell>
        {formatPhoneNumberWithExtension(u.phone, u.phoneExtension)}
      </TableCell>
      <TableCell>
        <BranchCell user={u} reloadUsers={onRefresh} />
      </TableCell>

      <TableCell>{formatDate(u.lastLoginAt)}</TableCell>
      <TableCell>
        <LinkButton onClick={onManageUser}>Manage User</LinkButton>
      </TableCell>
    </TableRow>
  );
}

function UsersInCompanySection() {
  const service = useUserService();
  const [users, setUsers] = useState<undefined | User[]>();

  async function onRefresh() {
    const _users = await service.listUsersForCompany();
    setUsers(_users);
  }

  useOnce(onRefresh);

  if (!users) {
    return <></>;
  }

  return (
    <>
      <Table>
        <TableHeaderRow>
          <TableHeaderCell style={{ width: "280px" }}>Email</TableHeaderCell>
          <TableHeaderCell style={{ width: "120px" }}>Name</TableHeaderCell>
          <TableHeaderCell style={{ width: "120px" }}>Phone</TableHeaderCell>
          <TableHeaderCell style={{ width: "180px" }}>
            Branch Location
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "120px" }}>
            Last Signin
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "120px" }}>Actions</TableHeaderCell>
        </TableHeaderRow>
        {users.map((u) => {
          return (
            <UsersInCompanySectionRow
              key={u.userId}
              user={u}
              onRefresh={onRefresh}
            />
          );
        })}
      </Table>
    </>
  );
}
export default UsersInCompanySection;
