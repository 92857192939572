// Note, there is code higher up that filters out SingleShipment, Personal and Logistic companies from this
export function calculateConversionValueFromShipmentFrequency(
    shipmentFrequency: string | undefined,
  ): number {
    switch (shipmentFrequency) {
      case "Daily":
        return 1000.0;
      case "Weekly":
        return 200.0;
      case "Occasional":
        return 25.0;
      default:
        return 25.0;
    }
  }