export function sumOf<ItemType>(items: ItemType[], f: (_: ItemType) => number) {
  let total = 0;

  items.forEach(function (item) {
    const value = f(item);
    total += value;
  });

  return total;
}
