import { DocumentType } from "@freightsimple/generated-dashboard-openapi-client";

export function describeDocumentType(documentType: DocumentType): string {
  switch (documentType) {
    case DocumentType.BillOfLading:
      return "Bill Of Lading";
    case DocumentType.CargoClaimsForm:
      return "Cargo Claims Form";
    case DocumentType.CarrierScannedBillOfLading:
      return "Carrier Scanned Bill Of Lading";
    case DocumentType.CarrierWeightReport:
      return "Carrier Weight Report";
    case DocumentType.CertificateOfOrigin:
      return "Certificate Of Origin";
    case DocumentType.CommercialInvoice:
      return "Commercial Invoice";
    case DocumentType.DangerousGoodsForm:
      return "Dangerous Goods Form";
    case DocumentType.ExportLicense:
      return "Export License";
    case DocumentType.ShipperPhoto:
      return "Pickup Photo";
    case DocumentType.InsuranceCertificate:
      return "Insurance Certificate";
    case DocumentType.PackingList:
      return "Packing List";
    case DocumentType.ProofOfDelivery:
      return "Proof Of Delivery";
    case DocumentType.PurchaseOrder:
      return "Purchase Order";
    case DocumentType.CustomsDocument:
      return "Customs Document";
    case DocumentType.PickupPackage:
      return "Pickup Package";
    case DocumentType.Image:
      return "Image";
    case DocumentType.Unknown:
      return "Unknown";
    case DocumentType.ClaimsOriginalInvoice:
      return "Claim - Original Invoice";
    case DocumentType.ClaimsPhoto:
      return "Claim - Photo";
    case DocumentType.ClaimsOtherDocument:
      return "Claim - Other Document";
    case DocumentType.InterlineBillOfLading:
      return "Interline Bill of Lading";
    case DocumentType.EmailAttachment:
      return "Email Attachment";
    case DocumentType.CarrierEmailAttachment:
      return "Email Attachment";
    case DocumentType.CustomerEmailAttachment:
      return "Email Attachment";
    case DocumentType.SentEmailAttachment:
      return "Email Attachment";
    case DocumentType.GoogleMapsScreenshot:
      return "Google Maps Screenshot";
    case DocumentType.LoadingDiagram:
      return "Loading Diagram";
  }
}
