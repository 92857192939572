import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import { MinMaxCurrencyInput } from "../../../Cargo/Controls/CurrencyInput";
import Input from "../../../Cargo/Controls/Input";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading2 } from "../../../Cargo/Text/Text";
import { ShipmentState } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import styled from "styled-components";
import CarriersDropdown from "../Components/CarriersDropdown";
import ShipmentStatesDropdown from "../Components/ShipmentStatesDropdown";
import StateProvinceDropdown from "../Components/StateProvinceDropdown";
import { Microcopy } from "../../../Cargo/Text/Text";
import Switch from "../../../Cargo/Controls/Switch";
import { DateRangePickerDropdown } from "../../../Cargo/Dates/DateRangePickerDropdown";

export interface FilterShipmentsDescription {
  proNumber?: string;
  carrierIdentifier?: string;
  minPriceDollars?: number;
  maxPriceDollars?: number;
  shipmentState?: ShipmentState;
  pickupReferenceNumber?: string;
  pickupBusinessName?: string;
  pickupPostalCode?: string;
  pickupStateOrProvinceCode?: string;
  deliveryReferenceNumber?: string;
  deliveryBusinessName?: string;
  deliveryPostalCode?: string;
  deliveryStateOrProvinceCode?: string;
  bookedAtStartRange?: string;
  bookedAtEndRange?: string;
  pickupDateStartRange?: string;
  pickupDateEndRange?: string;
  deliveredAtStartRange?: string;
  deliveredAtEndRange?: string;
  onlyShowFreightClaims?: boolean;
}

interface FilterShipmentsModalProps {
  onConfirm: (filterShipmentDescription: FilterShipmentsDescription) => void;
  onCancel: () => void;
  filterDescription: FilterShipmentsDescription;
}

interface PriceRowProps {
  minPrice: number | undefined;
  maxPrice: number | undefined;
  onMinPriceChange: (newValue: number | undefined) => void;
  onMaxPriceChange: (newValue: number | undefined) => void;
}

function PriceRow(props: PriceRowProps) {
  const { minPrice, maxPrice, onMaxPriceChange, onMinPriceChange } = props;
  return (
    <MinMaxCurrencyInput
      min={minPrice}
      max={maxPrice}
      setMin={onMinPriceChange}
      setMax={onMaxPriceChange}
    />
  );
}

interface FilterRowProps {
  title: string;
  value: string | undefined;
  placeholder: string;
  onChange: (newValue: string | undefined) => void;
}

function FilterRow(props: FilterRowProps) {
  return (
    <Input
      type="text"
      width={200}
      value={props.value}
      placeholder={props.placeholder}
      label={props.title}
      onChange={function (newValue: string) {
        props.onChange(newValue === "" ? undefined : newValue);
      }}
    />
  );
}

interface CarrierRowProps {
  carrierIdentifier: string | undefined;
  onCarrierIdentifierChanged: (newCarrierIdentfier: string | undefined) => void;
}

function CarrierRow(props: CarrierRowProps) {
  return (
    <>
      <CarriersDropdown
        carrierIdentifier={props.carrierIdentifier}
        onCarrierIdentifierChanged={props.onCarrierIdentifierChanged}
      />
      <Spacer height={16} />
    </>
  );
}

interface ShipmentStatesRowProps {
  shipmentState: ShipmentState | undefined;
  onShipmentStateChanged: (newShipmentState: ShipmentState | undefined) => void;
}

function ShipmentStatesRow(props: ShipmentStatesRowProps) {
  return (
    <>
      <ShipmentStatesDropdown
        shipmentState={props.shipmentState}
        onShipmentStateChange={props.onShipmentStateChanged}
      />
      <Spacer height={16} />
    </>
  );
}

interface FreightClaimsRow {
  onlyShowClaims: boolean;
  onHasClaimChange: (value: boolean) => void;
}

function FreightClaimsRow(props: FreightClaimsRow) {
  return (
    <Stack>
      <HorizontalStack width="200px" align="spread">
        <div
          style={{
            color: !props.onlyShowClaims ? Colors.LightText : "unset",
          }}
        >
          Only Shipments <br />
          with Freight Claims
        </div>
        <Switch on={props.onlyShowClaims} onChange={props.onHasClaimChange} />
      </HorizontalStack>
    </Stack>
  );
}

interface DatesRowProps {
  fromText: string;
  toText: string;
  startDate: undefined | string;
  endDate: undefined | string;
  setStartDate: (newValue: undefined | string) => void;
  setEndDate: (newValue: undefined | string) => void;
}

function DatesRow(props: DatesRowProps) {
  const { fromText, toText, startDate, endDate, setStartDate, setEndDate } =
    props;
  return (
    <>
      <DateRangePickerDropdown
        fromText={fromText}
        toText={toText}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      <Spacer height={16} />
    </>
  );
}

interface StateProvinceRowProps {
  stateOrProvinceCode: string | undefined;
  onStateOrProvinceCodeChange: (newValue: string | undefined) => void;
  label: string;
}

function StateProvinceRow(props: StateProvinceRowProps) {
  const { stateOrProvinceCode, onStateOrProvinceCodeChange, label } = props;
  return (
    <>
      <StateProvinceDropdown
        stateOrProvinceCode={stateOrProvinceCode}
        onStateOrProvinceCodeChange={onStateOrProvinceCodeChange}
        label={label}
      />
      <Spacer height={16} />
    </>
  );
}

const SectionHeader = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 16px;
  color: var(--freightsimple-color-normal-text);
  margin-bottom: 16px;
`;

export function FilterShipmentsModal(props: FilterShipmentsModalProps) {
  const [filterShipmentsDescripton, setFilterShipmentsDescription] = useState(
    props.filterDescription,
  );

  return (
    <Stack align="left" style={{ padding: "16px", width: "1024px" }}>
      <div
        style={{
          width: "100%",
          paddingBottom: "16px",
          borderBottom: `1px solid ${Colors.VeryVeryLightBlue}`,
        }}
      >
        <Heading2>Filter Shipments</Heading2>
        <Microcopy>
          Select filters to narrow down the range of shipments you are viewing
        </Microcopy>
      </div>
      <Spacer height={16} />

      <HorizontalStack verticalAlign="top" width="100%">
        <Stack width="25%" align="left">
          <SectionHeader>General Info</SectionHeader>

          <FilterRow
            title="PRO Number"
            value={filterShipmentsDescripton.proNumber || ""}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                proNumber: newValue,
              }));
            }}
          />
          <CarrierRow
            carrierIdentifier={filterShipmentsDescripton.carrierIdentifier}
            onCarrierIdentifierChanged={function (newCarrierIdentfier) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                carrierIdentifier: newCarrierIdentfier,
              }));
            }}
          />
          <PriceRow
            minPrice={filterShipmentsDescripton.minPriceDollars}
            maxPrice={filterShipmentsDescripton.maxPriceDollars}
            onMinPriceChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                minPriceDollars: newValue,
              }));
            }}
            onMaxPriceChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                maxPriceDollars: newValue,
              }));
            }}
          />
          <ShipmentStatesRow
            shipmentState={filterShipmentsDescripton.shipmentState}
            onShipmentStateChanged={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                shipmentState: newValue,
              }));
            }}
          />
          <FreightClaimsRow
            onHasClaimChange={(value) => {
              setFilterShipmentsDescription((prev) => ({
                ...prev,
                onlyShowFreightClaims: value === true ? true : undefined,
              }));
            }}
            onlyShowClaims={
              filterShipmentsDescripton.onlyShowFreightClaims ?? false
            }
          />
        </Stack>
        <Stack width="25%" align="left">
          <SectionHeader>Dates</SectionHeader>
          <DatesRow
            fromText="Booked From"
            toText="Booked To"
            startDate={filterShipmentsDescripton.bookedAtStartRange}
            endDate={filterShipmentsDescripton.bookedAtEndRange}
            setStartDate={function (newDate) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                bookedAtStartRange: newDate,
              }));
            }}
            setEndDate={function (newDate) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                bookedAtEndRange: newDate,
              }));
            }}
          />
          <DatesRow
            fromText="Pickup From"
            toText="Pickup To"
            startDate={filterShipmentsDescripton.pickupDateStartRange}
            endDate={filterShipmentsDescripton.pickupDateEndRange}
            setStartDate={function (newDate) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                pickupDateStartRange: newDate,
              }));
            }}
            setEndDate={function (newDate) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                pickupDateEndRange: newDate,
              }));
            }}
          />
          <DatesRow
            fromText="Delivered From"
            toText="Delivered To"
            startDate={filterShipmentsDescripton.deliveredAtStartRange}
            endDate={filterShipmentsDescripton.deliveredAtEndRange}
            setStartDate={function (newDate) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                deliveredAtStartRange: newDate,
              }));
            }}
            setEndDate={function (newDate) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                deliveredAtEndRange: newDate,
              }));
            }}
          />
        </Stack>
        <Stack width="25%" align="left">
          <SectionHeader>Pickup</SectionHeader>
          <FilterRow
            title="Pickup Reference Number"
            value={filterShipmentsDescripton.pickupReferenceNumber}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                pickupReferenceNumber: newValue,
              }));
            }}
          />
          <FilterRow
            title="Pickup Business Name"
            value={filterShipmentsDescripton.pickupBusinessName}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                pickupBusinessName: newValue,
              }));
            }}
          />
          <FilterRow
            title="Pickup Postal Code"
            value={filterShipmentsDescripton.pickupPostalCode}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                pickupPostalCode: newValue,
              }));
            }}
          />

          <StateProvinceRow
            label="Pickup State / Province"
            stateOrProvinceCode={
              filterShipmentsDescripton.pickupStateOrProvinceCode
            }
            onStateOrProvinceCodeChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                pickupStateOrProvinceCode: newValue,
              }));
            }}
          />
        </Stack>
        <Stack width="25%" align="left">
          <SectionHeader>Delivery</SectionHeader>
          <FilterRow
            title="Delivery Reference Number"
            value={filterShipmentsDescripton.deliveryReferenceNumber}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                deliveryReferenceNumber: newValue,
              }));
            }}
          />
          <FilterRow
            title="Delivery Business Name"
            value={filterShipmentsDescripton.deliveryBusinessName}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                deliveryBusinessName: newValue,
              }));
            }}
          />
          <FilterRow
            title="Delivery Postal Code"
            value={filterShipmentsDescripton.deliveryPostalCode}
            placeholder=""
            onChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                deliveryPostalCode: newValue,
              }));
            }}
          />
          <StateProvinceRow
            label="Delivery State / Province"
            stateOrProvinceCode={
              filterShipmentsDescripton.deliveryStateOrProvinceCode
            }
            onStateOrProvinceCodeChange={function (newValue) {
              setFilterShipmentsDescription((filterShipmentsDescripton) => ({
                ...filterShipmentsDescripton,
                deliveryStateOrProvinceCode: newValue,
              }));
            }}
          />
        </Stack>
      </HorizontalStack>
      <Spacer height={100} />
      <HorizontalStack width="100%" align="right">
        <Button secondary onClick={props.onCancel}>
          Cancel
        </Button>
        <Spacer width={16} />
        <Button
          onClick={function () {
            props.onConfirm(filterShipmentsDescripton);
          }}
        >
          Confirm
        </Button>
      </HorizontalStack>
      <Spacer height={16} />
    </Stack>
  );
}
