import { Helmet } from "react-helmet";

interface PageTitleProps {
  children: string;
}

const PageTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
  return (
    <Helmet>
      <title>freightsimple: {props.children}</title>
    </Helmet>
  );
};
export default PageTitle;
